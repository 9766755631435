/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState,useEffect } from "react";
import CloseIcon from "../../../../assets/img/close.svg";
import EditIcon from "../../../../assets/img/edit.svg";
import DeleteIcon2 from "../../../../assets/img/delete1.svg";
import ReactSelect from "react-select";
import { Cascader } from "antd";
import { fieldOptions, CustomfieldOptions, filinigStatusData, americanStatesData, expensesData, countriesData } from "./StaticData";
import { ColorPicker } from 'antd';



const generateUniqueId = () => {
    const timestamp = Date.now().toString(36);
    const randomString = Math.random().toString(36).substring(2, 15);
    const randomSegment = Math.random().toString(36).substring(2, 15);
    return timestamp + randomString + randomSegment;
  };
  


const AddNewQuestion = ({
    setCustomModalOpen,
    setTaxForm,
    taxForm,
    activeSectionId,
    activeSubSectionId,
    setAddOptionModalOpen,
    customDropdownOptions,
    setCustomDropdownOptions,
    setAddCardColumnModalOpen,
    CardFields,
    setCardFields,
    setAddCardDropdownOptionModalOpen,
    setAddGridDropdownOptionModalOpen,
    gridData,
    setAddGridColumnModalOpen,
    setGridData,
    setAddGridRowModalOpen,
    editmode,
    questionToEdit,
    moreOptions,
    setMoreOptions,
    setEditCardColumnTitleModalOpen,
    setEditGridColumnTitleModalOpen,
    setEditGridRowTitleModalOpen,
    listFields,
    setListFields,
    setAddListColumnModalOpen,
    setEditListColumnTitleModalOpen,
    setAddListDropdownOptionModalOpen
  }) => {
    const [fieldType, setFieldType] = useState("");
    const [questionText, setQuestionText] = useState("");
    const [required,setRequired] = useState(true)
    const [allowMultipleCards,setAllowMultipleCards] = useState(false)
    const [showTitle,setShowTitle] = useState(true)
    const [conditionQuestion, setConditionQuestion] = useState('')
    const [conditionType,setConditionType] = useState('')
    const [condition, setCondition] = useState('')
    const [validation,setValidation] = useState('')
    const [maxLength,setMaxLength] = useState(null)
    const [color, setColor] = useState('#090a09');
    const [fontSize, setFontSize] = useState('17');
    const [fontWeight,setFontWeight]  =  useState('')
    const [fontStyle,setFontStyle] = useState('')
    const [textPosition,setTextPosition] = useState('')
    const [conditionQuestionType,setConditionQuestionType] = useState('')
    const [conditionFieldAnswers,setConditionFieldAnswers] = useState([])
    const [corporation,setCorporation] = useState([])


    
    const handleChange = (selected) => {
      const field = selected?.length > 1 ? selected[1] : selected[0];
      setFieldType(field);
    };
  
    useEffect(() => {
      if (editmode) {
        setFieldType(questionToEdit?.type)
        setQuestionText(questionToEdit?.question);
        setFieldType(questionToEdit?.type);
        setRequired(questionToEdit?.required);
       if(questionToEdit?.type === 'card'){
        setCardFields(questionToEdit?.questionData);
       }else{
        setCardFields([])
       }
       if(questionToEdit?.type === 'grid'){
         setGridData(questionToEdit?.questionData);
       }else{
        setGridData({
          columns: [],
          rows: [],
          answers: {},
        })
       }
        setCondition(questionToEdit?.condition);
        setConditionQuestion(questionToEdit?.conditionQuestion);
        setValidation(questionToEdit?.validation);
        setMaxLength(questionToEdit?.maxLength);
       if(questionToEdit?.type === 'list'){
        setListFields(questionToEdit?.questionData)
       }else{
        setListFields({
          columns: [],
          rows: [],
        })
       }
        setAllowMultipleCards(questionToEdit?.allowMultiple)
        setShowTitle(questionToEdit?.showTitle)
        setFontSize(questionToEdit?.fontSize)
        setFontWeight(questionToEdit?.fontWeight)
        setTextPosition(questionToEdit?.textPosition)
        setColor(questionToEdit?.color)
        setFontStyle(questionToEdit?.fontStyle)
        setConditionType(questionToEdit?.conditionType)
        setCorporation((questionToEdit?.corporation))
      }

    }, [editmode]);
  
    useEffect(() => {
      if(validation){
        setMaxLength(null)
      }
      if(fieldType === 'number-percentage' || fieldType === 'number-amount'){
        setValidation('')
      }
    },[validation,fieldType])
  
    const conditionFieldOptions = taxForm?.sections
      ?.find((sec) => sec?.id === activeSectionId)
      ?.subsections?.find((sub) => sub?.id === activeSubSectionId)
      ?.fields?.map((question) => {
        if(questionToEdit?.id){
          if(question?.id !== questionToEdit?.id){
            return { label: question?.question, value: question?.question };
          }else{
            return []
          }
        }else{
          return { label: question?.question, value: question?.question }
        }
       
      });

   

    useEffect(() => {
     let conditionQuestionType = taxForm?.sections
      ?.find((sec) => sec?.id === activeSectionId)
      ?.subsections?.find((sub) => sub?.id === activeSubSectionId)
      ?.fields?.find(field => {
        if(field?.question === conditionQuestion){
          return field?.type
        }
      });
      let conditionAnswers = taxForm?.sections
      ?.find((sec) => sec?.id === activeSectionId)
      ?.subsections?.find((sub) => sub?.id === activeSubSectionId)
      ?.fields?.find(field => {
        if(field?.question === conditionQuestion){
         return field?.questionData?.map(ans => {
            return {label:ans, value:ans}
          })
        }
      });
      setConditionFieldAnswers(conditionAnswers?.questionData?.map(ans => {
        return {label:ans, value:ans}
      }))
      setConditionQuestionType(conditionQuestionType)
    },[conditionQuestion])

    

    return (
      <div className="custom-modal-overlay">
        <div
          className="custom-modal-cont-add-question"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="d-flex justify-content-end">
            <img
              className="cursor-pointer"
              src={CloseIcon}
              alt="close"
              onClick={() => setCustomModalOpen(null)}
            />
          </div>
          <h2 className="custom-modal-heading-add-question">
            Configure Question
          </h2>
          {/* <div className="d-flex align-items-center justify-content-between mt-5 px-3">
            <label htmlFor="question-text">Question title</label>
            <div>
              <input
                className="Question-text-input"
                type="text"
                id="question-text"
                placeholder="Short answer text ..."
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
              />
            </div>
          </div> */}
          <div class="mb-3 row d-flex justify-content-between align-items-center px-3 mt-5">
            <label for="question-text" className="col-sm-2 col-form-label">
              Question title
            </label>
            <div class="col-sm-5">
              <textarea
                id="question-text"
                placeholder="Short answer text ..."
                className="form-control"
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-5 px-3">
            <div>Field type</div>
            <Cascader
              value={fieldType}
              options={fieldOptions}
              onChange={handleChange}
              getPopupContainer={(trigger) => trigger.parentElement}
              placeholder="Select Field Type..."
              allowClear={false}
            />
          </div>
          {(fieldType === "dropdown-custom-single-select" ||
            fieldType === "dropdown-custom-multy-select") && (
            <div className="d-flex justify-content-between px-3 mt-4 gap-5">
              <div>Options</div>
              <div className="add-option-title cursor-pointer">
                <div className="d-flex gap-3 flex-wrap">
                  {customDropdownOptions?.length > 0 &&
                    customDropdownOptions?.map((option, idx) => {
                      return (
                        <div key={idx} className="option-cont">
                          <div>{option}</div>
                          <div
                            className="option-delete-icon cursor-pointer"
                            onClick={() =>
                              setCustomDropdownOptions(() =>
                                customDropdownOptions?.filter(
                                  (opt) => opt !== option
                                )
                              )
                            }
                          >
                            X
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                  className="mt-3"
                  onClick={() => setAddOptionModalOpen(true)}
                >
                  + Add option
                </div>
              </div>
            </div>
          )}
            {(fieldType === 'pre-defined-shareholders' || fieldType === 'pre-defined-officers') && <div>
               <div className={'d-flex justify-content-between px-3 mt-4'}>
                   <div>Corporation</div>
                   <ReactSelect className={"question-input"} value={{label:corporation,value:corporation}} options={[{label:"C-Corporation",value:"C-Corporation"},{label:"S-Corporation",value:"S-Corporation"}]} onChange={(selected) => setCorporation((selected?.value))}/>
               </div>
            </div>}
          {fieldType === "card" && (
            <div className="d-flex flex-column justify-content-between px-3 mt-4">
              <div className="add-option-title cursor-pointer">
                <div className="columns-header">Columns</div>
              </div>
              <div className="d-flex flex-column gap-3 mt-4 flex-wrap">
                {CardFields?.length > 0 &&
                  CardFields?.map((option, idx) => {
                    let value = CardFields?.find((field) => {
                      if (field?.label === option?.label) {
                        return field;
                      }
                    });
                    return (
                      <div key={idx} className="card-list-cont">
                        <div className="d-flex gap-2 justify-content-between align-items-center">
                          <div className="d-inline">
                            <span>{option?.label}</span>
                            <img
                              style={{ height: "15px", marginLeft: "8px" }}
                              className="cursor-pointer"
                              src={EditIcon}
                              alt="edit"
                              onClick={() => {
                                setEditCardColumnTitleModalOpen({
                                  state: "editcardcolumntitle",
                                  data: idx,
                                });
                              }}
                            />
                          </div>

                          <div className="d-flex justify-content-between align-items-center gap-3">
                            <Cascader
                              options={CustomfieldOptions}
                              allowClear={false}
                              value={value?.type}
                              onChange={(selected) => {
                                const addType = CardFields?.map((field) => {
                                  if (field?.label === option?.label) {
                                    return {
                                      ...field,
                                      type:
                                        selected?.length > 1
                                          ? selected[1]
                                          : selected[0],
                                      questionData:
                                        selected[0] === "yes-no"
                                          ? ["Yes", "No"]
                                          : selected[1] === "dropdown-states"
                                          ? americanStatesData
                                          : selected[1] === "dropdown-countries"
                                          ? countriesData
                                          : selected[1] ===
                                            "dropdown-filing-status"
                                          ? filinigStatusData
                                          : [],
                                    };
                                  }
                                  return field;
                                });
                                setCardFields(addType);
                              }}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              placeholder="Select Field Type..."
                            />
                            <div
                              className="column-delete-icon cursor-pointer"
                              onClick={() => {
                                const filtered = CardFields?.filter(
                                  (_, fieldIdx) => fieldIdx !== idx
                                );
                                setCardFields(filtered);
                              }}
                            >
                              X
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end gap-3 flex-wrap mt-4">
                          {(option?.type === "dropdown-custom-single-select" ||
                            option?.type === "dropdown-custom-multy-select") &&
                            option?.questionData?.length > 0 &&
                            option?.questionData?.map((fieldOpt, idx) => {
                              return (
                                <div key={idx} className="option-cont">
                                  <div>{fieldOpt}</div>
                                  <div
                                    className="option-delete-icon cursor-pointer"
                                    onClick={() => {
                                      let updatedCardFields = [...CardFields];
                                      updatedCardFields =
                                        updatedCardFields?.map((field) => {
                                          if (field?.label === option?.label) {
                                            return {
                                              ...field,
                                              questionData:
                                                field?.questionData?.filter(
                                                  (opt) => opt !== fieldOpt
                                                ),
                                            };
                                          }
                                          return field;
                                        });
                                      setCardFields(updatedCardFields);
                                    }}
                                  >
                                    X
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        {option?.type.includes("dropdown") &&
                        option?.type !== "dropdown-filing-status" &&
                        option?.type !== "dropdown-countries" &&
                        option?.type !== "dropdown-states" ? (
                          <div
                            className="text-end mt-3 text-primary cursor-pointer"
                            onClick={() =>
                              setAddCardDropdownOptionModalOpen({
                                state: true,
                                data: option?.label,
                              })
                            }
                          >
                            + Add option
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
              </div>
              <div
                className="mt-4 text-end cursor-pointer"
                onClick={() => setAddCardColumnModalOpen(true)}
              >
                + Add column
              </div>
            </div>
          )}
          {fieldType === "list" && (
            <div className="d-flex flex-column justify-content-between px-3 mt-4">
              <div className="add-option-title cursor-pointer">
                <div className="columns-header">Columns</div>
              </div>
              <div className="d-flex flex-column gap-3 mt-4 flex-wrap">
                {listFields?.columns?.length > 0 &&
                  listFields?.columns.map((option, idx) => {
                    let value = listFields?.columns?.find((col) => {
                      if (col?.name === option?.name) {
                        return col;
                      }
                    });
                    return (
                      <div key={idx} className="card-list-cont">
                        <div className="d-flex gap-2 justify-content-between align-items-center">
                          <div className="d-inline">
                            <span>{option?.name}</span>
                            <img
                              style={{ height: "15px", marginLeft: "8px" }}
                              className="cursor-pointer"
                              src={EditIcon}
                              alt="edit"
                              onClick={() => {
                                setEditListColumnTitleModalOpen({
                                  state: "editlistcolumntitle",
                                  data: idx,
                                });
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-between align-items-center gap-3">
                            <Cascader
                              options={CustomfieldOptions}
                              allowClear={false}
                              value={value?.type}
                              onChange={(selected) => {
                                const addType = listFields?.columns?.map(
                                  (field) => {
                                    if (field?.name === option?.name) {
                                      return {
                                        ...field,
                                        type:
                                          selected?.length > 1
                                            ? selected[1]
                                            : selected[0],
                                        questionData:
                                          selected[0] === "yes-no"
                                            ? ["Yes", "No"]
                                            : selected[1] === "dropdown-states"
                                            ? americanStatesData
                                            : selected[1] === "dropdown-countries"
                                             ? countriesData
                                            : selected[1] ===
                                              "dropdown-filing-status"
                                            ? filinigStatusData
                                            : [],
                                      };
                                    }
                                    return field;
                                  }
                                );
                                setListFields((prev) => {
                                  return { ...prev, columns: addType };
                                });
                              }}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              placeholder="Select Field Type..."
                            />
                            <div
                              className="column-delete-icon cursor-pointer"
                              onClick={() => {
                                const filtered = listFields?.columns?.filter(
                                  (_, fieldIdx) => fieldIdx !== idx
                                );
                                setListFields((prev) => {
                                  return { ...prev, columns: filtered };
                                });
                              }}
                            >
                              X
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end gap-3 flex-wrap mt-4">
                          {(option?.type === "dropdown-custom-single-select" ||
                            option?.type === "dropdown-custom-multy-select") &&
                            option?.questionData?.length > 0 &&
                            option?.questionData?.map((fieldOpt, optIdx) => {
                              return (
                                <div key={idx} className="option-cont">
                                  <div>{fieldOpt}</div>
                                  <div
                                    className="option-delete-icon cursor-pointer"
                                    onClick={() => {
                                      let updatedListFields = { ...listFields };
                                      updatedListFields =
                                        updatedListFields?.columns?.map(
                                          (field, fieldIdx) => {
                                            if (fieldIdx === idx) {
                                              return {
                                                ...field,
                                                questionData:
                                                  field?.questionData?.filter(
                                                    (opt) => opt !== fieldOpt
                                                  ),
                                              };
                                            }
                                            return field;
                                          }
                                        );
                                      setListFields((prev) => {
                                        return {
                                          ...prev,
                                          columns: updatedListFields,
                                        };
                                      });
                                    }}
                                  >
                                    X
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        {option?.type.includes("dropdown") &&
                        option?.type !== "dropdown-filing-status" &&
                        option?.type !== "dropdown-states" ? (
                          <div
                            className="text-end mt-3 text-primary cursor-pointer"
                            onClick={() =>
                              setAddListDropdownOptionModalOpen({
                                state: true,
                                data: idx,
                              })
                            }
                          >
                            + add option
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
              </div>
              <div
                className="mt-4 text-end cursor-pointer"
                onClick={() => setAddListColumnModalOpen(true)}
              >
                + Add column
              </div>
            </div>
          )}
          {fieldType === "grid" && (
            <div className="d-flex flex-column">
              <div className="d-flex flex-column justify-content-between border-bottom pb-5 px-3 mt-4">
                <div className="add-option-title cursor-pointer">
                  <div className="columns-header">Columns</div>
                </div>
                <div className="d-flex flex-column gap-3 mt-4 flex-wrap">
                  {gridData?.columns?.length > 0 &&
                    gridData?.columns?.map((column, idx) => {
                      let value = gridData?.columns?.find((field) => {
                        if (field?.label === column?.label) {
                          return field;
                        }
                      });
                      return (
                          <>
                              <div
                                  className="border-bottom p-3 d-flex justify-content-between align-items-center"
                                  key={column?.label}
                              >
                                  <div className="d-inline">
                                      <span>{column?.label}</span>
                                      <img
                                          style={{height: "15px", marginLeft: "8px"}}
                                          className="cursor-pointer"
                                          src={EditIcon}
                                          alt="edit"
                                          onClick={() => {
                                              setEditGridColumnTitleModalOpen({
                                                  state: "editgridcolumntitle",
                                                  data: idx,
                                              });
                                          }}
                                      />
                                  </div>
                                  <div className="d-flex justify-conetnt-center align-items-center gap-3">
                                      <Cascader
                                          value={value?.type}
                                          options={CustomfieldOptions}
                                          allowClear={false}
                                          onChange={(selected) => {
                                              const addType = gridData?.columns?.map(
                                                  (field) => {
                                                      if (field?.label === column?.label) {
                                                          return {
                                                              ...field,
                                                              type:
                                                                  selected?.length > 1
                                                                      ? selected[1]
                                                                      : selected[0],
                                                              questionData:
                                                                  selected[0] === "yes-no"
                                                                      ? ["Yes", "No"]
                                                                      : [],
                                                          };
                                                      }
                                                      return field;
                                                  }
                                              );
                                              setGridData((prev) => {
                                                  return {...prev, columns: addType};
                                              });
                                          }}
                                          getPopupContainer={(trigger) =>
                                              trigger.parentElement
                                          }
                                          placeholder="Select Field Type..."
                                      />
                                      <div
                                          className="cursor-pointer"
                                          onClick={() => {
                                              setGridData((prev) => {
                                                  return {
                                                      ...prev,
                                                      columns: prev?.columns?.filter(
                                                          (col) => col?.label !== column?.label
                                                      ),
                                                  };
                                              });
                                          }}
                                      >
                                          x
                                      </div>
                                  </div>

                              </div>
                              <div className="d-flex justify-content-end gap-3 flex-wrap mt-4">
                                  {(column?.type === "dropdown-custom-single-select" ||
                                          column?.type === "dropdown-custom-multy-select") &&
                                      column?.questionData?.length > 0 &&
                                      column?.questionData?.map((fieldOpt, optIdx) => {
                                          return (
                                              <div key={idx} className="option-cont">
                                                  <div>{fieldOpt}</div>
                                                  <div
                                                      className="option-delete-icon cursor-pointer"
                                                      onClick={() => {
                                                          let updatedGridFields = {...gridData}
                                                          updatedGridFields = {
                                                              ...updatedGridFields,
                                                              columns:updatedGridFields?.columns?.map((col) => {
                                                                  if(col?.label === column?.label){
                                                                      return {
                                                                          ...col,
                                                                          questionData: col?.questionData?.filter(d => d !== fieldOpt)
                                                                      }
                                                                  }

                                                                  return col
                                                              } )
                                                          }
                                                          setGridData(updatedGridFields)
                                                      }}
                                                  >
                                                      X
                                                  </div>
                                              </div>
                                          );
                                      })}
                              </div>
                              {column?.type.includes("dropdown") &&
                              column?.type !== "dropdown-filing-status" &&
                              column?.type !== "dropdown-countries" &&
                              column?.type !== "dropdown-states" ? (
                                  <div
                                      className="text-end text-primary cursor-pointer"
                                      onClick={() =>
                                          setAddGridDropdownOptionModalOpen({
                                              state: true,
                                              data: column?.label,
                                          })
                                      }
                                  >
                                      + Add option
                                  </div>
                              ) : (
                                  ""
                              )}
                          </>
                      );
                    })}
                </div>

                  <div
                      className="text-primary text-end cursor-pointer mt-3"
                      onClick={() => setAddGridColumnModalOpen({state: true})}
                  >
                      + Add column
                  </div>
              </div>
                <div className="d-flex flex-column justify-content-between pb-3 px-3 mt-4">
                    <div className="add-row-title cursor-pointer">
                        <div className="columns-header">Rows</div>
                    </div>
                    <div className="d-flex flex-column gap-3 mt-4 flex-wrap">
                        {gridData?.rows?.length > 0 &&
                            gridData?.rows?.map((row, idx) => {
                                return (
                                    <div
                                        className="border-bottom p-3 d-flex justify-content-between align-items-center"
                                        key={row?.label}
                                    >
                                        <div className="d-inline">
                                            <span>{row?.label}</span>
                                            <img
                                                style={{height: "15px", marginLeft: "8px"}}
                                                className="cursor-pointer"
                                                src={EditIcon}
                                                alt="edit"
                                                onClick={() => {
                                                    setEditGridRowTitleModalOpen({
                                                        state: "editgridrowtitle",
                                                        data: idx,
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setGridData((prev) => {
                                                    return {
                                                        ...prev,
                                                        rows: prev?.rows?.filter(
                                                            (r) => r?.label !== row?.label
                                                        ),
                                                    };
                                                });
                                            }}
                                        >
                                            x
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div
                  className="text-primary text-end cursor-pointer mt-2"
                  onClick={() => setAddGridRowModalOpen({ state: true })}
                >
                  + Add row
                </div>
              </div>
            </div>
          )}
          {(fieldType !== 'text-header' && fieldType !== 'horizontal-line' && fieldType !== 'divider') && 
          <div className="d-flex align-items-center justify-content-start gap-3 mt-5 px-3">
          <label htmlFor="question-text">Required</label>
          <div class="custom-switch">
            <input
              type="checkbox"
              id="switch"
              class="switch-input"
              checked={required}
              onChange={(e) => setRequired(e.target.checked)}
            />
            <label for="switch" class="switch-label"></label>
          </div>
        </div>}
          {fieldType === "card" && (
            <div className="d-flex align-items-center justify-content-start gap-3 mt-2  px-3">
              <label htmlFor="question-text">Allow multiple cards</label>
              <div class="custom-switch">
                <input
                  type="checkbox"
                  id="switch-card"
                  class="switch-input"
                  checked={allowMultipleCards}
                  onChange={(e) => setAllowMultipleCards((prev) => !prev)}
                />
                <label for="switch-card" class="switch-label"></label>
              </div>
            </div>
          )}
          {(fieldType === "card" || fieldType === "pre-defined-bank-information") && (
            <div className="d-flex align-items-center justify-content-start gap-3 mt-2  px-3">
              <label htmlFor="question-text">Show title</label>
              <div class="custom-switch">
                <input
                  type="checkbox"
                  id="switch-card-title"
                  class="switch-input"
                  checked={showTitle}
                  onChange={(e) => setShowTitle((prev) => !prev)}
                />
                <label for="switch-card-title" class="switch-label"></label>
              </div>
            </div>
          )}
          {fieldType === "grid" && (
            <div className="d-flex align-items-center justify-content-start gap-3 mt-2  px-3">
              <label htmlFor="question-text">Show title</label>
              <div class="custom-switch">
                <input
                  type="checkbox"
                  id="switch-card-title"
                  class="switch-input"
                  checked={showTitle}
                  onChange={(e) => setShowTitle((prev) => !prev)}
                />
                <label for="switch-card-title" class="switch-label"></label>
              </div>
            </div>
          )}
          {fieldType === "text-header" && (
            <div className="d-flex flex-column align-items-center justify-content-start gap-3 mt-4  px-3">
              <div className="d-flex justify-content-between align-items-center w-100">
                <label htmlFor="font-size">Font size (px)</label>
                <input
                  id="font-size"
                  className="form-control w-25"
                  type="number"
                  value={fontSize}
                  onChange={(e) => {
                    setFontSize(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center w-100">
                <label htmlFor="font-size">Font weight</label>
                <ReactSelect
                  className="question-input"
                  value={fontWeight}
                  onChange={(selected) => {
                    setFontWeight(selected);
                  }}
                  options={[
                    { label: "Thin", value: 100 },
                    { label: "Extra Light", value: 200 },
                    { label: "Light", value: 300 },
                    { label: "Normal", value: 400 },
                    { label: "Medium", value: 500 },
                    { label: "Semi Bold", value: 600 },
                    { label: "Bold", value: 700 },
                    { label: "Extra Bold", value: 800 },
                    { label: "Black", value: 900 },
                  ]}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center w-100">
                <label htmlFor="font-size">Text position</label>
                <ReactSelect
                  className="question-input"
                  value={textPosition}
                  onChange={(selected) => {
                    setTextPosition(selected);
                  }}
                  options={[
                    { label: "Start", value: "start" },
                    { label: "Center", value: "center" },
                    { label: "End", value: "end" },
                  ]}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center w-100">
                <label htmlFor="font-size">Font style</label>
                <ReactSelect
                  className="question-input"
                  value={fontStyle}
                  onChange={(selected) => {
                    setFontStyle(selected);
                  }}
                  options={[
                    { label: "Normal", value: "normal" },
                    { label: "Italic", value: "italic" },
                    { label: "Oblique", value: "oblique" },
                    { label: "Inherit", value: "inherit" },
                  ]}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center w-100">
                <label htmlFor="font-size">Pick a color</label>
                <ColorPicker
                  value={color}
                  onChange={(col) => setColor(col.toHexString())}
                />
              </div>
            </div>
          )}

          { (fieldType !== 'text-header' && fieldType !== 'horizontal-line' && fieldType !== 'divider') && <div>
          {!moreOptions ? (
            <div
              className="text-primary px-3 mt-4 cursor-pointer fw-bold"
              onClick={() => setMoreOptions(true)}
            >
              More options
            </div>
          ) : (
            <div
              className="text-primary px-3 mt-4 cursor-pointer fw-bold"
              onClick={() => setMoreOptions(false)}
            >
              Less options
            </div>
          )}
          </div>}
          {moreOptions &&
            (fieldType === "text-one-line" ||
              fieldType === "number-any-number" ||
              fieldType === "number-percentage" ||
              fieldType === "number-amount" ||
              fieldType === "date") && (
              <div className="px-3">
                <div className="fw-normal fs-5 py-3 border-top mt-4 ">
                  Validations
                </div>
                <div className="d-flex flex-column align-items-center justify-content-between gap-3">
                  {!validation &&
                    (fieldType === "text-one-line" ||
                      fieldType === "number-any-number" ||
                      fieldType === "number-percentage" ||
                      fieldType === "number-amount") && (
                      <div className="d-flex justify-content-between  align-items-center w-100 row">
                        <label className="col-sm-5">Max Length</label>
                        <div className="col-sm-3">
                          <input
                            type="number"
                            value={maxLength}
                            className="form-control"
                            placeholder="Length..."
                            onChange={(e) => setMaxLength(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                  {fieldType === "text-one-line" && (
                    <div className="d-flex justify-content-between  align-items-center w-100 row">
                      <label className="col-sm-5">Text Validation</label>
                      <div className="col-sm-5">
                        <ReactSelect
                          className="question-input col-md-5 w-100"
                          value={
                            validation
                              ? { label: validation, value: validation }
                              : ""
                          }
                          placeholder="Select..."
                          options={[
                            { label: "Email", value: "email" },
                            { label: "SSN", value: "ssn" },
                            { label: "EIN", value: "ein" },
                            { label: "Text", value: "" },
                          ]}
                          onChange={(selected) => {
                            setValidation(selected?.value);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {fieldType === "number-any-number" && (
                    <div className="d-flex justify-content-between  align-items-center w-100 row">
                      <label className="col-sm-5">Number Validation</label>
                      <div className="col-sm-5">
                        <ReactSelect
                          className="question-input col-md-5 w-100"
                          value={
                            validation
                              ? { label: validation, value: validation }
                              : ""
                          }
                          placeholder="Select..."
                          options={[
                            { label: "Phone Number", value: "phone" },
                            { label: "Zip Code", value: "zipcode" },
                            { label: "SSN", value: "ssn" },
                            { label: "EIN", value: "ein" },
                            { label: "Any number", value: "" },
                          ]}
                          onChange={(selected) => {
                            setValidation(selected?.value);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {fieldType === "date" && (
                    <div className="d-flex justify-content-between  align-items-center w-100 row">
                      <label className="col-sm-5">Date Validation</label>
                      <div className="col-sm-5">
                        <ReactSelect
                          value={
                            validation
                              ? { label: validation, value: validation }
                              : ""
                          }
                          className="question-input col-md-5 w-100"
                          placeholder="Select..."
                          options={[
                            { label: "Birthdate", value: "birthdate" },
                            { label: "Year", value: "year" },
                            { label: "Date", value: "" },
                          ]}
                          onChange={(selected) => {
                            setValidation(selected?.value);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          {(fieldType !== 'text-header' && fieldType !== 'horizontal-line' && fieldType !== 'divider') && moreOptions && (
            <div className="px-3">
              <div className="fw-normal fs-5 py-3 border-top mt-4 ">
                Condition
              </div>
              <div className="d-flex align-items-center justify-content-between gap-3">
                <ReactSelect
                value={{label:conditionType === 'show' ? "Show" : conditionType === '' ? 'Select ...' : 'Hide',value:conditionType === 'show' ? 'show' : conditionType === '' ? '' : 'hide'}}
                  options={[
                    {
                      label: "Show question when",
                      value: "show",
                    },
                    {
                      label: "Hide question when",
                      value: "hide",
                    },
                  ]}
                  onChange={(selected) => setConditionType(selected?.value)}
                  className="question-input condition-select"
                  placeholder="Select ..."
                />
                <div>If the value of</div>
                <ReactSelect
                  value={
                    conditionQuestion
                      ? { label: conditionQuestion, value: conditionQuestion }
                      : ""
                  }
                  isDisabled={!conditionType}
                  options={conditionFieldOptions}
                  onChange={(selected) => setConditionQuestion(selected?.label)}
                  className="question-input condition-select"
                  placeholder="Select..."
                />
                 <div>Is equal to</div>
                {(conditionQuestionType?.type?.includes("text") || conditionQuestionType?.type?.includes("number")) && (
                  <input
                    type="text"
                    className="w-25 form-control"
                    value={condition}
                    onChange={(e) => setCondition(e.target.value)}
                    />
                  )}
                {(conditionQuestionType?.type?.includes("dropdown") ||
                  conditionQuestionType?.type?.includes("yes-no")) && (
                    <ReactSelect
                    value={{label:condition || 'Select ...',value:condition || 'Select ...'}}
                    className="question-input w-25 condition-select"
                    options={conditionFieldAnswers}
                    onChange={(selected) => setCondition(selected.value)}
                    placeholder="Select ..."
                  />
                )}
              </div>
            </div>
          )}

          <div
            className={`d-flex justify-content-${
              editmode ? "between" : "end"
            } align-items-center gap-3 px-5 mt-5`}
          >
            {editmode && (
              <div className="d-flex align-items-center gap-2 cursor-pointer">
                <img src={DeleteIcon2} alt="delete" />
                <div
                  className="text-danger fw-bold"
                  onClick={() => {
                    setTaxForm((prevForm) => {
                      return {
                        ...prevForm,
                        sections: prevForm?.sections?.map((section) => {
                          if (section?.id === activeSectionId) {
                            return {
                              ...section,
                              subsections: section?.subsections?.map((sub) => {
                                if (sub?.id === activeSubSectionId) {
                                  return {
                                    ...sub,
                                    fields: sub?.fields?.filter(
                                      (question) =>
                                        question?.id !== questionToEdit?.id
                                    ),
                                  };
                                }
                                return sub;
                              }),
                            };
                          }
                          return section;
                        }),
                      };
                    });
                    setCustomModalOpen(null);
                    setCustomDropdownOptions([]);
                    setCardFields([]);
                    setGridData({
                      columns: [],
                      rows: [],
                      answers: {},
                    });
                    setRequired(false);
                    setCondition("");
                    setConditionQuestion("");
                    setValidation("");
                    setListFields({
                      columns: [],
                      rows: [],
                    });
                    setMaxLength(0);
                    setConditionType('')
                    setCorporation('')
                  }}
                >
                  Delete question
                </div>
              </div>
            )}
            <div className="d-flex align-items-center gap-3">
              <div
                className="text-primary cursor-pointer"
                onClick={() => setCustomModalOpen(null)}
              >
                Cancel
              </div>
              {editmode ? (
                <div
                  className={`close-form-btn  ${
                    !fieldType
                      ? "close-form-btn-disabled"
                      : "bg-primary text-white"
                  }`}
                  onClick={() => {
                    if (fieldType) {
                      const uniqId = generateUniqueId();
                      let questionData;
                      if (
                        fieldType === "text-one-line" ||
                        fieldType === "text-multi-line" ||
                        fieldType === "text-email" ||
                        fieldType === "text-comment" ||
                        fieldType === "number-any-number" ||
                        fieldType === "number-percentage" ||
                        fieldType === "number-amount" ||
                        fieldType === "number-phone" ||
                        fieldType === "number-zipcode" ||
                        fieldType === "date" ||
                        fieldType === "divider" || 
                        fieldType === 'horizontal-line' || 
                        fieldType === "pre-defined-deductions-list"
                      ) {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: "",
                        };
                      }
                      if (fieldType === "text-header") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          fontSize: fontSize,
                          fontWeight: fontWeight,
                          fontStyle: fontStyle,
                          textPosition: textPosition,
                          color: color,
                        };
                      }
                      if (fieldType === "yes-no") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: ["Yes", "No"],
                          answer: "",
                        };
                      }
                      if (fieldType === "file-upload") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          file: [],
                        };
                      }
                      if (fieldType === "dropdown-filing-status") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          type: fieldType,
                          fieldType: "",
                          question: questionText,
                          questionData: filinigStatusData,
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-states") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: americanStatesData,
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-countries") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: countriesData,
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-custom-single-select") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: customDropdownOptions,
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-custom-multy-select") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: customDropdownOptions,
                          answer: "",
                        };
                      }
                      if (fieldType === "pre-defined-dependents") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                      if (fieldType === "pre-defined-partners") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                        if (fieldType === "pre-defined-shareholders") {
                            questionData = {
                                id: uniqId,
                                identifier: questionText + "-" + uniqId,
                                required: required,
                                type: fieldType,
                                condition: condition,
                                conditionType:conditionType,
                                validation: validation,
                                maxLength: maxLength,
                                conditionQuestion: conditionQuestion,
                                fieldType: "",
                                corporation:corporation,
                                question: questionText,
                                answer: [],
                            };
                        }
                        if (fieldType === "pre-defined-officers") {
                            questionData = {
                                id: uniqId,
                                identifier: questionText + "-" + uniqId,
                                required: required,
                                type: fieldType,
                                condition: condition,
                                conditionType:conditionType,
                                validation: validation,
                                maxLength: maxLength,
                                conditionQuestion: conditionQuestion,
                                fieldType: "",
                                question: questionText,
                                isPrimaryContact:questionToEdit?.isPrimaryContact,
                                corporation:corporation,
                                primaryContactInfo:{firstname:"",middleInitial:"",lastname:"",title:"",ssn:"",email:"",phone_number:""},
                                answer: [],
                            };
                        }
                      if (fieldType === "pre-defined-income-sources") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                      if (fieldType === "pre-defined-dependent-child") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          childData: [
                            {
                              id: "323423423ewwefwefwefw",
                              name: "",
                              dob: "",
                              livewithyou: "",
                              isStudent: "",
                              capableOfEarning: "",
                              projectingToEarn: "",
                              howMuchForTuition: "",
                            },
                          ],
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                      if (fieldType === "expenses-list") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                      if (fieldType === "expenses-select") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: expensesData,
                          answer: [],
                        };
                      }
                      if (fieldType === "card") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          allowMultiple: allowMultipleCards,
                          showTitle: showTitle,
                          original: true,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          cardTemplate: CardFields,
                          questionData: CardFields,
                          answer: [],
                        };
                      }
                      if (fieldType === "grid") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          showTitle: showTitle,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: gridData,
                          answer: [],
                        };
                      }
                      if (fieldType === "list") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: listFields,
                          answer: [],
                        };
                      }
                      setTaxForm((prevForm) => {
                        return {
                          ...prevForm,
                          sections: prevForm?.sections?.map((section) => {
                            if (section?.id === activeSectionId) {
                              return {
                                ...section,
                                subsections: section?.subsections?.map(
                                  (sub) => {
                                    if (sub?.id === activeSubSectionId) {
                                      return {
                                        ...sub,
                                        fields: sub?.fields?.map((question) => {
                                          if (
                                            question?.id === questionToEdit?.id
                                          ) {
                                            return questionData;
                                          }
                                          return question;
                                        }),
                                      };
                                    }
                                    return sub;
                                  }
                                ),
                              };
                            }
                            return section;
                          }),
                        };
                      });
                      setCustomModalOpen(null);
                      setCustomDropdownOptions([]);
                      setCardFields([]);
                      setGridData({
                        columns: [],
                        rows: [],
                        answers: {},
                      });
                      setRequired(false);
                      setCondition("");
                      setConditionQuestion("");
                      setValidation("");
                      setListFields({
                        columns: [],
                        rows: [],
                      });
                      setMaxLength(0);
                    }
                  }}
                >
                  Save (close)
                </div>
              ) : (
                <div
                  className={`close-form-btn ${
                  !fieldType ? "close-form-btn-disabled" : ""
                  }`}
                  onClick={() => {
                    let questionGroup = [];
                    if (fieldType) {
                      const uniqId = generateUniqueId();
                      let questionData;
                      if (
                        fieldType === "text-one-line" ||
                        fieldType === "text-multi-line" ||
                        fieldType === "text-email" ||
                        fieldType === "text-comment" ||
                        fieldType === "number-any-number" ||
                        fieldType === "number-percentage" ||
                        fieldType === "number-amount" ||
                        fieldType === "number-phone" ||
                        fieldType === "number-zipcode" ||
                        fieldType === "date" ||
                        fieldType === "divider" || 
                        fieldType === 'horizontal-line' || 
                        fieldType === "pre-defined-deductions-list"
                      ) {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: "",
                        };
                      }
                      if (fieldType === "text-header") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          fontSize: fontSize,
                          fontWeight: fontWeight,
                          fontStyle: fontStyle,
                          textPosition: textPosition,
                          color: color,
                        };
                      }
                      if (fieldType === "file-upload") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          file: [],
                        };
                      }
                      if (fieldType === "yes-no") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: ["Yes", "No"],
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-filing-status") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          type: fieldType,
                          fieldType: "",
                          question: questionText,
                          questionData: filinigStatusData,
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-states") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: americanStatesData,
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-countries") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: countriesData,
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-custom-single-select") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: customDropdownOptions,
                          answer: "",
                        };
                      }
                      if (fieldType === "dropdown-custom-multy-select") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: customDropdownOptions,
                          answer: "",
                        };
                      }
                      if (fieldType === "pre-defined-dependents") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                      if (fieldType === "pre-defined-partners") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                        if (fieldType === "pre-defined-shareholders") {
                            questionData = {
                                id: uniqId,
                                identifier: questionText + "-" + uniqId,
                                required: required,
                                type: fieldType,
                                condition: condition,
                                conditionType:conditionType,
                                validation: validation,
                                maxLength: maxLength,
                                conditionQuestion: conditionQuestion,
                                fieldType: "",
                                corporation:corporation,
                                question: questionText,
                                answer: [],
                            };
                        }
                        if (fieldType === "pre-defined-officers") {
                            questionData = {
                                id: uniqId,
                                identifier: questionText + "-" + uniqId,
                                required: required,
                                type: fieldType,
                                condition: condition,
                                conditionType:conditionType,
                                validation: validation,
                                maxLength: maxLength,
                                conditionQuestion: conditionQuestion,
                                fieldType: "",
                                question: questionText,
                                isPrimaryContact:false,
                                corporation:corporation,
                                primaryContactInfo:{firstname:"",middleInitial:"",lastname:"",title:"",ssn:"",email:"",phone_number:""},
                                answer: [],
                            };
                        }
                      if (fieldType === "pre-defined-income-sources") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                      if (fieldType === "pre-defined-dependent-child") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          childData: [
                            {
                              id: "323423423ewwefwefwefw",
                              name: "",
                              dob: "",
                              livewithyou: "",
                              isStudent: "",
                              capableOfEarning: "",
                              projectingToEarn: "",
                              howMuchForTuition: "",
                            },
                          ],
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                      if (fieldType === "expenses-list") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          answer: [],
                        };
                      }
                      if (fieldType === "expenses-select") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: expensesData,
                          answer: [],
                        };
                      }
                      if (fieldType === "card") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          allowMultiple: allowMultipleCards,
                          showTitle: showTitle,
                          original: true,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          cardTemplate: CardFields,
                          questionData: CardFields,
                          answer: [],
                        };
                      }
                      if (fieldType === "list") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: listFields,
                          answer: [],
                        };
                      }
                      if (fieldType === "grid") {
                        questionData = {
                          id: uniqId,
                          identifier: questionText + "-" + uniqId,
                          required: required,
                          showTitle: showTitle,
                          type: fieldType,
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: questionText,
                          questionData: gridData,
                          answer: [],
                        };
                      }
                      if (fieldType === "pre-defined-name-group") {
                        questionGroup = [
                          {
                            id: generateUniqueId(),
                            identifier:
                              "asdefwe1212321sdfss" + generateUniqueId(),
                            required: true,
                            type: "text-one-line",
                            condition: condition,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            fieldType: "",
                            question: "First Name",
                            answer: "",
                          },
                          {
                            id: generateUniqueId(),
                            identifier:
                              "asdfwefewr23425rfs" + generateUniqueId(),
                            required: false,
                            type: "text-one-line",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            fieldType: "",
                            question: "Middle Name",
                            answer: "",
                          },
                          {
                            id: generateUniqueId(),
                            identifier: "asdasffqer322r" + generateUniqueId(),
                            required: true,
                            type: "text-one-line",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            fieldType: "",
                            question: "Last Name",
                            answer: "",
                          },
                          {
                            id: generateUniqueId(),
                            identifier: "asdasfwefwer23r" + generateUniqueId(),
                            required: false,
                            type: "dropdown-custom-single-select",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            questionData: [
                              "Mr.",
                              "Mrs.",
                              "Ms.",
                              "Miss",
                              "Dr.",
                              "Prof.",
                            ],
                            fieldType: "",
                            question: "Select Prefix",
                            answer: "",
                          },
                        ];
                      }
                      if (fieldType === "pre-defined-address-group") {
                        questionGroup = [
                          {
                            id: generateUniqueId(),
                            identifier:
                              "asdasdwefwf423424" + generateUniqueId(),
                            required: true,
                            type: "dropdown-custom-single-select",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            questionData: [
                              "Home Address",
                              "Business Address",
                              "Mailing Address",
                              "Permanent Address",
                              "Temporary Address",
                              "Billing Address",
                              "Shipping Address",
                            ],
                            fieldType: "",
                            question: "Type Of Address",
                            answer: "",
                          },
                          {
                            id: generateUniqueId(),
                            identifier:
                              "fdsfdfsfewrgerge3" + generateUniqueId(),
                            required: true,
                            type: "text-one-line",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            fieldType: "",
                            question: "Street",
                            answer: "",
                          },
                          {
                            id: generateUniqueId(),
                            identifier:
                              "asdasdsadawefggsadfwf423424" +
                              generateUniqueId(),
                            required: false,
                            type: "number-any-number",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            fieldType: "",
                            question: "Apartment Number",
                            answer: "",
                          },
                          {
                            id: generateUniqueId(),
                            identifier:
                              "asdasdwefggasdsadfwf423424" + generateUniqueId(),
                            required: true,
                            type: "text-one-line",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            fieldType: "",
                            question: "City",
                            answer: "",
                          },
                          {
                            id: generateUniqueId(),
                            identifier:
                              "asdasdwefggffadsadfwf423424" +
                              generateUniqueId(),
                            required: true,
                            type: "dropdown-custom-single-select",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            questionData: americanStatesData,
                            fieldType: "",
                            question: "State",
                            answer: "",
                          },
                          {
                            id: generateUniqueId(),
                            identifier:
                              "asdasdwefggsadfwf423424" + generateUniqueId(),
                            required: true,
                            type: "number-zipcode",
                            condition: condition,
                            conditionType:conditionType,
                            validation: validation,
                            maxLength: maxLength,
                            conditionQuestion: conditionQuestion,
                            fieldType: "",
                            question: "ZIP Code",
                            answer: "",
                          },
                        ];
                      }
                      if (fieldType === "pre-defined-bank-information") {
                        questionData = {
                          id: generateUniqueId(),
                          identifier: questionText + "-" + generateUniqueId(),
                          required: required,
                          type: "card",
                          condition: condition,
                          conditionType:conditionType,
                          validation: validation,
                          maxLength: maxLength,
                          conditionQuestion: conditionQuestion,
                          fieldType: "",
                          question: "Bank Information",
                          questionData: [
                            {
                              id: generateUniqueId(),
                              label: "Financial institution name",
                              type: "text-one-line",
                              questionData: [],
                              answer: "",
                            },
                            {
                              id: generateUniqueId(),
                              label: "Account holder name",
                              type: "text-one-line",
                              questionData: [],
                              answer: "",
                            },
                            {
                              id: generateUniqueId(),
                              label: "Account type",
                              type: "dropdown-custom-single-select",
                              questionData: ["Checkings", "Savings"],
                              answer: "",
                            },
                            {
                              id: generateUniqueId(),
                              label: "Routing number",
                              type: "number-any-number",
                              questionData: [],
                              answer: "",
                            },
                            {
                              id: generateUniqueId(),
                              label: "Account number",
                              type: "number-any-number",
                              questionData: [],
                              answer: "",
                            },
                            {
                              id: generateUniqueId(),
                              label: "Select if applicable to this account",
                              type: "dropdown-custom-single-select",
                              questionData: ["IRA", "Non-U.S"],
                              answer: "",
                            },
                          ],
                          answer: [],
                        };
                      }
                      setTaxForm((prevForm) => {
                        return {
                          ...prevForm,
                          sections: prevForm?.sections?.map((section) =>
                            section?.id === activeSectionId
                              ? {
                                  ...section,
                                  subsections: section?.subsections?.map(
                                    (subsection) => {
                                      if (
                                        subsection?.id === activeSubSectionId
                                      ) {
                                        return {
                                          ...subsection,
                                          fields: [
                                            ...subsection?.fields,
                                            ...(questionGroup?.length < 1
                                              ? [questionData]
                                              : []),
                                            ...(questionGroup?.length > 0
                                              ? questionGroup
                                              : []),
                                          ],
                                        };
                                      }
                                      return subsection;
                                    }
                                  ),
                                }
                              : section
                          ),
                        };
                      });
                      setCustomModalOpen(null);
                      setCustomDropdownOptions([]);
                      setCardFields([]);
                      setGridData({
                        columns: [],
                        rows: [],
                        answers: {},
                      });
                      setRequired(false);
                      setMoreOptions(false);
                      setCondition(false);
                      setConditionQuestion(false);
                      setValidation("");
                      setMaxLength(0);
                      setListFields({
                        columns: [],
                        rows: [],
                      });
                      questionGroup = [];
                    }
                  }}
                >
                  Done (close)
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  

  export default AddNewQuestion