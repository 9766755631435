import axios from "axios";
import { DOCUMENTS_GROUP_ACTION_TYPE } from "./Type";
import { BASEURL } from "../../utilites";


const getdocumentsgroup = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/groupdocuments/get-groupDocumentby-admin`)
			.then((response) => {
				console.log(">>>>>>>>> getForms >>>>>>>>",response)
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.documentGroupData,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.documentGroupError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: [],
					},
				});
			});
	};
};

const createdocumentsgroupidle = (body) => {
	return async (dispatch) => {
		dispatch({
			type: DOCUMENTS_GROUP_ACTION_TYPE.createDocumentGroupIdle,
			payload: {
				data:[],
				message: "",
				state: "idle",
			},
		});
	};
};

const createdocumentsgroup = (body,endpointParam) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/groupdocuments/${endpointParam}`,body)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.createDocumentGroupSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.createDocumentGroupError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data:[],
					},
				});
			});
	};
};

const deletedocumentsgroupidle = (body) => {
	return async (dispatch) => {
		dispatch({
			type: DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentGroupIdle,
			payload: {
				data:[],
				message: "",
				state: "idle",
			},
		});
	};
};

const deletedocumentsgroup = (body,endpointParams) => {
	return async (dispatch) => {		
		axios
			.delete(`${BASEURL}/api/groupdocuments/${endpointParams}`,{data:body})
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentGroupSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentGroupError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data:[],
					},
				});
			});
	};
};

const markdocumentsgroupidle = (body) => {
	return async (dispatch) => {
		dispatch({
			type: DOCUMENTS_GROUP_ACTION_TYPE.markDocumentGroupIdle,
			payload: {
				data:[],
				message: "",
				state: "idle",
			},
		});
	};
};
const markdocumentsgroup = (body) => {
	return async (dispatch) => {
		console.log("@55555",body);
		
		axios
			.patch(`${BASEURL}/api/groupdocuments/mark-groupDocument-byadmin`,body)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.markDocumentGroupSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.markDocumentGroupError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data:[],
					},
				});
			});
	};
};

const editdocumentsgroupidle = (body) => {
	return async (dispatch) => {
		dispatch({
			type: DOCUMENTS_GROUP_ACTION_TYPE.editDocumentGroupIdle,
			payload: {
				data:[],
				message: "",
				state: "idle",
			},
		});
	};
};
const editdocumentsgroup = (body) => {
	return async (dispatch) => {
		console.log("@55555",body);
		
		axios
			.patch(`${BASEURL}/api/groupdocuments/edit-groupDocument-byadmin`,body)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.editDocumentGroupSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.editDocumentGroupError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data:[],
					},
				});
			});
	};
};

const getTaxProdocumentsgroup = (pro_user_id) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/groupdocuments/get-groupDocument?pro_user_id=${pro_user_id}`)
			.then((response) => {
				console.log(">>>>>>>>> getForms >>>>>>>>",response)
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.getTaxProdocumentGroupDataSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.getTaxProdocumentGroupError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: [],
					},
				});
			});
	};
};
const getTaxProAdmindocumentsgroup = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/groupdocuments/get-groupDocument-created-by-admin`)
			.then((response) => {
				console.log("@7777777777 step 3",response)
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.getTaxProAdmindocumentGroupDataSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.getTaxProAdmindocumentGroupError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: [],
					},
				});
			});
	};
};
const handleMeetingError = (type, error) => {
	if (type === "simple") {
		return error.code === "ERR_NETWORK" ? error?.message : error?.response?.data?.error;
	}
};

const createdocumentname = (body) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/documents/create-document-name`,body)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.createDocumentNameSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.createDocumentNameError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data:[],
					},
				});
			});
	};
};

const editdocumentname = (body) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/documents/edit-document-name`,body)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.editDocumentNameSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.editDocumentNameError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data:[],
					},
				});
			});
	};
};

const deletedocumentname = (body) => {
	return async (dispatch) => {
		axios.delete(`${BASEURL}/api/documents/delete-document-name?doc_id=${body?.doc_id}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentNameSuccess,
					payload: { data: response.data.data, message: '', status: true },
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentNameError,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data:[],
					},
				});
			});
	};
};
export const documentsgroup = {
	getdocumentsgroup,
	createdocumentsgroup,
	createdocumentsgroupidle,
	deletedocumentsgroup,
	deletedocumentsgroupidle,
	editdocumentsgroup,
	editdocumentsgroupidle,
	markdocumentsgroupidle,
	markdocumentsgroup,
	getTaxProdocumentsgroup,
	getTaxProAdmindocumentsgroup,
	createdocumentname,
	editdocumentname,
	deletedocumentname

};
