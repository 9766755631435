import { BASEURL } from '../../utilites';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { DASHBOARD_ACTION_TYPE } from './Type';
import { user } from '../user/action';
import { global } from '../global/action';

// Agreement Function
const Agreement = (moveToNext, setMessage, email) => {
	console.log(setMessage);
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/dashboard/onBoard/agreement`)
			.then((response) => {
				// Handle the response data here
				moveToNext();
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				if (error.code === 'ERR_NETWORK') {
					setMessage({
						show: true,
						type: 'Error',
						text: error?.message,
					});
					return;
				}
				setMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error,
				});
			});
	};
};

// userData update Function
const userPersonalDetails = (userObj, getServices, setMessage) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/dashboard/onBoard/personalDetail`, userObj)
			.then((response) => {
				// Handle the response data here
				getServices();
				// Re-get User Data To Get user Data Updated after Updation
				dispatch(user.ReGetUserData());
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				if (error.code === 'ERR_NETWORK') {
					setMessage({
						show: true,
						type: 'Error',
						text: error?.message,
					});
					return;
				}
				setMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error,
				});
			});
	};
};

// userData update Function
const getServices = (moveToNext, setMessage, setOptions) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/services`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.services,
					payload: response.data,
				});
				moveToNext && moveToNext();
				// Handle the response data here
				setOptions && setOptions([response.data[0].id.toString()]);
			})
			.catch((error) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.services,
					payload: [
						{ id: 1, name: 'Individuals or family tax preparation' },
						{ id: 2, name: 'Tax savings strategies and planning' },
						{ id: 3, name: 'Accounting or Bookkeeping services' },
					],
				});
				// Handle any errors that occurred during the request
				if (error.code === 'ERR_NETWORK') {
					setMessage &&
						setMessage({
							show: true,
							type: 'Error',
							text: error?.message,
						});
					return;
				}
				setMessage &&
					setMessage({
						show: true,
						type: 'Error',
						text: error?.response?.data?.error,
					});
			});
	};
};

// addService Form Function
const addService = (userObj, connectionStripe) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/dashboard/onBoard/addservice`, userObj)
			.then((response) => {
				// Handle the response data here
				connectionStripe();
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				dispatch({
					type: DASHBOARD_ACTION_TYPE.stripeConnection,
					status: false,
					message: error.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
				});
			});
	};
};

// addService Form Function
const AddDriverLicense = (FilesObj, moveToNext, setMessage) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/dashboard/onBoard/uploadId`, FilesObj)
			.then((response) => {
				console.log(response.data);
				// Handle the response data here
				dispatch(global.Loader(false));
				moveToNext();
			})
			.catch((error) => {
				dispatch(global.Loader(false));
				// Handle any errors that occurred during the request
				if (error.code === 'ERR_NETWORK') {
					setMessage({
						show: true,
						type: 'Error',
						text: error?.message,
					});
					return;
				}
				setMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error,
				});
			});
	};
};

const getUserRoles = () => {
	return async (dispatch, getState) => {
		// Accessing roles from state
		const roles = await getState().dashboard.roles;
		// If already fetched then return
		if (roles && roles.length > 0) {
			return;
		}

		axios
			.get(`${BASEURL}/api/roles`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.GET_USER_ROLES,
					payload: response.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getTaxProfessionals = () => {
	return async (dispatch) => {
		await axios
			.get(`${BASEURL}/api/professionals`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.GET_TAX_PROFESSIONALS,
					payload: response.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getTotalCounts = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/dashboard/total-counts`)
			.then((response) => {
				const data = response.data.data;
				dispatch({
					type: DASHBOARD_ACTION_TYPE.TOTAL_COUNTS,
					payload: {
						leads: data.totalLeads,
						completed: data.totalCompleted,
						earnings: data.totalEarnings,
					},
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getRecentLeads = (limit = 5) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/dashboard/recent-leads?limit=${limit}`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.RECENT_LEADS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getChartData = (period = 'year') => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/dashboard/get-counts?period=${period}`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.CHART_DATA,
					payload: { chartData: response.data.data, chartPeriod: period },
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getCalendarData = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/dashboard/calendar-data`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.CALENDAR_DATA,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getLeads = (
	name,
	assignedDate,
	taxpro_id,
	service,
	period,
	dateRange,
	page,
	per_page,
	onlyActive = false
) => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.LEADS_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/leads/get`, {
				params: {
					name,
					assignedDate,
					taxpro_id,
					service,
					period,
					dateRange,
					page,	
					per_page,
					onlyActive,
				},
			})
			.then((response) => {
				const { data, count, statistics } = response.data.data;
				dispatch({
					type: DASHBOARD_ACTION_TYPE.LEADS,
					payload: { data, count, statistics },
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getDisputes = (keyword, status, page = 1, per_page = 10) => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.DISPUTES_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/disputes`, {
				params: { keyword, status, page, per_page },
			})
			.then((response) => {
				const { data, count, statistics } = response.data.data;
				dispatch({
					type: DASHBOARD_ACTION_TYPE.DISPUTES,
					payload: { data, count, statistics },
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getJobs = (name, createdDate, taxpro_id, service, status, page = 1, per_page = 10) => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.JOBS_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/jobs/get`, {
				params: { name, createdDate, taxpro_id, service, status, page, per_page },
			})
			.then((response) => {
				const { data, count } = response.data.data;
				dispatch({
					type: DASHBOARD_ACTION_TYPE.JOBS,
					payload: { data, count },
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getProjects = () => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.PROJECTS_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/leads/get-projects`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.PROJECTS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getAllLeads = (name, role) => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.LEADS_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/leads/get`, {
				params: { name, role },
			})
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.LEADS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const deleteLead = (id, setAlert, reload) => {
	return async (dispatch) => {
		axios
			.delete(`${BASEURL}/api/leads/${id}`)
			.then((res) => {
				if (res.data.success) {
					setAlert('Lead deleted successfully!');
					// Reload leads
					reload();
				}
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const deleteJob = (id, setAlert, reload) => {
	return async (dispatch) => {
		axios
			.delete(`${BASEURL}/api/jobs/${id}`)
			.then((res) => {
				if (res.data.success) {
					setAlert('Job deleted successfully!');
					// Reload jobs data
					reload();
				}
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const deleteAppointment = (id, close, setAlert, reload) => {
	return async (dispatch) => {
		axios
			.delete(`${BASEURL}/api/appointments/${id}`)
			.then((res) => {
				if (res.data.success) {
					// Close offcanvas
					close && close();
					// Show alert
					setAlert && setAlert('Appointment deleted successfully!');
					// Reload appointments data
					reload && reload();
				}
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getUsers = (name, dateCreated, role, verifyStatus, page = 1, per_page = 10) => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.USERS_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/users/get`, {
				params: { name, dateCreated, role, verifyStatus, page, per_page },
			})
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.USERS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getDocuments = (type, page = 1, per_page = 10, keyword, dateAdded, owner, status, folder) => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.DOCUMENTS_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/documents`, {
				params: { type, page, per_page, keyword, dateAdded, owner, status, folder },
			})
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.DOCUMENTS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getFolders = (setFolder) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/documents/folders`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.FOLDERS,
					payload: response.data.data.data,
				});
				setFolder && setFolder(response.data.data.data[0].id);
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

const getCustomFolders = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/documents/folders`, { params: { custom: true } })
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.CUSTOM_FOLDERS,
					payload: response.data.data.data,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

const getAppointments = (keyword, status, startDate, page = 1, per_page = 10) => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.APPOINTMENTS_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/appointments`, {
				params: { keyword, status, startDate, page, per_page },
			})
			.then((response) => {
				const { data, count, statistics } = response.data.data;
				dispatch({
					type: DASHBOARD_ACTION_TYPE.APPOINTMENTS,
					payload: { data, count, statistics },
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getUpcomingAppointments = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/appointments/upcoming`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.UPCOMING_APPOINTMENTS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

const getUsersForAppointment = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/appointments/users`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.USERS_FOR_APPOINTMENT,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getAvatars = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/documents`, {
				params: { type: 6 },
			})
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.AVATARS,
					payload: response.data.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getDocumentNames = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/documents/names`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.DOCUMENT_NAMES,
					payload: response.data.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getAccountManagers = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/accountmanagers/`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.ACCOUNT_MANAGERS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const deleteDocument = (uuid, user_id, reload, setAlert) => {
	return async () => {
		// Fetch data
		axios
			.delete(`${BASEURL}/api/documents/${uuid}${user_id ? `?user_id=${user_id}` : ''}`)
			.then(() => {
				// Show alert
				setAlert && setAlert('Document deleted successfully!');
				// Reload documents data
				reload && reload();
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const reset = () => {
	return async (dispatch) => {
		dispatch({ type: DASHBOARD_ACTION_TYPE.RESET });
	};
};

//  Function To Connect Stripe
const stripeConnection = (stripeObj) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/stripe/connectAccount`, stripeObj)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.stripeConnection,
					status: true,
					message: response.data.data.message,
				});
			})
			.catch((error) => {
				console.log('dddsssssssss', error);
				dispatch({
					type: DASHBOARD_ACTION_TYPE.stripeConnection,
					status: false,
					message:
						error.code === 'ERR_NETWORK'
							? error?.message
							: error?.response?.data?.error?.raw?.message
							? error?.response?.data?.error?.raw?.message
							: error?.response?.data?.error,
				});
			});
	};
};

//  Function To Get Stripe Account Status
const getAccountStatus = () => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/stripe/accountstatus`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.stripeAccountStatus,
					status: true,
					message: response.data.data.message,
					data: response.data.data,
				});
			})
			.catch((error) => {
				console.log('>>>>>', error?.response?.data?.error);
				dispatch({
					type: DASHBOARD_ACTION_TYPE.stripeAccountStatus,
					status: false,
					message:
						error.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error.message,
				});
			});
	};
};

//  Function To Reset Stripe Account Status Response
const resetAccountStatus = () => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.stripeAccountStatusIdle,
		});
	};
};

//  Function To Reset Stripe Account Status Response
const stripeConnectionReset = () => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.stripeConnectionIdle,
		});
	};
};

//  Function To Update OnBoard Status Final Submit
const onBoardUpdateStatus = () => {
	return async (dispatch) => {
		console.log('>>>>>>>>>>>>>');
		axios
			.post(`${BASEURL}/api/dashboard/onBoard/updateStatus`)
			.then((response) => {
				console.log('>>>>RESP', response);
				dispatch({
					type: DASHBOARD_ACTION_TYPE.onBoardUpdateStatus,
					status: true,
					message: response.data.data.message,
				});
				dispatch(user.ReGetUserData());
			})
			.catch((error) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.onBoardUpdateStatus,
					status: false,
					message: error.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
				});
			});
	};
};

//  Function To Reset OnBoard Status Final Submit
const onBoardUpdateStatusReset = () => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.onBoardUpdateStatusIdle,
		});
	};
};

// Fecthing All Activities
const getActivities = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/activities`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.ACTIVITIES,
					payload: response.data.data.activities,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

// Function To Fetch All Users
const getAllUsers = (name, role) => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.USERS_FETCHING_START,
		});
		// Fetch data
		axios
			.get(`${BASEURL}/api/users/get`, {
				params: { name, role },
			})
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.ALL_USERS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

//  Function To Reset AllUsers List
const resetAllUsers = () => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.onBoardUpdateStatusIdle,
		});
	};
};

const setAvailability = ({ timeRanges }) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/auth/profile/set-availability`, {
				time_ranges: timeRanges,
			})
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.AVAILABILITY,
					payload: {
						state: true,
						message: DASHBOARD_ACTION_TYPE.AVAILABILITY_SUCCESS,
					},
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				dispatch({
					type: DASHBOARD_ACTION_TYPE.AVAILABILITY,
					payload: {
						state: false,
						message: error?.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
					},
				});
			});
	};
};

const updateProfile = ({ uuid, profileObj }) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/auth/profile/${uuid}`, profileObj)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.PROFILE,
					payload: {
						state: true,
						message: DASHBOARD_ACTION_TYPE.PROFILE_SUCCESS,
					},
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				dispatch({
					type: DASHBOARD_ACTION_TYPE.PROFILE,
					payload: {
						state: false,
						message: error?.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
					},
				});
			});
	};
};

// Function To Track Of Assigning of Tax Pro
const assignRandomTaxProTracker = () => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/leads/assign-randomly`)
			.then((response) => {})
			.catch((error) => {
				// Handle any errors that occurred during the request
			});
	};
};

const taxPlanning = ({ obj }) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/taxform/tax-planing`, obj)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.taxPlanning,
					payload: {
						state: true,
						message: response.data,
					},
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				dispatch({
					type: DASHBOARD_ACTION_TYPE.taxPlanning,
					payload: {
						state: false,
						message: error?.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
					},
				});
			});
	};
};

const resetTaxPlanning = () => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.taxPlanningIdle,
		});
	};
};

const taxPreparation = ({ obj }) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/taxform/tax-preparation`, obj)
			.then((response) => {
				console.log(response);
				dispatch({
					type: DASHBOARD_ACTION_TYPE.taxPreparation,
					payload: {
						state: true,
						message: response.data?.data,
					},
				});
			})
			.catch((error) => {
				console.log(error);
				// Handle any errors that occurred during the request
				dispatch({
					type: DASHBOARD_ACTION_TYPE.taxPreparation,
					payload: {
						state: false,
						message: error?.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
					},
				});
			});
	};
};
const taxPreparationOnFormSave = ({ obj }) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/taxform/tax-preparation`, obj)
			.then((response) => {
				console.log(response);
				dispatch({
					type: DASHBOARD_ACTION_TYPE.taxPreparationOnFormSave,
					payload: {
						state: true,
						message: response.data?.data,
					},
				});
			})
			.catch((error) => {
				console.log(error);
				// Handle any errors that occurred during the request
				dispatch({
					type: DASHBOARD_ACTION_TYPE.taxPreparationOnFormSave,
					payload: {
						state: false,
						message: error?.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
					},
				});
			});
	};
};


const resetTaxPreparation = () => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.taxPreparationIdle,
		});
	};
};
const resetTaxPreparationOnFormSave = () => {
	return async (dispatch) => {
		dispatch({
			type: DASHBOARD_ACTION_TYPE.taxPreparationOnFormSaveIdle,
		});
	};
};

const getLeadsByUUID = ({ UUID }) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/leads/${UUID}`)
			.then((response) => {
				// console.log("lead data>>>>", res.data);
				dispatch({
					type: DASHBOARD_ACTION_TYPE.leadByUUID,
					payload: {
						state: true,
						data: response.data,
						message: 'success',
					},
				});
			})
			.catch((error) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.leadByUUID,
					payload: {
						state: false,
						data: [],
						message: error?.code === 'ERR_NETWORK' ? error?.message : error?.response?.data?.error,
					},
				});
			});
	};
};

const startQuestionnaire = (pro_id) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/assessments/start`, {
				pro_id,
			})
			.then(() => {
				dispatch(user.ReGetUserData());
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

const answerQuestion = (pro_id, currentContent, question_id, answer_id, questions_count) => {
	return async (dispatch) => {
		axios
			.put(`${BASEURL}/api/assessments/answer`, {
				pro_id,
				currentContent,
				question_id,
				answer_id,
				questions_count,
			})
			.then(() => {
				dispatch(user.ReGetUserData());
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

const exportLeadsData = (name, assignedDate, taxpro_id, service, period, dateRange) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/leads/excel-data`, {
				params: {
					name,
					assignedDate,
					taxpro_id,
					service,
					period,
					dateRange,
				},
			})
			.then((response) => {
				console.log(response.data);
				const fileType =
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				const fileExtension = '.xlsx';

				const ws = utils.json_to_sheet(response.data.data);
				const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
				const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
				const data = new Blob([excelBuffer], { type: fileType });
				saveAs(data, 'leads-data' + fileExtension);
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};

const getAllExistingDocuments = (type, keyword, folder) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/documents`, {
				params: { type, keyword, folder },
			})
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.EXISTING_DOCUMENTS,
					payload: response.data.data.data,
				});
			})
			.catch((error) => {
				console.error(error);
			});
	};
};

const calendlyVerification = (code) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/calendly/${code}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.calendlyVerificarionSuccess,
					payload: {
						message: DASHBOARD_ACTION_TYPE.calendlyVerificarionSuccess,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.then(() => {
				dispatch(user.ReGetUserData());
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.calendlyVerificarionError,
					payload: {
						message: error?.response?.data?.error,
						status: false,
						data: {},
					},
				});
			});
	};
};

const outlookCalendarVerification = (code) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/outlookcalendar/${code}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.outlookCalendarVerificarionSuccess,
					payload: {
						message: DASHBOARD_ACTION_TYPE.outlookCalendarVerificarionSuccess,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.then(() => {
				dispatch(user.ReGetUserData());
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.outlookCalendarVerificarionError,
					payload: {
						message: error?.response?.data?.error,
						status: false,
						data: {},
					},
				});
			});
	};
};


const googleCalendarVerification = (code) => {
	return async (dispatch) => {
		axios
		.get(`${BASEURL}/api/googlecalendar/${code}`)
		.then((response) => {
			// HANDLE RESPONSE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.googleCalendarVerificationSuccess,
					payload: {
						message: DASHBOARD_ACTION_TYPE.googleCalendarVerificationSuccess,
						status: true,
						data: response?.data?.data,
					},
				});
			})
			.then(() => {
				dispatch(user.ReGetUserData());
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.googleCalendarVerificationError,
					payload: {
						message: error?.response?.data?.error,
						status: false,
						data: {},
					},
				});
			});
	};
};
const getUserAppointment = (id) => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/appointments/user?userId=${id}`)
			.then((response) => {
				dispatch({
					type: DASHBOARD_ACTION_TYPE.USER_APPOINTMENT,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				// Handle any errors that occurred during the request
				console.error(error);
			});
	};
};
const createFileUpload = (formData) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/documents`, formData,{
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			})
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.createFileUpload,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.createFileUpload,
					payload: {
						message: handleDashboardError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};
const updateJobStatusByTaxPro = (uuid) => {
	return async (dispatch) => {
		axios
			.patch(`${BASEURL}/api/jobs/update-status-bytaxPro/${uuid}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.updateJobStatusByTaxPro,
					payload: {
						message: 'success',
						status: response?.data?.success,
						data: response?.data?.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: DASHBOARD_ACTION_TYPE.updateJobStatusByTaxPro,
					payload: {
						message: handleDashboardError('simple', error),
						status: false,
						data: {},
					},
				});
			});
	};
};
const handleDashboardError = (type, error) => {
	if (type === 'simple') {
		return error.code === 'ERR_NETWORK'
			? error?.message
			: error?.response?.data?.error
				? error?.response?.data?.error
				: 'Internel Server Error!';
	}
};
export const dashboard = {
	Agreement,
	userPersonalDetails,
	getServices,
	getUserRoles,
	getTaxProfessionals,
	addService,
	AddDriverLicense,
	getTotalCounts,
	getRecentLeads,
	getCalendarData,
	getChartData,
	getLeads,
	getDisputes,
	getJobs,
	getProjects,
	deleteLead,
	deleteJob,
	deleteAppointment,
	getUsers,
	getDocuments,
	getFolders,
	getCustomFolders,
	getAppointments,
	getUpcomingAppointments,
	getUsersForAppointment,
	getAvatars,
	getDocumentNames,
	getAccountManagers,
	deleteDocument,
	reset,
	stripeConnection,
	getAccountStatus,
	resetAccountStatus,
	onBoardUpdateStatus,
	stripeConnectionReset,
	onBoardUpdateStatusReset,
	getActivities,
	getAllUsers,
	resetAllUsers,
	setAvailability,
	updateProfile,
	assignRandomTaxProTracker,
	taxPlanning,
	resetTaxPlanning,
	getLeadsByUUID,
	taxPreparation,
	resetTaxPreparation,
	startQuestionnaire,
	answerQuestion,
	getAllLeads,
	exportLeadsData,
	getAllExistingDocuments,
	calendlyVerification,
	outlookCalendarVerification,
	googleCalendarVerification,
	getUserAppointment,
	createFileUpload,
	updateJobStatusByTaxPro,
	taxPreparationOnFormSave,
	resetTaxPreparationOnFormSave
};
