/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import CalendarSvg from '../../../assets/img/start-adornment.svg'
import FileSvg from '../../../assets/img/filesvg.svg'
import PlusSvg from '../../../assets/img/plussvg.svg'
import FileBlueSvg from '../../../assets/img/filebluesvg.svg'
import QuestionaireSvg from '../../../assets/img/quetenionairesvg.svg'
import BinSvg from '../../../assets/img/binsvg.svg'
import EditDocumentSvg from '../../../assets/img/editrequestsvg.svg'
import RequestDocumentSvg from '../../../assets/img/requestfilesvg.svg'
import DeleteIcon from '../../../assets/img/x.svg'
import DeleteIcon2 from '../../../assets/img/delete1.svg'
import SearchIcon from '../../../assets/img/search.svg'
import dropdownIcon from '../../../assets/img/angle-down-small.svg'
import editIcon from '../../../assets/img/edit1.svg'
import { GLOBAL_VARIABLES } from '../../../utilites/variables';
import Checkbox from '../../../components/forms/fields/Checkbox';
import dayjs from 'dayjs'





import { useDispatch, useSelector } from 'react-redux';
import {DatePicker} from 'antd';
import {
	BASEURL,
	// getLeadStatus,
	// setQueryParams,
	getQueryParam,
	useAutoReplyHook,
	// removeQueryParam,
} from '../../../utilites';
import allActions from '../../../Store/action';

import Header from '../components/Header';
import Preloader from '../components/Preloader';
import Select from '../../../components/forms/fields/Select';
import Input from '../../../components/forms/fields/Input';
import TextArea from '../../../components/forms/fields/TextArea';
import DropDown from '../../../components/forms/fields/DropDown';
import Message from '../../../components/forms/fields/Message';
import UserAvatar from '../../../components/UserAvatar';
import Modal from '../../../components/Dashboard/Modal';
import FormPreviewModal from '../../../components/forms/Modal/FormPreviewModal';
import { getCurrentFormsObj } from '../../../utilites/functions';
import CreateNewForm from '../../../components/forms/CreateNewForm';
import OpenJobForm from '../../../components/forms/OpenJobForm';
import RequestJobDocumentForm from '../../../components/forms/RequestJobDocumentForm';


const invoicePeriodOptions = [
	{ value: '3', label: '3' },
	{ value: '6', label: '6' },
	{ value: '12', label: '12' },
];
const partionalPaymentOption = [
	{ value: 20, label: '20%' },
	{ value: 40, label: '40%' },
	{ value: 60, label: '60%' },
	{ value: 80, label: '80%' },
	{ value: 'custom', label: 'Custom' },
];
const invoiceOptions = [
	{ value: 'FullPayment', label: 'Full Payment' },
	{ value: 'PartialPayment', label: 'Partial Payment' },
	// { value: 'RecurringPayment', label: 'Recurring Payment' },
];
const defaultDocument = [
	{ id: 1, name: 'W-2 (Wages)', description: 'W-2 (Wages)', message: '' },
	{ id: 5, name: 'Real Estate Taxes', description: 'Real Estate Taxes', message: '' },
	{ id: 2, name: '1099 (Banks and Brokerages)', description: '1099 (Banks and Brokerages)', message: '' },
	{ id: 4, name: '1098s (Home Mortgage Interest)', description: '1098s (Home Mortgage Interest)', message: '' }
]


const { DocumentOptions } = GLOBAL_VARIABLES;



const CustomDeleteModal = ({customModalOpen,setCustomModalOpen,handleDeleteTemplate,dynamicForms}) => {
	return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)} >
	<div className="custom-modal-cont-delete" onClick={(e) => e.stopPropagation()}>
		<h2 className="custom-modal-heading">Delete questionnaire</h2>
		<p className="custom-modal-description">Are you sure you want to delete this template?</p>
		<div className="custom-modal-buttons">
            <button className="btn" onClick={() => {
              setCustomModalOpen(null)
            }}>Cancel</button>
            <button  className="btn btn-danger" onClick={() => {
			  const templateToDelete = dynamicForms?.find(form => form?.form_data?.name === customModalOpen?.data)
              handleDeleteTemplate(templateToDelete?.id)
			  setCustomModalOpen(null)
            }} >Delete</button>
          </div>
		<div className="custom-modal-buttons">
		</div>
	</div>
</div>;
}	

const CreateFormTitleModal = ({setCustomModalOpen,formTitle,setFormTitle,setCreateModalOpen}) => {
	return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)}>
		<div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
			<h2 className="custom-modal-heading">New questionnaire</h2>
			<div className="custom-modal-input-cont">
				  <label htmlFor="section-title" className='text-dark txt-sm'>Enter questionnaire name</label>
				  <input type="text" id="section-title" value={formTitle} onChange={(e) => setFormTitle(e.target.value)} />
			</div>
			<div className="custom-modal-buttons">
			  <button className="btn" onClick={() => {
				setCustomModalOpen(null)
			  }}>Cancel</button>
			  <button  className="btn btn-primary" onClick={() => {
				setCustomModalOpen(null)
				setCreateModalOpen({state:true,editmode:false})
			  }} disabled={formTitle?.length < 3}>Create</button>
			</div>
		</div>
	</div>;
  };

  const EditFormTitleModal = ({setCustomModalOpen,customModalOpen,formTitle,selectedDynamicForms,setSelectedDynamicForms}) => {
    const [title,setTitle] = useState(formTitle)
    return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)}>
      <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
        <h2 className="custom-modal-heading">Edit questionnaire tittle</h2>
        <div className="custom-modal-input-cont">
            <label htmlFor="section-title" className='fs-6'>Enter a single sentence description that best describes the purpose of your questionnaire.</label>
            <input type="text" id="section-title" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="custom-modal-buttons">
          <button className="btn" onClick={() => {
          setCustomModalOpen(null)
          }}>Cancel</button>
          <button di className="btn btn-primary" onClick={() => {
            let updatedDynaimcForms = [...selectedDynamicForms]
             updatedDynaimcForms = updatedDynaimcForms?.map((form) => {
              if(form?.name === customModalOpen?.data){
                return {
                  ...form,
                  name:title
                }
              }
              return form
            }
          ) 
          setSelectedDynamicForms(updatedDynaimcForms)
          setCustomModalOpen(false)
          }} disabled={title?.length < 3}>Create</button>
        </div>
      </div>
    </div>;
    };

const CreateFormModal = ({children,setCreateModalOpen}) => {
	return <div className="custom-modal-overlay" onClick={() => {
    document.body.style.overflowY = '';
  }}>
		<div className="custom-modal-cont-create-form" onClick={(e) => e.stopPropagation()}>
			{children}
		</div>
	</div>;
  };

const CreateJob = () => {

   
	const [{loginStatus, LoginData }, { documentNames, taxPreparation, taxPlanning }, { personalConnections },{createDocumentGroup,getTaxProdocumentGroup,getTaxProAdmindocumentGroup,deleteDocumentGroup},{createMilestone}] =
		useSelector((state) => [state.user, state.dashboard, state.firebase,state.documentGroup,state.milestone]);
    const location = useLocation();

    const  paramsData  = location?.state?.paramsData 
    const [lead, setLead] = useState(null);
    const [alertMessage, setAlertMessage] = useState({ show: false });
    // Form
    const [serviceType, setServiceType] = useState(getQueryParam('service') || lead?.services[0]?.id);
	const [otherServiceType, setOtherServiceType] = useState('');
	const [completionDate, setCompletionDate] = useState();
	const [invoiceDeadline, setInvoiceDedline] = useState(new Date( paramsData?.invoiceDueDate));
	const [cost, setCost] = useState(paramsData?.invoiceAmount);
	const [uncleKamFee, setUncleKamFee] = useState();
	const [remaning, setRemanning] = useState();
	const [message, setMessage] = useState();
	const [documents, setDocuments] = useState([]);
	const [otherDocumentName,setOtherDocumentName] = useState('');
	const [selectedFormNames, setSelectedFormNames] = useState([]);
	const [invoiceType, setInvoiceType] = useState(paramsData?.invoiceType);
	const [agreement, setAgreement] = useState(false);
	const [subscriptionOption, setSubscriptionOption] = useState();
	const [deduction, setDeduction] = useState();
	const [customPartial, setCustomPartial] = useState();
	const [createContractResponse, setCreateContractResponse] = useState();
	const [isDraft, setIsDraft] = useState(false);
	const [selectedDocuments, setSelectedDocuments] = useState([])
	const [editDocument, setEditDocument] = useState('')
  const [docMessage, setdocMessage] = useState({show:false})
	// const [addMessageOnRequest, setAddMessageOnRequest] = useState('')
	const [otherDocumentId, setOtherDocumentId] = useState('')
	const [documentsSelectedInDropdown, setDocumentsSelectedIndropDown] = useState([])
	const [selectedDocumentIds,setSelectedDocumentIds] = useState([])
	const [selectedForms,setSelectedForms] = useState([])
	const [selectedDynamicForms,setSelectedDynamicForms] = useState([])
	const [dynamicForms,setDynamicForms] = useState([])
	const [customModalOpen,setCustomModalOpen] = useState(null)
	const [searchModalOpen,setSearchModalOpen] = useState(null)
	const [questionaireSearchValue,setQuestionaireSearchValue] = useState('')
	const [formTitle,setFormTitle] = useState('')
	const [createFormModalOpen,setCreateFormModalOpen] = useState({state:false,editmode:false})
  const [dynamicFormsCreatedByAdmins, setDynamicFormsCreatdByAdmins] = useState([])
	const [selectedJob, setSelectedJob] = useState(null);
  const [messageSaveForm, setmessageSaveForm] = useState({ show: false });
  const [messageDeleteForm, setmessageDeleteForm] = useState({ show: false });
  const [activeFormData,setActiveFormData] = useState({})
  const [groupName, setGroupName] = useState('');
	const [modalMessage, setmodalMessage] = useState({ show: false });

	const modalRef = useRef(null);
	const [errors, setErrors] = useState({
		serviceType: false,
		completionDate: false,
		cost: false,
		message: false,
		documents: false,
		otherServiceType: false,
		otherDocumentName: false,
		general: '',
	});

	const { uuid } = useParams();

  
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const button = useRef();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const [milestones, setMilestones] = useState([
		{ name: "", dueDate: "", amount: ""},
	]);
	const [isMilestonesValid,setIsMilestonesValid] = useState(false);
	const [totalCost, setTotalCost] = useState(0);
	const milestonesFee = totalCost * 0.2;
	const youReceive = totalCost - milestonesFee;

	const handleAddMilestone = () => {
		setMilestones([
			...milestones,
			{name: ``, dueDate: "", amount: 0},
		]);
	};

	const handleMilestoneChange = (index, field, value) => {
		const updatedMilestones = milestones.map((milestone, i) =>
			i === index ? { ...milestone, [field]: value } : milestone
		);
		setMilestones(updatedMilestones);

		// Update total cost
		const newTotal = updatedMilestones.reduce(
			(sum, milestone) => sum + parseFloat(milestone.amount || 0),
			0
		);
		setTotalCost(newTotal);
	};
	const prevTotalCostprevTotalCost = (index) => {
		// Use the filter method to create a new array excluding the milestone at the given index
		setMilestones((prevMilestones) =>
			prevMilestones.filter((_, i) => i !== index)
		);

		// Optional: Recalculate the total cost after deleting a milestone
		setTotalCost((prevTotalCost) =>
			milestones.reduce((sum, milestone, i) =>
					i !== index ? sum + parseFloat(milestone.amount || 0) : sum
				, 0)
		);
	};
	const handleDeleteMilestone = (index) => {
		setMilestones((prevMilestones) =>
			prevMilestones.filter((_, i) => i !== index)
		);

		setTotalCost((prevTotalCost) =>
			milestones.reduce((sum, milestone, i) =>
					i !== index ? sum + parseFloat(milestone.amount || 0) : sum
				, 0)
		);
	};

  useEffect(()=>{
    if (/^\d*\.?\d*$/.test(paramsData?.invoiceAmount)) {
      setCost(paramsData?.invoiceAmount);
      setUncleKamFee(
        (
          (parseInt(
            LoginData?.userpro?.percentage ||
              lead?.userpro?.percentage
          ) /
            100) *
            paramsData?.invoiceAmount
        ).toFixed(2)
      );
      setRemanning(
        (
          paramsData?.invoiceAmount -
          (
            (parseInt(
              LoginData?.userpro?.percentage ||
                lead?.userpro?.percentage
            ) /
              100) *
              paramsData?.invoiceAmount
          ).toFixed(2)
        ).toFixed(2)
      );
    }
  },[paramsData?.invoiceAmount])
	const loadLeadByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/leads/${UUID}`)
			.then((res) => {
				console.log('lead data>>>>', res.data);
				setLead(res.data);
				if (!getQueryParam('service')) setServiceType(res.data?.services[0]?.id);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadLeadByUUID(uuid);
	}, [uuid, loadLeadByUUID]);

	useEffect(() => {
		dispatch(allActions.dashboard.getDocumentNames());
	}, [dispatch]);


    useEffect(() => {
        if(createMilestone?.status === true){
    

        dispatch(allActions.global.Loader(false));
}
        else if(createMilestone?.status === false){

        }       

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[createMilestone])


	useEffect(() => {
		// if Success
		if (taxPreparation?.state === true) {
			setAlertMessage({ show: false });
			changeLoading(false);
			navigate(`/dashboard/jobs/${createContractResponse?.data?.data?.uuid}`, {
				state: { message: 'Contract Created Successfully!' },
			});
			dispatch(allActions.dashboard.resetTaxPreparation());
		}

		// if Error
		if (taxPreparation?.state === false) {
			// Show Message Alert
			setAlertMessage({
				show: true,
				type: 'Error',
				text: taxPreparation.message,
			});
			changeLoading(false);
			dispatch(allActions.dashboard.resetTaxPreparation());
		}
	}, [taxPreparation.message, taxPreparation?.state]);

	useEffect(() => {
		// if Success
		if (taxPlanning?.state === true) {
			setAlertMessage({ show: false });
			changeLoading(false);
			navigate(`/dashboard/jobs/${createContractResponse?.data?.data?.uuid}`, {
				state: { message: 'Contract Created Successfully!' },
			});
			dispatch(allActions.dashboard.resetTaxPlanning());

		}

		// if Error
		if (taxPlanning?.state === false) {
			// Show Message Alert
			setAlertMessage({
				show: true,
				type: 'Error',
				text: taxPlanning.message,
			});
			changeLoading(false);
			dispatch(allActions.dashboard.resetTaxPlanning());
		}
	}, [taxPlanning.message, taxPlanning?.state]);

	useEffect(() => {
		// Validate other document name
		if (
			documentNames.filter(
				(documentName) =>
					documentName.name?.toLowerCase() === 'others' &&
					documents.includes(documentName.id.toString())
			).length > 0 &&
			otherDocumentName === ''
		) {
			!errors.otherDocumentName && setErrors({ ...errors, otherDocumentName: true });
		} else {
			errors.otherDocumentName && setErrors({ ...errors, otherDocumentName: false });
		}
		const doc = documentsSelectedInDropdown?.find(doc => doc?.description?.toLowerCase() === 'others')
		if (doc) {
			setOtherDocumentId(doc?.id)
		} else {
      setOtherDocumentId()
		}
	}, [selectedDocuments, setErrors, errors, documentNames, otherDocumentName, documentsSelectedInDropdown]);

  
	useEffect(() => {
		// Validate other service type
		if (serviceType === 'others' && otherServiceType === '') {
			!errors.otherServiceType && setErrors({ ...errors, otherServiceType: true });
		} else {
			errors.otherServiceType && setErrors({ ...errors, otherServiceType: false });
		}
	}, [serviceType, setErrors, errors, otherServiceType]);

	const getInvoiceData = () => {
		let obj = { invoice_data: {}, payment_due_date: invoiceDeadline };
		// For Partial Payment
		if (invoiceType?.value === 'PartialPayment') {
			obj.invoice_data.deductionType = deduction?.value === 'custom' ? '' : '%';
			obj.invoice_type = 'partial';
			obj.invoice_data.deduction = deduction?.value === 'custom' ? customPartial : deduction?.value;
			obj.invoice_data.fullAmount = cost;
			obj.invoice_data.remainingAmount =
				deduction?.value === 'custom'
					? cost - customPartial
					: cost - ((deduction?.value / 100) * cost).toFixed(2);
		}
		// For Subscription Payment
		if (invoiceType?.value === 'RecurringPayment') {
			obj.invoice_type = 'subscription';
			obj.invoice_data.deduction = subscriptionOption?.value;
			// obj.invoice_data.fullAmount = cost;
			// obj.invoice_data.remainingAmount = cost;
		}
		if (invoiceType.value === 'FullPayment') {
			obj.invoice_type = 'full';
			// obj.invoice_data.fullAmount = cost;
			// obj.invoice_data.remainingAmount = cost;
		}
		return obj;
	};

	const getDynamicForms = async () => {
    const endpoint = "get-dynamic-form"
		try {
		const resp = await axios.get(`${BASEURL}/api/taxform/${endpoint}?pro_user_id=${LoginData.isAdmin?lead?.userpro?.user?.id:null}`)    
			setDynamicForms(resp?.data?.data)
		} catch (error) {
			console.log(error);
		}
	}
  const getDynamicFormsCreatedByAddmins = async () => {
		try {
		const resp = await axios.get(`${BASEURL}/api/taxform/get-dynamic-form-created-by-admin`)
    setDynamicFormsCreatdByAdmins(resp?.data?.data)
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
    if(lead){
      getDynamicForms()
      getDynamicFormsCreatedByAddmins()
    }
	},[lead])
	
	useEffect(() => {
		if(isMilestonesValid && milestones?.length > 0){
			setIsMilestonesValid(false)
		}
	}, [milestones]);

	function validateMilestones(milestones) {
		return milestones.every(
			({ name, dueDate, amount }) => name && dueDate && amount
		);
	}
	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errors.general !== '') setErrors({ errors, general: '' });
		if(milestones?.length === 0){
			setIsMilestonesValid(true)
			setAlertMessage({
				show: true,
				type: 'Error',
				text: 'Please ensure at least one milestone is added for this job before proceeding.'
			});
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});

			return
		}
		if(!validateMilestones(milestones)){
			setIsMilestonesValid(true)
			setAlertMessage({
				show: true,
				type: 'Error',
				text: 'Please fill in all required fields (name, due date, and amount) for all milestones before proceeding.'
			});
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});

			return
		}
		button.current.disabled = true;
		const documents = selectedDocuments
		let selectedDocNames = documentNames?.filter((obj) => documents?.some(doc => doc.id === obj.id));
		let taxPlanObj = {
			lead_id: lead.id,
			service_id: serviceType,
			method: 'create',
			docName: selectedDocNames?.map((item) => item?.name),
      job_uuid:23
		};
    
		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPlanning());
		dispatch(allActions.dashboard.resetTaxPreparation());
		setAlertMessage({ show: false });
		axios
			.post(`${BASEURL}/api/jobs/create`, {
				lead_id: lead.id,
				service_id: serviceType,
				other_service_type: otherServiceType,
				customer_id: lead.user.id,
				pro_id: LoginData?.userpro?.id || lead.userpro?.id,
				additional: message,
				// other_document_name: otherDocumentName,
				isDraft,
				completionDate,
				cost,
				documents,
				// ...getInvoiceData(),
			})
			.then( async(response) => {
				setCreateContractResponse(response);
				handleAutoMessaging({ response });
				await milestones.map( async(v,i)=>
					 dispatch( await allActions.milestone.createMilestone({...v,job_id:response?.data?.data?.id,status_id:"0",user_id:lead?.user?.id})))
				if (
					!(
						taxPlanObj?.service_id === 2 &&
						(LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager) &&
						taxPlanObj?.service_id === 1
					)
				) {
					button.current.disabled = false;
				}
				// if SERVICE IS TAX PLAN
				if (taxPlanObj?.service_id === 'others' || taxPlanObj?.service_id === 2 || taxPlanObj?.service_id === '2' ||  taxPlanObj?.service_id === 3  || taxPlanObj?.service_id === '3' ||  taxPlanObj?.service_id === 4  || taxPlanObj?.service_id === '4') {
          
          const dynamicForms = selectedDynamicForms?.map((form) => {
						return {name:form?.name, message:form?.message,formData:form?.data}
					})
          console.log("@4444 step1",dynamicForms,selectedDynamicForms);

          const staticForms = selectedFormNames?.map((form) => {
						return {name:form.value, message:form.message,formData:{}}
					});
          taxPlanObj.formName = [...dynamicForms,...staticForms]
					// taxPlanObj.formData = {nn:'dd'};
          taxPlanObj.job_uuid=response?.data?.data?.uuid  
          if (taxPlanObj.formName.length > 0) {
						callTaxPlanning(taxPlanObj);
					}
          else {
            changeLoading(false);
            // Navigate to lead details page
            navigate(`/dashboard/jobs/${response?.data?.data?.uuid}`, {
              state: { message: 'Contract Created and sent to customer Successfully!' },
            });
          }
				}
				// if SERVICE IS TAX PREPARATION
				else if (taxPlanObj?.service_id === '1' || taxPlanObj?.service_id === 1) {
          
          // Modify Object To Tax Preparation
					delete taxPlanObj.docName;
          const dynamicForms = selectedDynamicForms?.map((form) => {
            return {name:form?.name, message:form?.message,formData:form?.data}
					})
          console.log("@44444 step2",dynamicForms,selectedDynamicForms);
          const staticForms = selectedFormNames?.map((form) => {
						return {name:form.value, message:form.message,formData:{}}
					});
          taxPlanObj.formName = [...dynamicForms,...staticForms]
          taxPlanObj.job_uuid=response?.data?.data?.uuid          
					// taxPlanObj.formData = {nn:'dd'};
          if (taxPlanObj.formName.length > 0) {
					callTaxPreparation(taxPlanObj);
          }
          else {
            changeLoading(false);
            // Navigate to lead details page
            navigate(`/dashboard/jobs/${response?.data?.data?.uuid}`, {
              state: { message: 'Contract Created and sent to customer Successfully!' },
            });
          }
				}
				// FOR OTHER SERVICES
				else {
					changeLoading(false);
					await milestones.map( async(v,i)=>
						dispatch( await allActions.milestone.createMilestone({...v,job_id:response?.data?.data?.id,status_id:"0",user_id:lead?.user?.id})))
					// Navigate to lead details page
					navigate(`/dashboard/jobs/${response?.data?.data?.uuid}`, {
						state: { message: 'Contract Created and sent to customer Successfully!' },
					});
				}
			})
			.catch((error) => {
				changeLoading(false);
				setAlertMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error || error?.message,
				});
				button.current.disabled = false;
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const callTaxPlanning = (taxPlanObj) => {
		reset_mesage();
		dispatch(allActions.dashboard.resetTaxPlanning());
		dispatch(allActions.dashboard.taxPlanning({ obj: taxPlanObj }));
	};

	const callTaxPreparation = (taxPreparationObj) => {
		reset_mesage();
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.dashboard.taxPreparation({ obj: taxPreparationObj }));
	};

	const reset_mesage = () => {
		setAlertMessage({ show: false });
	};
	const handleAutoMessaging = ({ response }) => {
		// let message = `<p>Based on our discussions, I've create a contract for you to review and approve.</p>
		// <img src="https://cdn-icons-png.flaticon.com/512/2666/2666523.png" class="bg-white p-1 rounded" alt="Girl in a jacket" width="60" height="60">
		// <br></br>
		// <a href="${window.location.origin}/dashboard/contract/${response?.data?.data?.uuid}" target="_blank" >Contract</a>`;

		// handleAutoReply({
		// 	personalConnections,
		// 	LoginData,
		// 	receiver: lead?.user,
		// 	type: 'simple',
		// 	encodedString: isDraft
		// 		? `<p>Please <a href="${window.location.origin}/dashboard/contract/${response?.data?.data?.uuid}" target="_blank" >click here</a> to upload the new documents I have requested.</p>`
		// 		: message,
		// });

		var encodedString = window.btoa(
			JSON.stringify({
				milestones:milestones,
				milestone_action:'create-contract'				
			})
		);
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: lead?.user,
			type: "milestone",
			encodedString: encodedString,
		  });
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	useEffect(() => {
		if (isDraft === true) {
			// Set default values for draft contract payment info part.
			setCompletionDate(new Date());
			setCost(0);
			setUncleKamFee(0);
			setRemanning(0);
			setInvoiceType({ label: 'Full Payment', value: 'FullPayment' });
			setInvoiceDedline(new Date());
		} else {
			// Clean up the default values for draft contract payment info part.
			setCompletionDate();
      if(!paramsData){
        setInvoiceDedline();
        setInvoiceType();
        setCost();
        setUncleKamFee();
        setRemanning();
      }
			// Reset documents state as well
			setDocuments([]);
		}
	}, [isDraft]);

	useEffect(() => {
		setSelectedDocumentIds(selectedDocuments?.map((doc) => doc?.id ))
	},[selectedDocuments])

	useEffect(() => {
       setSelectedForms(selectedFormNames?.map(name => name?.value))
	},[selectedFormNames])

	// useEffect(() => {
	// 	setSelectedDynamicForms(dynamicForms?.map(name => name?.form_data?.name))
	//  },[dynamicForms])


	useEffect(() => {
		if (loginStatus && createDocumentGroup.status !== true && lead) {  
			dispatch(allActions.global.Loader(true));      
			dispatch(allActions.documentsgroup.getTaxProdocumentsgroup(LoginData.isAdmin?lead?.userpro?.user?.id:null
      ));
		}
	}, [loginStatus, dispatch,lead]);

  useEffect(() => {
		if (loginStatus) {
			dispatch(allActions.global.Loader(true));
			dispatch(allActions.documentsgroup.getTaxProAdmindocumentsgroup());
		}
	}, [loginStatus, dispatch]);

	// create Group  document
	useEffect(() => {
		if (!createDocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setmodalMessage({
				show: true,
				type: 'Error',
				text: createDocumentGroup.message,
			});
		}
		if (createDocumentGroup.status === true) {
			document.querySelector('.requestJobDocumentsModalClose')?.click();
      dispatch(allActions.documentsgroup.getTaxProdocumentsgroup(LoginData.isAdmin?lead?.userpro?.user?.id:null
      ));
      dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, createDocumentGroup]);


  // get   Group document
	useEffect(() => {
		if (!getTaxProdocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: getTaxProdocumentGroup.message,
			});
		}
		if (getTaxProdocumentGroup.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, getTaxProdocumentGroup]);


    // get admin  Group document
	useEffect(() => {
		if (!getTaxProAdmindocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: getTaxProAdmindocumentGroup.message,
			});
		}
		if (getTaxProAdmindocumentGroup.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, getTaxProAdmindocumentGroup]);


	// delete Group  document
	useEffect(() => {
		if (!deleteDocumentGroup.status) {
			dispatch(allActions.global.Loader(false));      
			setdocMessage({
				show: true,
				type: 'Error',
				text: deleteDocumentGroup.message,
			});
		}
		if (deleteDocumentGroup.status === true) {
				dispatch(allActions.documentsgroup.getTaxProdocumentsgroup(LoginData.isAdmin?lead?.userpro?.user?.id:null
      ));
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, deleteDocumentGroup]);

	const handleSelectDocument = (document) => {
    document.forEach((documents)=>{ 
		const isDocument = selectedDocuments.find(doc => doc.id === document.id)
		if (!isDocument) {
			setSelectedDocuments(prev => [...prev, documents])
			setDocumentsSelectedIndropDown(prev => [...prev, documents])
		} else {
			const filterAndDelete = selectedDocuments.filter(document => document.id !== isDocument.id)
			setSelectedDocuments(filterAndDelete)
			setDocumentsSelectedIndropDown(filterAndDelete)
		}
  })
	}

	const handleDeleteSelectedDocument = (id) => {
		const filterAndDelete = selectedDocuments.filter(document => document.id !== id)
		setSelectedDocuments(filterAndDelete)
		setDocumentsSelectedIndropDown(filterAndDelete)
	}


	const openJobData = (form) => {
		let formData={
			name:form?.value,
			formData:getCurrentFormsObj(form?.value)
		}
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(allActions.taxForms.dispatchViewFormDetails({document:formData,navigate:()=>document.querySelector('.formPreview').click()}));
	};

	const handleDeleteTemplate = async (id) => {
    const endpoint ="delete-dynamic-form"
		changeLoading(true)
		try {
				await axios.delete(`${BASEURL}/api/taxform/${endpoint}`,{data:{form_id:id,pro_user_id:LoginData.isAdmin?lead?.userpro?.user?.id:null}})
				getDynamicForms()
				changeLoading(false)
        setmessageDeleteForm({
          show: false,
        });
			} catch (error) {
				console.log(error);
        changeLoading(false)
        setmessageDeleteForm({
          show: true,
          type: 'Error',
          text:error?.response?.data?.error?error?.response?.data?.error:'server error'
        });
			}
	}	

	const handleSaveTemplate = async (formTitle,taxForm) => {
    const endpoint ="create-dynamic-form"
		changeLoading(true)
		try { 
		 await axios.post(`${BASEURL}/api/taxform/${endpoint}`,{form_data:{name:formTitle,data:taxForm,byAdmin:false},pro_user_id:LoginData.isAdmin?lead?.userpro?.user?.id:null})
     if(getDynamicForms){
       getDynamicForms()
      }
      changeLoading(false)
      setmessageSaveForm({
				show: false,
			});
		} catch (error) {
      changeLoading(false)
      setmessageSaveForm({
        show: true,
        type: 'Error',
        text:error?.response?.data?.error?error?.response?.data?.error:'server error'
      });
		  console.log(error);
		}
	}
	

	useEffect(() => {
		function handleClickOutside(event) {
		  if (searchModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
			setSearchModalOpen(false);
		  }
		}
	
		if (searchModalOpen) {
		  document.addEventListener('mousedown', handleClickOutside);
		}
	
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside);
		};
	  }, [searchModalOpen, setSearchModalOpen]);
	
		
    useEffect(() => {
        document.body.style.overflow = 'auto';    
    }, []);
  	const handleRequest = (doc) => {
      let body = {
        doc_data: {
          documents: doc,
          groupName: groupName,
        },
        pro_user_id:LoginData.isAdmin?lead?.userpro?.user?.id:null
      };    
      dispatch(allActions.global.Loader(true));
      dispatch(allActions.documentsgroup.createdocumentsgroupidle());
      dispatch(allActions.documentsgroup.createdocumentsgroup(body,'create-groupDocument'));
    };  
const handleDeleteGroupDocument=()=>{
  let body = {
    doc_id: customModalOpen?.data?.id,
    pro_user_id:LoginData.isAdmin?lead?.userpro?.user?.id:null

  };
  dispatch(allActions.global.Loader(true));
  dispatch(allActions.documentsgroup.deletedocumentsgroupidle());
  dispatch(allActions.documentsgroup.deletedocumentsgroup(body,'delete-groupDocument'));
  
}
const singleDocumentRequestHandle =(docs)=>{  
  setSelectedDocuments([...selectedDocuments,...docs])
  }
	if (lead !== null) {
		return (
      <div className="clearfix">
        {customModalOpen?.state === "delete" && (
          <CustomDeleteModal
            customModalOpen={customModalOpen}
            dynamicForms={dynamicForms}
            setCustomModalOpen={setCustomModalOpen}
            handleDeleteTemplate={handleDeleteTemplate}
          />
        )}
        {customModalOpen?.state === "formtitle" && (
          <CreateFormTitleModal
            setCustomModalOpen={setCustomModalOpen}
            formTitle={formTitle}
            setFormTitle={setFormTitle}
            setCreateModalOpen={setCreateFormModalOpen}
          />
        )}
        {customModalOpen?.state === "editformtitle" && (
          <EditFormTitleModal
            setCustomModalOpen={setCustomModalOpen}
            customModalOpen={customModalOpen}
            formTitle={customModalOpen?.formTitle}
            selectedDynamicForms={selectedDynamicForms}
            setSelectedDynamicForms={setSelectedDynamicForms}
          />
        )}
        {customModalOpen?.state === "openjobform" && (
          <CreateFormModal setCreateModalOpen={setCustomModalOpen}>
            <OpenJobForm
              selectedJob={selectedJob}
              setIsFormModalOpen={setCustomModalOpen}
              activeFormData={activeFormData}
              setActiveFormData={setActiveFormData}
              previewMode={true}
            />
          </CreateFormModal>
        )}
        {customModalOpen?.state === "delete-groupDocument" && (
          <CustomDeleteModal
            customModalOpen={customModalOpen}
            dynamicForms={dynamicForms}
            setCustomModalOpen={setCustomModalOpen}
            handleDeleteTemplate={handleDeleteGroupDocument}
          />
        )}

        <Header name="Create Contract" />
        <div className="container page">
          <form className="clearfix pt-4 mb-4" onSubmit={handleSubmit}>
            {alertMessage.show ? <Message message={alertMessage} /> : <></>}
            <div className="card border-1 border-light rounded p-4 mb-4">
              <div className="card-header d-flex justify-content-between bg-white border-0">
                <h3 className="card-title text-primary fw-bold">Customer</h3>
              </div>
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div>
                    <UserAvatar
                      avatar={lead.user.avatar}
                      alt={`${lead.user.firstname} ${lead.user.lastname}`}
                      className="img-fluid object-fit-cover rounded-circle"
                      style={{ height: 40 }}
                    />
                  </div>
                  <div>
                    <p className="text-black fw-bold m-0 ms-2">
                      {lead.user.firstname} {lead.user.lastname}
                    </p>
                    <p className="text-muted m-0 ms-2">Tax Pro</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-1 border-light rounded p-4 mb-4">
              <div className="card-header d-flex justify-content-between bg-white border-0">
                <h3 className="card-title text-primary fw-bold">Services</h3>
                {/* <div className="card-actions">
									<span className="btn btn-sm text-primary">+</span>
									<span className="btn btn-sm text-primary">
										<BsThreeDotsVertical />
									</span>
								</div> */}
              </div>
              <div className="card-body">
                <div className="mb-4">
                  <div className="text-dark">Service type</div>
                  <Select
                    value={serviceType}
                    name="serviceType"
                    placeholder="Service type"
                    fn={setServiceType}
                  >
                    {lead.services?.map((service) => (
                      <option key={service.id} value={service.id}>
                        {service.name}
                      </option>
                    ))}
                    <option value="others">Others</option>
                  </Select>
                </div>

                {serviceType === "others" && (
                  <div className="mb-4">
                    <Input
                      type="text"
                      value={otherServiceType}
                      name="otherServiceType"
                      placeholder="Other service type"
                      fn={setOtherServiceType}
                      hasValidationError={errors.otherServiceType}
                      autoFocus={true}
                      required={true}
                    />
                  </div>
                )}

                <div className="mb-4">
                  <div className="text-dark">Description</div>
                  <TextArea
                    inputName="form-control"
                    defaultValue={message}
                    placeholder="Write a description of the job here."
                    name="message"
                    fn={setMessage}
                    row="6"
                    required={false}
                    showLabel={false}
                  />
                </div>

                <div className="mb-0">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="isDraft"
                      className="form-check-input"
                      id="isDraft"
                      value="1"
                      checked={isDraft}
                      onChange={(e) => setIsDraft(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="isDraft">
                      Draft Mode
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {isDraft ? null : (
              <div className="card border-1 border-light rounded p-4 mb-4">
                <div className="card-header d-flex justify-content-between bg-white border-0">
                  <h3 className="card-title text-primary fw-bold">
                    Payment Info
                  </h3>
                  {/* <div className="card-actions">
										<span className="btn btn-sm text-primary">+</span>
										<span className="btn btn-sm text-primary">
											<BsThreeDotsVertical />
										</span>
									</div> */}
                </div>
                <div className="px-3">
                  {milestones.map((milestone, index) => (
                    <div className="row mb-3 align-items-center" key={index}>
                      <div className="col-md-4">
                        <label className="form-label">Name of milestone</label>
                        <input
                          type="text"
                          className="form-control"
                          value={milestone.name}
                          onChange={(e) =>
                            handleMilestoneChange(index, "name", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Due date</label>
                        <DatePicker
                          value={
                            milestone?.dueDate
                              ? typeof milestone?.dueDate === "string"
                                ? dayjs(milestone?.dueDate)
                                : milestone?.dueDate
                              : null
                          }
                          onChange={(date) => {
                            if (date) {
                              handleMilestoneChange(
                                index,
                                "dueDate",
                                dayjs(date).format("YYYY-MM-DD")
                              );
                            }
                          }}
                          format="DD/MM/YYYY"
                          placeholderText="DD/MM/YYYY"
                          className="question-input form-control"
						  disabledDate={(current) => {
							return current && current.isBefore(dayjs().startOf('day'));
						}}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">Amount</label>
                        <input
                          type="number"
                          className="form-control"
                          value={milestone.amount}
                          onChange={(e) =>
                            handleMilestoneChange(
                              index,
                              "amount",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-1 d-flex align-items-center justify-content-center">
                        <Link
                          type="button"
                          className="btn btn-link text-danger mt-3"
                          onClick={() => handleDeleteMilestone(index)}
                        >
                          <img src={DeleteIcon2} alt="Delete milestone" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
                {milestones?.length === 0 && (
                  <div className={"px-3 mb-3"}>
                    You currently have no milestones. <br /> Click the button
                    below to add one and start tracking your progress.
                  </div>
                )}

                <div
                  className="btn border border-primary text-primary w-25  d-inline mb-3 mx-3"
                  onClick={handleAddMilestone}
                >
                  + Add Milestone
                </div>

                <div className="column">
                  <div className="w-full mt-3">
                    <label className="form-label">Total cost</label>
                    <input
                      type="text"
                      className={`form-control ${
                        isMilestonesValid ? "border border-danger" : ""
                      }`}
                      value={`$ ${totalCost?.toFixed(2)}`}
                      readOnly
                    />
                  </div>
                  <div className="w-full mt-3">
                    <label className="form-label">20% Uncle Kam fee</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={true}
                      value={`$ ${milestonesFee?.toFixed(2)}`}
                      readOnly
                    />
                  </div>
                  <div className="w-full mt-3">
                    <label className="form-label">You'll receive</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={true}
                      value={`$ ${youReceive?.toFixed(2)}`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="card border-1 border-light rounded p-4 mb-4 px-5">
              <h3 className="card-title text-primary fw-bold ">
                Tax Organization Form
              </h3>
              <div className="d-flex align-items-center justify-content-between mt-3 ">
                <div className="d-flex gap-2 align-items-center ">
                  <img src={QuestionaireSvg} alt="file" />
                  <p className="mt-0 mb-0 text-dark fw-bold">
                    Forms ({selectedDynamicForms.length})
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <p className="mt-0 mb-0 text-black fw-bold">
                    Add questionnaire
                  </p>
                  <div className="questionaire-search-input-cont">
                    <img src={SearchIcon} alt="search" />
                    <input
                      onChange={(e) =>
                        setQuestionaireSearchValue(e.target.value)
                      }
                      onFocus={() => setSearchModalOpen(true)}
                      className="questionaire-search-input"
                      type="text"
                      placeholder="Search or select"
                    />
                    <img
                      onClick={() => setSearchModalOpen((prev) => !prev)}
                      className="cursor-pointer"
                      src={dropdownIcon}
                      alt="search"
                    />
                    {searchModalOpen && (
                      <div
                        ref={modalRef}
                        className="search-questionaire-modal-cont"
                      >
                        {(questionaireSearchValue
                          ? [
                              ...dynamicFormsCreatedByAdmins,
                              ...dynamicForms,
                            ].filter((form) =>
                              form?.form_data?.name
                                ?.toLowerCase()
                                .includes(
                                  questionaireSearchValue?.toLowerCase()
                                )
                            )
                          : [...dynamicFormsCreatedByAdmins, ...dynamicForms]
                        ).map((option) => {
                          return (
                            <div
                              key={option.label}
                              onClick={() => {
                                const isForm = selectedDynamicForms?.find(
                                  (name) =>
                                    name?.name === option?.form_data?.name
                                );
                                if (isForm) {
                                  const deletedFormName =
                                    selectedDynamicForms?.filter(
                                      (name) =>
                                        name?.name !== option?.form_data?.name
                                    );
                                  setSelectedDynamicForms(deletedFormName);
                                } else {
                                  setSelectedDynamicForms((prev) => [
                                    ...prev,
                                    {
                                      name: option?.form_data?.name,
                                      value: option?.form_data?.name,
                                      label: option?.form_data?.name,
                                      message: "",
                                      data: option?.form_data.data,
                                    },
                                  ]);
                                }
                                //   setAddMessageOnRequest("");
                              }}
                              className={`${
                                selectedDynamicForms?.find(
                                  (form) =>
                                    form?.name === option?.form_data?.name
                                )
                                  ? "border border-primary rounded"
                                  : ""
                              }`}
                            >
                              <div className="form-title">
                                {option?.form_data?.name}{" "}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {messageSaveForm?.show && <Message message={messageSaveForm} />}
              {selectedDynamicForms.length > 0 &&
                selectedDynamicForms.map((name, id) => {
                  return (
                    <div
                      key={name?.value}
                      className="mt-5 mb-3 d-flex align-items-start justify-content-between border-b pb-3"
                    >
                      <div className="d-flex gap-4 cursor-pointer">
                        <img
                          className="mt-1 icon-h"
                          src={QuestionaireSvg}
                          alt="requestfile"
                          onClick={() => {
                            openJobData(name?.name);
                          }}
                        />
                        <div role="button">
                          <div
                            className="d-flex align-itesm-center gap-5"
                            onClick={() => {
                              setActiveFormData(name);
                              setCustomModalOpen({
                                state: "openjobform",
                                data: name,
                              });
                            }}
                          >
                            <div className="d-flex align-items-center gap-3">
                              <p className="mb-0 fw-bold">{name?.name}</p>
                              <img
                                src={EditDocumentSvg}
                                alt="edit"
                                className="edit-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCustomModalOpen({
                                    state: "editformtitle",
                                    data: name?.name,
                                    formTitle: name?.name,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {dynamicForms?.find(
                            (form) => form?.form_data?.name === name?.name
                          ) ? (
                            <div
                              onClick={() => {
                                setCustomModalOpen({
                                  state: "delete",
                                  data: name?.name,
                                });
                              }}
                              className="text-danger"
                            >
                              Delete template
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                const isAded =
                                  dynamicFormsCreatedByAdmins?.find(
                                    (f) => f?.form_data?.name === name?.name
                                  );
                                if (isAded) {
                                  setCustomModalOpen({
                                    state: "editformtitle",
                                    data: name?.name,
                                  });
                                } else {
                                  handleSaveTemplate(name?.name, name?.data);
                                }
                              }}
                              className="text-success"
                            >
                              Save as new template
                            </div>
                          )}
                          {/* <p className='mb-0 mt-1 max-w'>{name.label} </p> */}
                          {/* <p onClick={() => setAddMessageOnRequest(name.value)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p>
										{(addMessageOnRequest === name.value || name?.message) && <textarea
													className='w-100'
													value={name?.message}
													onChange={(e) => {
														const edited = selectedFormNames?.map(n => {
															if (n.value === name.value) {
																return {
																	value: n.value,
																	label:n.label,
																	message: e.target.value
																}
															}
															return n
														})
														setSelectedFormNames(edited)
													}}
												></textarea>} */}
                        </div>
                      </div>
                      <div className="d-flex gap-4">
                        <img
                          className="cursor-pointer icon"
                          onClick={() => {
                            setFormTitle(name?.name);
                            setCreateFormModalOpen({
                              state: true,
                              editmode: true,
                              data: name?.data,
                            });
                          }}
                          src={editIcon}
                          alt="delete"
                        />
                        <img
                          className="cursor-pointer icon"
                          onClick={() => {
                            const deletedDynaimcFormName =
                              selectedDynamicForms?.filter(
                                (formname) => formname !== name
                              );
                            setSelectedDynamicForms(deletedDynaimcFormName);
                          }}
                          src={DeleteIcon2}
                          alt="delete"
                        />
                      </div>
                    </div>
                  );
                })}

              {selectedFormNames.length > 0 &&
                selectedFormNames.map((name, id) => {
                  return (
                    <div
                      key={name.value}
                      className="mt-5 mb-3 d-flex align-items-start justify-content-between border-b pb-3"
                    >
                      <div className="d-flex gap-4 cursor-pointer">
                        <img
                          className="mt-1 icon-h"
                          src={QuestionaireSvg}
                          alt="requestfile"
                          onClick={() => {
                            openJobData(name);
                          }}
                        />
                        <div
                          role="button"
                          onClick={() => {
                            // setMessage({ show: false });
                            dispatch(
                              allActions.dashboard.getLeadsByUUID({
                                UUID: lead?.uuid,
                              })
                            );
                          }}
                        >
                          <div
                            className="d-flex align-itesm-center gap-5"
                            onClick={() => {
                              openJobData(name);
                            }}
                          >
                            <p className="mb-0 fw-bold">{name?.name}</p>
                          </div>
                          {/* <div className="text-danger">Delete Template</div> */}
                          <div
                            onClick={() => {
                              // handleS//
                            }}
                            className="text-success"
                          >
                            Save As Template
                          </div>
                          {/* <p className='mb-0 mt-1 max-w'>{name.label} </p> */}
                          {/* <p onClick={() => setAddMessageOnRequest(name.value)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p>
										{(addMessageOnRequest === name.value || name?.message) && <textarea
													className='w-100'
													value={name?.message}
													onChange={(e) => {
														const edited = selectedFormNames?.map(n => {
															if (n.value === name.value) {
																return {
																	value: n.value,
																	label:n.label,
																	message: e.target.value
																}
															}
															return n
														})
														setSelectedFormNames(edited)
													}}
												></textarea>} */}
                        </div>
                      </div>
                      <div className="d-flex gap-4">
                        {/* <img className='cursor-pointer icon' onClick={() => {
											const deletedFormName = selectedFormNames.filter(formname => formname.value !== name.value)
											setSelectedFormNames(deletedFormName)
										}} src={editIcon} alt='delete' /> */}
                        <img
                          className="cursor-pointer icon"
                          onClick={() => {
                            const deletedFormName = selectedFormNames.filter(
                              (formname) => formname.value !== name.value
                            );
                            setSelectedFormNames(deletedFormName);
                          }}
                          src={DeleteIcon2}
                          alt="delete"
                        />
                      </div>
                    </div>
                  );
                })}
              <div className="border-dashed rounded mt-3 bg-muted-200  pb-5 bg-color">
                <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
                  <h3 className="text-center text-grey-400 fw-bold">
                    Smart Questionnaire
                  </h3>
                  <p className="text-center w-85 mt-2 text-2 px-5 ">
                    Select from among our pre-built Smart Questionnaires. Your
                    Client’s input are saved as they answer each question,
                    allowing them to continue right from where they last left
                    off.
                  </p>
                </div>
                {messageDeleteForm?.show && (
                  <Message message={messageDeleteForm} />
                )}

                <div className="row d-flex justify-content-start  card-cont">
                  <Link
                    to="#"
                    // className={`dropdown-item text-light invoiceModal`}
                    className="border-solid d-none flex-column justify-content-center align-items-center gap-2 custom-card rounded"
                    data-bs-toggle="modal"
                    data-bs-target="#createForm"
                  >
                    <img style={{ height: "40px" }} src={PlusSvg} alt="plus" />
                  </Link>
                  <div
                    className="border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded"
                    onClick={() => {
                      setCustomModalOpen({ state: "formtitle", data: {} });
                    }}
                  >
                    <img style={{ height: "40px" }} src={PlusSvg} alt="plus" />
                    Create New
                  </div>
                  {dynamicForms.map((option) => {
                    return (
                      <div
                        key={option.label}
                        onClick={() => {
                          const isForm = selectedDynamicForms?.find(
                            (name) => name?.name === option?.form_data?.name
                          );
                          if (isForm) {
                            const deletedFormName =
                              selectedDynamicForms?.filter(
                                (name) => name?.name !== option?.form_data?.name
                              );
                            setSelectedDynamicForms(deletedFormName);
                          } else {
                            setSelectedDynamicForms((prev) => [
                              ...prev,
                              {
                                name: option?.form_data?.name,
                                value: option?.form_data?.name,
                                label: option?.form_data?.name,
                                data: option?.form_data?.data,
                                message: "",
                              },
                            ]);
                          }
                          //   setAddMessageOnRequest("");
                        }}
                        className={`dynamic-form-card px-5 text-center border-solid ${
                          selectedDynamicForms?.find(
                            (form) => form?.name === option?.form_data?.name
                          )
                            ? "border-primary"
                            : ""
                        } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                      >
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setCustomModalOpen({
                              state: "delete",
                              data: option?.form_data?.name,
                            });
                            // handleDeleteTemplate(option?.id);
                          }}
                          className="delete"
                          src={DeleteIcon}
                          alt="delete-icon"
                        />
                        <img
                          style={{ height: "40px" }}
                          src={FileBlueSvg}
                          alt="plus"
                        />
                        {option?.form_data?.name}
                        {/* Dynamic */}
                      </div>
                    );
                  })}
                  <div className="w-100 dashed-border my-3"></div>
                  {dynamicFormsCreatedByAdmins?.map((option) => {
                    return (
                      <div
                        key={option.label}
                        onClick={() => {
                          const isForm = selectedDynamicForms?.find(
                            (name) => name?.name === option?.form_data?.name
                          );
                          if (isForm) {
                            const deletedFormName =
                              selectedDynamicForms?.filter(
                                (name) => name?.name !== option?.form_data?.name
                              );
                            setSelectedDynamicForms(deletedFormName);
                          } else {
                            setSelectedDynamicForms((prev) => [
                              ...prev,
                              {
                                name: option?.form_data?.name,
                                value: option?.form_data?.name,
                                label: option?.form_data?.name,
                                data: option?.form_data?.data,
                                message: "",
                              },
                            ]);
                          }
                          //   setAddMessageOnRequest("");
                        }}
                        className={`dynamic-form-card px-5 text-center border-solid ${
                          selectedDynamicForms?.find(
                            (form) => form?.name === option?.form_data?.name
                          )
                            ? "border-primary"
                            : ""
                        } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                      >
                        <img
                          style={{ height: "40px" }}
                          src={FileBlueSvg}
                          alt="plus"
                        />
                        {option?.form_data?.name}
                        {/* Dynamic */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="card border-1 border-light rounded p-4 mb-4 px-5">
              {/* <h3 className="card-title text-primary fw-bold">Documents request</h3> */}
              <h3 className="card-title text-primary fw-bold">
                Documents request
              </h3>
              <div>
                <div className=" d-flex justify-content-between bg-white border-0 mt-2"></div>
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="d-flex gap-2 align-items-center ">
                    <img src={FileSvg} alt="file" />
                    <p className="mt-0 mb-0 text-dark fw-bold">
                      File Requests ({selectedDocuments?.length})
                    </p>
                  </div>
                  {/* <div className='d-flex align-items-center gap-2'>
                                        <p className='mt-0 mb-0 text-dark fw-bold'>Add questionnaire</p>
                                                 <Input
										    		type="search"
												value={customPartial}
												name="partialValue"
												placeholder="Search or Select"
												required={false}
												showLabel={false}
											/>
                                    </div> */}
                </div>
                {selectedDocuments.length > 0 &&
                  selectedDocuments.map((document) => {
                    return (
                      <div className="mt-5 mb-3 d-flex align-items-start justify-content-between border-b">
                        <div className="d-flex gap-4 w-50">
                          <img
                            className="mt-1 icon-h"
                            src={RequestDocumentSvg}
                            alt="requestfile"
                          />
                          <div className="w-100">
                            <div className="d-flex align-itesm-center gap-5">
                              <input
                                disabled={editDocument !== document?.id}
                                className="mb-0 fw-bold w-100 border-0 outline-0"
                                onChange={(e) => {
                                  const edited = selectedDocuments?.map(
                                    (doc) => {
                                      if (doc.id === document.id) {
                                        return {
                                          id: doc.id,
                                          name: e.target.value,
                                          description: doc.description,
                                          message: doc.message,
                                        };
                                      }
                                      return doc;
                                    }
                                  );
                                  setSelectedDocuments(edited);
                                }}
                                value={document.name}
                              />
                            </div>
                            <textarea
                              cols={60}
                              rows={5}
                              onChange={(e) => {
                                const edited = selectedDocuments?.map((doc) => {
                                  if (doc.id === document.id) {
                                    return {
                                      id: doc.id,
                                      name: doc.name,
                                      description: e.target.value,
                                      message: doc.message,
                                    };
                                  }
                                  return doc;
                                });
                                setSelectedDocuments(edited);
                              }}
                              disabled={editDocument !== document?.id}
                              type="text"
                              value={document.description}
                              className="mb-0 mt-1 max-w w-100 border-0 mb-3"
                            />
                            {/* {(addMessageOnRequest === document?.id || document.message) && <textarea
													className='w-100'
													value={document?.message}
													onChange={(e) => {
														const edited = selectedDocuments?.map(doc => {
															if (doc.id === document.id) {
																return {
																	id: doc.id,
																	name: doc.name,
																	description: doc.description,
																	message: e.target.value
																}
															}
															return doc
														})
														setSelectedDocuments(edited)
													}}
												></textarea>} */}
                            {/* <p onClick={() => setAddMessageOnRequest(document?.id)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p> */}
                          </div>
                        </div>
                        <div className="d-flex gap-4 align-items-center justify-content-center">
                          <div className="d-flex align-items-center gap-2">
                            {editDocument && editDocument === document?.id ? (
                              <p
                                className="mb-0 mt-0 text-success fw-bold cursor-pointer"
                                onClick={() => setEditDocument("")}
                              >
                                Save
                              </p>
                            ) : (
                              <img
                                onClick={() => setEditDocument(document?.id)}
                                className="cursor-pointer icon"
                                src={EditDocumentSvg}
                                alt="edit"
                              />
                            )}
                            <img
                              onClick={() =>
                                handleDeleteSelectedDocument(document.id)
                              }
                              className="cursor-pointer icon"
                              src={BinSvg}
                              alt="delete"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className="clearfix m-t-10">
                  <Link
                    className={`btn btn-primary  rounded-2 `}
                    data-bs-toggle="modal"
                    data-bs-target="#requestJobSingleDocumentsModal"
                  >
                    Request Single Document
                  </Link>
                </div>
                <div className="border-dashed rounded mt-3 bg-muted-200 pb-5 bg-color">
                  <div className="mt-5 d-flex flex-column justify-content-center align-items-center px-3">
                    {docMessage.show && <Message message={docMessage} />}
                    <h3 className="text-center text-grey-400 fw-bold">
                      Request Forms and Supporting Documents
                    </h3>
                    <p className="text-center w-85 mt-2 text-2 ">
                      Pick from any of the pre-defined request templates or type
                      your own and save as template.
                      <br /> Your client can directly drag and drop files onto
                      your request to complete them.
                    </p>
                  </div>
                  <div className="row d-flex justify-content-start  card-cont">
                    <Link
                      to="#"
                      // className={`dropdown-item text-light invoiceModal`}
                      className="border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded"
                      data-bs-toggle="modal"
                      data-bs-target="#requestJobDocumentsModal"
                    >
                      <img
                        style={{ height: "40px" }}
                        src={PlusSvg}
                        alt="plus"
                      />
                      Create New
                    </Link>
                    {/* <div data-bs-toggle='modal'
										data-bs-target='#doqumentsList'
										aria-controls='doqumentsList' className='border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded'>
									</div> */}
                    {getTaxProdocumentGroup?.data?.map((document) => {
                      return (
                        <div
                          onClick={() =>
                            handleSelectDocument(document?.docs_data?.documents)
                          }
                          className={`dynamic-form-card px-5 text-center border-solid ${
                            selectedDocumentIds.includes(document.id)
                              ? "border-primary"
                              : ""
                          } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                        >
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              setCustomModalOpen({
                                state: "delete-groupDocument",
                                data: document,
                              });
                              // handleDeleteTemplate(option?.id);
                            }}
                            className="delete"
                            src={DeleteIcon}
                            alt="delete-icon"
                          />

                          <img
                            style={{ height: "40px" }}
                            src={FileBlueSvg}
                            alt="plus"
                          />
                          {document?.docs_data?.groupName}
                        </div>
                      );
                    })}
                    <div className="w-100 dashed-border my-3"></div>
                    {getTaxProAdmindocumentGroup?.data?.map((document) => {
                      return (
                        <div
                          onClick={() =>
                            handleSelectDocument(document?.docs_data?.documents)
                          }
                          className={`px-5 text-center border-solid ${
                            selectedDocumentIds.includes(document.id)
                              ? "border-primary"
                              : ""
                          } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                        >
                          <img
                            style={{ height: "40px" }}
                            src={FileBlueSvg}
                            alt="plus"
                          />
                          {document?.docs_data?.groupName}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <Modal
                title="Create new form"
                bodyClass="p-2 text-center text-dark text-opacity-50 w-100"
                trigger="createForm"
                showBtn={false}
                btnClass="createFormModalClose"
                size="xl"
                // fullscreen={true}
              > */}
              {createFormModalOpen?.state && (
                <CreateFormModal setCreateModalOpen={setCreateFormModalOpen}>
                  <CreateNewForm
                    setSelectedDynamicForms={setSelectedDynamicForms}
                    selectedDynamicForms={selectedDynamicForms}
                    getDynamicForms={getDynamicForms}
                    formTitle={formTitle}
                    setFormTitle={setFormTitle}
                    setCreateFormModalOpen={setCreateFormModalOpen}
                    setCreateF={createFormModalOpen}
                    editmode={createFormModalOpen?.editmode}
                    formToEdit={createFormModalOpen?.data || {}}
                  />
                </CreateFormModal>
              )}

              {/* </Modal> */}
            </div>
            <div className="clearfix p-2 mt-4">
              <div className="form-check">
                <input
                  type="checkbox"
                  name="agreed"
                  className="form-check-input"
                  id="agreed"
                  value="1"
                  checked={agreement}
                  onChange={(e) => setAgreement(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="agreed">
                  Yes, I understand and agree to the Uncle Kam Terms of Service,
                  including the <Link to="/user-agreement">User Agreement</Link>{" "}
                  and <Link to="/privacy-policy">Privacy Policy</Link>.
                </label>
              </div>
            </div>
            <div className="clearfix p-2 mt-4 d-flex justify-content-end">
              <span
                className="btn btn-outline-primary me-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </span>
              <button
                className={`btn btn-primary${
                  Object.values(errors).indexOf(true) > -1 || !agreement
                    ? " disabled"
                    : ""
                }`}
                type="submit"
                ref={button}
              >
                Submit
              </button>
            </div>
          </form>
          <Modal
            title="Create Group Documents"
            bodyClass="px-5 py-4 text-center"
            trigger="requestJobDocumentsModal"
            showBtn={false}
            btnClass="requestJobDocumentsModalClose"
            size="lg"
          >
            <RequestJobDocumentForm
              closeClass="requestJobDocumentsModalClose"
              sendRequestHandle={(e) => handleRequest(e)}
              secondaryInputField={true}
              setGroupName={setGroupName}
              groupName={groupName}
              modalMessage={modalMessage}
              setmodalMessage={setmodalMessage}
              buttonText={"Create Document Group"}
              Heading={[
                { heading: "Name" },
                { heading: "Description" },
                { heading: "Edit" },
                { heading: "Delete" },
              ]}
            />
          </Modal>

          {/* request single document  */}
          <Modal
            title="Create  Documents"
            bodyClass="px-5 py-4 text-center"
            trigger="requestJobSingleDocumentsModal"
            showBtn={false}
            btnClass="requestJobSingleDocumentsModalClose"
            size="lg"
          >
            <RequestJobDocumentForm
              closeClass="requestJobSingleDocumentsModalClose"
              setGroupName={setGroupName}
              groupName={groupName}
              singleDocumentRequest={true}
              modalMessage={modalMessage}
              setmodalMessage={setmodalMessage}
              singleDocumentRequestHandle={(e) =>
                singleDocumentRequestHandle(e)
              }
              buttonText={"Create Document"}
              Heading={[
                { heading: "Name" },
                { heading: "Description" },
                { heading: "Edit" },
                { heading: "Delete" },
              ]}
            />
          </Modal>
        </div>
        <FormPreviewModal />
      </div>
    );
	} else {
		return <Preloader/>;
	}
};

export default CreateJob;
