/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Checkbox from '../../../components/forms/fields/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DatePicker from 'react-datepicker';
import { BASEURL, useAutoReplyHook } from '../../../utilites';
import allActions from '../../../Store/action';
import Header from '../components/Header';
import Preloader from '../components/Preloader';
import Select from '../../../components/forms/fields/Select';
import Input from '../../../components/forms/fields/Input';
import TextArea from '../../../components/forms/fields/TextArea';
import DropDown from '../../../components/forms/fields/DropDown';
import Message from '../../../components/forms/fields/Message';
import UserAvatar from '../../../components/UserAvatar';
import { GLOBAL_VARIABLES } from '../../../utilites/variables';

const invoicePeriodOptions = [
	{ value: '3', label: '3' },
	{ value: '6', label: '6' },
	{ value: '12', label: '12' },
];
const partionalPaymentOption = [
	{ value: 20, label: '20%' },
	{ value: 40, label: '40%' },
	{ value: 60, label: '60%' },
	{ value: 80, label: '80%' },
	{ value: 'custom', label: 'Custom' },
];
const invoiceOptions = [
	{ value: 'FullPayment', label: 'Full Payment' },
	{ value: 'PartialPayment', label: 'Partial Payment' },
	// { value: 'RecurringPayment', label: 'Recurring Payment' },
];
const { DocumentOptions } = GLOBAL_VARIABLES;
const EditContract = () => {
	const [{ LoginData }, { personalConnections }, { documentNames, taxPreparation, taxPlanning }] = useSelector(
		(state) => [state.user, state.firebase, state.dashboard]
	);
	const [lead, setLead] = useState(null);
	const job = lead?.jobs[0];
	const [alertMessage, setAlertMessage] = useState({ show: false });
	// Form
	const [serviceType, setServiceType] = useState();
	const [completionDate, setCompletionDate] = useState();
	const [invoiceDeadline, setInvoiceDedline] = useState();
	const [cost, setCost] = useState();
	const [uncleKamFee, setUncleKamFee] = useState();
	const [remaning, setRemanning] = useState();
	const [message, setMessage] = useState();
	const [invoiceType, setInvoiceType] = useState();
	const [agreement, setAgreement] = useState(true);
	const [customPartial, setCustomPartial] = useState();
	const [subscriptionOption, setSubscriptionOption] = useState();
	const [deduction, setDeduction] = useState();
	const [isDraft, setIsDraft] = useState(false);
	// const [selectedDocuments, setSelectedDocuments]= useState([])
	// 
	const [documents, setDocuments] = useState([]);
	const [selectedFormName, setSelectedFormName] = useState();
	const [otherDocumentName, setOtherDocumentName] = useState('');
	const [errors, setErrors] = useState({
		serviceType: false,
		completionDate: false,
		cost: false,
		message: false,
		documents: false,
		otherDocumentName: false,
		general: '',
	});
	const { uuid } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const button = useRef();

	const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				console.log('job data>>>>', res.data);
				setLead(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadJobByUUID(uuid);
	}, [uuid, loadJobByUUID]);

	useEffect(() => {
		if (job) {
			if (job.status_id === 3 || job.status_id === 4) {
				navigate(`/dashboard/jobs/${job?.uuid}`);
			} else {
				setCompletionDate(new Date(job?.completed_at));
				setInvoiceDedline(new Date(job?.payment_due_date));
				setCost(job?.cost);
				// setDocuments()
				setUncleKamFee(((parseInt(LoginData?.userpro?.percentage) / 100) * job?.cost).toFixed(2));
				setRemanning(
					(
						job?.cost - ((parseInt(LoginData?.userpro?.percentage) / 100) * job?.cost).toFixed(2)
					).toFixed(2)
				);
				setMessage(job?.description);
				setServiceType(job?.service?.id)
				// If status_id is 5 (draft) then set isDraft to true
				if (job?.status_id === 5) {
					setIsDraft(true);
				}
				// Set Invoice Type
				if (job?.invoice_type === 'full') {
					setInvoiceType(invoiceOptions[0]);
				} else if (job?.invoice_type === 'partial') {
					setInvoiceType(invoiceOptions[1]);
					setDeduction(
						partionalPaymentOption.find((item) => item.value === job?.invoice_data?.deduction)
					);
				} else if (job?.invoice_type === 'subscription') {
					setInvoiceType(invoiceOptions[2]);
					setSubscriptionOption(
						invoicePeriodOptions.find((item) => item.value === job?.invoice_data?.deduction)
					);
				}
			}
		}
	}, [job]);

	useEffect(() => {
		// if Success
		if (taxPreparation?.state === true) {
			setAlertMessage({ show: false });
			changeLoading(false);
			navigate(`/dashboard/jobs/${job?.uuid}`);
		}

		// if Error
		if (taxPreparation?.state === false) {
			// Show Message Alert
			setAlertMessage({
				show: true,
				type: 'Error',
				text: taxPreparation.message,
			});
			changeLoading(false);
		}
	}, [taxPreparation.message, taxPreparation?.state]);

	useEffect(() => {
		// if Success
		if (taxPlanning?.state === true) {
			setAlertMessage({ show: false });
			changeLoading(false);
			navigate(`/dashboard/jobs/${job?.uuid}`);
		}

		// if Error
		if (taxPlanning?.state === false) {
			// Show Message Alert
			setAlertMessage({
				show: true,
				type: 'Error',
				text: taxPlanning.message,
			});
			changeLoading(false);
		}
	}, [taxPlanning.message, taxPlanning?.state]);

	const getInvoiceData = () => {
		let obj = { invoice_data: {}, payment_due_date: invoiceDeadline };
		// For Partial Payment
		if (invoiceType?.value === 'PartialPayment') {
			obj.invoice_data.deductionType = deduction?.value === 'custom' ? '' : '%';
			obj.invoice_type = 'partial';
			obj.invoice_data.deduction = deduction?.value === 'custom' ? customPartial : deduction?.value;
			obj.invoice_data.fullAmount = cost;
			obj.invoice_data.remainingAmount =
				deduction?.value === 'custom'
					? cost - customPartial
					: cost - ((deduction?.value / 100) * cost).toFixed(2);
		}
		// For Subscription Payment
		if (invoiceType?.value === 'RecurringPayment') {
			obj.invoice_type = 'subscription';
			obj.invoice_data.deduction = subscriptionOption?.value;
		}
		if (invoiceType.value === 'FullPayment') {
			obj.invoice_type = 'full';
		}
		return obj;
	};
	// *******************************************
	// const callTaxPlanning = (taxPlanObj) => {
	// 	reset_mesage();
	// 	dispatch(allActions.dashboard.resetTaxPlanning());
	// 	dispatch(allActions.dashboard.taxPlanning({ obj: taxPlanObj }));
	// };

	// const callTaxPreparation = (taxPreparationObj) => {
	// 	reset_mesage();
	// 	dispatch(allActions.dashboard.resetTaxPreparation());
	// 	dispatch(allActions.dashboard.taxPreparation({ obj: taxPreparationObj }));
	// };

	// const reset_mesage = () => {
	// 	setAlertMessage({ show: false });
	// };
	useEffect(() => {
		if(parseInt(serviceType) !== 1 && documentNames.length > 0){
			const getIds = job?.documents?.map((data) => documentNames.find((DD) => DD?.name === data?.name))
			if (getIds) {
				const ff = [...new Set(getIds.filter(data => data).map(data => data?.id?.toString()))];
				setDocuments(ff)
			}
		}
		if(parseInt(serviceType)===1){
			setSelectedFormName(DocumentOptions.find((data)=>data.value===job.form_data[0]?.name))
		}
	}, [job?.documents?.length,serviceType,documentNames])

	
	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();

		let selectedDocNames = documentNames?.filter((obj) => documents?.includes(obj?.id?.toString()));
		let taxPlanObj = {
			lead_id: lead.id,
			service_id: serviceType,
			method: 'create',
			docName: selectedDocNames?.map((item) => item?.name),		};
		// 
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		changeLoading(true);
		dispatch(allActions.dashboard.resetTaxPlanning());
		dispatch(allActions.dashboard.resetTaxPreparation());
		setAlertMessage({ show: false });
		axios
			.put(`${BASEURL}/api/jobs/update/${job?.uuid}`, {
				pro_id: LoginData?.userpro?.id || job.userpro?.id,
				additional: message,
				completionDate,
				isDraft,
				cost,
				// 
				documents,
				other_document_name: otherDocumentName,
				customer_id: lead.user.id,
				service_id: serviceType,
				// 
				...getInvoiceData(),
			})
			.then((response) => {
				if (
					!(
						taxPlanObj?.service_id === 2 &&
						(LoginData?.isTaxPro || LoginData?.isAdmin || LoginData?.isAccountManager) &&
						taxPlanObj?.service_id === 1
					)
				) {
					button.current.disabled = false;
				}
				// if SERVICE IS TAX PLAN
				if (taxPlanObj?.service_id === '2' || taxPlanObj?.service_id === 2) {
					taxPlanObj.job_uuid=job?.uuid
					// callTaxPlanning(taxPlanObj);
				}
				// if SERVICE IS TAX PREPARATION
				else if (taxPlanObj?.service_id === '1' || taxPlanObj?.service_id === 1) {
					// Modify Object To Tax Preparation

					delete taxPlanObj.docName;
					taxPlanObj.formName = selectedFormName?.value;
					taxPlanObj.formData = {};
					// callTaxPreparation(taxPlanObj);

					console.log('taxPlanObj', taxPlanObj);
				}
				// FOR OTHER SERVICES
				else {
					changeLoading(false);
					// Navigate to lead details page
					navigate(`/dashboard/jobs/${response?.data?.data?.uuid}`, {
						state: { message: 'Contract Created and sent to customer Successfully!' },
					});
				}
				changeLoading(false);
				handleAutoMessaging(job.uuid);
				navigate(`/dashboard/jobs/${job?.uuid}`);
			})
			.catch((error) => {
				console.log("calling", 6)
				changeLoading(false);
				setAlertMessage({
					show: true,
					type: 'Error',
					text: error?.response?.data?.error || error?.message,
				});
				button.current.disabled = false;
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.message || error.message });
			});
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	const handleAutoMessaging = (uuid) => {
		if (!isDraft) {
			handleAutoReply({
				personalConnections,
				LoginData,
				receiver: lead?.user,
				type: 'simple',
				encodedString: `<p>Hi ${lead?.user?.firstname}, I have updated the contract based on your request. Please click the link below to view and sign here <a href="${window.location.origin}/dashboard/contract/${uuid}">Contract</a> to get started on the job. Thanks</p>	<br></br>
		<p>To upload documents from your mobile phone, please scan the QR code using your phone's camera. The QR code will direct you to the upload page.</p>
		<a href="${window.location.origin}/qr-code/${uuid}" target="_blank" >QR Code</a>`,
			});
		}
	};

	useEffect(() => {
		if (isDraft === true) {
			// Set default values for draft contract payment info part.
			setCompletionDate(new Date());
			setCost(0);
			setUncleKamFee(0);
			setRemanning(0);
			setInvoiceType({ label: 'Full Payment', value: 'FullPayment' });
			setInvoiceDedline(new Date());
		} else {
			// Clean up the default values for draft contract payment info part.
			setCompletionDate();
			setCost();
			setUncleKamFee();
			setRemanning();
			setInvoiceType();
			setInvoiceDedline();
		}
	}, [isDraft]);
	if (lead !== null && job !== null) {
		return (
			<div className='clearfix'>
				<Header name='Edit Job' />

				<div className='container page'>
					<form className='clearfix pt-4 mb-4' onSubmit={handleSubmit}>
						{alertMessage.show ? <Message message={alertMessage} /> : <></>}
						<div className='card border-1 border-light rounded p-4 mb-4'>
							<div className='card-header d-flex justify-content-between bg-white border-0'>
								<h3 className='card-title text-primary fw-bold'>Client</h3>
								<div className='card-actions'>
									<span className='btn btn-sm text-primary'>+</span>
									<span className='btn btn-sm text-primary'>
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className='card-body'>
								<div className='d-flex align-items-center'>
									<div>
										<UserAvatar
											avatar={lead.user.avatar}
											alt={`${lead.user.firstname} ${lead.user.lastname}`}
											className='img-fluid object-fit-cover rounded-circle'
											style={{ height: 40 }}
										/>
									</div>
									<p className='text-dark m-0 ms-2'>
										{`${lead.user.firstname} ${lead.user.lastname}`}
									</p>
								</div>
							</div>
						</div>
						<div className='card border-1 border-light rounded p-4 mb-4'>
							<div className='card-header d-flex justify-content-between bg-white border-0'>
								<h3 className='card-title text-primary fw-bold'>Terms</h3>
								<div className='card-actions'>
									<span className='btn btn-sm text-primary'>+</span>
									<span className='btn btn-sm text-primary'>
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className='card-body'>
								<div className='mb-4'>
									<div className='text-dark'>Service type</div>
									<Select
										value={serviceType}
										name='serviceType'
										placeholder='Service type'
										fn={setServiceType}
									>
										<option value={job?.service?.id || 'others'}>
											{job?.service?.name || job?.others}
										</option>
									</Select>
								</div>
								<div className={`mb-${job?.status_id === 5 ? '4' : '0'}`}>
									<div className='text-dark'>Description</div>
									<TextArea
										inputName='form-control'
										defaultValue={message}
										placeholder='Enter details of the job here'
										name='message'
										fn={setMessage}
										row='6'
										required={false}
										showLabel={false}
									/>
								</div>
								{job?.status_id === 5 ? (
									<div className='mb-0'>
										<div className='form-check'>
											<input
												type='checkbox'
												name='isDraft'
												className='form-check-input'
												id='isDraft'
												value={isDraft ? 1 : null}
												checked={isDraft}
												onChange={(e) => setIsDraft(e.target.checked)}
											/>
											<label className='form-check-label' htmlFor='isDraft'>
												Draft Mode
											</label>
										</div>
									</div>
								) : null}
							</div>
						</div>
						{isDraft ? null : (
							<div className='card border-1 border-light rounded p-4 mb-4'>
								<div className='card-header d-flex justify-content-between bg-white border-0'>
									<h3 className='card-title text-primary fw-bold'>Payment</h3>
									<div className='card-actions'>
										<span className='btn btn-sm text-primary'>
											<BsThreeDotsVertical />
										</span>
									</div>
								</div>
								<div className='card-body'>
									<div className='mb-4'>
										<div className='text-dark'>Completion date</div>
										<div className='form-floating'>
											<DatePicker
												className='form-control'
												wrapperClassName='d-block'
												selected={completionDate}
												onChange={(date) => setCompletionDate(date)}
												dateFormat='MM/dd/yyyy'
												placeholderText='mm/dd/yyyy'
												minDate={new Date()}
												isClearable
											/>
										</div>
									</div>
									<div className='mb-4'>
										<div className='text-dark'>Total Cost</div>
										<Input
											type='number'
											value={cost}
											name='cost'
											placeholder='00.00'
											fn={(value) => {
												setCost(value);
												setUncleKamFee(
													((parseInt(LoginData?.userpro?.percentage) / 100) * value).toFixed(2)
												);
												setRemanning(
													(
														value -
														((parseInt(LoginData?.userpro?.percentage) / 100) * value).toFixed(2)
													).toFixed(2)
												);
											}}
											// hasValidationError={errors.reason}
											required={true}
											showLabel={false}
										/>
									</div>
									<div className='mb-4'>
										<div className='text-dark'>{LoginData?.userpro?.percentage}% Uncle Kam fee</div>
										<Input
											type='number'
											value={uncleKamFee}
											name='cost'
											placeholder='00.00'
											fn={setUncleKamFee}
											// hasValidationError={errors.reason}
											required={false}
											showLabel={false}
											isDisabled={true}
										/>
									</div>
									<div className='mb-4'>
										<div className='text-dark'>You'll receive</div>
										<Input
											type='number'
											value={remaning}
											name='cost'
											placeholder='00.00'
											fn={setRemanning}
											// hasValidationError={errors.reason}
											required={false}
											showLabel={false}
											isDisabled={true}
										/>
									</div>
									<div className='mb-4'>
										<div className='text-dark'>Select invoice type</div>
										<DropDown
											options={invoiceOptions}
											value={invoiceType}
											setValue={setInvoiceType}
											label={'Select Invoice Type'}
											showLabel={false}
										/>
									</div>
									{invoiceType?.value === 'RecurringPayment' ? (
										<div className='mb-3 col-md-12'>
											<div className='text-dark'>Select subscription duration</div>
											<DropDown
												options={invoicePeriodOptions}
												value={subscriptionOption}
												setValue={setSubscriptionOption}
												label={'Subscription Duration'}
												showLabel={false}
											/>
										</div>
									) : (
										''
									)}
									{invoiceType?.value === 'PartialPayment' ? (
										<div className='mb-3 col-md-12'>
											<DropDown
												options={partionalPaymentOption}
												value={deduction}
												setValue={setDeduction}
												label={'Deposit'}
												showLabel={false}
											/>
										</div>
									) : (
										''
									)}

									{deduction?.value === 'custom' && (
										<div className='mb-4'>
											<div className='text-dark'>Custom Amount</div>
											<Input
												type='number'
												value={customPartial}
												name='partialValue'
												placeholder='00.00'
												fn={(e) => {
													if (Number(e) <= Number(cost)) {
														setCustomPartial(e);
													}
												}}
												required={false}
												showLabel={false}
											/>
										</div>
									)}
									<div>
										<div className='mb-4'>
											<div className='text-dark'>Invoice expiry date</div>
											<div className='form-floating'>
												<DatePicker
													className='form-control'
													wrapperClassName='d-block'
													selected={invoiceDeadline}
													onChange={(date) => setInvoiceDedline(date)}
													dateFormat='MM/dd/yyyy'
													placeholderText='mm/dd/yyyy'
													minDate={moment().add(1, 'days').toDate()}
													isClearable
													required
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						{/* start updating missing code */}
						<div className="card border-1 border-light rounded p-4">
							<div className="card-header d-flex justify-content-between bg-white border-0">
								<h3 className="card-title text-primary fw-bold">Documents request</h3>
								<div className="card-actions">
									<span className="btn btn-sm text-primary">+</span>
									<span className="btn btn-sm text-primary">
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className="card-body">
								<div>
									{parseInt(serviceType) !== 1 && documentNames.length > 0 && (
										<div className="row text-start">
											{documentNames?.map((documentName) => (
												<div className="col-6 mt-2" key={documentName.id}>
													<Checkbox
														wrapperClass="form-check"
														name="documents"
														state={documents}
														value={documentName.id}
														label={documentName.name}
														fn={setDocuments}
														id={`doc-${documentName.id}`}
														required={false}
													// checked={job?.documents?.find((data)=>documentName.name===data.name)}
													/>
												</div>
											))}
										</div>
									)}
									{/* currentTask */}
									{parseInt(serviceType) === 1 && (
										<div className="clearfix">
											<div className="text-dark">Form type</div>
											<DropDown
												options={DocumentOptions}
												value={selectedFormName}
												setValue={setSelectedFormName}
												label={'Select Form Type'}
												showLabel={false}
											/>
										</div>
									)}
								</div>
								{/* currentTask */}
								{documentNames.filter(
									(documentName) =>
										documentName.name.toLowerCase() === 'others' &&
										documents.includes(documentName.id.toString())
								).length > 0 && (
										<div className="row text-start mt-4">
											<Input
												type="text"
												value={otherDocumentName}
												name="documentName"
												placeholder="Document Name"
												label="Type document name here *"
												fn={setOtherDocumentName}
												hasValidationError={errors.otherDocumentName}
												autoFocus={true}
											/>
										</div>
									)}
								{isDraft && selectedFormName ? (
									<>
										<div className="mt-4">
											<div className="text-dark">Additional documents</div>
											<div className="row text-start">
												{documentNames?.map((documentName) => (
													<div className="col-6 mt-2" key={documentName.id}>
														<Checkbox
															wrapperClass="form-check"
															name="documents"
															state={documents}
															value={documentName.id}
															label={documentName.name}
															fn={setDocuments}
															id={`doc-${documentName.id}`}
															required={false}
														/>
													</div>
												))}
											</div>
										</div>
									</>
								) : null}
							</div>
						</div>
						{/* end updating missing code */}
						<div className='clearfix p-2 mt-4'>
							<div className='form-check'>
								<input
									type='checkbox'
									name='agreed'
									className='form-check-input'
									id='agreed'
									value='1'
									checked={agreement}
									onChange={(e) => setAgreement(e.target.checked)}
									disabled={true}
								/>
								<label className='form-check-label' htmlFor='agreed'>
									Yes, I understand and agree to the Uncle Kam Terms of Service, including the{' '}
									<Link to='/user-agreement'>User Agreement</Link> and{' '}
									<Link to='/privacy-policy'>Privacy Policy</Link>.
								</label>
							</div>
						</div>
						<div className='clearfix p-2 mt-4 d-flex justify-content-end'>
							<span className='btn btn-outline-primary me-3' onClick={() => navigate(-1)}>
								Cancel
							</span>
							<button
								className={`btn btn-primary${Object.values(errors).indexOf(true) > -1 || !agreement
										? ' disabled'
										: ''
									}`}
								type='submit'
								ref={button}
							>
								{isDraft ? "Save" : "Publish"}
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	} else {
		return <Preloader />;
	}
};

export default EditContract;
