import { DOCUMENTS_GROUP_ACTION_TYPE } from "./Type";

const DEFAULT_STATE = {
	documentGroup: { message: "", status: "idle", data: [] },
	createDocumentGroup: { message: "", status: "idle", data: [] },
	deleteDocumentGroup: { message: "", status: "idle", data: [] },
	markDocumentGroup: { message: "", status: "idle", data: [] },
	editDocumentGroup: { message: "", status: "idle", data: [] },
	getTaxProdocumentGroup: { message: "", status: "idle", data: [] },
	getTaxProAdmindocumentGroup: { message: "dff", status: "idle", data: [] },
	createDocumentName: { message: "", status: "idle", data: [] },
	editDocumentName: { message: "", status: "idle", data: [] },
	deleteDocumentName: { message: "", status: "idle", data: [] },



	formInformation: null,
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const documentGroup = (state = INITIAL_STATE, action) => {

	if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.documentGroupIdle) {
		return {
			...state,
			documentGroup: {
				data: action.formObj,
				message: "",
				state: "idle",
			},
		};
	} else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.documentGroupData) {
		return {
			...state,
			documentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.documentGroupError) {
		return {
			...state,
			documentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.createDocumentGroupIdle) {
		return {
			...state,
			createDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.createDocumentGroupSuccess) {
		return {
			...state,
			createDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.createDocumentGroupError) {
		return {
			...state,
			createDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentGroupIdle) {
		return {
			...state,
			deleteDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentGroupSuccess) {
		return {
			...state,
			deleteDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentGroupError) {
		return {
			...state,
			deleteDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.markDocumentGroupIdle) {
		return {
			...state,
			markDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.markDocumentGroupSuccess) {
		return {
			...state,
			markDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.markDocumentGroupError) {
		return {
			...state,
			markDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.editDocumentGroupIdle) {
		return {
			...state,
			editDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.editDocumentGroupSuccess) {
		return {
			...state,
			editDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.editDocumentGroupError) {
		return {
			...state,
			editDocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.getTaxProdocumentGroupIdle) {
		return {
			...state,
			getTaxProdocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.getTaxProdocumentGroupDataSuccess) {

		return {
			...state,
			getTaxProdocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.getTaxProdocumentGroupError) {
		return {
			...state,
			getTaxProdocumentGroup: action.payload
		};
	}
		else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.getTaxProAdmindocumentGroupIdle) {
		return {
			...state,
			getTaxProAdmindocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.getTaxProAdmindocumentGroupDataSuccess) {
		
		return {
			...state,
			getTaxProAdmindocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.getTaxProAdmindocumentGroupError) {
		return {
			...state,
			getTaxProAdmindocumentGroup: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.createDocumentNameIdle) {
		return {
			...state,
			createDocumentName: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.createDocumentNameSuccess) {
		return {
			...state,
			createDocumentName: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.createDocumentNameError) {
		return {
			...state,
			createDocumentName: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.editDocumentNameIdle) {
		return {
			...state,
			editDocumentName: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.editDocumentNameSuccess) {
		return {
			...state,
			editDocumentName: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.editDocumentNameError) {
		return {
			...state,
			editDocumentName: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentNameIdle) {
		return {
			...state,
			deleteDocumentName: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentNameSuccess) {
		return {
			...state,
			deleteDocumentName: action.payload
		};
	}
	else if (action.type === DOCUMENTS_GROUP_ACTION_TYPE.deleteDocumentNameError) {
		return {
			...state,
			deleteDocumentName: action.payload
		};
	}
	return state;
};

export default documentGroup;
