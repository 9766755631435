import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { BASEURL, useAutoReplyHook } from '../../utilites';
import allActions from '../../Store/action';
import Button from './fields/Button';
import Input from './fields/Input';
import editIcon from '../../assets/img/edit1.svg';
import success from '../../assets/img/success.jpg';
import { MdDelete } from 'react-icons/md';
import Message from './fields/Message';
import { Link } from 'react-router-dom';
import TextArea from './fields/TextArea';
import { CiCirclePlus } from 'react-icons/ci';

export default function RequestJobDocumentForm({
	data,
	setAlert,
	reloadData,
	leadData,
	closeClass,
	sendRequestHandle,
	secondaryInputField,
	setGroupName,
	groupName,
	modalMessage,
	Heading,
	setmodalMessage = () => {},
	singleDocumentRequest,
	singleDocumentRequestHandle,
	buttonText,
}) {
	const [
		{ documentNames },
		{ personalConnections },
		{ LoginData },
		{ createDocumentName, editDocumentName, deleteDocumentName },
	] = useSelector((state) => [state.dashboard, state.firebase, state.user, state.documentGroup]);
	const [documents, setDocuments] = useState([]);
	const [otherDocumentName, setOtherDocumentName] = useState('');

	const [editAllow, seteditAllow] = useState(false);
	const [name, setName] = useState('');
	const [description, setdescription] = useState('');
	const [newName, setNewName] = useState('');
	const [newDescription, setNewDescription] = useState('');
	const [editId, setEditId] = useState('');

	const [errors, setErrors] = useState({
		documents: false,
		otherDocumentName: false,
		general: '',
	});
	const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const button = useRef();

	useEffect(() => {
		dispatch(allActions.dashboard.getDocumentNames());
	}, [dispatch]);

	useEffect(() => {
		// Validate documents
		if (documents.length === 0) {
			!errors.documents && setErrors({ ...errors, documents: true });
		} else {
			errors.documents && setErrors({ ...errors, documents: false });
		}
		// Validate other document name
		if (
			documentNames.filter(
				(documentName) =>
					documentName.name.toLowerCase() === 'others' &&
					documents.includes(documentName.id.toString())
			).length > 0 &&
			otherDocumentName === ''
		) {
			!errors.otherDocumentName && setErrors({ ...errors, otherDocumentName: true });
		} else {
			errors.otherDocumentName && setErrors({ ...errors, otherDocumentName: false });
		}
	}, [documents, setErrors, errors, documentNames, otherDocumentName]);

	useEffect(() => {
		if (modalMessage?.text) {
			setErrors({ documents: false, otherDocumentName: false, general: modalMessage.text });
		}
	}, [modalMessage]);

	// create Group  document name
	useEffect(() => {
		if (!createDocumentName.status) {
			dispatch(allActions.global.Loader(false));
			setmodalMessage({
				show: true,
				type: 'Error',
				text: createDocumentName?.message || 'server error',
			});
		}
		if (createDocumentName.status === true) {
			dispatch(allActions.global.Loader(false));
			let newDocument = {
				id: createDocumentName?.data?.data?.id,
				description: newDescription,
				message: '',
				name: newName,
			};
			if (newName && newDescription) {
				setDocuments([...documents, newDocument]);
				setNewName('');
				setNewDescription('');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, createDocumentName]);

	// edit Group  document name
	useEffect(() => {
		if (!editDocumentName.status) {
			dispatch(allActions.global.Loader(false));
			setmodalMessage({
				show: true,
				type: 'Error',
				text: editDocumentName?.message || 'server error',
			});
		}
		if (editDocumentName.status === true) {
			dispatch(allActions.global.Loader(false));
			setmodalMessage({ show: false });
			if (name && description) {
				documents.forEach((item) => {
					if (item.id === editId) {
						item.name = name;
						item.description = description;
					}
				});
				setEditId('');
				setName('');
				setdescription('');
				seteditAllow(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, editDocumentName]);

	// delete Group  document name
	useEffect(() => {
		if (!deleteDocumentName.status) {
			dispatch(allActions.global.Loader(false));
			setmodalMessage({
				show: true,
				type: 'Error',
				text: deleteDocumentName?.message || 'server error',
			});
		}
		if (deleteDocumentName.status === true) {
			dispatch(allActions.global.Loader(false));
			setmodalMessage({ show: false });
			setDocuments(
				documents.filter((doc) => {
					// Check if every property in `targetDoc` matches the current `doc` property
					return !Object.keys(editId).every((key) => editId[key] === doc[key]);
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, deleteDocumentName]);

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errors.general !== '') setErrors({ errors, general: '' });

		if (sendRequestHandle) {
			sendRequestHandle(documents);
		} else if (singleDocumentRequestHandle) {
			singleDocumentRequestHandle(documents);
			// Close Modal
			document.querySelector(`.${closeClass ? closeClass : 'requestDocumentsModalClose'}`).click();
		} else {
			button.current.disabled = true;
			axios
				.post(`${BASEURL}/api/jobs/request-documents`, {
					customer_id: data.customer_id,
					pro_id: data.pro_id,
					job_id: data.job_id,
					other_document_name: otherDocumentName,
					documents,
				})
				.then(() => {
					button.current.disabled = false;
					// Close Modal
					document
						.querySelector(`.${closeClass ? closeClass : 'requestDocumentsModalClose'}`)
						.click();
					setAlert && setAlert('Documents has been requested successfully!');
					handleAutoMessaging();
				})
				.then(() => {
					reloadData();
				})
				.catch((error) => {
					button.current.disabled = false;
					console.error(error.message);
					// Set general error
					if (errors.general === '')
						setErrors({ errors, general: error?.response?.data?.message || error.message });
				});
		}
	};

	const handleAutoMessaging = () => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: leadData,
			type: 'simple',
			encodedString: `<p>I just sent you a new document request. Please upload through your Projects page. Thanks</p>`,
		});
	};

	const asd = (index) => {
		if (editAllow === index) {
			seteditAllow(false);
		} else {
			seteditAllow(index);
		}
	};
	const saveData = (id) => {
		let newDocument = {
			doc_id: id,
			description: description,
			message: '',
			name: name,
		};
		setEditId(id);
		dispatch(allActions.documentsgroup.editdocumentname(newDocument));
	};
	const deleteDocument = (targetDoc) => {
		let newDocument = {
			doc_id: targetDoc.id,
		};
		setEditId(targetDoc);
		dispatch(allActions.documentsgroup.deletedocumentname(newDocument));
	};
	return (
		<form className="needs-validation" onSubmit={handleSubmit}>
			{modalMessage?.show ? <Message message={modalMessage} /> : <></>}

			{secondaryInputField && (
				<div className="mb-per-5">
					<Input
						type="text"
						value={groupName}
						name="Group Name"
						placeholder="Group Name"
						fn={setGroupName}
						hasValidationError={errors.otherDocumentName}
						autoFocus={true}
					/>
				</div>
			)}
			{/* <div className="row text-start mb-3">
				{documentNames.length > 0 &&
					documentNames?.map((documentName) => (
						<div className="col-12" key={documentName.id}>
							<Checkbox
								wrapperClass="mb-2"
								name="documents"
								state={documents}
								value={documentName.id}
								label={documentName.name}
								// checked={documents.find(doc => doc.id === documentName.id)}
								onChnage={(doc) => {									
									let alreadyExist=documents.find((doc)=>doc.id===documentName?.id)
									if(!alreadyExist){
										setDocuments((prev) => [...prev, { id: documentName?.id, name: documentName?.name, description: documentName?.name, message: '' }])
									}
									else{
										let otherDocuments=documents.filter((doc)=>doc.id!==documentName?.id)
										setDocuments(otherDocuments)
									}
								}}
								id={`request-doc-${documentName.id}`}
								required={false}
							/>
						</div>
					))}
			</div> */}
			{documents?.find((document) => document.name.toLowerCase() === 'others') && (
				<div className="row text-start mb-3">
					<Input
						type="text"
						value={otherDocumentName}
						name="documentName"
						placeholder="Document Name"
						fn={setOtherDocumentName}
						hasValidationError={errors.otherDocumentName}
						autoFocus={true}
					/>
				</div>
			)}
			{!modalMessage && errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			{(secondaryInputField || singleDocumentRequest) && (
				<table className="table horizontal-line">
					<thead>
						<tr>
							{Heading?.map((data) => (
								<th scope="col fw-bolder">{data.heading}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{documents.map((data, index) => {
							return (
								<tr>
									<td>
										{editAllow === index ? (
											<Input type="text" value={name} fn={setName} showLabel={false} />
										) : (
											data?.name
										)}
									</td>
									<td>
										{editAllow === index ? (
											<Input
												type="text"
												value={description}
												fn={setdescription}
												showLabel={false}
											/>
										) : (
											data?.description
										)}
									</td>
									<td>
										{' '}
										{editAllow !== index ? (
											<img
												style={{ height: '30px' }}
												className="cursor-pointer"
												src={editIcon}
												alt="edit"
												onClick={() => {
													setEditId('');
													setmodalMessage({ show: false });
													setName(data?.name);
													setdescription(data?.description);
													asd(index);
												}}
											/>
										) : (
											<img
												style={{ height: '30px' }}
												className="cursor-pointer"
												src={success}
												alt="edit"
												onClick={() => {
													saveData(data?.id);
												}}
											/>
										)}
									</td>
									<td>
										<MdDelete onClick={() => deleteDocument(data)} size={23} color="red" />
									</td>
								</tr>
							);
						})}
						<tr>
							<td>
								{/* <div className="mb-3 col-lg-6 col-sm-12"> */}
								<Input
									// wrapperClass={}
									placeholder={'Write name'}
									required={false}
									type="text"
									value={newName}
									fn={setNewName}
									showLabel={false}
								/>

								{/* </div> */}
								<div
									style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 250 }}
								>
									{newName.trim().length > 0 &&
										documentNames
											?.filter((v) => v.name?.toLowerCase().indexOf(newName.toLowerCase()) !== -1)
											.map((v) => {
												return (
													<>
														<Link onClick={() => setNewName(v.name)}>
															<div className="tagContainer">
																<span className="labelSuggestion">{v.name}</span>
																<button className="closeButton">+</button>
															</div>
														</Link>
													</>
												);
											})}
								</div>
							</td>
							<td colSpan={2}>
								<TextArea
									inputName="form-control"
									defaultValue={newDescription}
									placeholder="Details"
									name="details"
									fn={setNewDescription}
									row="6"
									required={false}
									showLabel={false}
								/>
							</td>
							<td>
								<Link
									// className="btn btn-primary"
									onClick={() => {
										let newDocument = {
											description: newDescription,
											message: '',
											name: newName,
										};

										setmodalMessage({ show: false });
										dispatch(allActions.global.Loader(true));
										dispatch(allActions.documentsgroup.createdocumentname(newDocument));
									}}
								>
									<CiCirclePlus size={30} />
								</Link>
							</td>
						</tr>
					</tbody>
				</table>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText={buttonText || 'Send Request'}
			/>
		</form>
	);
}
