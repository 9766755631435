import { useState } from "react";

 const AddListColumnModal = ({
    listFields,
    setListFields,
    setAddListColumnModalOpen,
  }) => {
    const [column, setColumn] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    return (
      <div
        className="custom-modal-overlay"
        onClick={() => setAddListColumnModalOpen(false)}
      >
        <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
          <h2 className="custom-modal-heading">Add Column</h2>
          <p className="custom-modal-description">
          Enter label for new list column
          </p>
          <div className="custom-modal-input-cont">
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
            <input
              className={errorMessage ? "border-1 border-danger" : ""}
              type="text"
              id="section-title"
              value={column}
              onChange={(e) => {
                setColumn(e.target.value);
                setErrorMessage("");
              }}
              />
          </div>
          <div className="custom-modal-buttons">
            <button
              className="btn"
              onClick={() => {
                setAddListColumnModalOpen(false);
              }}
            >
              Cancel
            </button>
            <div
              di
              className="btn btn-primary"
              onClick={() => {
                setListFields((prev) => {
                    return {...prev,columns:[...prev?.columns,{name:column,type:'text-one-line'}]}
                });
                  setAddListColumnModalOpen(false);
                  setErrorMessage("");
              }}
              disabled={column?.length < 3}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    );
  };


  export default AddListColumnModal