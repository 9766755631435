import React from "react";
import ReactDOM from "react-dom/client";
// global styles
import "./App.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import App from "./App";
// Import Store and Provider for Redux
import store from "./Store";
import { Provider } from "react-redux";
// Axios import
import axios from "axios";
// Stripe imports
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_KEY } from "./utilites";

const root = ReactDOM.createRoot(document.getElementById("root"));

axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem("token");
	config.headers.Authorization = token ? `Bearer ${token}` : '';
	config.withCredentials = true;
	return config;
});

// axios.interceptors.response.use(
// 	response => response,
// 	error => {
// 		if (error.response && error.response.status >= 500 && error.response.status < 600) {
// 			window.location.href = '/500';
// 		}
// 		return Promise.reject(error);
// 	}
// );

const stripePromise = loadStripe(STRIPE_KEY);

root.render(
	<Provider store={store}>
		<Elements stripe={stripePromise}>
			<App />
		</Elements>
	</Provider>
);
