
export const DOCUMENTS_GROUP_ACTION_TYPE = {
	documentGroupIdle: 'documentGroupIdle',
	documentGroupData: 'documentGroupData',
	documentGroupError: 'documentGroupError',

	// create document
	createDocumentGroupIdle: 'createDocumentGroupIdle',
	createDocumentGroupSuccess: 'createDocumentGroupSuccess',
	createDocumentGroupError: 'createDocumentGroupError',

	// delete document
	deleteDocumentGroupIdle: 'deleteDocumentGroupIdle',
	deleteDocumentGroupSuccess: 'deleteDocumentGroupSuccess',
	deleteDocumentGroupError: 'deleteDocumentGroupError',

	// mark document
	markDocumentGroupIdle: 'markDocumentGroupIdle',
	markDocumentGroupSuccess: 'markDocumentGroupSuccess',
	markDocumentGroupError: 'markDocumentGroupError',

	// edit document
	editDocumentGroupIdle: 'editDocumentGroupIdle',
	editDocumentGroupSuccess: 'editDocumentGroupSuccess',
	editDocumentGroupError: 'editDocumentGroupError',

    // get taxpro group document
	getTaxProdocumentGroupIdle: 'getTaxProdocumentGroupIdle',
	getTaxProdocumentGroupDataSuccess: 'getTaxProdocumentGroupDataSuccess',
	getTaxProdocumentGroupError: 'getTaxProdocumentGroupError',

	    // get taxpro group document  created by admin
		getTaxProAdmindocumentGroupIdle: 'getTaxProAdmindocumentGroupIdle',
		getTaxProAdmindocumentGroupDataSuccess: 'getTaxProAdmindocumentGroupDataSuccess',
		getTaxProAdmindocumentGroupError: 'getTaxProAdmindocumentGroupError',

	// create document name
	createDocumentNameIdle: 'createDocumentNameIdle',
	createDocumentNameSuccess: 'createDocumentNameSuccess',
	createDocumentNameError: 'createDocumentNameError',

	// edit document name
	editDocumentNameIdle: 'editDocumentNameIdle',
	editDocumentNameSuccess: 'editDocumentNameSuccess',
	editDocumentNameError: 'editDocumentNameError',

	// delete document name
	deleteDocumentNameIdle: 'deleteDocumentNameIdle',
	deleteDocumentNameSuccess: 'deleteDocumentNameSuccess',
	deleteDocumentNameError: 'deleteDocumentNameError',
};
