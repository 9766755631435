/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect,useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link,useParams } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
import { AiOutlineCheck } from 'react-icons/ai';
import { BsThreeDotsVertical,BsThreeDots } from 'react-icons/bs';
import { BASEURL } from '../../../utilites';
import axios from 'axios';
import { FileTypeIcon } from '../../../utilites';
import allActions from '../../../Store/action';
import Modal from '../../../components/Dashboard/Modal';
import Message from '../../../components/forms/fields/Message';
import RequestJobDocumentForm from '../../../components/forms/RequestJobDocumentForm';
import UploadDraftDocumentForm from '../../../components/forms/UploadDraftDocumentForm';
import EditDocumentForm from '../../../components/forms/EditDocumentForm';
import RenderMultiPagePDF from '../components/RenderMultiPagePDF';
import FormPreviewModal from '../../../components/forms/Modal/FormPreviewModal';
import RequestJobTaxForm from '../../../components/forms/RequestJobTaxForm';
import FileIcon  from '../.../../../../assets/img/file.svg'
import DotMoreIcon from '../../../assets/img/dotmore.png'
import OpenJobForm from '../../../components/forms/OpenJobForm';
import { useSelector } from 'react-redux';
import GroupDocument from '../../../components/forms/groupDocument';


const OpenFormModal = ({children,setCreateModalOpen}) => {
	return <div className="custom-modal-overlay">
		<div className="custom-modal-cont-create-form" onClick={(e) => e.stopPropagation()}>
			{children}
		</div>
	</div>;
  };

export default function JobDocuments({
	data,
  forms,
  handleGetJobForms,
	reload,
	documentRequestData,
	documentRequestLeadData,
	leadUUID,
	taxProUserId,
}) {
	const documentsData = data?.documents;
	const draftsData = data?.drafts;
	const [{ loginStatus,LoginData },{getTaxProdocumentGroup,getTaxProAdmindocumentGroup}] = useSelector((state) => [state.user,state.documentGroup]);
	const [message, setMessage] = useState({ show: false });
	const [swalProps, setSwalProps] = useState({});
	const [selectedDocument, setSelectedDocument] = useState(null);
	const [selectedJob, setSelectedJob] = useState(null);
	const [documentToView, setDocumentToView] = useState();
	const [jobs,setJobs] = useState([])
	const [openJobForm,setOpenJobForm] = useState(false)
  const [selectedDocumentIds,setSelectedDocumentIds] = useState([])
	const [selectedDocuments, setSelectedDocuments] = useState([])
	// const [documentsSelectedInDropdown, setDocumentsSelectedIndropDown] = useState([])
	const [messageDocModal, setmessageDocModal] = useState({ show: false });
	const [modalMessage, setmodalMessage] = useState({ show: false });

  // eslint-disable-next-line no-unused-vars
  const [activeFormData,setActiveFormData] = useState({})
    const {uuid} = useParams()
    

	const dispatch = useDispatch();

	const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				setJobs(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadJobByUUID(uuid)
	},[uuid])

	useEffect(() => {
		// if Status True
		if (jobs.status === true) {
			changeLoading(false);
		}

		// if Status False
		if (jobs.status === false) {
			changeLoading(false);
			setMessage({
				show: true,
				type: 'Error',
				text: jobs?.message,
			});
		}
		if (jobs.status === 'idle') {
			dispatch(allActions.jobs.getJobsByTaxPro({ uuid: leadUUID }));
		}
	}, [jobs.status, jobs.message, jobs?.data]);


	useEffect(() => {
		if (loginStatus) {  
			dispatch(allActions.global.Loader(true));      
			dispatch(allActions.documentsgroup.getTaxProdocumentsgroup(LoginData.isAdmin?jobs?.userpro?.user?.id:null
      ));
		}
	}, [loginStatus, dispatch,jobs]);

  useEffect(() => {
		if (loginStatus) {
			dispatch(allActions.global.Loader(true));
			dispatch(allActions.documentsgroup.getTaxProAdmindocumentsgroup());
		}
	}, [loginStatus, dispatch]);



  // get   Group document
	useEffect(() => {
		if (!getTaxProdocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: getTaxProdocumentGroup.message,
			});
		}
		if (getTaxProdocumentGroup.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, getTaxProdocumentGroup]);


    // get admin  Group document
	useEffect(() => {
		if (!getTaxProAdmindocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: getTaxProAdmindocumentGroup.message,
			});
		}
		if (getTaxProAdmindocumentGroup.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, getTaxProAdmindocumentGroup]);


	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};
	const deleteDocument = (uuid) => {
		dispatch(allActions.dashboard.deleteDocument(uuid, data?.user?.id)).then(() => {
			// TODO: Improve this
			setTimeout(() => {
				reload();
			}, 500);
		});
	};


	const handleDeleteDocumentClick = (uuid) => {
		setSwalProps((prev) => ({
			...prev,
			[uuid]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this document!',
				confirmButtonText: 'Yes, delete it!',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, [uuid]: { show: false } })),
				onConfirm: () => deleteDocument(uuid),
			},
		}));
	};
  const handleDeleteForm = (id) => {
    const deleteForm = async (id) => {
      try {
        await axios.delete(`${BASEURL}/api/jobs/${uuid}/${id}`)
        setMessage({
          show: true,
          type: 'Success',
          text: 'Fom has been successfully deleted.',
        });
    		loadJobByUUID(uuid)
        setTimeout(() => {
          setMessage({show:false})
        },3000)
      } catch (error) {
        setMessage({
          show: true,
          type: 'Error',
          text: 'Request error!',
        });
      }
    }
    setSwalProps((prev) => ({
			...prev,
			[id]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this form!',
				confirmButtonText: 'Yes, delete it!',
				denyButtonText: 'Cancel',
				didClose: () => {setSwalProps({})},
				onConfirm: () => deleteForm(id),
			},
		}));
  }

	// GET JOBS
	useEffect(() => {
		console.log("useEffectIdInJobDoc", leadUUID)
		dispatch(allActions.jobs.getJobsByTaxPro({ uuid: leadUUID }));
	}, []);

	useEffect(() => {
      setSelectedJob(
       {uuid: jobs?.jobs?.find((d) => data.id === d.id)?.uuid,service_id: jobs?.jobs?.find((d) => data.id === d.id)?.service_id,lead_id:jobs?.jobs?.find((d) => data.id === d.id)?.lead_id}
    )
        handleGetJobForms(data?.id)
	}, [jobs]);


    const conditionCheck = (activeSection,activeSubSection,question) => {
    const answer =  activeFormData?.data?.sections
    ?.find((sec) => sec?.id === activeSection)
    ?.subsections?.find((sub) => sub?.id === activeSubSection)
    ?.fields?.find(
      (field) => field?.question === question?.conditionQuestion
    )
    ?.answer
    if(typeof answer !== 'string' ){
      return false
    }else{
      return answer?.toLowerCase()  === question?.condition.toLowerCase()
    }
  
  };

	const isFormFilled = (taxForm) => {
		if (!taxForm || !taxForm.sections || taxForm.sections.length === 0) {
			return false;
		}

		for (const section of taxForm.sections) {
			for (const subsection of section?.subsections) {
				for (const field of subsection?.fields) {
					if (
						field?.question === 'divider' ||
						field?.type === 'list' ||
						field?.type === 'file-upload' ||
						field?.type === 'text-header' ||
						field?.type === 'horizontal-line'
					) {
						continue;
					}
					if (field.condition && !conditionCheck(section?.id, subsection?.id, field)) {
						continue;
					}
					if (field.type === 'grid') {
						const { answers, columns, rows } = field.questionData;

						if (field.required && Object.keys(answers).length < rows.length) {
							return false;
						}

						for (const rowId of rows.map((row) => row.id)) {
							const rowAnswers = answers[rowId];
							if (field.required && !rowAnswers) {
								return false;
							}

							for (const col of columns) {
								const colAnswer = rowAnswers?.[col.id];
								if (field.required && (colAnswer === undefined || colAnswer === '')) {
									return false;
								}
							}
						}
						continue;
					}

					if (field.type === 'card') {
						for (const question of field?.questionData) {
							if (
								typeof question?.answer === 'string' &&
								(!question?.answer || question?.answer?.trim() === '')
							) {
								return false;
							}
						}
						continue;
					}

					if (field?.required && Array.isArray(field.answer)) {
						if (field.answer.length === 0) {
							if (field?.type === 'expenses-list') {
								continue;
							} else {
								return false;
							}
						}
					} else if (field.required && (!field.answer || field?.answer?.trim() === '')) {
						return false;
					}
				}
			}
		}
		return true;
	};

  const handleSelectDocument = (document) => {
    document.forEach((documents)=>{ 
		const isDocument = selectedDocuments.find(doc => doc.id === document.id)
		if (!isDocument) {
			setSelectedDocuments(prev => [...prev, documents])
			// setDocumentsSelectedIndropDown(prev => [...prev, documents])
		} else {
			const filterAndDelete = selectedDocuments.filter(document => document.id !== isDocument.id)
			setSelectedDocuments(filterAndDelete)
			// setDocumentsSelectedIndropDown(filterAndDelete)
		}
  })
	}
  const handleGroupDocument=()=>{
    console.log("@1111111",data);
    dispatch(allActions.global.Loader(true));      

    axios
    .post(`${BASEURL}/api/jobs/request-documents`, {
      customer_id: documentRequestData.customer_id,
      pro_id: documentRequestData.pro_id,
      job_id: documentRequestData.job_id,
      documents:selectedDocuments,
    })
    .then(() => {      
      dispatch(allActions.global.Loader(false));      
      document.querySelector(".requestJobGroupDocumentsModalClose").click();
      reload();
      setmessageDocModal({show:false})
    })
    .catch((error)=>{      
      setmessageDocModal({
				show: true,
				type: 'Error',
				text: error.response.data?.error?error.response.data?.error:'Server Error',
			})
      dispatch(allActions.global.Loader(false));      

    })
  }
	return (
    <div className="clearfix">
      {openJobForm?.state === "openjobform" && (
        <OpenFormModal setCreateModalOpen={setOpenJobForm}>
          <OpenJobForm
            selectedJob={selectedJob?.uuid}
            setIsFormModalOpen={setOpenJobForm}
            setActiveFormData={setActiveFormData}
            activeFormData={activeFormData}
			handleGetJobForms={handleGetJobForms}
			job={data}
          />
        </OpenFormModal>
      )}
      {message.show ? <Message message={message} /> : <></>}
        <h5 className="text-black fw-bold">Tax Forms</h5>
      {selectedDocument?.length > 0 && (
      <div className="row my-3">
        <div className="col-md-6">
          <strong>Description</strong>
        </div>
        <div className="col-md-2">
          <strong> Status</strong>
        </div>
        <div className="col-md-3">
          <strong>Date</strong>
        </div>
      </div>
      )}
      {forms?.map((selected, id) => {
        const form = selected?.jobformsdata?.form_data;
        const formId = selected?.jobformsdata?.id;
        const dateCreated = selected?.jobformsdata?.created_at
        return (
          <div
            key={form.name}
            className="d-flex flex-row align-items-center justify-content-between px-4 py-4 border border-light rounded-3 mb-3 flex-wrap"
          >
            <div className="d-flex align-items-center gap-4 col-md-5 col-sm-12">
              <div className=" d-flex justify-content-center">
                <div>
                  <img src={FileIcon} alt="file" />
                </div>
              </div>

              <div className="d-flex  align-items-center">
                <Link
                  className="text-dark text-decoration-none d-flex align-items-center gap-3"
                  onClick={() => {
                   if(form?.formData !== undefined && Object.keys(form?.formData).length > 0) {
                       setMessage({ show: false });
                       setOpenJobForm({
                           state: "openjobform",
                           data: { ...form, data: form?.formData, id: formId },
                       });
                       setActiveFormData({
                           name: form?.name,
                           submitted: document?.submitted,
                           id: formId,
                           service_id: selectedJob?.service_id,
                           data: form?.formData,
                           lead_id: selectedJob?.lead_id,
                       });
                   }else {
                       setMessage({
                           show: true,
                           type: 'Error',
                           text: 'The form is undefined or empty.',
                       });
                   }
                  }}
                >
                  <div className="d-flex align-items-center">
                    <p className="my-0 fw-bold">{form?.name}</p>
                    {/* <p className='my-0 text-muted'>{form.message}</p> */}
                  </div>
                </Link>
              </div>
            </div>
            <div className="d-flex align-items-center " style={{ gap: 120 }}>
              {isFormFilled(form?.formData) ? (
                <div className={`btn btn-success btn-sm text-white`}>
                  Completed
                </div>
              ) : (
                <div className={`btn btn-grey-400 btn-sm text-white`}>
                  Pending
                </div>
              )}

              {/* <button className='btn btn-secondary'>Pending</button>
                <button className="btn btn-success">completed</button> */}
              <div>
                {new Date(dateCreated).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }) ||
                  new Date().toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
              </div>
            </div>

            <div className="text-end dropdown-center col-md-2">
              <button
                type="button"
                className={`btn btn-sm text-primary border-0 rounded-circle`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  width: 30,
                  height: 30,
                }}
              >
                <img src={DotMoreIcon} alt="dotmore" />
                {/* <BsThreeDotsVertical /> */}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => {
                        if(form?.formData !== undefined && Object.keys(form?.formData).length !== 0) {
                            setMessage({ show: false });
                            setOpenJobForm({
                                state: "openjobform",
                                data: { ...form, data: form?.formData, id: formId },
                            });
                            setActiveFormData({
                                name: form?.name,
                                submitted: document?.submitted,
                                id: formId,
                                service_id: selectedJob?.service_id,
                                data: form?.formData,
                                lead_id: selectedJob?.lead_id,
                            });
                        }else {
                            setMessage({
                                show: true,
                                type: 'Error',
                                text: 'The form is undefined or empty.',
                            });
                        }
                    }}
                  >
                    View
                  </Link>
                </li>
                {!isFormFilled(form?.formData) && (
                  <li>
                    <Link
                      className="dropdown-item text-danger  "
                      onClick={() => {
                       handleDeleteForm(formId)
                      }}
                    >
                      Delete
                    <SweetAlert2 {...swalProps[formId]} />
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        );
      })}

      {forms?.length === 0 && (
        <div className="card p-5 border-1 border-secondary-100 text-center rounded shadow-hover">
          <div className="">
           No form or questionnaire has been sent to the customer for this job.
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button
              className={`btn btn-primary px-2 w-25 rounded-2 ${
                data.status_id === 4 && "disabled"
              }`}
              data-bs-toggle="modal"
              data-bs-target="#requestJobTaxForms"
            >
              Request Additional Tax forms
            </button>
          </div>
        </div>
      )}

      {forms?.length > 0 && (
        <button
          className={`btn btn-primary px-2 w-25 rounded-2 ${
            data.status_id === 4 && "disabled"
          }`}
          data-bs-toggle="modal"
          data-bs-target="#requestJobTaxForms"
        >
          Request Additional Tax forms
        </button>
      )}

      <h5 className="text-black py-3 mt-3 fw-bold">Requested Documents</h5>
      {documentsData || documentsData?.length !== 0 ? (
        <>
          {documentsData?.map((document, index) => (
            <div
              key={document.id}
              className="row align-items-center px-3 py-3 border border-light text-black rounded-3 mb-3"
            >
              <div
                className="col-md-6 d-flex align-items-center"
                style={{ gap: 10 }}
              >
                {/* <TbFileDescription size={28} color="grey" extension={document?.extension} /> */}
                <div>
                  <p className="mb-0 mt-0 fw-bold">
                    {document?.jobdocument?.name || "Sample Document Name"}
                  </p>
                  <p className="mb-0 mt-0">
                    {document?.jobdocument?.description || "Sample Description"}
                  </p>
                  {document?.jobdocument.message && (
                    <p className="mb-0 mt-0 text-muted">
                      {document?.jobdocument?.message || "Sample Message"}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-2 d-flex align-items-center">
                <div
                  className={`btn btn-${
                    document?.path || document?.link?.length>0 ? "success" : "grey-400"
                  } btn-sm text-white`}
                >
                  {document?.path  || document?.link?.length>0 ? "Submitted" : "Pending"}
                </div>
              </div>

              <div className="col-md-3 d-flex align-items-center text-black">
                <span className="text-dark text-black ">
                  {new Date(
                    document.updated_at || new Date()
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </div>

              <div className="col-md-1 text-end dropdown-center">
                <button
                  type="button"
                  className="btn btn-sm text-primary border-0 rounded-circle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    width: 30,
                    height: 30,
                  }}
                >
                  <BsThreeDots />
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      to="#"
                      className={`dropdown-item${
                        document?.path || document?.link?.length>0 ? "" : " disabled"
                      }`}
                      data-bs-toggle="modal"
                      data-bs-target="#viewDocumentModal"
                      onClick={() => setDocumentToView(document)}
                    >
                      View
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={document?.path ? document.path : "#"}
                      className={`dropdown-item${
                        document?.path ? "" : " disabled"
                      }`}
                      download={document?.name}
                    >
                      Download
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider border-light" />
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item text-danger"
                      onClick={() => handleDeleteDocumentClick(document.uuid)}
                    >
                      Delete
                    </Link>
                    <SweetAlert2 {...swalProps[document.uuid]} />
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="card p-5 border-1 border-secondary-100 text-center rounded shadow-hover">
          <h4>The customer has not started the assessment yet.</h4>
        </div>
      )}
      {documentsData?.length === 0 && (
        <div className="card p-5 border-1 border-secondary-100 text-center rounded shadow-hover">
          <div className="">
            No document has been requested from the customer for this job.
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button
              className={`btn btn-primary rounded-0 w-25 px-2 rounded-2 ${
                data.status_id === 4 && "disabled"
              }`}
              data-bs-toggle="modal"
              data-bs-target="#requestJobDocumentsModal"
            >
              Request Documents
            </button>
          </div>
          <div className="clearfix m-t-10">
          <button
            className={`btn btn-primary px-2 w-25 rounded-2 ${
              data.status_id === 4 && "disabled"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#requestJobGroupDocumentsModal"
            onClick={()=>setSelectedDocuments([])}
          >
            Request Group Document
          </button>
        </div>
        </div>
      )}

      {documentsData?.length > 0 && (
        <div className="clearfix">
          <button
            className={`btn btn-primary px-2 w-25 rounded-2 ${
              data.status_id === 4 && "disabled"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#requestJobDocumentsModal"
          >
            Request Additional document
          </button>
        </div>
      )}
            {documentsData?.length > 0 && (
        <div className="clearfix m-t-10">
          <button
            className={`btn btn-primary px-2 w-25 rounded-2 ${
              data.status_id === 4 && "disabled"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#requestJobGroupDocumentsModal"
            onClick={()=>setSelectedDocuments([])}
          >
            Request Group Document
          </button>
        </div>
      )}
      <h5 className="fw-bold mt-5">Draft documents</h5>
      {draftsData?.length !== 0 && (
        <>
          {draftsData?.map((document, index) => (
            <div
              className="row align-items-center px-2 py-4 border border-light rounded-3 mb-3"
              key={document.id}
            >
              <div className="col-md-1 d-flex justify-content-center">
                <div
                  className={`rounded-circle bg-${
                    document?.path || document?.link?.length>0 ? "success" : "grey-200"
                  } text-white d-flex justify-content-center align-items-center`}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                >
                  <AiOutlineCheck />
                </div>
              </div>
              <div
                className="col-md-7 d-flex align-items-center"
                style={{ gap: 10 }}
              >
                <FileTypeIcon extension={document?.extension} />{" "}
                {document?.name}
              </div>
              <div className="col-3 text-end">
                {(document?.path || document?.link?.length>0) && (
                  <span className="text-dark text-opacity-50">
                    Added{" "}
                    {new Date(document.updated_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                )}
              </div>
              <div className="col text-end dropdown-center">
                <button
                  type="button"
                  className={`btn btn-sm text-primary border-0 rounded-circle`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    width: 30,
                    height: 30,
                  }}
                >
                  <BsThreeDotsVertical />
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      to="#"
                      className={`dropdown-item${
                        document?.path || document?.link?.length>0 ? "" : " disabled"
                      }`}
                      data-bs-toggle="modal"
                      data-bs-target="#viewDocumentModal"
                      onClick={() => setDocumentToView(document)}
                    >
                      View
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={`dropdown-item${
                        document?.path || document?.link?.length>0 ? "" : " disabled"
                      }`}
                      data-bs-toggle="modal"
                      data-bs-target="#openEditDocumentModal"
                      onClick={() => setDocumentToView(document)}
                    >
                      Edit Document Name
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider border-light" />
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="dropdown-item text-danger"
                      onClick={() => handleDeleteDocumentClick(document.uuid)}
                    >
                      Delete
                    </Link>
                    <SweetAlert2 {...swalProps[document.uuid]} />
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </>
      )}
      {draftsData?.length === 0 && (
        <div className="card p-5 border-1 border-secondary-100 text-center rounded shadow-hover">
          <div className="">
            No draft or final document has been uploaded for this job.
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button
            className={`btn btn-primary px-2 w-25 rounded-2 ${
              data.status_id === 4 && "disabled"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#uploadDraftDocumentModal"
          >
            Upload Document
          </button>
          </div>
        </div>
      )}
      {draftsData?.length > 0 && documentRequestData.isTaxPro && data.status_id !== 3 && (
        <div className="clearfix">
           <button
            className={`btn btn-primary px-2 w-25 rounded-2 ${
              data.status_id === 4 && "disabled"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#uploadDraftDocumentModal"
          >
            Upload Document
          </button>
        </div>
      )}
      <Modal
        title="Request Documents"
        bodyClass="px-5 py-4 text-center"
        trigger="requestJobDocumentsModal"
        showBtn={false}
        btnClass="requestJobDocumentsModalClose"
        size="lg"
      >

        <RequestJobDocumentForm
          data={documentRequestData}
          reloadData={reload}
          customer_id={data?.user_id}
          leadData={documentRequestLeadData}
          closeClass="requestJobDocumentsModalClose"
          singleDocumentRequest={true}
          modalMessage={modalMessage}
					setmodalMessage={setmodalMessage}
					Heading={[{ heading: 'Name' }, { heading: 'Description' },{ heading: 'Edit' },{ heading: 'Delete' }]}
          buttonText={"Request Document"}
        />
      </Modal>
      <Modal
        title="Request Tax Form"
        bodyClass="px-5 py-4 "
        trigger="requestJobTaxForms"
        showBtn={false}
        btnClass="requestJobTaxFormsModalClose"
        size="xl"
      >
        <RequestJobTaxForm
          data={forms}
          reloadData={reload}
          userData={documentRequestData}
          setSelectedDocument={setSelectedDocument}
          jobs={jobs}
          setJobs={setJobs}
		      handleGetJobForms={handleGetJobForms} 
        />
      </Modal>
      <Modal
        title="Upload draft document"
        bodyClass="px-5 py-4 text-center"
        trigger="uploadDraftDocumentModal"
        btnClass="uploadDraftDocumentModalClose"
        showBtn={false}
      >
        <UploadDraftDocumentForm
          userId={taxProUserId}
          jobId={data.id}
          reloadData={reload}
          targetUserUuid={data.user?.uuid}
        />
      </Modal>
      <Modal
        title="View Document"
        bodyClass="px-5 py-4 text-center"
        trigger="viewDocumentModal"
        showBtn={false}
        size="lg"
      >
     {documentToView &&
          ( documentToView?.path ?
            (documentToView?.location.includes(".pdf") ? (
              <>
                <RenderMultiPagePDF file={documentToView.path} />
              </>
            ) : (
              <img
                src={documentToView.path}
                className="w-100 img-fluid rounded"
                alt={documentToView.name}
                style={{ objectFit: "contain" }}
              />
            ))
            :
            documentToView?.link.map((value)=>
              (value.location.includes(".pdf") ? (
                 <>
                   <RenderMultiPagePDF file={value.path} />
                 </>
               ) : (
                 <img
                   src={value.path}
                   className="w-100 img-fluid rounded"
                   alt={documentToView.name}
                   style={{ objectFit: "contain" }}
                 />
               ))
  
             )
     
          )
            }
      </Modal>
      <Modal
        title="Edit document"
        bodyClass="p-4 text-center text-dark text-opacity-50"
        trigger="openEditDocumentModal"
        showBtn={false}
        btnClass="editDocumentModalClose"
      >
        {documentToView && (
          <EditDocumentForm data={documentToView} reloadDocuments={reload} />
        )}
      </Modal>
      <Modal
				title="Create Group Documents"
				bodyClass="px-5 py-4 text-center"
				trigger="requestJobGroupDocumentsModal"
				showBtn={false}
				btnClass="requestJobGroupDocumentsModalClose"
				size="lg"
			>
				<GroupDocument
					closeClass="requestJobGroupDocumentsModalClose"
          getTaxProdocumentGroup={getTaxProdocumentGroup}
          getTaxProAdmindocumentGroup={getTaxProAdmindocumentGroup}
          selectedDocumentIds={selectedDocumentIds}
          setSelectedDocumentIds={setSelectedDocumentIds}
          handleSelectDocument={handleSelectDocument}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          // setDocumentsSelectedIndropDown={setDocumentsSelectedIndropDown}
          handleGroupDocument={handleGroupDocument}
          messageDocModal={messageDocModal}
				/>
			</Modal>
      <FormPreviewModal />
    </div>
  );
}
