import DeleteIcon from '../../assets/img/x.svg';
import FileBlueSvg from '../../assets/img/filebluesvg.svg';
import RequestDocumentSvg from '../../assets/img/requestfilesvg.svg';
import { useState } from 'react';
import EditDocumentSvg from '../../assets/img/editrequestsvg.svg';
import BinSvg from '../../assets/img/binsvg.svg';
import Message from './fields/Message';

export default function GroupDocument({
	getTaxProdocumentGroup,
	getTaxProAdmindocumentGroup,
	selectedDocumentIds,
	setSelectedDocumentIds,
	handleSelectDocument,
	selectedDocuments,
	setSelectedDocuments,
	// setDocumentsSelectedIndropDown,
	handleGroupDocument,
	messageDocModal
}) {
	const [editDocument, setEditDocument] = useState('');

	const handleDeleteSelectedDocument = (id) => {
		const filterAndDelete = selectedDocuments.filter((document) => document.id !== id);
		setSelectedDocuments(filterAndDelete);
		// setDocumentsSelectedIndropDown(filterAndDelete);
	};
	return (
		<div className="row d-flex justify-content-start">
			{messageDocModal.show ? <Message message={messageDocModal} /> : <></>}
			
			{selectedDocuments.length > 0 &&
				selectedDocuments.map((document) => {
					return (
						<div className="mt-5 mb-3 d-flex align-items-start justify-content-between border-b">
							<div className="d-flex gap-4 w-50">
								<img className="mt-1 icon-h" src={RequestDocumentSvg} alt="requestfile" />
								<div className="w-100">
									<div className="d-flex align-itesm-center gap-5">
										<input
											disabled={editDocument !== document?.id}
											className="mb-0 fw-bold w-100 border-0 outline-0"
											onChange={(e) => {
												const edited = selectedDocuments?.map((doc) => {
													if (doc.id === document.id) {
														return {
															id: doc.id,
															name: e.target.value,
															description: doc.description,
															message: doc.message,
														};
													}
													return doc;
												});
												setSelectedDocuments(edited);
											}}
											value={document.name}
										/>
									</div>
									<textarea
										cols={60}
										rows={5}
										onChange={(e) => {
											const edited = selectedDocuments?.map((doc) => {
												if (doc.id === document.id) {
													return {
														id: doc.id,
														name: doc.name,
														description: e.target.value,
														message: doc.message,
													};
												}
												return doc;
											});
											setSelectedDocuments(edited);
										}}
										disabled={editDocument !== document?.id}
										type="text"
										value={document.description}
										className="mb-0 mt-1 max-w  border-0 mb-3"
									/>
									{/* {(addMessageOnRequest === document?.id || document.message) && <textarea
													className='w-100'
													value={document?.message}
													onChange={(e) => {
														const edited = selectedDocuments?.map(doc => {
															if (doc.id === document.id) {
																return {
																	id: doc.id,
																	name: doc.name,
																	description: doc.description,
																	message: e.target.value
																}
															}
															return doc
														})
														setSelectedDocuments(edited)
													}}
												></textarea>} */}
									{/* <p onClick={() => setAddMessageOnRequest(document?.id)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p> */}
								</div>
							</div>
							<div className="d-flex gap-4 align-items-center justify-content-center">
								<div className="d-flex align-items-center gap-2">
									{editDocument && editDocument === document?.id ? (
										<p
											className="mb-0 mt-0 text-success fw-bold cursor-pointer"
											onClick={() => setEditDocument('')}
										>
											Save
										</p>
									) : (
										<img
											onClick={() => setEditDocument(document?.id)}
											className="cursor-pointer icon"
											src={EditDocumentSvg}
											alt="edit"
										/>
									)}
									<img
										onClick={() => handleDeleteSelectedDocument(document.id)}
										className="cursor-pointer icon"
										src={BinSvg}
										alt="delete"
									/>
								</div>
							</div>
						</div>
					);
				})}

			{getTaxProdocumentGroup?.data?.map((document) => {
				return (
					<div
						onClick={() => handleSelectDocument(document?.docs_data?.documents)}
						className={`dynamic-form-card px-5 text-center border-solid ${
							selectedDocumentIds.includes(document.id) ? 'border-primary' : ''
						} d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
					>
						<img
							onClick={(e) => {
								e.stopPropagation();
								// setCustomModalOpen({
								//   state: "delete-groupDocument",
								//   data: document,
								// });
								// handleDeleteTemplate(option?.id);
							}}
							className="delete"
							src={DeleteIcon}
							alt="delete-icon"
						/>

						<img style={{ height: '40px' }} src={FileBlueSvg} alt="plus" />
						{document?.docs_data?.groupName}
					</div>
				);
			})}
			<div className="w-100 dashed-border my-3"></div>
			{getTaxProAdmindocumentGroup?.data?.map((document) => {
				return (
					<div
						onClick={() => handleSelectDocument(document?.docs_data?.documents)}
						className={`px-5 text-center border-solid ${
							selectedDocumentIds.includes(document.id) ? 'border-primary' : ''
						} d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
					>
						<img style={{ height: '40px' }} src={FileBlueSvg} alt="plus" />
						{document?.docs_data?.groupName}
					</div>
				);
			})}

<div className="clearfix m-t-10">
          <button
            className={`btn btn-primary px-2 w-25 rounded-2 `}
			onClick={handleGroupDocument}
            // data-bs-toggle="modal"
            // data-bs-target="#requestJobGroupDocumentsModal"
          >
            Request Group Document
          </button>
        </div>
		</div>
	);
}
