/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect, useCallback} from 'react'
import FileBlueSvg from '../../assets/img/filebluesvg.svg'
import QuestionaireSvg from '../../assets/img/quetenionairesvg.svg'
import editIcon from '../../assets/img/edit1.svg'
import DeleteIcon2 from '../../assets/img/delete1.svg'
import EditDocumentSvg from '../../assets/img/editrequestsvg.svg'
import DeleteIcon from '../../assets/img/x.svg'
import PlusSvg from '../../assets/img/plussvg.svg'
import { useDispatch } from 'react-redux';
import allActions from '../../Store/action';
import axios from 'axios'
import { BASEURL } from '../../utilites';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom'
import CreateNewForm from './CreateNewForm'
import Message from './fields/Message'
import { useSelector } from 'react-redux'
import OpenJobForm from '../../components/forms/OpenJobForm';




const CreateFormModal = ({children,setCreateModalOpen,formTitle,setFormTitle}) => {
	return <div className="custom-modal-overlay" onClick={() => {
    document.body.style.overflowY = '';
  }}>
		<div className="custom-modal-cont-create-form" onClick={(e) => e.stopPropagation()}>
			{children}
		</div>
	</div>;
  };

  const CustomDeleteModal = ({customModalOpen,setCustomModalOpen,handleDeleteTemplate,dynamicForms}) => {
    return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)} >
    <div className="custom-modal-cont-delete" onClick={(e) => e.stopPropagation()}>
      <h2 className="custom-modal-heading">Delete questionnaire</h2>
      <p className="custom-modal-description">Are you sure you want to delete this template?</p>
      <div className="custom-modal-buttons">
              <button className="btn" onClick={() => {
                setCustomModalOpen(null)
              }}>Cancel</button>
              <button di className="btn btn-danger" onClick={() => {
          const templateToDelete = dynamicForms?.find(form => form?.form_data?.name === customModalOpen?.data)
                handleDeleteTemplate(templateToDelete?.id)
          setCustomModalOpen(null)
              }} >Delete</button>
            </div>
      <div className="custom-modal-buttons">
      </div>
    </div>
  </div>;
  }	


  const CreateFormTitleModal = ({setCustomModalOpen,formTitle,setFormTitle,setCreateModalOpen}) => {
    return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)}>
      <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
        <h2 className="custom-modal-heading">New questionnaire</h2>
        <div className="custom-modal-input-cont">
            <label htmlFor="section-title" className='text-dark txt-sm'>Enter questionnaire name</label>
            <input type="text" id="section-title" value={formTitle} onChange={(e) => setFormTitle(e.target.value)} />
        </div>
        <div className="custom-modal-buttons">
          <button className="btn" onClick={() => {
          setCustomModalOpen(null)
          }}>Cancel</button>
          <button di className="btn btn-primary" onClick={() => {
          setCustomModalOpen(null)
          setCreateModalOpen({state:true,editmode:false})
          }} disabled={formTitle?.length < 3}>Create</button>
        </div>
      </div>
    </div>;
    };

    const EditFormTitleModal = ({setCustomModalOpen,formTitle,selectedDynamicForms,setSelectedDynamicForms}) => {
      console.log(formTitle);
      const [title,setTitle] = useState(formTitle)
      return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)}>
        <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
          <h2 className="custom-modal-heading">Edit questionnaire tittle</h2>
          <div className="custom-modal-input-cont">
              <label htmlFor="section-title" className='fs-6'>Enter a single sentence description that best describes the purpose of your questionnaire.</label>
              <input type="text" id="section-title" value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className="custom-modal-buttons">
            <button className="btn" onClick={() => {
            setCustomModalOpen(null)
            }}>Cancel</button>
            <button di className="btn btn-primary" onClick={() => {
              let updatedDynaimcForms = [...selectedDynamicForms]
               updatedDynaimcForms = updatedDynaimcForms?.map((form) => {
                if(form?.name === formTitle){
                  return {
                    ...form,
                    name:title
                  }
                }
                return form
              }
            ) 
            setSelectedDynamicForms(updatedDynaimcForms)
            setCustomModalOpen(false)
            }} disabled={title?.length < 3}>Save</button>
          </div>
        </div>
      </div>;
      };


const RequestJobTaxForm = ({data,userData,setSelectedDocument,jobs,setJobs, handleGetJobForms}) => {
  const [{ LoginData }] =
  useSelector((state) => [state.user]);
	const [selectedFormNames, setSelectedFormNames] = useState(data || []);
    const [selectedDynamicForms,setSelectedDynamicForms] = useState([])
	const [selectedForms,setSelectedForms] = useState([])
    const [additionalTaxForms,setEdditionalTaxFroms] = useState([])
    const [alreadySelectedForms,setAlreadySelectedForms] = useState([])
    const [dynamicForms,setDynamicForms] = useState([])
    const [dynamicFormsCreatedByAdmins, setDynamicFormsCreatdByAdmins] = useState([])
    const [createFormModalOpen,setCreateFormModalOpen] = useState(false)
    const [customModalOpen,setCustomModalOpen] = useState('')
    const [formTitle,setFormTitle] = useState('')
    const [message, setMessage] = useState({ show: false });


    const {uuid} = useParams()

    const dispatch = useDispatch()

    const changeLoading = (status) => {
        dispatch(allActions.global.Loader(status));
      };

    const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				setJobs(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

        const handleSubmit = (e) => {
        e.preventDefault()
        changeLoading(true)
        axios
			.post(`${BASEURL}/api/jobs/request-taxforms`, {
				customer_id: userData.customer_id,
				pro_id:userData.pro_id,
				job_id: userData.job_id,
				forms:selectedDynamicForms,
			})
			.then(() => {
                 changeLoading(false)
                handleGetJobForms(userData?.job_id)
                setEdditionalTaxFroms([])
                setSelectedForms()
                document
					.querySelector(`.${'requestJobTaxFormsModalClose'}`)
					.click();
          setSelectedDynamicForms([])
			})
            .catch((error) => {              
              changeLoading(false)
              setMessage({
                show: true,
                type: 'Error',
                text:error?.response?.data?.error?error?.response?.data?.error:'server error'
              });
            
            })
    }

    useEffect(() => {
		if(jobs?.jobs?.length > 0){
            setSelectedDocument(jobs?.jobs[0].form_data)
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobs]);

    useEffect(() => {
        setSelectedForms(selectedFormNames?.map(name => name?.name))
     },[selectedFormNames])
     useEffect(() => {
        setSelectedForms(additionalTaxForms?.map(name => name?.name))
     },[additionalTaxForms])

     useEffect(() => {
        setSelectedFormNames(() => data?.map(form => {
            return {name:form.name,value:form.name,label:form.name, message:''}
        }))
        setAlreadySelectedForms(data?.map(data => data.name))
     },[data])


     const handleSaveTemplate = async (formTitle,taxForm) => {
      changeLoading(true)
      const endpoint =  "create-dynamic-form"

      try { 

       await axios.post(`${BASEURL}/api/taxform/${endpoint}`,{form_data:{name:formTitle,data:taxForm,byAdmin:false},pro_user_id:LoginData.isAdmin?jobs?.userpro?.user?.id:null })
       if(getDynamicForms){
         getDynamicForms()
        }
        setMessage({
          show: false,});
        changeLoading(false)
      } catch (error) {
        console.log(error);
        changeLoading(false)
        setMessage({
          show: true,
          type: 'Error',
          text:error?.response?.data?.error?error?.response?.data?.error:'server error'
        });
      }
    }



     const handleDeleteTemplate = async (id) => {
      changeLoading(true)
      const endpoint = LoginData?.isAdmin ?  "/delete-dynamic-form-byadmin" : "delete-dynamic-form"

      try {
          await axios.delete(`${BASEURL}/api/taxform/${endpoint}`,{data:{form_id:id}})
          getDynamicForms()
          changeLoading(false)
          setMessage({
            show: false,});
        } catch (error) {
          changeLoading(false)
          setMessage({
            show: true,
            type: 'Error',
            text:error?.response?.data?.error?error?.response?.data?.error:'server error'
          });
          console.log(error);
        }
    }	

     const getDynamicForms = async () => {
      const endpoint = LoginData?.isAdmin ?  "/get-dynamic-form-byadmin" : "get-dynamic-form"
		try {
		const resp = await axios.get(`${BASEURL}/api/taxform/${endpoint}`)
			setDynamicForms(resp?.data?.data)
		} catch (error) {
			console.log(error);
		}
	}
  const getDynamicFormsCreatedByAddmins = async () => {
		try {
		const resp = await axios.get(`${BASEURL}/api/taxform/get-dynamic-form-created-by-admin`)
    setDynamicFormsCreatdByAdmins(resp?.data?.data)
		} catch (error) {
			console.log(error);
		}
	}

  useEffect(() => {
    getDynamicForms()
    getDynamicFormsCreatedByAddmins()
  },[])

     return (
    <div className="border-dashed rounded mt-3 bg-muted-200  pb-5 bg-color">
      {customModalOpen?.state === "delete" && (
          <CustomDeleteModal
            customModalOpen={customModalOpen}
            dynamicForms={dynamicForms}
            setCustomModalOpen={setCustomModalOpen}
            handleDeleteTemplate={handleDeleteTemplate}
          />
        )}
          {createFormModalOpen?.state && (
                <CreateFormModal setCreateModalOpen={setCreateFormModalOpen}>
                  <CreateNewForm
                    setSelectedDynamicForms={setSelectedDynamicForms}
                    selectedDynamicForms={selectedDynamicForms}
                    getDynamicForms={getDynamicForms}
                    formTitle={formTitle}
                    setFormTitle={setFormTitle}
                    setCreateFormModalOpen={setCreateFormModalOpen}
                    setCreateF={createFormModalOpen}
                    editmode={createFormModalOpen?.editmode}
                    formToEdit={createFormModalOpen?.data || {}}
                  />
                </CreateFormModal>
              )}
              {customModalOpen?.state === "formtitle" && (
          <CreateFormTitleModal
            setCustomModalOpen={setCustomModalOpen}
            formTitle={formTitle}
            setFormTitle={setFormTitle}
            setCreateModalOpen={setCreateFormModalOpen}
          />
        )}
            {customModalOpen?.state === "editformtitle" && (
          <EditFormTitleModal
            setCustomModalOpen={setCustomModalOpen}
            customModalOpen={customModalOpen}
            formTitle={customModalOpen?.formTitle}
            selectedDynamicForms={selectedDynamicForms}
            setSelectedDynamicForms={setSelectedDynamicForms}
            
          />
        )}
        {customModalOpen?.state === "openjobform" && (
        <CreateFormModal setCreateModalOpen={setCustomModalOpen}>
          <OpenJobForm
            selectedJob={{}}
           setIsFormModalOpen={setCustomModalOpen}
           activeFormData={customModalOpen?.data}
           setActiveFormData={{}}
           previewMode={true}
            
          />
          </CreateFormModal>
        )}

        {selectedDynamicForms.length > 0 &&
                selectedDynamicForms.map((name, id) => {
                  return (
                    <div
                      key={name?.value}
                      className="mt-5 mb-3 d-flex align-items-start justify-content-between border-b pb-3 px-4"
                    >
                      <div className="d-flex gap-4 cursor-pointer">
                        <img
                          className="mt-1 icon-h"
                          src={QuestionaireSvg}
                          alt="requestfile"
                         
                        />
                        <div
                          role="button"
                          onClick={() => {
                            // setMessage({ show: false });
                            
                          }}
                        >
                          <div
                            className="d-flex align-itesm-center gap-5"
                          >
                           <div className='d-flex align-items-center gap-3'>
                           <p className="mb-0 fw-bold"  onClick={() => {
                              setCustomModalOpen({state:'openjobform',data:name});
                            }}>{name?.name}</p>  
                           <img src={EditDocumentSvg} alt='edit' className='edit-icon' onClick={(e) => {
                            e.stopPropagation()
                            setCustomModalOpen({state:'editformtitle',formTitle:name?.name})
                           }}/>
                           </div>
                          </div>
                          {dynamicForms?.find(
                            (form) => form?.form_data?.name === name?.name
                          ) ? (
                            <div
                              onClick={() => {
                                setCustomModalOpen({
                                  state: "delete",
                                  data: name?.name,
                                });
                              }}
                              className="text-danger text-start"
                            >
                              Delete template
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                handleSaveTemplate(name?.name, name?.data);
                              }}
                              className="text-success"
                            >
                              Save as new template
                            </div>
                          )}

                          {/* <p className='mb-0 mt-1 max-w'>{name.label} </p> */}
                          {/* <p onClick={() => setAddMessageOnRequest(name.value)} className='mt-1 mb-o text-primary cursor-pointer'>Add a Message</p>
										{(addMessageOnRequest === name.value || name?.message) && <textarea
													className='w-100'
													value={name?.message}
													onChange={(e) => {
														const edited = selectedFormNames?.map(n => {
															if (n.value === name.value) {
																return {
																	value: n.value,
																	label:n.label,
																	message: e.target.value
																}
															}
															return n
														})
														setSelectedFormNames(edited)
													}}
												></textarea>} */}
                        </div>
                      </div>
                      <div className="d-flex gap-4">
                        <img
                          className="cursor-pointer icon"
                          onClick={() => {
                            setFormTitle(name?.name)
                              setCreateFormModalOpen({state:true,editmode:true,data:name?.data})
                          }}
                          src={editIcon}
                          alt="delete"
                        />
                        <img
                          className="cursor-pointer icon"
                          onClick={() => {
                            const deletedDynaimcFormName =
                              selectedDynamicForms?.filter(
                                (formname) => formname !== name
                              );
                            setSelectedDynamicForms(deletedDynaimcFormName);
                          }}
                          src={DeleteIcon2}
                          alt="delete"
                        />
                      </div>
                    </div>
                  );
                })}
                <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
                  <h3 className="text-center text-grey-400 fw-bold">
                    Smart Questionnaire
                  </h3>
                  <p className="text-center w-85 mt-2 text-2 px-5 ">
                    Select from among our pre-built Smart Questionnaires. Your
                    Client’s input are saved as they answer each question,
                    allowing them to continue right from where they last left
                    off.
                  </p>
                </div>
                <div className="row d-flex justify-content-start  card-cont">
                  <Link
                    to="#"
                    // className={`dropdown-item text-light invoiceModal`}
                    className="border-solid d-none flex-column justify-content-center align-items-center gap-2 custom-card rounded"
                    data-bs-toggle="modal"
                    data-bs-target="#createForm"
                  >
                    <img style={{ height: "40px" }} src={PlusSvg} alt="plus" />
                  </Link>
                  <div
                    className="border-solid d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded"
                    onClick={() => {
                      setCustomModalOpen({ state: "formtitle", data: {} });
                    }}
                  >
                    <img style={{ height: "40px" }} src={PlusSvg} alt="plus" />
                    Create New
                  </div>
                  {dynamicForms.map((option) => {
                    return (
                      <div
                        key={option.label}
                        onClick={() => {
                          const isForm = selectedDynamicForms?.find(
                            (name) => name?.name === option?.form_data?.name
                          );
                          if (isForm) {
                            const deletedFormName =
                              selectedDynamicForms?.filter(
                                (name) => name?.name !== option?.form_data?.name
                              );
                            setSelectedDynamicForms(deletedFormName);
                          } else {
                            setSelectedDynamicForms((prev) => [
                              ...prev,
                              {
                                name: option?.form_data?.name,
                                value: option?.form_data?.name,
                                label: option?.form_data?.name,
                                data: option?.form_data?.data,
                                message: "",
                              },
                            ]);
                          }
                          //   setAddMessageOnRequest("");
                        }}
                        className={`dynamic-form-card px-5 text-center border-solid ${
                          selectedDynamicForms?.find(
                            (form) => form?.name === option?.form_data?.name
                          )
                            ? "border-primary"
                            : ""
                        } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                      >
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setCustomModalOpen({
                              state: "delete",
                              data: option?.form_data?.name,
                            });
                            // handleDeleteTemplate(option?.id);
                          }}
                          className="delete"
                          src={DeleteIcon}
                          alt="delete-icon"
                        />
                        <img
                          style={{ height: "40px" }}
                          src={FileBlueSvg}
                          alt="plus"
                        />
                        {option?.form_data?.name}
                        {/* Dynamic */}
                      </div>
                    );
                  })}
                  <div className="w-100 dashed-border my-3"></div>
                  {dynamicFormsCreatedByAdmins?.map((option) => {
                    return (
                      <div
                        key={option.label}
                        onClick={() => {
                          const isForm = selectedDynamicForms?.find(
                            (name) => name?.name === option?.form_data?.name
                          );
                          if (isForm) {
                            const deletedFormName =
                              selectedDynamicForms?.filter(
                                (name) => name?.name !== option?.form_data?.name
                              );
                            setSelectedDynamicForms(deletedFormName);
                          } else {
                            setSelectedDynamicForms((prev) => [
                              ...prev,
                              {
                                name: option?.form_data?.name,
                                value: option?.form_data?.name,
                                label: option?.form_data?.name,
                                data: option?.form_data?.data,
                                message: "",
                              },
                            ]);
                          }
                          //   setAddMessageOnRequest("");
                        }}
                        className={`dynamic-form-card px-5 text-center border-solid ${
                          selectedDynamicForms?.find(
                            (form) => form?.name === option?.form_data?.name
                          )
                            ? "border-primary"
                            : ""
                        } d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
                      >
                        <img
                          style={{ height: "40px" }}
                          src={FileBlueSvg}
                          alt="plus"
                        />
                        {option?.form_data?.name}
                        {/* Dynamic */}
                      </div>
                    );
                  })} 
                </div>
                {message.show ? <Message message={message} /> : <></>}

               <div className='d-flex justify-content-center'>
               <button className='btn btn-primary' onClick={handleSubmit}>Send request</button>
               </div>
              </div>
  )
}

export default RequestJobTaxForm