import { useState } from "react";

const EditSubSectionModal = ({
    taxForm,
    setTaxForm,
    setCustomModalOpen,
    activeSectionId,
    activeSubSectionId,
   }) => {
     const [subsection, setSubSection] = useState(taxForm?.sections
      ?.find((section) => section?.id === activeSectionId)
      ?.subsections?.find(
        (subsection) => subsection?.id === activeSubSectionId
      )?.name);
     const [errorMessage, setErrorMessage] = useState("");
     return (
       <div
         className="custom-modal-overlay"
         onClick={() => setCustomModalOpen(false)}
       >
         <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
           <h2 className="custom-modal-heading">Edit Subsection</h2>
           <p className="custom-modal-description">
           Enter title for new subsection
           </p>
           <div className="custom-modal-input-cont">
             {errorMessage && <div className="text-danger">{errorMessage}</div>}
             <input
               className={errorMessage ? "border-1 border-danger" : ""}
               type="text"
               id="section-title"
               value={subsection}
               onChange={(e) => {
                setSubSection(e.target.value);
                 setErrorMessage("");
               }}
               />
           </div>
           <div className="custom-modal-buttons">
             <button
               className="btn"
               onClick={() => {
                setCustomModalOpen(false);
               }}
             >
               Cancel
             </button>
             <div
               di
               className="btn btn-primary"
               onClick={() => {
                setTaxForm((prevForm) => {
                  return {
                    ...prevForm,
                    sections:taxForm?.sections?.map(section => {
                      return {
                        ...section,
                        subsections: section?.subsections?.map((sub) => {
                          if(sub?.id === activeSubSectionId){
                            return {
                              ...sub,
                              name:subsection
                            }
                          }
                          return sub
                        } )
                      }
                    })
                  }
                })
                setCustomModalOpen(false)
               }}
               disabled={subsection?.length < 3}
             >
               Save
             </div>
           </div>
         </div>
       </div>
     );
   };
  


   export default EditSubSectionModal