import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../../Store/action";
import Message from "../fields/Message";

export default function EditMilestoneModal({ milestone, onSave, onCancel, job_id,taxPro }) {
    const [{ updateMilestone },{LoginData}] = useSelector((state) => [state.milestone,state.user]);
    const [message, setMessage] = useState({ show: false });
    const [name, setName] = useState(milestone?.name || "");
    const [description, setDescription] = useState(milestone?.description || "");
    const [amount, setAmount] = useState(milestone?.invoices?.amount || "");
    const [dueDate, setDueDate] = useState(milestone?.invoices?.due_date || "");
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    const validateFields = () => {
        const newErrors = {};
        if (!name.trim()) {
            newErrors.name = "Name is required.";
        }
        if (!amount || isNaN(amount) || Number(amount) <= 0) {
            newErrors.amount = "Amount must be a positive number.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {
        if (!validateFields()) return;

        const milestoneObj = {
            name: name,
            description:description,
            amount: amount,
            status_id: milestone?.status_id,
            milestone_id: milestone?.id,
            dueDate: dueDate,
            pro_id:LoginData.isAdmin&&taxPro

        };

        dispatch(allActions.global.Loader(true));
        dispatch(allActions.milestone.updateMilestoneReset());
        dispatch(allActions.milestone.updateMilestone(milestoneObj));
    };

    useEffect(() => {
        console.log("@888888", updateMilestone);
        if (updateMilestone?.status === true) {
            onCancel();
            document.querySelector('.reviewMilestoneModalClose').click();
            dispatch(allActions.milestone.getJobMilestone(job_id,taxPro));
            setMessage({ show: false });
            dispatch(allActions.global.Loader(false));
        }
        if (updateMilestone?.status === false) {
            setMessage({
                show: true,
                type: "Error",
                text: updateMilestone.message,
            });
            dispatch(allActions.global.Loader(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMilestone?.status]);

    return (
        <div
            className="modal fade show"
            tabIndex="-1"
            style={{
                display: "block",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1055,
            }}
            role="dialog"
        >
            <div className="modal-dialog modal-lg p-3" role="document">
                <div className="modal-content p-3">
                    {message.show ? <Message message={message} /> : null}
                    <div className="modal-header">

                        <h5 className="modal-title fw-bold text-black">Edit Milestone</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onCancel}
                        ></button>
                    </div>
                    <div className="modal-body p-3">
                        <form className="text-start">
                            <div className="mb-4">
                                <label htmlFor="milestoneName" className="form-label text-black">
                                    Name of milestone
                                </label>
                                <input
                                    type="text"
                                    id="milestoneName"
                                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter milestone name"
                                />
                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                            </div>

                            <div className="mb-4">
                                <label htmlFor="milestoneAmount" className="form-label text-black">
                                    Amount
                                </label>
                                <input
                                    type="number"
                                    id="milestoneAmount"
                                    className={`form-control ${errors.amount ? "is-invalid" : ""}`}
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    placeholder="$0.00"
                                />
                                {errors.amount && <div className="invalid-feedback">{errors.amount}</div>}
                            </div>

                            <div className="mb-4">
                                <label htmlFor="milestoneDueDate" className="form-label text-black ">
                                    Milestone due date (Optional)
                                </label>
                                <input
                                    className="form-control"
                                    type="date"
                                    onChange={(e) => setDueDate(dayjs(e.target.value).format("YYYY-MM-DD"))}
                                    value={dueDate}
                                />
                            </div>
                            <div className="mb-4">
                            <label className="text-start fs-5 text-black" htmlFor="milestoneDescription">
                            Description
                        </label>
                        <textarea
                            placeholder="..."
                            className={`form-control pb-5 ${errors && !description.trim() ? 'is-invalid' : ''}`}
                            id="milestoneDescription"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn text-primary"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
