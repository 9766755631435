import { useState } from "react";

const EditFormTitleModal = ({
    formTitle,
    setFormTitle,
    setEditFormTitleModalOpen
   }) => {
     const [title, setTitle] = useState(formTitle)
   
     const [errorMessage, setErrorMessage] = useState("");
     return (
       <div
         className="custom-modal-overlay"
         onClick={() => setEditFormTitleModalOpen(false)}
       >
         <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
           <h2 className="custom-modal-heading">Edit Form Title</h2>
           <p className="custom-modal-description">
           Enter new title 
           </p>
           <div className="custom-modal-input-cont">
             {errorMessage && <div className="text-danger">{errorMessage}</div>}
             <input
               className={errorMessage ? "border-1 border-danger" : ""}
               type="text"
               id="section-title"
               value={title}
               onChange={(e) => {
                setTitle(e.target.value);
                 setErrorMessage("");
               }}
               />
           </div>
           <div className="custom-modal-buttons">
             <button
               className="btn"
               onClick={() => {
                setEditFormTitleModalOpen(false);
               }}
             >
               Cancel
             </button>
             <div
               di
               className="btn btn-primary"
               onClick={() => {
                setFormTitle(title)
                setEditFormTitleModalOpen(false)
               }}
               disabled={title?.length < 3}
             >
               Save
             </div>
           </div>
         </div>
       </div>
     );
   };



   export default EditFormTitleModal