/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import Input from "../../../fields/Input";
import ItemizedDeductions from "../Fields/ItemizedDeductions";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import ReactSelect from "react-select";
import EditIcon from "../../../../../assets/img/edit.svg";
import DeleteIcon2 from "../../../../../assets/img/delete1.svg";
import PartnersList from "../AdminSideComponents/PartnersList";
import ShareholdersList from "../AdminSideComponents/ShareholdersList";
import OfficersList from "../AdminSideComponents/OfficersList";
import LockerSvg from "../../../../../assets/img/locker.svg";
import {FileUploader} from "react-drag-drop-files";
import {VscEye} from "react-icons/vsc";
import React from "react";

const RenderInputList = ({
                             groupedData,
                             activeSection,
                             activeSubSection,
                             activeFormData,
                             conditionCheck,
                             setActiveQuestionId,
                             handleQuestionChange,
                             validationErrors,
                             setPartnerToEdit,
                             setActiveFormData,
                             setAddPartner,
                             addPartner,
                             partnerToEdit,
                             handleSavePartner,
                             handleSaveEditedPartner,
                             addShareholder,
                             setAddShareholder,
                             shareholderToEdit,
                             setShareholderToEdit,
                             setOfficerToEdit,
                             officerToEdit,
                             addOfficer,
                             setAddOfficer,
                             handleSaveOfficer,
                             handleSaveEditedOfficer,
                             handlePrimaryContactInfoChange,
                             expensesData,
                             dependent,
                             handleDependentChange,
                             dependentFielderrors,
                             setDependent,
                             handleAddDependent,
                             incomeSources,
                             uploading,
                             handleFileUpload,
                             setActiveFileId,
                             activeFileID,
                             uploadProgress,
                             setSelectedDocument,
                             handleDeleteFile,
                             handleCardInputChange,
                             handleGridInputChange,
                             handleListFieldChange,
                             addRow
}
) => {

    
    return (
        <>
            {groupedData?.length === 0 &&
                activeSection &&
                activeSubSection &&
                activeFormData?.data?.sections?.find(
                    (sec) => sec?.id === activeSection
                )?.subsections.length > 0 &&
                activeFormData?.data?.sections
                    ?.find((sec) => sec?.id === activeSection)
                    ?.subsections?.find(
                    (subsection) => subsection?.id === activeSubSection
                )
                    ?.fields?.map((form, index) => {
                    if (
                        form?.type === "text-one-line" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label className="col-sm-6 col-form-label">
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <Input
                                        className={`form-control`}
                                        value={form?.answer}
                                        fn={(e) => {
                                            const inputValue = e;
                                            if (!form?.validation && form?.maxLength) {
                                                if (inputValue.length <= form?.maxLength || inputValue.length < form?.answer?.length) {
                                                    handleQuestionChange(e, form.id);
                                                }
                                            } else {
                                                handleQuestionChange(e, form.id);
                                            }
                                        }
                                        }
                                        type="text"
                                        hasValidationError={validationErrors[index]}
                                        placeholder="Enter your answer here..."
                                        showLabel={false}
                                        mask={form?.validation === 'ssn' ?  "999-99-9999" : form?.validation === 'ein' ?  "99-99999999" : null}
                                        max={10}
                                    />
                                </div>
                            </div>
                        );
                    }
                    if(form?.type === 'horizontal-line'){
                        return <hr  className="horizontal-line"/>
                    }
                    if (
                        form?.type === "text-multi-line" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label className="col-sm-6 col-form-label">
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                            <textarea
                                value={form?.answer}
                                className={`form-control ${
                                    validationErrors[index] ? "is-invalid" : ""
                                }`}
                                onChange={(e) =>
                                    handleQuestionChange(e.target.value, form?.id)
                                }
                                type="text"
                                placeholder="Enter your answer here..."
                            ></textarea>
                                </div>
                            </div>
                        );
                    }
                    if (form?.type === "text-header" &&
                        (!form?.condition || conditionCheck(form))) {
                        return (
                            <div
                                style={{padding:'10px 0px'}}
                                className={` d-flex mt-2  gap-2 `}
                            >
                                <div
                                    className='w-100'
                                    style={{
                                        fontSize:form?.fontSize ? `${form?.fontSize}px` : '20px',
                                        color:form?.color || 'black',
                                        fontStyle:form?.fontStyle?.value || 'normal',
                                        fontWeight:form?.fontWeight?.value || 'normal',
                                        textAlign: form?.textPosition?.value ||  'center',
                                    }}
                                >{form?.question}</div>
                            </div>
                        );
                    }
                    if (
                        form?.type === "text-comment" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3 border-top border-top-1"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <div className="fs-6 fw-bold my-2">Comments</div>
                                <div>
                                    <div className="mt-1">
                              <textarea
                                  value={form?.answer}
                                  className={`form-control w-100 pb-5 ${
                                      validationErrors[index] ? "is-invalid" : ""
                                  }`}
                                  onChange={(e) =>
                                      handleQuestionChange(e.target.value, form?.id)
                                  }
                                  type="text"
                                  placeholder="Enter your answer here..."
                              ></textarea>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    if (
                        form?.type === "text-email" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label className="col-sm-6 col-form-label">
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <input
                                        value={form?.answer}
                                        className={`form-control ${
                                            validationErrors[index] ? "is-invalid" : ""
                                        }`}
                                        onChange={(e) =>
                                            handleQuestionChange(e.target.value, form?.id)
                                        }
                                        type="email"
                                        placeholder="Enter your answer here..."
                                    />
                                </div>
                            </div>
                        );
                    }
                    if (
                        form?.type === "number-any-number" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label className="col-sm-6 col-form-label">
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <Input
                                        className={`form-control`}
                                        value={form?.answer}
                                        fn={(e) => {
                                            const inputValue = e;
                                            if (!form?.validation && form?.maxLength) {
                                                if (inputValue.length <= form?.maxLength || inputValue.length < form?.answer?.length) {
                                                    handleQuestionChange(e, form.id);
                                                }
                                            } else {
                                                handleQuestionChange(e, form.id);
                                            }
                                        }}
                                        type={form?.validation ? "text" : "number"}
                                        hasValidationError={validationErrors[index]}
                                        placeholder="Enter your answer here..."
                                        showLabel={false}
                                        mask={
                                            form?.validation === "ssn"
                                                ? "999-99-9999"
                                                : form?.validation === "ein"
                                                    ? "99-99999999"
                                                    : form?.validation === "phone"
                                                        ? "(999) 999-9999"
                                                        : form?.validation === "zipcode"
                                                            ? "99999999"
                                                            : null
                                        }
                                        formatChars={form?.validation === 'zipcode' ? {
                                            9: "[0-9-]",
                                        } : null}
                                        max={10}
                                    />
                                </div>
                            </div>
                        );
                    }
                    if (
                        form?.type === "number-phone" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label className="col-sm-6 col-form-label">
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <Input
                                        value={form?.answer}
                                        className={`form-control`}
                                        fn={(value) => handleQuestionChange(value, form?.id)}
                                        type="text"
                                        hasValidationError={validationErrors[index]}
                                        placeholder="Enter your answer here..."
                                        mask="(999) 999-9999"
                                        showLabel={false}
                                        max={10}
                                    />
                                </div>
                            </div>
                        );
                    }
                    if (
                        form?.type === "number-zipcode" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label className="col-sm-6 col-form-label">
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <Input
                                        value={form?.answer}
                                        className={`form-control`}
                                        fn={(value) =>
                                            handleQuestionChange(value, form?.id)
                                        }
                                        type="text"
                                        hasValidationError={validationErrors[index]}
                                        placeholder="ie. 93882"
                                        mask="99999999"
                                        maskChar={null}
                                        formatChars={{
                                            9: "[0-9-]",
                                        }}
                                        showLabel={false}
                                        max={10}
                                    />
                                </div>
                            </div>
                        );
                    }

                    if (
                        form?.type === "number-amount" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor={form?.question}
                                >
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <div className="amount-input-cont ">
                                        <div className="amount-icon-cont">$</div>
                                        <input
                                            value={form?.answer}
                                            className={`form-control ${
                                                validationErrors[index] ? "is-invalid" : ""
                                            }`}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!form?.validation && form?.maxLength) {
                                                    if (inputValue.length <= form?.maxLength || inputValue.length < form?.answer?.length) {
                                                        handleQuestionChange(inputValue, form.id);
                                                    }
                                                } else {
                                                    handleQuestionChange(inputValue, form.id);
                                                }
                                            }}
                                            type="number"
                                            id={form?.question}
                                            placeholder="Enter your answer here..."
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    if (
                        form?.type === "number-percentage" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3 question-cont-form"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor={form?.question}
                                >
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <div className="amount-input-cont">
                                        <input
                                            value={form?.answer}
                                            className={`form-control ${
                                                validationErrors[index] ? "is-invalid" : ""
                                            }`}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!form?.validation && form?.maxLength) {
                                                    if (inputValue.length <= form?.maxLength || inputValue.length < form?.answer?.length) {
                                                        handleQuestionChange(inputValue, form.id);
                                                    }
                                                } else {
                                                    handleQuestionChange(inputValue, form.id);
                                                }
                                            }}
                                            type="number"
                                            id={form?.question}
                                            placeholder="Enter your answer here..."
                                        />
                                        <div className="amount-icon-cont">%</div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    if (
                        form?.type === "pre-defined-deductions-list" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <ItemizedDeductions question={form?.question}/>

                            </div>
                        );
                    }
                    if (
                        form?.type === "date" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="row mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label className="col-sm-6 col-form-label">
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <DatePicker
                                        value={
                                            form?.answer
                                                ? typeof form?.answer === "string"
                                                    ? dayjs(form.answer)
                                                    : form?.answer
                                                : null
                                        }
                                        onChange={(date) => {
                                            handleQuestionChange(date, form?.id);
                                        }}
                                        format={
                                            form?.validation === "year"
                                                ? "YYYY"
                                                : "DD/MM/YYYY"
                                        }
                                        placeholder={
                                            form?.validation === "year"
                                                ? "YYYY"
                                                : "DD/MM/YYYY"
                                        }
                                        className={`form-control ${
                                            validationErrors[form?.id] ? "is-invalid" : ""
                                        }`}
                                        disabledDate={
                                            form?.validation === "birthdate"
                                                ? (current) =>
                                                    current && current.isAfter(dayjs())
                                                : undefined
                                        }
                                        picker={
                                            form?.validation === "year" ? "year" : undefined
                                        }
                                    />
                                </div>
                            </div>
                        );
                    }
                    if (
                        form?.type?.includes("dropdown") &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        const options = form?.questionData?.map((state) => ({
                            value: state,
                            label: state,
                        }));
                        return (
                            <div
                                className="row mb-3 question-cont-form"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label className="col-sm-6 col-form-label">
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="col-sm-6">
                                    <ReactSelect
                                        placeholder="Enter your answer here..."
                                        className={`input-cont-form question-input w-100 ${
                                            validationErrors[index]
                                                ? "border border-danger"
                                                : ""
                                        }`}
                                        value={
                                            form?.type === "dropdown-custom-multy-select" &&
                                            Array.isArray(form?.answer) &&
                                            form?.answer?.length > 0
                                                ? form?.answer?.map((answrField) => ({
                                                    label: answrField,
                                                    value: answrField,
                                                }))
                                                : form?.answer
                                                    ? {
                                                        label: form?.answer,
                                                        value: form?.answer,
                                                    }
                                                    : null
                                        }
                                        options={options}
                                        isMulti={
                                            form?.type === "dropdown-custom-multy-select"
                                        }
                                        onChange={(selected) =>
                                            handleQuestionChange(
                                                form?.type === "dropdown-custom-multy-select"
                                                    ? selected.map((option) => option.value)
                                                    : selected?.value,
                                                form?.id
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        );
                    }
                    if (
                        form?.type?.includes("yes-no") &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className="question-cont-form gap-2 mb-3"
                                onClick={() => setActiveQuestionId(form?.id)}
                            >
                                <label>
                                    {form?.question}
                                    {form?.required && (
                                        <span className="text-danger"> *</span>
                                    )}
                                </label>
                                <div className="d-flex align-items-center gap-2">
                                    <div className="d-flex gap-3">
                                        {form?.questionData?.map((answer) => {
                                            return (
                                                <div
                                                    className={`yes-no-cont ${
                                                        form?.answer === answer
                                                            ? "yes-no-cont-active"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        handleQuestionChange(answer, form?.id);
                                                    }}
                                                >
                                                    {answer}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    if(form?.type === 'pre-defined-partners' && (!form?.condition || conditionCheck(form))){
                        return <div className="border border-1 rounded  p-3">
                            <div className="my-3">
                                <table className="table">
                                    <thead className="table-cont">
                                    <tr>
                                        <th scope="col">
                                            Partner name
                                        </th>
                                        <th scope="col">
                                            Partner type
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {form?.answer?.map(
                                        (partner) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    className="dependant-rows"
                                                >
                                                    <td>
                                                        {partner?.type_of_entity === 'Individual' ? partner?.firstname : partner?.entity_name}
                                                    </td>
                                                    <td>
                                                        {
                                                            partner?.type_of_entity
                                                        }
                                                    </td>
                                                    <td className="d-flex align-items-center gap-2">
                                                        <img
                                                            onClick={() => {
                                                                setPartnerToEdit({
                                                                    state: true,
                                                                    data: partner,
                                                                });
                                                            }}
                                                            className="cursor-pointer"
                                                            style={{
                                                                height: "24px",
                                                            }}
                                                            src={EditIcon}
                                                            alt="edit"
                                                        />
                                                        <img
                                                            className="cursor-pointer"
                                                            style={{
                                                                height: "24px",
                                                            }}
                                                            src={DeleteIcon2}
                                                            alt="delete"
                                                            onClick={() => {
                                                                setActiveFormData((prevForm) => {
                                                                    return {
                                                                        ...prevForm,
                                                                        data: {
                                                                            ...prevForm?.data,
                                                                            sections:
                                                                                activeFormData?.data?.sections?.map(
                                                                                    (section) => {
                                                                                        return {
                                                                                            ...section,
                                                                                            subsections:
                                                                                                section?.subsections?.map(
                                                                                                    (sub) => {
                                                                                                        if (
                                                                                                            sub?.id ===
                                                                                                            activeSubSection
                                                                                                        ) {
                                                                                                            return {
                                                                                                                ...sub,
                                                                                                                fields:
                                                                                                                    sub?.fields?.map(
                                                                                                                        (
                                                                                                                            field
                                                                                                                        ) => {
                                                                                                                            if (field?.id === form?.id) {
                                                                                                                                return {
                                                                                                                                    ...field,
                                                                                                                                    answer: field?.answer?.filter((part) => part?.id !== partner?.id ),
                                                                                                                                };
                                                                                                                            }
                                                                                                                            return {
                                                                                                                                ...field,
                                                                                                                            };
                                                                                                                        }
                                                                                                                    ),
                                                                                                            };
                                                                                                        }
                                                                                                        return sub;
                                                                                                    }
                                                                                                ),
                                                                                        };
                                                                                    }
                                                                                ),
                                                                        }

                                                                    };
                                                                });
                                                                setAddPartner(false)
                                                                setPartnerToEdit({state:false,data:null})
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                    </tbody>
                                </table>
                                {addPartner === false && (
                                    <div
                                        className="text-primary fw-bold text-center cursor-pointer"
                                        onClick={() => {
                                            setAddPartner(true);
                                        }}
                                    >
                                        + Add partner
                                    </div>
                                )}
                            </div>
                            <div className="">
                                {(addPartner ||
                                    partnerToEdit?.state) && (
                                    <PartnersList
                                        setAddPartner={setAddPartner}
                                        taxForm={activeFormData?.data}
                                        question={form}
                                        activeSection={activeSection}
                                        activeSubSection={
                                            activeSubSection
                                        }
                                        partnerToEdit={partnerToEdit}
                                        setPartnerToEdit={
                                            setPartnerToEdit
                                        }
                                        handleSavePartner={handleSavePartner}
                                        handleSaveEditedPartner={handleSaveEditedPartner}
                                    />
                                )}
                            </div>
                        </div>
                    }
                    if (
                        form?.type === "pre-defined-shareholders" && (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div className="border border-1 rounded p-3 mt-3">
                                <div className="my-3">
                                    <table className="table">
                                        <thead className="table-cont">
                                        <tr>
                                            <th scope="col">
                                                Shareholder name
                                            </th>
                                            <th scope="col">
                                                Taxpayer identification number
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {form?.answer?.map(
                                            (partner) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="dependant-rows"
                                                    >
                                                        <td>
                                                            {partner?.type_of_entity === 'Individual' ? partner?.firstname : partner?.entity_name}
                                                        </td>
                                                        <td>
                                                            {partner?.type_of_entity === 'Individual' ? partner?.ssn : partner?.ein}
                                                        </td>
                                                        <td className="d-flex align-items-center gap-2">
                                                            <img
                                                                onClick={() => {
                                                                    setShareholderToEdit({
                                                                        state: true,
                                                                        data: partner,
                                                                    });
                                                                }}
                                                                className="cursor-pointer"
                                                                style={{
                                                                    height: "24px",
                                                                }}
                                                                src={EditIcon}
                                                                alt="edit"
                                                            />
                                                            <img
                                                                className="cursor-pointer"
                                                                style={{
                                                                    height: "24px",
                                                                }}
                                                                src={DeleteIcon2}
                                                                alt="delete"
                                                                onClick={() => {
                                                                    setActiveFormData((prevForm) => {
                                                                        return {
                                                                            ...prevForm,
                                                                            data: {
                                                                                ...prevForm?.data,
                                                                                sections:
                                                                                    activeFormData?.data?.sections?.map(
                                                                                        (section) => {
                                                                                            return {
                                                                                                ...section,
                                                                                                subsections:
                                                                                                    section?.subsections?.map(
                                                                                                        (sub) => {
                                                                                                            if (
                                                                                                                sub?.id ===
                                                                                                                activeSubSection
                                                                                                            ) {
                                                                                                                return {
                                                                                                                    ...sub,
                                                                                                                    fields:
                                                                                                                        sub?.fields?.map(
                                                                                                                            (
                                                                                                                                field
                                                                                                                            ) => {
                                                                                                                                if (field?.id === form?.id) {
                                                                                                                                    return {
                                                                                                                                        ...field,
                                                                                                                                        answer: field?.answer?.filter((part) => part?.id !== partner?.id ),
                                                                                                                                    };
                                                                                                                                }
                                                                                                                                return {
                                                                                                                                    ...field,
                                                                                                                                };
                                                                                                                            }
                                                                                                                        ),
                                                                                                                };
                                                                                                            }
                                                                                                            return sub;
                                                                                                        }
                                                                                                    ),
                                                                                            };
                                                                                        }
                                                                                    ),
                                                                            }

                                                                        };
                                                                    });
                                                                    setAddPartner(false)
                                                                    setPartnerToEdit({state:false,data:null})
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                        </tbody>
                                    </table>
                                    {addPartner === false && (
                                        <div
                                            className="text-primary fw-bold text-center cursor-pointer"
                                            onClick={() => {
                                                setAddShareholder(true);
                                            }}
                                        >
                                            + Add shareholder
                                        </div>
                                    )}
                                </div>
                                {(addShareholder ||
                                    shareholderToEdit?.state) && (
                                    <ShareholdersList
                                        corporation={form?.corporation || ''}
                                        setAddShareholder={setAddShareholder}
                                        taxForm={activeFormData?.data}
                                        question={form}
                                        activeSection={activeSection}
                                        handleSaveShareholder={handleSavePartner}
                                        handleSaveEditedShareholder={handleSaveEditedPartner}
                                        activeSubSection={
                                            activeSubSection
                                        }
                                        shareholderToEdit={shareholderToEdit}
                                        setShareholderToEdit={
                                            setShareholderToEdit
                                        }
                                    />
                                )}
                            </div>
                        );
                    }

                    if (
                        form?.type === "pre-defined-officers" && (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div className="border border-1 rounded p-3 mt-3">
                                <div
                                    className='d-flex justify-content-between align-items-center gap-2 deductions-cont'>
                                </div>
                                <div className="my-3">
                                    <table className="table">
                                        <thead className="table-cont">
                                        <tr>
                                            <th scope="col">
                                                Officer name
                                            </th>
                                            <th scope="col">
                                                Title
                                            </th>
                                            <th scope="col">
                                                Compensation
                                            </th>
                                            <th scope="col">
                                                Shareholder
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {form?.answer?.map(
                                            (officer) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="dependant-rows"
                                                    >
                                                        <td>
                                                            {officer?.firstname}
                                                        </td>
                                                        <td>
                                                            {
                                                                officer?.title
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                officer?.compensation
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                officer?.shareholder ? 'Yes' : "No"
                                                            }
                                                        </td>
                                                        <td className="d-flex align-items-center gap-2">
                                                            <img
                                                                onClick={() => {
                                                                    setOfficerToEdit({
                                                                        state: true,
                                                                        data: officer,
                                                                    });
                                                                }}
                                                                className="cursor-pointer"
                                                                style={{
                                                                    height: "24px",
                                                                }}
                                                                src={EditIcon}
                                                                alt="edit"
                                                            />
                                                            <img
                                                                className="cursor-pointer"
                                                                style={{
                                                                    height: "24px",
                                                                }}
                                                                src={DeleteIcon2}
                                                                alt="delete"
                                                                onClick={() => {
                                                                    setActiveFormData((prevForm) => {
                                                                        return {
                                                                            ...prevForm,
                                                                            data: {
                                                                                ...prevForm?.data,
                                                                                sections:
                                                                                    activeFormData?.data?.sections?.map(
                                                                                        (section) => {
                                                                                            return {
                                                                                                ...section,
                                                                                                subsections:
                                                                                                    section?.subsections?.map(
                                                                                                        (sub) => {
                                                                                                            if (
                                                                                                                sub?.id ===
                                                                                                                activeSubSection
                                                                                                            ) {
                                                                                                                return {
                                                                                                                    ...sub,
                                                                                                                    fields:
                                                                                                                        sub?.fields?.map(
                                                                                                                            (
                                                                                                                                field
                                                                                                                            ) => {
                                                                                                                                if (field?.id === form?.id) {
                                                                                                                                    return {
                                                                                                                                        ...field,
                                                                                                                                        answer: field?.answer?.filter((part) => part?.id !== officer?.id ),
                                                                                                                                    };
                                                                                                                                }
                                                                                                                                return {
                                                                                                                                    ...field,
                                                                                                                                };
                                                                                                                            }
                                                                                                                        ),
                                                                                                                };
                                                                                                            }
                                                                                                            return sub;
                                                                                                        }
                                                                                                    ),
                                                                                            };
                                                                                        }
                                                                                    ),
                                                                            }

                                                                        };
                                                                    });
                                                                    setAddPartner(false)
                                                                    setPartnerToEdit({state:false,data:null})
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                        </tbody>
                                    </table>
                                    {addOfficer === false && (
                                        <div
                                            className="text-primary fw-bold text-center cursor-pointer"
                                            onClick={() => {
                                                setAddOfficer(true);
                                            }}
                                        >
                                            + Add officer
                                        </div>
                                    )}
                                </div>
                                {(addOfficer ||
                                    officerToEdit?.state) && (
                                    <OfficersList
                                        corporation={form?.corporation || ''}
                                        setAddOfficer={setAddOfficer}
                                        taxForm={activeFormData?.data}
                                        setTaxForm={setActiveFormData}
                                        question={form}
                                        activeSection={activeSection}
                                        activeSubSection={
                                            activeSubSection
                                        }
                                        officerToEdit={officerToEdit}
                                        setOfficerToEdit={
                                            setOfficerToEdit
                                        }
                                        handleSaveOfficer={handleSaveOfficer}
                                        handleSaveEditedOfficer={handleSaveEditedOfficer}
                                    />
                                )}
                                {form?.answer?.find(off => off?.will_sign_tax_return) ? <>
                                    <hr className={'mt-5'}/>
                                    <div className="fw-bold">The following officer has been selected to sign
                                        the
                                        return
                                    </div>
                                    <div>{form?.answer?.find(off => off?.will_sign_tax_return)?.firstname}</div>
                                    <div
                                        className="fw-bold d-flex justify-content-between align-items-center gap-2">
                                        <div>Is this officer the primary contact person for the corporation?
                                        </div>
                                        <div className="d-flex gap-3">
                                            {['yes', 'no']?.map((answer) => {
                                                return (
                                                    <div
                                                        className={`yes-no-cont ${
                                                            form?.isPrimaryContact === answer
                                                                ? "yes-no-cont-active"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            setActiveFormData((prevForm) => {
                                                                return {
                                                                    ...prevForm,
                                                                    data: {
                                                                        ...prevForm?.data,
                                                                        sections:
                                                                            activeFormData?.data?.sections?.map(
                                                                                (section) => {
                                                                                    return {
                                                                                        ...section,
                                                                                        subsections:
                                                                                            section?.subsections?.map(
                                                                                                (sub) => {
                                                                                                    if (
                                                                                                        sub?.id ===
                                                                                                        activeSubSection
                                                                                                    ) {
                                                                                                        return {
                                                                                                            ...sub,
                                                                                                            fields:
                                                                                                                sub?.fields?.map(
                                                                                                                    (
                                                                                                                        field
                                                                                                                    ) => {
                                                                                                                        if (field?.id === form?.id) {
                                                                                                                            return {
                                                                                                                                ...field,
                                                                                                                                isPrimaryContact:answer
                                                                                                                            };
                                                                                                                        }
                                                                                                                        return {
                                                                                                                            ...field,
                                                                                                                        };
                                                                                                                    }
                                                                                                                ),
                                                                                                        };
                                                                                                    }
                                                                                                    return sub;
                                                                                                }
                                                                                            ),
                                                                                    };
                                                                                }
                                                                            ),
                                                                    }

                                                                };
                                                            });

                                                        }}
                                                    >
                                                        {answer}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {form?.isPrimaryContact === 'no' && <>
                                        <div className={'mt-4'}>Primary contact</div>
                                        <hr className={'mt-1'}/>
                                        <div className="fw-bold mt-3">First name</div>
                                        <div className="w-50 mt-2">
                                            <input
                                                type="text"
                                                className="w-full form-control"
                                                onChange={(e) => handlePrimaryContactInfoChange("firstname",e.target.value,form)}
                                                value={form?.primaryContactInfo?.firstname || ''}
                                            />
                                        </div>
                                        <div className="fw-bold mt-3">Middle initial</div>
                                        <div className="w-50 mt-2">
                                            <input
                                                type="text"
                                                className="w-full form-control"
                                                onChange={(e) => handlePrimaryContactInfoChange("middleInitial",e.target.value,form)}
                                                value={form?.primaryContactInfo?.middleInitial || ""}
                                            />
                                        </div>
                                        <div className="fw-bold mt-3">Last name</div>
                                        <div className="w-50 mt-2">
                                            <input
                                                type="text"
                                                className="w-full form-control"
                                                onChange={(e) => handlePrimaryContactInfoChange("lastname",e.target.value,form)}
                                                value={form?.primaryContactInfo?.lastname || ""}
                                            />
                                        </div>
                                        <div className="fw-bold mt-3">Title</div>
                                        <div className="w-50 mt-2">
                                            <input
                                                type="text"
                                                className="w-full form-control"
                                                onChange={(e) => handlePrimaryContactInfoChange("title",e.target.value,form)}
                                                value={form?.primaryContactInfo?.title || ""}
                                            />
                                        </div>
                                        <div className="fw-bold mt-3">SSN</div>
                                        <div className="w-50 mt-2">
                                            <Input
                                                className={`form-control`}
                                                value={form?.primaryContactInfo?.ssn || ""}
                                                fn={(e) => {
                                                    handlePrimaryContactInfoChange("ssn",e,form)
                                                }}
                                                placeholder=""
                                                showLabel={false}
                                                mask={"999-99-9999"}
                                                max={10}
                                            />
                                        </div>
                                        <div className="fw-bold mt-3">Email</div>
                                        <div className="w-50 mt-2">
                                            <input
                                                type="text"
                                                className="w-full form-control"
                                                onChange={(e) => handlePrimaryContactInfoChange("email",e.target.value,form)}
                                                value={form?.primaryContactInfo?.email || ""}
                                            />
                                        </div>
                                        <div className="fw-bold mt-3">Phone number</div>
                                        <div className="w-50 mt-2">
                                            <Input
                                                className={`form-control`}
                                                value={form?.primaryContactInfo?.phone_number || ""}
                                                fn={(e) => {
                                                    handlePrimaryContactInfoChange("phone_number",e,form)

                                                }}
                                                placeholder=""
                                                showLabel={false}
                                                mask={"(999) 999-9999"}
                                                max={10}
                                            />
                                        </div>
                                    </>}
                                </> : form?.answer?.length > 0 ?
                                    <div>
                                        <div className={'fw-bold'}>No signing officer selected</div>
                                        <hr/>
                                        <div className={'mt-3'}>Select the officer who will sign the tax return. Create or open
                                            an entry for the signing officer above and check the "Officer will sign the tax
                                            return" box.
                                        </div>
                                    </div>
                                    : null}
                            </div>

                        );
                    }
                    if (
                        form?.type === "expenses-list" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div className="income-sources-cont mt-3">
                                <div className="d-flex flex-column gap-3">
                                    {expensesData?.map((expense) => {
                                        return (
                                            <div className="d-flex align-items-center gap-3">
                                                <img
                                                    className="expenses-list-source-icon"
                                                    src={LockerSvg}
                                                    alt={expense}
                                                />
                                                <div className="fw-bold expenses-list-title">
                                                    {expense}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    }

                    if (
                        form?.type === "expenses-select" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className={`income-sources-cont rounded mt-3 ${
                                    validationErrors[index]
                                        ? "border border-danger"
                                        : ""
                                }`}
                            >
                                <div className="fs-5 mt-2">{form?.question}</div>
                                <div className="income-sources-cards-cont">
                                    {expensesData?.map((expense) => {
                                        return (
                                            <div
                                                className={`col-md-4 income-sources-card ${
                                                    form?.answer?.find(
                                                        (answer) => answer === expense
                                                    )
                                                        ? "income-sources-card-active"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    const isExpenseAdded = form?.answer?.find(
                                                        (answer) => answer === expense
                                                    );

                                                    if (!isExpenseAdded) {
                                                        setActiveFormData((prevForm) => {
                                                            return {
                                                                ...prevForm,
                                                                data: {
                                                                    ...prevForm?.data,
                                                                    sections:
                                                                        activeFormData.data?.sections?.map(
                                                                            (sec) => {
                                                                                if (
                                                                                    sec?.id === activeSection
                                                                                ) {
                                                                                    return {
                                                                                        ...sec,
                                                                                        subsections:
                                                                                            sec?.subsections?.map(
                                                                                                (sub) => {
                                                                                                    if (
                                                                                                        sub?.id ===
                                                                                                        activeSubSection
                                                                                                    ) {
                                                                                                        return {
                                                                                                            ...sub,
                                                                                                            fields:
                                                                                                                sub?.fields?.map(
                                                                                                                    (field) => {
                                                                                                                        if (
                                                                                                                            field?.id ===
                                                                                                                            form?.id
                                                                                                                        ) {
                                                                                                                            return {
                                                                                                                                ...field,
                                                                                                                                answer:
                                                                                                                                    [
                                                                                                                                        ...field?.answer,
                                                                                                                                        expense,
                                                                                                                                    ],
                                                                                                                            };
                                                                                                                        }
                                                                                                                        return field;
                                                                                                                    }
                                                                                                                ),
                                                                                                        };
                                                                                                    }
                                                                                                    return sub;
                                                                                                }
                                                                                            ),
                                                                                    };
                                                                                }
                                                                                return sec;
                                                                            }
                                                                        ),
                                                                },
                                                            };
                                                        });
                                                    } else {
                                                        setActiveFormData((prevForm) => {
                                                            return {
                                                                ...prevForm,
                                                                data: {
                                                                    ...prevForm?.data,
                                                                    sections:
                                                                        activeFormData.data?.sections?.map(
                                                                            (sec) => {
                                                                                if (
                                                                                    sec?.id === activeSection
                                                                                ) {
                                                                                    return {
                                                                                        ...sec,
                                                                                        subsections:
                                                                                            sec?.subsections?.map(
                                                                                                (sub) => {
                                                                                                    if (
                                                                                                        sub?.id ===
                                                                                                        activeSubSection
                                                                                                    ) {
                                                                                                        return {
                                                                                                            ...sub,
                                                                                                            fields:
                                                                                                                sub?.fields?.map(
                                                                                                                    (field) => {
                                                                                                                        if (
                                                                                                                            field?.id ===
                                                                                                                            form?.id
                                                                                                                        ) {
                                                                                                                            return {
                                                                                                                                ...field,
                                                                                                                                answer:
                                                                                                                                    field?.answer?.filter(
                                                                                                                                        (
                                                                                                                                            answer
                                                                                                                                        ) =>
                                                                                                                                            answer !==
                                                                                                                                            expense
                                                                                                                                    ),
                                                                                                                            };
                                                                                                                        }
                                                                                                                        return field;
                                                                                                                    }
                                                                                                                ),
                                                                                                        };
                                                                                                    }
                                                                                                    return sub;
                                                                                                }
                                                                                            ),
                                                                                    };
                                                                                }
                                                                                return sec;
                                                                            }
                                                                        ),
                                                                },
                                                            };
                                                        });
                                                    }
                                                }}
                                            >
                                                <img
                                                    className="expenses-select-icon"
                                                    src={LockerSvg}
                                                    alt={expense}
                                                />
                                                <div className="expenses-select-title">
                                                    {expense}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    }

                    if (
                        form?.type === "pre-defined-dependents" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                          <div
                            onClick={() => setActiveQuestionId(form?.id)}
                            className={`mt-3 ${
                              validationErrors[index]
                                ? "border border-danger"
                                : ""
                            }`}
                          >
                            <div style={{ overflowX: "auto" }}>
                              <table
                                className="table"
                                style={{ minWidth: "1500px" }}
                              >
                                <thead className="table-cont">
                                  <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Middle Initial</th>
                                    <th scope="col">Last Name</th>
                                    <th scope="col">Prefix</th>
                                    <th scope="col">SSN/ITIN</th>
                                    <th scope="col">Date of Birth</th>
                                    <th scope="col">Relationship</th>
                                    <th scope="col">Amount paid</th>
                                    <th scope="col">Years lived</th>
                                    <th scope="col">
                                      All that apply to this persone
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {form?.answer?.map((dependent, index) => (
                                    <tr key={index} className="dependant-rows">
                                      <td>{dependent.firstName}</td>
                                      <td>{dependent.middleInitial}</td>
                                      <td>{dependent.lastName}</td>
                                      <td>{dependent.prefix}</td>
                                      <td>{dependent.ssnOrItin}</td>
                                      <td>
                                        {typeof dependent?.dateOfBirth ===
                                        "object"
                                          ? dependent.dateOfBirth.format(
                                              "DD/MM/YYYY"
                                            )
                                          : dayjs(
                                              dependent?.dateOfBirth
                                            ).format("DD/MM/YYYY")}
                                      </td>
                                      <td>{dependent.relationship}</td>
                                      <td>
                                        {dependent.amountPaidToCareProvider}
                                      </td>
                                      <td>
                                        {dependent.yearsLivedWithTaxpayer}
                                      </td>
                                      <td>
                                        {dependent.selectAllthatApplayToThisPerson?.map(
                                          (a) => {
                                            return (
                                              <div className="fs-6 mx-2">
                                                {a}
                                              </div>
                                            );
                                          }
                                        )}
                                      </td>
                                      <td>
                                        <img
                                          src={DeleteIcon2}
                                          style={{ height: "25px" }}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setActiveFormData((prevForm) => {
                                              return {
                                                ...prevForm,
                                                data: {
                                                  ...prevForm?.data,
                                                  sections:
                                                    activeFormData.data?.sections?.map(
                                                      (sec) => {
                                                        if (
                                                          sec?.id ===
                                                          activeSection
                                                        ) {
                                                          return {
                                                            ...sec,
                                                            subsections:
                                                              sec?.subsections?.map(
                                                                (sub) => {
                                                                  if (
                                                                    sub?.id ===
                                                                    activeSubSection
                                                                  ) {
                                                                    return {
                                                                      ...sub,
                                                                      fields:
                                                                        sub?.fields?.map(
                                                                          (
                                                                            field
                                                                          ) => {
                                                                            if (
                                                                              field?.id ===
                                                                              form?.id
                                                                            ) {
                                                                              return {
                                                                                ...field,
                                                                                answer:
                                                                                  field?.answer?.filter(
                                                                                    (
                                                                                      dep
                                                                                    ) =>
                                                                                      dep?.firstName !==
                                                                                      dependent?.firstName
                                                                                  ),
                                                                              };
                                                                            }
                                                                            return field;
                                                                          }
                                                                        ),
                                                                    };
                                                                  }
                                                                  return sub;
                                                                }
                                                              ),
                                                          };
                                                        }
                                                        return sec;
                                                      }
                                                    ),
                                                },
                                              };
                                            });
                                          }}
                                          alt="delete"
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <form className="dependents-form">
                              {/* Input fields for each property */}
                              <div className="dependent-header">Dependent</div>
                              <div className="d-flex flex-column gap-3 mt-3">
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="firstname"
                                  >
                                    First name
                                  </label>
                                  <input
                                    name="firstName"
                                    value={dependent.firstName}
                                    onChange={handleDependentChange}
                                    placeholder="Enter your answer here...."
                                    id="firstName"
                                    className={`question-input input-cont-form form-control ${
                                      dependentFielderrors.firstName
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="middleInitial"
                                  >
                                    Middle Initial
                                  </label>
                                  <input
                                    className={`question-input input-cont-form form-control ${
                                      dependentFielderrors.middleInitial
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                    name="middleInitial"
                                    value={dependent.middleInitial}
                                    onChange={handleDependentChange}
                                    placeholder="Enter your answer here...."
                                    id="middleInitial"
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="lastName"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    name="lastName"
                                    value={dependent.lastName}
                                    onChange={handleDependentChange}
                                    placeholder="Enter your answer here...."
                                    id="lastName"
                                    className={`question-input input-cont-form form-control ${
                                      dependentFielderrors.lastName
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="lastName"
                                  >
                                    Select Prefix
                                  </label>
                                  <ReactSelect
                                  value={{label:dependent?.prefix,value:dependent?.prefix}}
                                    placeholder="Enter your answer here...."
                                    className={`input-cont-form question-input  ${
                                      dependentFielderrors.prefix
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                    options={[
                                      { label: "Mr.", value: "Mr." },
                                      { label: "Miss.", value: "Miss." },
                                    ]}
                                    onChange={(e) =>
                                      setDependent({
                                        ...dependent,
                                        prefix: e.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="dependant-form-divider">
                                  TaxPayer Other Information
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="SSN/ITIN"
                                  >
                                    SSN/ITIN
                                  </label>
                                  <input
                                    name="ssnOrItin"
                                    value={dependent.ssnOrItin}
                                    onChange={handleDependentChange}
                                    placeholder="Enter your answer here...."
                                    className={`question-input input-cont-form form-control ${
                                      dependentFielderrors.ssnOrItin
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                    id="SSN/ITIN"
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="dateOfBirth"
                                  >
                                    Date Of Birth
                                  </label>
                                  <DatePicker
                                    value={
                                      dependent.dateOfBirth
                                        ? typeof dependent.dateOfBirth ===
                                          "string"
                                          ? dayjs(dependent.dateOfBirth)
                                          : dependent.dateOfBirth
                                        : null
                                    }
                                    onChange={(date) => {
                                      setDependent((prev) => {
                                        return {
                                          ...prev,
                                          dateOfBirth: date,
                                        };
                                      });
                                    }}
                                    format="DD/MM/YYYY"
                                    placeholder="DD/MM/YYYY"
                                    className={`question-input input-cont-form form-control ${
                                      dependentFielderrors.dateOfBirth
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="Relationship"
                                  >
                                    Relationship
                                  </label>
                                  <ReactSelect
                                    name="relationship"
                                    value={{label:dependent?.relationship,value:dependent?.relationship}}
                                    placeholder="Enter your answer here...."
                                    onChange={(e) =>
                                      setDependent({
                                        ...dependent,
                                        relationship: e.value,
                                      })
                                    }
                                    options={[
                                      {
                                        label: "Relative",
                                        value: "Relative",
                                      },
                                      { label: "Others", value: "Others" },
                                    ]}
                                    id="Relationship"
                                    className={`input-cont-form question-input ${
                                      dependentFielderrors.relationship
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="amountPaidToCareProvider"
                                  >
                                    Amount paid to care provider
                                  </label>

                                  <div
                                    className={`amount-input-cont dependant-amount-input  ${
                                      dependentFielderrors.amountPaidToCareProvider
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                  >
                                    <div className="amount-icon-cont col-sm-6">
                                      $
                                    </div>
                                    <input
                                      type="number"
                                      name="amountPaidToCareProvider"
                                      id={dependent?.amountPaidToCareProvider}
                                      placeholder="Enter your answer here..."
                                      value={dependent.amountPaidToCareProvider}
                                      onChange={handleDependentChange}
                                      className={`question-input form-control `}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="yearsLivedWithTaxpayer"
                                  >
                                    Years lived with taxpayer
                                  </label>
                                  <ReactSelect
                                  value={{label:dependent?.yearsLivedWithTaxpayer,value:dependent?.yearsLivedWithTaxpayer}}
                                    name="yearsLivedWithTaxpayer"
                                    placeholder="Enter your answer here...."
                                    onChange={(e) =>
                                      setDependent({
                                        ...dependent,
                                        yearsLivedWithTaxpayer: e.value,
                                      })
                                    }
                                    options={[
                                      { label: "1-5", value: "1-5" },
                                      { label: "5-10", value: "5-10" },
                                    ]}
                                    id="yearsLivedWithTaxpayer"
                                    className={`input-cont-form question-input ${
                                      dependentFielderrors.yearsLivedWithTaxpayer
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                  />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <label
                                    className="col-sm-6 col-form-label"
                                    htmlFor="yearsLivedWithTaxpayer"
                                  >
                                    Select all that apply to this person{" "}
                                  </label>
                                  <ReactSelect
                                    name="yearsLivedWithTaxpayer"
                                    placeholder="Enter your answer here...."
                                    onChange={(selectedOptions) =>
                                      setDependent({
                                        ...dependent,
                                        selectAllthatApplayToThisPerson:
                                          selectedOptions.map(
                                            (option) => option.value
                                          ),
                                      })
                                    }
                                    options={[
                                      {
                                        label: "Married as of 12/31/2023",
                                        value: "Married as of 12/31/2023",
                                      },
                                      {
                                        label: "Full-time student during 2023",
                                        value: "Full-time student during 2023",
                                      },
                                      {
                                        label:
                                          "Totally and permanently disabled at any time during 2023",
                                        value:
                                          "Totally and permanently disabled at any time during 2023",
                                      },
                                      {
                                        label: "Had income during 2023",
                                        value: "Had income during 2023",
                                      },
                                      {
                                        label:
                                          "Filed or will file their own 2023 income tax return",
                                        value:
                                          "Filed or will file their own 2023 income tax return",
                                      },
                                      {
                                        label: "None of the above",
                                        value: "None of the above",
                                      },
                                    ]}
                                    id="yearsLivedWithTaxpayer"
                                    className={`input-cont-form  question-input ${
                                      dependentFielderrors.yearsLivedWithTaxpayer
                                        ? "border-1 border-danger"
                                        : ""
                                    }`}
                                    isMulti
                                    value={dependent?.selectAllthatApplayToThisPerson?.map(
                                      (value) => ({
                                        label: value,
                                        value: value,
                                      })
                                    )}
                                  />
                                </div>
                              </div>
                              {/* Add other checkboxes similarly */}
                              <div className="add-question-btn-cont">
                                <div
                                  onClick={handleAddDependent}
                                  className="add-dependent-btn"
                                >
                                  Add dependent
                                </div>
                              </div>
                            </form>
                          </div>
                        );
                    }
                    if (
                        form?.type === "pre-defined-income-sources" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className={`income-sources-cont ${
                                    validationErrors[index]
                                        ? "border border-danger rounded"
                                        : ""
                                }`}
                            >
                                <div className="income-sources-cont-title">
                                    {form?.question}
                                </div>
                                <div className="income-sources-cont-subtitle">
                                    Select all that apply, even If you didn't receive a
                                    form
                                </div>
                                <div className="income-sources-cards-cont">
                                    {incomeSources?.map((source) => {
                                        return (
                                            <div
                                                className={`col-md-4 income-sources-card ${
                                                    form?.answer?.find(
                                                        (answer) => answer === source?.name
                                                    )
                                                        ? "income-sources-card-active"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    const isSourceAdded = form?.answer?.find(
                                                        (answer) => answer === source?.name
                                                    );
                                                    if (!isSourceAdded) {
                                                        setActiveFormData((prevForm) => {
                                                            return {
                                                                ...prevForm,
                                                                data: {
                                                                    ...prevForm?.data,
                                                                    sections:
                                                                        activeFormData.data?.sections?.map(
                                                                            (sec) => {
                                                                                if (
                                                                                    sec?.id === activeSection
                                                                                ) {
                                                                                    return {
                                                                                        ...sec,
                                                                                        subsections:
                                                                                            sec?.subsections?.map(
                                                                                                (sub) => {
                                                                                                    if (
                                                                                                        sub?.id ===
                                                                                                        activeSubSection
                                                                                                    ) {
                                                                                                        return {
                                                                                                            ...sub,
                                                                                                            fields:
                                                                                                                sub?.fields?.map(
                                                                                                                    (field) => {
                                                                                                                        if (
                                                                                                                            field?.id ===
                                                                                                                            form?.id
                                                                                                                        ) {
                                                                                                                            return {
                                                                                                                                ...field,
                                                                                                                                answer:
                                                                                                                                    [
                                                                                                                                        ...field?.answer,
                                                                                                                                        source?.name,
                                                                                                                                    ],
                                                                                                                            };
                                                                                                                        }
                                                                                                                        return field;
                                                                                                                    }
                                                                                                                ),
                                                                                                        };
                                                                                                    }
                                                                                                    return sub;
                                                                                                }
                                                                                            ),
                                                                                    };
                                                                                }
                                                                                return sec;
                                                                            }
                                                                        ),
                                                                },
                                                            };
                                                        });
                                                    } else {
                                                        setActiveFormData((prevForm) => {
                                                            return {
                                                                ...prevForm,
                                                                data: {
                                                                    ...prevForm?.data,
                                                                    sections:
                                                                        activeFormData.data?.sections?.map(
                                                                            (sec) => {
                                                                                if (
                                                                                    sec?.id === activeSection
                                                                                ) {
                                                                                    return {
                                                                                        ...sec,
                                                                                        subsections:
                                                                                            sec?.subsections?.map(
                                                                                                (sub) => {
                                                                                                    if (
                                                                                                        sub?.id ===
                                                                                                        activeSubSection
                                                                                                    ) {
                                                                                                        return {
                                                                                                            ...sub,
                                                                                                            fields:
                                                                                                                sub?.fields?.map(
                                                                                                                    (field) => {
                                                                                                                        if (
                                                                                                                            field?.id ===
                                                                                                                            form?.id
                                                                                                                        ) {
                                                                                                                            return {
                                                                                                                                ...field,
                                                                                                                                answer:
                                                                                                                                    field?.answer?.filter(
                                                                                                                                        (
                                                                                                                                            answer
                                                                                                                                        ) =>
                                                                                                                                            answer !==
                                                                                                                                            source?.name
                                                                                                                                    ),
                                                                                                                            };
                                                                                                                        }
                                                                                                                        return field;
                                                                                                                    }
                                                                                                                ),
                                                                                                        };
                                                                                                    }
                                                                                                    return sub;
                                                                                                }
                                                                                            ),
                                                                                    };
                                                                                }
                                                                                return sec;
                                                                            }
                                                                        ),
                                                                },
                                                            };
                                                        });
                                                    }
                                                }}
                                            >
                                                <img
                                                    className="income-source-icon"
                                                    src={source?.icon}
                                                    alt={source?.name}
                                                />
                                                <div className="">{source?.name}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    }
                    if (form?.type === "file-upload" && (!form?.condition || conditionCheck(form))) {
                        return (
                            <div className="row mb-3 question-cont-form">
                                <div className="col-sm-6 col-form-label">{form?.question}</div>

                                {/* File Uploader Section */}
                                {!uploading && (
                                    <div className="col-sm-6 d-flex gap-3 justify-content-end">
                                        <FileUploader
                                            classes="form-control file-upload file-upload-custom-styles"
                                            multiple={false}
                                            values={[]}
                                            handleChange={(files) => {
                                                handleFileUpload(files, form?.id, setActiveFileId);
                                            }}
                                            name="documents"
                                            types={["jpg", "png", "jpeg", "pdf"]}
                                            maxSize={5} // Size in MB
                                            onSizeError={() => {
                                                console.log("File size exceeds the limit");
                                            }}
                                            onTypeError={() => {
                                                console.log("Incorrect file type");
                                            }}
                                            label="Click here or Drag & Drop files to upload documents"
                                        />
                                    </div>
                                )}

                                {/* Upload Progress Section */}
                                {uploading && form?.id === activeFileID && (
                                    <div className="col-sm-6 d-flex gap-3 justify-content-end">
                                        <div className="progress w-100 custom-progress-styles">
                                            <div
                                                className="progress-bar progress-bar-striped progress-bar-animated"
                                                role="progressbar"
                                                style={{
                                                    width: `${uploadProgress}%`,
                                                }}
                                                aria-valuenow={uploadProgress}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            >
                                                {uploadProgress}%
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* File List Section */}
                                {form?.file?.length > 0 ? (
                                    <div className="d-flex flex-column gap-2 align-items-end w-100">
                                        {form?.file?.map((file, index) => {
                                            return (
                                                <div
                                                    key={file?.uuid || index} // Ensure unique keys
                                                    className="d-flex justify-content-end align-items-center w-100 mt-3 gap-3"
                                                >
                                                    <div className="text-primary">{`taxDoc${file?.extension}`}</div>
                                                    <div className="d-flex justify-content-end align-items-center gap-2">
                                                        <div
                                                            onClick={() => setSelectedDocument(file)}
                                                            className="file-open-icon-cont d-flex align-items-center justify-content-center"
                                                            role="button"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#openDocumentModal"
                                                            aria-controls="openDocumentModal"
                                                            data-bs-id={file.uuid}
                                                        >
                                                            <VscEye color="#4B57E7" size={13} />
                                                        </div>
                                                        <img
                                                            style={{ height: "20px" }}
                                                            src={DeleteIcon2}
                                                            className="cursor-pointer"
                                                            alt="delete"
                                                            onClick={() => {
                                                                handleDeleteFile(file?.uuid, form?.id, setActiveFileId);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        );
                    }


                    if (
                        form?.type === "card" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div
                                className={`form-card-cont px-4 border border-1 rounded m-2 py-3 ${
                                    validationErrors[index]
                                        ? "border border-danger"
                                        : ""
                                }`}
                            >
                                {form?.showTitle &&
                                    <div className="card-question my-3 fw-bold fs-5 ">
                                        {form?.question}
                                    </div>}
                                <div className="mt-3">
                                    {form?.questionData?.length > 0 &&
                                        form?.questionData?.map((q) => {
                                            if (q?.type === "text-one-line") {
                                                return (
                                                    <div className="row  p-0 justify-content-between align-items-start mb-3" key={index}>
                                                        <div className="col-md-7 text-start">
                                                            <label
                                                                htmlFor={q?.label}
                                                            >
                                                                {q?.label}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-5 ">
                                                            <input
                                                                type="text"
                                                                id={q?.label}
                                                                placeholder="Enter your answer here..."
                                                                className="question-input form-control"
                                                                value={q?.answer}
                                                                onChange={(e) =>
                                                                    handleCardInputChange(
                                                                        e.target.value,
                                                                        form,
                                                                        q
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (q?.type === "text-email") {
                                                return (
                                                    <div className="row  p-0 justify-content-between align-items-start mb-3" key={index}>
                                                        <div className="col-md-7 text-start">
                                                            <label
                                                                htmlFor={q?.label}
                                                            >
                                                                {q?.label}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-5 ">
                                                            <input
                                                                value={q?.answer}
                                                                onChange={(e) =>
                                                                    handleCardInputChange(
                                                                        e.target.value,
                                                                        form,
                                                                        q
                                                                    )
                                                                }
                                                                type="email"
                                                                id={q?.label}
                                                                placeholder="Enter your answer here..."
                                                                className="question-input form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (q?.type === "number-any-number") {
                                                return (
                                                    <div
                                                        className="row p-0 justify-content-between align-items-start mb-3"
                                                        key={index}
                                                    >
                                                        <div className="col-md-7 text-start">
                                                            <label htmlFor={q?.label}>
                                                                {q?.label}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <input
                                                                value={q?.answer}
                                                                onWheel={(e) => e.target.blur()}
                                                                onChange={(e) =>
                                                                    handleCardInputChange(
                                                                        e.target.value,
                                                                        form,
                                                                        q
                                                                    )
                                                                }
                                                                type="number"
                                                                id={q?.label}
                                                                placeholder="Enter your answer here..."
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }


                                            if (q?.type === "number-amount") {
                                                return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                        <div className="col-md-7 text-start">
                                                            <label htmlFor={q?.label}>
                                                                {q?.label}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-5 d-flex align-items-center">
                                                            <div className='input-group'>
                                                                <span className="input-group-text grid-amount">$</span>
                                                                <input
                                                                    value={q?.answer}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onChange={(e) =>
                                                                        handleCardInputChange(
                                                                            e.target.value,
                                                                            form,
                                                                            q
                                                                        )
                                                                    }
                                                                    type="number"
                                                                    id={q?.label}
                                                                    placeholder="Enter your answer here..."
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (q?.type === "number-percentage") {
                                                return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                        <div className="col-md-7 text-start">
                                                            <label htmlFor={q?.label}>
                                                                {q?.label}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-5 d-flex align-items-center">
                                                            <div className='input-group'>
                                                                <input
                                                                    value={q?.answer}
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onChange={(e) =>
                                                                        handleCardInputChange(
                                                                            e.target.value,
                                                                            form,
                                                                            q
                                                                        )
                                                                    }
                                                                    type="number"
                                                                    id={q?.label}
                                                                    placeholder="Enter your answer here..."
                                                                    className="[&::-webkit-inner-spin-button]:appearance-none form-control"
                                                                />
                                                                <span className="input-group-text grid-amount">%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if(q?.type === 'horizontal-line'){
                                                return <hr className="horizontal-line p-0"/>
                                            }
                                            if (q?.type === "yes-no") {
                                                return (
                                                    <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                                        <div className="col-md-7 text-start">
                                                            <label htmlFor={q?.label}>
                                                                {q?.label}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                                            <div className="d-flex gap-3">
                                                                {q?.questionData?.map(
                                                                    (answer) => {
                                                                        return (
                                                                            <div
                                                                                className={`yes-no-cont ${
                                                                                    q?.answer === answer
                                                                                        ? "yes-no-cont-active"
                                                                                        : ""
                                                                                }`}
                                                                                onClick={() => {
                                                                                    handleCardInputChange(
                                                                                        answer,
                                                                                        form,
                                                                                        q
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {answer}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (q?.type === "date") {
                                                return (
                                                    <div
                                                        className="row p-0 justify-content-between align-items-start mb-3"
                                                        key={index}
                                                    >
                                                        <div className="col-md-7 text-start">
                                                            <label
                                                                htmlFor={q?.label}
                                                            >
                                                                {q?.label}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                                            <DatePicker
                                                                value={
                                                                    q?.answer
                                                                        ? typeof q?.answer === "string"
                                                                            ? dayjs(q.answer)
                                                                            : q?.answer
                                                                        : null
                                                                }
                                                                onChange={(date) => {
                                                                    handleCardInputChange(date,form, q);
                                                                }}
                                                                format="DD/MM/YYYY"
                                                                placeholder="DD/MM/YYYY"
                                                                className="question-input form-control "
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (q?.type?.includes("dropdown")) {
                                                const options = q?.questionData?.map(
                                                    (state) => ({
                                                        value: state,
                                                        label: state,
                                                    })
                                                );
                                                return (
                                                    <div
                                                        className="row p-0 justify-content-between align-items-start mb-3"
                                                        key={index}
                                                    >
                                                        <div className='col-md-7'>
                                                            <label htmlFor={q?.label}>
                                                                {q?.label}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                                            <ReactSelect
                                                                className="input-cont question-input"
                                                                options={options}
                                                                isMulti={
                                                                    q?.type ===
                                                                    "dropdown-custom-multy-select"
                                                                }
                                                                value={
                                                                    q?.type ===
                                                                    "dropdown-custom-multy-select" &&
                                                                    Array.isArray(q?.answer)
                                                                        ? q?.answer?.map((answrField) => ({
                                                                            label: answrField,
                                                                            value: answrField,
                                                                        }))
                                                                        : {
                                                                            label: q?.answer,
                                                                            value: q?.answer,
                                                                        }
                                                                }
                                                                onChange={(selected) => {
                                                                    handleCardInputChange(
                                                                        q?.type ===
                                                                        "dropdown-custom-multy-select"
                                                                            ? selected.map(
                                                                                (option) => option.value
                                                                            ) // For multi-select, save as array of values
                                                                            : selected?.value,
                                                                        form,
                                                                        q
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                </div>
                                {form?.allowMultiple &&   <div className="d-flex justify-content-around mt-4 align-items-center">
                                    {!form?.original &&
                                        <div className="text-danger cursor-pointer" onClick={() => {
                                            setActiveFormData((prevForm) => {
                                                return {
                                                    ...prevForm,
                                                    data: {
                                                        ...prevForm?.data,
                                                        sections: activeFormData?.data?.sections?.map((section) => {
                                                            return {
                                                                ...section,
                                                                subsections: section?.subsections?.map((sub) => {
                                                                    if (sub?.id === activeSubSection) {
                                                                        return {
                                                                            ...sub,
                                                                            fields: sub?.fields?.filter(f => f?.id !== form?.id)
                                                                        };
                                                                    }
                                                                    return sub;
                                                                }),
                                                            };
                                                        }),
                                                    },
                                                };
                                            })
                                        }}> Remove {form?.question || 'card'}</div> }
                                    <div className="text-primary cursor-pointer" onClick={() => {
                                        const generateUniqueId = () => {
                                            const timestamp = Date.now().toString(36);
                                            const randomString = Math.random().toString(36).substring(2, 15);
                                            const randomSegment = Math.random().toString(36).substring(2, 15);
                                            return timestamp + randomString + randomSegment;
                                        };

                                        setActiveFormData((prevForm) => {
                                            return {
                                                ...prevForm,
                                                data: {
                                                    ...prevForm?.data,
                                                    sections: activeFormData?.data?.sections?.map((section) => {
                                                        return {
                                                            ...section,
                                                            subsections: section?.subsections?.map((sub) => {
                                                                if (sub?.id === activeSubSection) {
                                                                    const newField = {
                                                                        ...form,
                                                                        id: generateUniqueId(),
                                                                        original: false,
                                                                        questionData: form?.cardTemplate?.map(tempField => ({
                                                                            ...tempField,
                                                                            id: generateUniqueId(),
                                                                        }))
                                                                    };
                                                                    return {
                                                                        ...sub,
                                                                        fields: [
                                                                            ...sub?.fields.slice(0, index + 1),
                                                                            newField,
                                                                            ...sub?.fields.slice(index + 1)
                                                                        ]
                                                                    };
                                                                }
                                                                return sub;
                                                            }),
                                                        };
                                                    }),
                                                },
                                            };
                                        });


                                    }}> Add new {form?.question || 'card'} </div>
                                </div>}
                            </div>
                        );
                    }
                    if (
                        form?.type === "grid" &&
                        (!form?.condition || conditionCheck(form))
                    ) {
                        return (
                            <div className="mt-4 border mx-2 px-4 py-4 border-1 rounded mb-3">
                                <div className="row">
                                    <div className="col-md-5">
                                        {form?.showTitle &&
                                            <div className="grid-header-item grid-question d-flex align-items-center">
                                                {form?.question}
                                            </div>}
                                    </div>
                                    {form?.questionData?.columns.map((column, index) => (
                                        <div key={column.id}  className="col text-center">
                                            {column.label}
                                        </div>
                                    ))}
                                </div>

                                {/* Grid Rows */}
                                {form?.questionData?.rows.map((row) => (
                                    <div key={row.id} className="row mb-2 d-flex align-items-center mt-3">
                                        <div className="col-md-5 align-items-center">
                                            {row.label}
                                        </div>
                                        {form?.questionData?.columns.map((column, columnIndex) => {
                                            const answer =
                                                form.questionData?.answers[row.id]?.[column.id] || "";

                                            if (column?.type === "text-one-line") {
                                                return (
                                                    <div key={columnIndex} className="col px-1">
                                                        <input
                                                            type="text"
                                                            id={column?.label}
                                                            placeholder="Enter your answer here..."
                                                            className="form-control"
                                                            value={answer}
                                                            onChange={(e) =>
                                                                handleGridInputChange(
                                                                    row.id,
                                                                    column.id,
                                                                    form?.question,
                                                                    e.target.value
                                                                )
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    </div>
                                                );
                                            }

                                            if (column?.type === "text-multi-line") {
                                                return (
                                                    <div key={columnIndex} className="col px-1">
                                      <textarea
                                          id={column?.label}
                                          placeholder="Enter your answer here..."
                                          value={answer}
                                          onChange={(e) =>
                                              handleGridInputChange(
                                                  row.id,
                                                  column.id,
                                                  form?.question,
                                                  e.target.value
                                              )
                                          }
                                          className="form-control"
                                          style={{ width: "100%" }}
                                      ></textarea>
                                                    </div>
                                                );
                                            }

                                            if (column?.type === "text-email") {
                                                return (
                                                    <div key={columnIndex} className="col px-1">
                                                        <input
                                                            type="email"
                                                            id={column?.label}
                                                            placeholder="Enter your email here..."
                                                            className="form-control"
                                                            value={answer}
                                                            onChange={(e) =>
                                                                handleGridInputChange(
                                                                    row.id,
                                                                    column.id,
                                                                    form?.question,
                                                                    e.target.value
                                                                )
                                                            }
                                                            style={{ width: "100%" }}
                                                        />
                                                    </div>
                                                );
                                            }

                                            if (column?.type === "number-any-number") {
                                                return (
                                                    <div key={columnIndex} className="col px-1 d-flex justify-conent">
                                                        <input
                                                            type="number"
                                                            id={column?.label}
                                                            placeholder="Enter your number here..."
                                                            className="form-control"
                                                            value={answer}
                                                            onChange={(e) =>
                                                                handleGridInputChange(
                                                                    row.id,
                                                                    column.id,
                                                                    form?.question,
                                                                    e.target.value
                                                                )
                                                            }
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </div>
                                                );
                                            }

                                            if (column?.type === "number-amount") {
                                                return (
                                                    <div key={columnIndex} className="col px-1">
                                                        <div className="input-group"
                                                             style={{ width: "100%" }}
                                                        >
                                                            <span className="input-group-text grid-amount">$</span>
                                                            <input
                                                                type="number"
                                                                id={column?.label}
                                                                placeholder="Enter amount"
                                                                className="form-control"
                                                                value={answer}
                                                                onChange={(e) =>
                                                                    handleGridInputChange(
                                                                        row.id,
                                                                        column.id,
                                                                        form?.question,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                onWheel={(e) => e.target.blur()}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            if (column?.type === "number-percentage") {
                                                return (
                                                    <div key={columnIndex} className="col px-1">
                                                        <div className="input-group"
                                                             style={{ width: "100%" }}>
                                                            <input
                                                                type="number"
                                                                id={column?.label}
                                                                placeholder="Enter percentage"
                                                                className="form-control"
                                                                value={answer}
                                                                onChange={(e) =>
                                                                    handleGridInputChange(
                                                                        row.id,
                                                                        column.id,
                                                                        form?.question,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                onWheel={(e) => e.target.blur()}
                                                            />
                                                            <span className="input-group-text grid-amount">%</span>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            if (column?.type === "yes-no") {
                                                return (
                                                    <div key={columnIndex} className="col px-1 d-flex justify-content-center">
                                                        <div className="d-flex gap-2">
                                                            {column?.questionData?.map((ans, ansIndex) => (
                                                                <div
                                                                    key={ansIndex}
                                                                    onClick={() => {
                                                                        handleGridInputChange(
                                                                            row.id,
                                                                            column.id,
                                                                            form?.question,
                                                                            ans
                                                                        );
                                                                    }}
                                                                    className={`yes-no-cont ${
                                                                        ans === answer ? "yes-no-cont-active" : "yes-no-cont"
                                                                    }`}
                                                                >
                                                                    {ans}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (
                                                column?.type?.includes(
                                                    "dropdown"
                                                )
                                            ) {
                                                const options =
                                                    column?.questionData?.map(
                                                        (state) => ({
                                                            value: state,
                                                            label: state,
                                                        })
                                                    );
                                                return (
                                                    <div
                                                        className="col px-1"
                                                        key={index}
                                                    >
                                                        <ReactSelect
                                                            className="question-input-list"
                                                            options={
                                                                options
                                                            }
                                                            value={
                                                                column?.type ===
                                                                "dropdown-custom-multy-select" &&
                                                                Array.isArray(form?.questionData?.answers[row?.id] ? form?.questionData?.answers[row?.id][column?.id] : [])
                                                                    ? form?.questionData?.answers[row?.id] && form?.questionData?.answers[row?.id][column?.id]?.map((answrField) => ({
                                                                    label: answrField,
                                                                    value: answrField,
                                                                }))
                                                                    : {
                                                                        label: form?.questionData?.answers[row?.id] ? form?.questionData?.answers[row?.id][column?.id] : 'Select ...',
                                                                        value: form?.questionData?.answers[row?.id] ? form?.questionData?.answers[row?.id][column?.id] : 'Select ...'
                                                                    }
                                                            }
                                                            isMulti={
                                                                column?.type ===
                                                                "dropdown-custom-multy-select"
                                                            }
                                                            onChange={(selected) =>
                                                                handleGridInputChange(row.id, column.id, form?.question, selected?.value)
                                                            }
                                                            menuPosition="fixed"
                                                            placeholder={'Select ...'}

                                                        />
                                                    </div>
                                                );
                                            }

                                            if (column?.type === "date") {
                                                return (
                                                    <div key={columnIndex} className="col px-1">
                                                        <DatePicker
                                                            value={
                                                                answer
                                                                    ? typeof answer === "string"
                                                                        ? dayjs(answer)
                                                                        : answer
                                                                    : null
                                                            }
                                                            onChange={(date) =>
                                                                handleGridInputChange(row.id, column.id, form?.question, date)
                                                            }
                                                            format="DD/MM/YYYY"
                                                            placeholder="DD/MM/YYYY"
                                                            className="form-control"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </div>
                                                );
                                            }

                                            return null;
                                        })}
                                    </div>
                                ))}
                            </div>
                        );
                    }




                    if(form?.type === 'list'  &&
                        (!form?.condition || conditionCheck(form))){
                        return <div
                            className={`mt-2 mb-3`}
                        >
                            <div className="container mt-5">
                                <div className="table-responsive">
                                    <div className="table-scroll-container">
                                        {/* Header Row (Columns) */}
                                        <div className="fw-bold">
                                            {form?.question}
                                        </div>
                                        <div className="row  mt-3">
                                            {form?.questionData?.columns.map((col, index) => (
                                                <div key={index} className="col px-3 text-sm mb-1 fw-bold">
                                                    {col.name}
                                                </div>
                                            ))}
                                        </div>
                                        {/* Rows */}
                                        {form?.questionData?.rows.map((row, rowIndex) => (
                                            <div key={rowIndex} className="row mb-2 position-relative">
                                                {form?.questionData?.columns.map((col, colIndex) => {
                                                    if (col?.type === "text-one-line") {
                                                        return (
                                                            <div key={colIndex} className="col px-1 text-center">
                                                                <input
                                                                    type="text"
                                                                    id={col?.name}
                                                                    placeholder="Enter your answer..."
                                                                    className="form-control"
                                                                    style={{ width: "100%" }}
                                                                    value={row[col?.name]}
                                                                    onChange={(e) => handleListFieldChange(form,rowIndex,col?.name,e.target.value)}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    if (col?.type === "text-multi-line") {
                                                        return (
                                                            <div key={colIndex} className="col px-1">
                                      <textarea
                                          id={col?.name}
                                          placeholder="Enter your answer..."
                                          className="form-control"
                                          style={{ width: "100%" }}
                                          value={row[col?.name]}
                                          onChange={(e) => handleListFieldChange(form,rowIndex,col?.name,e.target.value)}
                                      ></textarea>
                                                            </div>
                                                        );
                                                    }

                                                    if (col?.type === "text-email") {
                                                        return (
                                                            <div key={colIndex} className="col px-1">
                                                                <input
                                                                    type="email"
                                                                    id={col?.name}
                                                                    placeholder="Enter your email here..."
                                                                    className="form-control"
                                                                    style={{ width: "100%" }}
                                                                    value={row[col?.name]}
                                                                    onChange={(e) => handleListFieldChange(form,rowIndex,col?.name,e.target.value)}
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    if (col?.type === "number-any-number") {
                                                        return (
                                                            <div key={colIndex} className="col px-1">
                                                                <input
                                                                    type="number"
                                                                    id={col?.name}
                                                                    placeholder="Enter your number here..."
                                                                    className="form-control"
                                                                    style={{ width: "100%" }}
                                                                    value={row[col?.name]}
                                                                    onChange={(e) => handleListFieldChange(form,rowIndex,col?.name,e.target.value)}
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    if (col?.type === "number-amount") {
                                                        return (
                                                            <div key={colIndex} className="col px-1 d-flex align-items-center justify-content-center">
                                                                <div className="input-group"  style={{ width: "100%" }}>
                                                                    <span className="input-group-text grid-amount">$</span>
                                                                    <input
                                                                        type="number"
                                                                        id={col?.name}
                                                                        placeholder="Enter amount"
                                                                        className="form-control"
                                                                        value={row[col?.name]}
                                                                        onChange={(e) => handleListFieldChange(form,rowIndex,col?.name,e.target.value)}
                                                                        onWheel={(e) => e.target.blur()}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    if (col?.type === "number-percentage") {
                                                        return (
                                                            <div key={colIndex} className="col px-1 d-flex align-items-center justify-content-center">
                                                                <div className="input-group "
                                                                     style={{ width: "100%" }}
                                                                >
                                                                    <input
                                                                        type="number"
                                                                        id={col?.name}
                                                                        placeholder="Enter percentage"
                                                                        className="form-control"
                                                                        value={row[col?.name]}
                                                                        onChange={(e) => handleListFieldChange(form,rowIndex,col?.name,e.target.value)}
                                                                        onWheel={(e) => e.target.blur()}

                                                                    />
                                                                    <span className="input-group-text grid-amount">%</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    if (col?.type === "yes-no") {
                                                        return (
                                                            <div key={colIndex} className="col px-1 d-flex align-items-center justify-content-center">
                                                                <div className="d-flex gap-2">
                                                                    {col?.questionData?.map((answer) => (
                                                                        <div key={answer} className={`yes-no-cont ${row[col?.name] === answer ? 'yes-no-cont-active' : 'yes-no-cont' }`} onClick={() => {
                                                                            handleListFieldChange(form,rowIndex,col?.name,answer)
                                                                        }}>
                                                                            {answer}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    if (col?.type === "date") {
                                                        return (
                                                            <div key={colIndex} className="col px-1">
                                                                <DatePicker
                                                                    format="DD/MM/YYYY"
                                                                    placeholder="DD/MM/YYYY"
                                                                    className="form-control"
                                                                    style={{ width: "100%" }}
                                                                    value={
                                                                        row[col?.name]
                                                                            ? typeof row[col?.name] === "string"
                                                                                ? dayjs(row[col?.name])
                                                                                : row[col?.name]
                                                                            : null
                                                                    }
                                                                    onChange={(date) => handleListFieldChange(form,rowIndex,col?.name,date)}
                                                                />
                                                            </div>
                                                        );
                                                    }

                                                    if (col?.type?.includes('dropdown')) {
                                                        const options = col?.questionData?.map(
                                                            (state) => ({
                                                                value: state,
                                                                label: state,
                                                            })
                                                        );
                                                        return (
                                                            <div className="col px-1 " key={index}>
                                                                <ReactSelect
                                                                    className="question-input-list"
                                                                    options={options}
                                                                    styles={{ zIndex: 9999 , padding:'200px'}}
                                                                    isMulti={
                                                                        col?.type ===
                                                                        "dropdown-custom-multy-select"
                                                                    }
                                                                    value={
                                                                        col?.type ===
                                                                        "dropdown-custom-multy-select" &&
                                                                        Array.isArray(row[col?.name])
                                                                            ? row[col?.name]?.map(
                                                                                (answrField) => ({
                                                                                    label: answrField,
                                                                                    value: answrField,
                                                                                })
                                                                            )
                                                                            : row[col?.name]
                                                                                ? {
                                                                                    label: row[col?.name],
                                                                                    value: row[col?.name],
                                                                                }
                                                                                : null
                                                                    }
                                                                    onChange={(selected) => {
                                                                        handleListFieldChange(
                                                                            form,
                                                                            rowIndex,
                                                                            col?.name,
                                                                            col?.type ===
                                                                            "dropdown-custom-multy-select"
                                                                                ? selected?.map(
                                                                                    (option) => option.value
                                                                                )
                                                                                : selected?.value
                                                                        );
                                                                    }}
                                                                    menuPosition="fixed"
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    return null;

                                                })}
                                                <div className="col-auto d-flex cursor-pointer del-row-icon" onClick={() => {
                                                    setActiveFormData((prevForm) => {
                                                        return {
                                                            ...prevForm,
                                                            data:{
                                                                ...prevForm?.data,
                                                                sections:prevForm?.data?.sections?.map(sec => {
                                                                    if(sec?.id === activeSection){
                                                                        return {
                                                                            ...sec,
                                                                            subsections: sec?.subsections?.map(sub => {
                                                                                if(sub?.id === activeSubSection){
                                                                                    return {
                                                                                        ...sub,
                                                                                        fields: sub?.fields?.map((field) => {
                                                                                            if(field?.id === form?.id){
                                                                                                return {
                                                                                                    ...field,
                                                                                                    questionData: {
                                                                                                        ...field?.questionData,
                                                                                                        rows: field?.questionData?.rows?.filter((_,idx) => idx!== rowIndex)
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                            return field
                                                                                        })
                                                                                    }
                                                                                }
                                                                                return sub
                                                                            })
                                                                        }
                                                                    }
                                                                    return sec
                                                                })
                                                            }
                                                        }
                                                    })
                                                }}>
                                                    <img src={DeleteIcon2} alt="closeIcon" style={{height:'20px'}} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div className="cursor-pointer fw-normal text-primary mt-3"  onClick={() => addRow(form)}>
                                            Add Row
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                })}</>
    )
}


export default RenderInputList;