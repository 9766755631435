import { useState } from "react";

const generateUniqueId = () => {
    const timestamp = Date.now().toString(36);
    const randomString = Math.random().toString(36).substring(2, 15);
    const randomSegment = Math.random().toString(36).substring(2, 15);
    return timestamp + randomString + randomSegment;
  };

  
 const AddGridRowModal = ({
    gridData,
    setAddGridRowModalOpen,
    setGridData
   }) => {
     const [row, setRow] = useState("");
     const [errorMessage, setErrorMessage] = useState("");
     return (
       <div
         className="custom-modal-overlay"
         onClick={() => setAddGridRowModalOpen(false)}
       >
         <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
           <h2 className="custom-modal-heading">Add Row</h2>
           <p className="custom-modal-description">
           Enter label for new row
           </p>
           <div className="custom-modal-input-cont">
             {errorMessage && <div className="text-danger">{errorMessage}</div>}
             <input
               className={errorMessage ? "border-1 border-danger" : ""}
               type="text"
               id="section-title"
               value={row}
               onChange={(e) => {
                 setRow(e.target.value);
                 setErrorMessage("");
               }}
               />
           </div>
           <div className="custom-modal-buttons">
             <button
               className="btn"
               onClick={() => {
                setAddGridRowModalOpen(false);
               }}
             >
               Cancel
             </button>
             <div
               di
               className="btn btn-primary"
               onClick={() => {
                 const isOptionAdded = gridData?.rows?.find(
                   (col) => col?.label === row
                 );
   
                 if (!isOptionAdded) {
                   setGridData(prev => {
                     return {
                       ...prev,
                       rows:[...prev?.rows,{id:generateUniqueId(),label:row,type:'text-one-line'}]
                     }
                   })
                   setAddGridRowModalOpen(false);
                   setErrorMessage("");
                 } else {
                   setErrorMessage("The column is added already!");
                 }
               }}
               disabled={row?.length < 3}
             >
               Save
             </div>
           </div>
         </div>
       </div>
     );
   };


   export default AddGridRowModal