import { moneyFormatter } from '../../../utilites';

export default function Payments({ totalPrice,escrow,milestoneRemaining,milestonePaid }) {
    return (
        <div className='clearfix'>
            <div className='progress bg-light'>
                <div
                    className='progress-bar bg-primary'
                    role='progressbar'
                    style={{
                        width: `${(milestonePaid / totalPrice) * 100}%`,
                    }}
                    aria-valuenow={milestonePaid}
                    aria-valuemin='0'
                    aria-valuemax={totalPrice - 0}
                />
                <div
                    className='progress-bar bg-secondary-700'
                    role='progressbar'
                    style={{
                        width: `${0}%`,
                    }}
                    aria-valuenow={0}
                    aria-valuemin='0'
                    aria-valuemax={totalPrice - milestonePaid}
                />
            </div>
            <ul className='list-group mt-3'>
                <li className='list-group-item border-0 px-0 py-1'>
					<span
                        className='d-inline-flex bg-primary rounded-circle me-1'
                        style={{
                            width: 13,
                            height: 13,
                        }}
                    />
                    Milestone paid
                    <span className='float-end'>{moneyFormatter(milestonePaid)}</span>
                </li>
                <li className='list-group-item border-0 px-0 py-1'>
					<span
                        className='d-inline-flex bg-secondary-700 rounded-circle me-1'
                        style={{
                            width: 13,
                            height: 13,
                        }}
                    />
                    Milestone remaining
                    <span className='float-end'>{moneyFormatter(milestoneRemaining > 0 ? milestoneRemaining : 0)}</span>
                </li>
                <li className='list-group-item border-0 px-0 py-1'>
					<span
                        className='d-inline-flex bg-dark-subtle rounded-circle me-1'
                        style={{
                            width: 13,
                            height: 13,
                        }}
                    />
                    In escrow
                    <span className='float-end'>{moneyFormatter(escrow)}</span>
                </li>
                <li className='list-group-item border-0 px-0 py-1'>
					<span
                        className='d-inline-flex bg-light rounded-circle me-1'
                        style={{
                            width: 13,
                            height: 13,
                        }}
                    />
                    Project price
                    <span className='float-end'>{moneyFormatter(totalPrice)}</span>
                </li>
            </ul>
        </div>
    );
}
