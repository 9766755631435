import React, { useState } from 'react'
import ReactSelect from 'react-select'
import { americanStatesData, countriesData } from '../StaticData'
import Input from '../../../fields/Input'

const PartnersList = ({ 
    setAddPartner,
    question,partnerToEdit,setPartnerToEdit,handleSavePartner,handleSaveEditedPartner
 }) => {

    const [partner, setPartner] = useState(partnerToEdit?.state ? partnerToEdit?.data : {})

    const handleChange = (field, value) => {
        setPartner(prevPartner => ({
            ...prevPartner,
            [field]: value
        }))
    }
    

    return (
      <div className="mt-3">
        <div className="fs-5">Partner information</div>
        <hr />
        <div className="fw-bold">Type of entity</div>
        <div className="w-50 mt-2">
          <ReactSelect
            options={[
              { label: "Individual", value: "Individual" },
              { label: "Corporation", value: "Corporation" },
              { label: "S Corporation", value: "S Corporation" },
              { label: "Partnership", value: "Partnership" },
              { label: "Disregarded Entity", value: "Disregarded Entity" },
              {
                label: "Estate of deceased partner",
                value: "Estate of deceased partner",
              },
              { label: "Other estate", value: "Other estate" },
              { label: "Trust", value: "Trust" },
            ]}
            className="question-input"
            onChange={(option) => handleChange("type_of_entity", option.value)}
            value={
              partner?.type_of_entity
                ? {
                    label: partner.type_of_entity,
                    value: partner.type_of_entity,
                  }
                : null
            }
          />
        </div>
        {(partner?.type_of_entity === "Individual" || partner?.type_of_entity === 'Disregarded Entity') && (
          <>
            <div className="fw-bold mt-3">First name</div>
            <div className="w-50 mt-2">
              <input
                type="text"
                className="w-full form-control"
                onChange={(e) => handleChange("firstname", e.target.value)}
                value={partner?.firstname || ""}
              />
            </div>
            <div className="fw-bold mt-3">Middle initial</div>
            <div className="w-50 mt-2">
              <input
                type="text"
                className="w-full form-control"
                onChange={(e) => handleChange("middle_initial", e.target.value)}
                value={partner?.middle_initial || ""}
              />
            </div>
            <div className="fw-bold mt-3">Last name</div>
            <div className="w-50 mt-2">
              <input
                type="text"
                className="w-full form-control"
                onChange={(e) => handleChange("lastname", e.target.value)}
                value={partner?.lastname || ""}
              />
            </div>
          </>
        )}

        {(partner?.type_of_entity !== "Individual" || partner?.type_of_entity === 'Disregarded Entity') && (
          <>
            <div className="fw-bold mt-3">Entity name</div>
            <div className="w-50 mt-2">
              <input
                type="text"
                className="w-full form-control"
                onChange={(e) => handleChange("entity_name", e.target.value)}
                value={partner?.entity_name || ""}
              />
            </div>
          </>
        )}
        {partner?.type_of_entity !== "Individual" && (
          <>
            <div className="fw-bold mt-3">EIN</div>
            <div className="w-50 mt-2">
              <Input
                className={`form-control`}
                value={partner?.ein || ""}
                fn={(e) => {
                  const inputValue = e;
                  handleChange("ein", inputValue);
                }}
                placeholder=""
                showLabel={false}
                mask={"99-99999999"}
                max={10}
              />
            </div>
          </>
        )}
        {(partner?.type_of_entity === "Individual" || partner?.type_of_entity === 'Disregarded Entity') && (
          <>
            <div className="fw-bold mt-3">SSN</div>
            <div className="w-50 mt-2">
              <Input
                className={`form-control`}
                value={partner?.ssn || ""}
                fn={(e) => {
                  const inputValue = e;
                  handleChange("ssn", inputValue);
                }}
                //   type={"number"}
                //   hasValidationError={validationErrors[index]}
                placeholder=""
                showLabel={false}
                mask={"999-99-9999"}
                //   formatChars={form?.validation === 'zipcode' ? {
                //     9: "[0-9-]",
                //   } : null}
                max={10}
              />
            </div>
          </>
        )}
        <div className="fw-bold mt-3">Select one of the following</div>
        <div className="w-50 mt-2">
          <ReactSelect
            options={[
              {
                label: "General partner or LLC member-manager",
                value: "General partner or LLC member-manager",
              },
              {
                label: "Limited partner or other LLC member",
                value: "Limited partner or other LLC member",
              },
            ]}
            className="question-input"
            onChange={(option) => handleChange("partner_role", option.value)}
            value={
              partner?.partner_role
                ? { label: partner.partner_role, value: partner.partner_role }
                : null
            }
          />
        </div>
        <div className="fw-bold mt-3">Select one of the following</div>
        <div className="w-50 mt-2">
          <ReactSelect
            options={[
              { label: "Domestic partner", value: "Domestic partner" },
              { label: "Foreign partner", value: "Foreign partner" },
            ]}
            className="question-input"
            onChange={(option) =>
              handleChange("partner_nationality", option.value)
            }
            value={
              partner?.partner_nationality
                ? {
                    label: partner.partner_nationality,
                    value: partner.partner_nationality,
                  }
                : null
            }
          />
        </div>
        {partner?.partner_nationality === "foreign_partner" && (
          <>
            <div className="fw-bold mt-3">Country of citizenship</div>
            <div className="w-50 mt-2">
              <ReactSelect
                options={countriesData?.map((country) => ({
                  label: country,
                  value: country,
                }))}
                className="question-input"
                onChange={(option) => handleChange("citizenship", option.value)}
                value={
                  partner?.citizenship
                    ? { label: partner.citizenship, value: partner.citizenship }
                    : null
                }
              />
            </div>
          </>
        )}
        {partner?.type_of_entity === "individual" && (
          <div className="d-flex flex-column gap-2">
            <div className="fw-bold mt-3">Select if applicable</div>
            <div className="d-flex align-items-center gap-2">
              <input
                className="cursor-pointer"
                type="checkbox"
                onChange={(e) =>
                  handleChange("will_sign_tax_return", e.target.checked)
                }
                checked={partner?.will_sign_tax_return || false}
              />
              <div>This partner will sign the tax return</div>
            </div>
          </div>
        )}
        <div className="fs-5 mt-5">Partner Contact</div>
        <hr />
        <div className="fw-bold mt-3">Email</div>
        <div className="w-50 mt-2">
          <input
            type="text"
            className="w-full form-control"
            onChange={(e) => handleChange("email", e.target.value)}
            value={partner?.email || ""}
          />
        </div>
        <div className="fw-bold mt-3">Phone number</div>
        <div className="w-50 mt-2">
          <Input
            className={`form-control`}
            value={partner?.phone_number || ""}
            fn={(e) => {
              const inputValue = e;
              handleChange("phone_number", inputValue);
            }}
            placeholder=""
            showLabel={false}
            mask={"(999) 999-9999"}
            max={10}
          />
        </div>
        <div className="fw-bold mt-3">Type of address</div>
        <div className="w-50 mt-2">
          <ReactSelect
            options={[
              { label: "US", value: "US" },
              { label: "Foreign", value: "Foreign" },
            ]}
            className="question-input"
            onChange={(option) => handleChange("address_type", option.value)}
            value={
              partner?.address_type
                ? { label: partner.address_type, value: partner.address_type }
                : null
            }
          />
        </div>
        <div className="fw-bold mt-3">Street</div>
        <div className="w-50 mt-2">
          <input
            type="text"
            className="w-full form-control"
            onChange={(e) => handleChange("street", e.target.value)}
            value={partner?.street || ""}
          />
        </div>
        <div className="fw-bold mt-3">City</div>
        <div className="w-50 mt-2">
          <input
            type="text"
            className="w-full form-control"
            onChange={(e) => handleChange("city", e.target.value)}
            value={partner?.city || ""}
          />
        </div>
        {partner?.address_type === "Foreign" && (
          <>
            <div className="fw-bold mt-3">Province</div>
            <div className="w-50 mt-2">
              <input
                type="text"
                className="w-full form-control"
                onChange={(e) => handleChange("province", e.target.value)}
                value={partner?.province || ""}
              />
            </div>
            <div className="fw-bold mt-3">Postal code</div>
            <div className="w-50 mt-2">
              <input
                type="number"
                className="w-full form-control"
                onChange={(e) => handleChange("postal_code", e.target.value)}
                value={partner?.postal_code || ""}
              />
            </div>
            <div className="fw-bold mt-3">Country</div>
            <div className="w-50 mt-2">
              <ReactSelect
                options={countriesData?.map((country) => ({
                  label: country,
                  value: country,
                }))}
                className="question-input"
                onChange={(option) => handleChange("country", option.value)}
                value={
                  partner?.country
                    ? { label: partner.country, value: partner.country }
                    : null
                }
              />
            </div>
          </>
        )}
        {partner?.address_type === "us" && (
          <>
            <div className="fw-bold mt-3">State</div>
            <div className="w-50 mt-2">
              <ReactSelect
                options={americanStatesData?.map((state) => ({
                  label: state,
                  value: state,
                }))}
                className="question-input"
                onChange={(option) => handleChange("state", option.value)}
                value={
                  partner?.state
                    ? { label: partner.state, value: partner.state }
                    : null
                }
              />
            </div>
            <div className="fw-bold mt-3">ZIP Code</div>
            <div className="w-50 mt-2">
              <Input
                className={`form-control`}
                value={partner?.zip_code || ""}
                fn={(e) => {
                  const inputValue = e;
                  handleChange("zip_code", inputValue);
                }}
                placeholder=""
                showLabel={false}
                mask={"99999999"}
                formatChars={{
                  9: "[0-9-]",
                }}
                max={10}
              />
            </div>
          </>
        )}
        <div className="fs-5 mt-5">Partner Ownership</div>
        <hr />
        <div className="fw-bold mt-3">
          Ownership percentage (beginning of the year)
        </div>
        <div className="w-50 mt-2">
          <input
            type="number"
            className="w-full form-control"
            onChange={(e) =>
              handleChange("ownership_start_year", e.target.value)
            }
            value={partner?.ownership_start_year || ""}
          />
        </div>
        <div className="fw-bold mt-3">
          Ownership percentage (end of the year)
        </div>
        <div className="w-50 mt-2">
          <input
            type="number"
            className="w-full form-control"
            onChange={(e) => handleChange("ownership_end_year", e.target.value)}
            value={partner?.ownership_end_year || ""}
          />
        </div>
        <div className="fw-bold mt-3">
          Capital contributions during the year
        </div>
        <div className="w-50 mt-2">
          <input
            type="number"
            className="w-full form-control"
            onChange={(e) =>
              handleChange("capital_contributions", e.target.value)
            }
            value={partner?.capital_contributions || ""}
          />
        </div>
        <div className="fw-bold mt-3">Distributions during the year</div>
        <div className="w-50 mt-2">
          <input
            type="number"
            className="w-full form-control"
            onChange={(e) => handleChange("distributions", e.target.value)}
            value={partner?.distributions || ""}
          />
        </div>
        <div className="fs-5 mt-5">Partner Other Information</div>
        <hr />
        <div className="fw-bold mt-3">Guaranteed payments</div>
        <div className="w-50 mt-2">
          <input
            type="number"
            className="w-full form-control"
            onChange={(e) =>
              handleChange("guaranteed_payments", e.target.value)
            }
            value={partner?.guaranteed_payments || ""}
          />
        </div>
        <div className="fw-bold mt-3">
          Amount paid for health insurance premiums
        </div>
        <div className="w-50 mt-2">
          <input
            type="number"
            className="w-full form-control"
            onChange={(e) =>
              handleChange("health_insurance_premiums", e.target.value)
            }
            value={partner?.health_insurance_premiums || ""}
          />
        </div>
        <div className="d-flex gap-3 justify-content-center py-3">
          {!partnerToEdit?.state ? (
            <div
              className="btn btn-primary text-center mt-3 fw-bold cursor-pointer"
              onClick={() => handleSavePartner(partner, question)}
            >
              Save
            </div>
          ) : (
            <div
              className="btn btn-primary text-center mt-3 fw-bold cursor-pointer"
              onClick={() => handleSaveEditedPartner(partner, question)}
            >
              Save edited
            </div>
          )}
          <div
            className="btn btn-danger text-center mt-3 fw-bold cursor-pointer"
            onClick={() => {
              setAddPartner(false);
              setPartnerToEdit({ state: false, data: null });
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    );
}

export default PartnersList 
