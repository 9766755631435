const CreateSectionTitleModal = ({
    setCustomModalOpen,
    sectionTitle,
    setSectionTitle,
    createNewSection,
  }) => {
  
    return (
      <div className="custom-modal-overlay">
        <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
          <h2 className="custom-modal-heading">Create a new section</h2>
          <p className="custom-modal-description">
            Enter a single sentence description that best describes the purpose of
            this section.
          </p>
          <div className="custom-modal-input-cont">
            <label htmlFor="section-title">Enter section tittle</label>
            <input
              type="text"
              id="section-title"
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
            />
          </div>
          <div className="custom-modal-buttons">
            <button
              className="btn"
              onClick={() => {
                setCustomModalOpen(null);
              }}
            >
              Cancel
            </button>
            <button
              di
              className="btn btn-primary"
              onClick={() => {
                createNewSection();
              }}
              disabled={sectionTitle?.length < 3}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };

  export default CreateSectionTitleModal