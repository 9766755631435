import axios from "axios";
import { getCurrentFormData, getCurrentFormsObj } from "../../utilites/functions";
import { TAXFORMS_ACTION_TYPE } from "./Type";
import { BASEURL } from "../../utilites";
import { global } from "../global/action";

// FUNCTION TO DISPATCH SIGNLE VALUE DIRECTLY TO STORE
const moveToNext = ({ parentIndex, myIndex, taxPayerFormData }) => {
	return async (dispatch) => {
		let OptionsSlice = taxPayerFormData.slice();

		// MARK AS COMPLETED
		OptionsSlice[parentIndex].SubOption[myIndex].completed = true;

		// CLOSE THIS FORM
		OptionsSlice[parentIndex].SubOption[myIndex].open = false;

		// CHECK IF ITS LAST ONE INDEX
		let getLength = OptionsSlice[parentIndex].SubOption.length - 1 === myIndex;

		// IF NOT OPEN NEXT THIS FORM
		if (!getLength) OptionsSlice[parentIndex].SubOption[myIndex + 1].open = true;

		// IF IT IS CLOSE SIDE BAR AND OPEN NEXT SIDEBAR AND FORM AS WELL
		if (getLength) {
			OptionsSlice[parentIndex].open = false;

			OptionsSlice[parentIndex + 1].open = true;
			OptionsSlice[parentIndex + 1].SubOption[0].open = true;
		}

		dispatch({
			type: TAXFORMS_ACTION_TYPE.taxPayerFormData,
			payload: OptionsSlice,
		});
		window.scrollTo(0, 0);
	};
};

// FUNCTION TO DISPATCH SIGNLE VALUE DIRECTLY TO STORE
const moveToBack = ({ parentIndex, myIndex, taxPayerFormData }) => {
	return async (dispatch) => {
		let OptionsSlice = taxPayerFormData.slice();

		// CLOSE THIS FORM
		OptionsSlice[parentIndex].SubOption[myIndex].open = false;

		// CHECK IF ITS LAST ONE INDEX
		let getLength = 0 === myIndex;

		// IF NOT OPEN NEXT THIS FORM
		if (!getLength) OptionsSlice[parentIndex].SubOption[myIndex - 1].open = true;

		// IF IT IS CLOSE SIDE BAR AND OPEN NEXT SIDEBAR AND FORM AS WELL
		if (getLength) {
			OptionsSlice[parentIndex].open = false;

			OptionsSlice[parentIndex - 1].open = true;
			OptionsSlice[parentIndex - 1].SubOption[
				OptionsSlice[parentIndex - 1].SubOption.length - 1
			].open = true;
		}

		console.log("OptionsSlice", OptionsSlice);
		dispatch({
			type: TAXFORMS_ACTION_TYPE.taxPayerFormData,
			payload: OptionsSlice,
		});
		window.scrollTo(0, 0);
	};
};

// FUNCTION TO OPEN FORM
const openThisForm = ({ parentIndex, myIndex, taxPayerFormData }) => {
	return async (dispatch) => {
		const localActiveOption = taxPayerFormData.slice();
		localActiveOption.forEach((data) => {
			data.SubOption.forEach((subData) => {
				subData.open = false;
			});
		});
		localActiveOption[parentIndex].SubOption[myIndex].open = true;

		dispatch({
			type: TAXFORMS_ACTION_TYPE.taxPayerFormData,
			payload: localActiveOption,
		});
	};
};

// FUNCTION TO OPEN SIDEBAR FOR FORMS
const openThisSideBar = ({ parentIndex, taxPayerFormData }) => {
	return async (dispatch) => {
		const localOptions = taxPayerFormData.slice();
		localOptions.forEach((option) => {
			option.open = false;
		});
		localOptions[parentIndex].open = !localOptions[parentIndex].open;

		dispatch({
			type: TAXFORMS_ACTION_TYPE.taxPayerFormData,
			payload: localOptions,
		});
	};
};

// FUNCTION TO DISPATCH SIGNLE VALUE DIRECTLY TO STORE
const markMeCompleted = ({ parentIndex, myIndex, taxPayerFormData }) => {
	return async (dispatch) => {
		let OptionsSlice = taxPayerFormData.slice();

		// MARK AS COMPLETED
		OptionsSlice[parentIndex].SubOption[myIndex].completed = true;

		dispatch({
			type: TAXFORMS_ACTION_TYPE.taxPayerFormData,
			payload: OptionsSlice,
		});
	};
};

// FUNCTION TO OPEN SIDEBAR FOR FORMS
const StoreFormData = ({ formData }) => {
	return async (dispatch) => {
		dispatch({
			type: TAXFORMS_ACTION_TYPE.taxPayerForm,
			payload: formData,
		});
	};
};

// FUNCTION TO RESET AND SET VALUES FOR NEW
const dispatchViewFormDetails = ({ document, navigate }) => {
	return async (dispatch) => {
		const Options = getCurrentFormData(document.name);
		const formObj = getCurrentFormsObj(document.name);

		Object.keys(formObj).forEach((key) => (formObj[key] = {}));

		// TURN OFF FORMS
		for (const category of Options) {
			// Reset parent category
			category.open = false;
			category.completed = false;

			for (const subOption of category.SubOption) {
				// Reset nested sub-option
				subOption.open = false;
				subOption.completed = false;
			}
		}
		Options[0].open = true;
		Options[0].SubOption[0].open = true;

		dispatch({ type: TAXFORMS_ACTION_TYPE.taxPayerFormIdle, payload: Options, formObj: formObj });

		if (Object.keys(document?.formData).length > 0) {
			dispatch({
				type: TAXFORMS_ACTION_TYPE.taxPayerForm,
				payload: { data: document?.formData, message: "Form Data Exist", state: true },
			});
		}
		// delete document.formData;
		dispatch({ type: TAXFORMS_ACTION_TYPE.formInformation, payload: document });

		setTimeout(() => {
			if (navigate) navigate("/dashboard/organizer");
		}, 100);
	};
};

const getForms = () => {
	return async (dispatch) => {
		axios
			.get(`${BASEURL}/api/taxform/get-dynamic-form-byadmin`)
			.then((response) => {
				console.log(">>>>>>>>> getForms >>>>>>>>",response)
				// HANDLE RESPONSE
				dispatch({
					type: TAXFORMS_ACTION_TYPE.forms,
					// payload: { data, count },
					payload: {
						message: TAXFORMS_ACTION_TYPE.forms,
						status: true,
						data: response.data.data,
					},
				});
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: TAXFORMS_ACTION_TYPE.forms,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const createNewForm = (formTitle,taxForm,resetSelectedForms) => {
	return async (dispatch) => {
		axios
			.post(`${BASEURL}/api/taxform/create-dynamic-form-byadmin`,{form_data:{name:formTitle,data:taxForm}})
			.then((response) => {
				console.log(response,">>>>>> response");
				resetSelectedForms();
				dispatch(taxForms.getForms())
				// HANDLE RESPONSE
				dispatch({
					type: TAXFORMS_ACTION_TYPE.formCreated,
					// payload: { data, count },
					payload: {
						message: TAXFORMS_ACTION_TYPE.formCreated,
						status: true,
						data: response,
					},
				});
				dispatch(global.Loader(false));
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: TAXFORMS_ACTION_TYPE.formCreated,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
				dispatch(global.Loader(false));
			});
	};
}

const deleteFormById = (id) => {
	return async (dispatch) => {
		console.log(id,"form_id>>>>>>>>>>>>>>");
		axios
			.delete(`${BASEURL}/api/taxform/delete-dynamic-form-byadmin`,{data:{form_id:id}})
			.then((response) => {
				console.log(response,">>>>>> response");
				dispatch(taxForms.getForms())
				// HANDLE RESPONSE
				dispatch({
					type: TAXFORMS_ACTION_TYPE.formDeleted,
					// payload: { data, count },
					payload: {
						message: TAXFORMS_ACTION_TYPE.formDeleted,
						status: true,
						data: response.data,
					},
				});
				dispatch(global.Loader(false));
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: TAXFORMS_ACTION_TYPE.formDeleted,
					payload: {
						message: handleMeetingError("simple", error),
						status: false,
						data: {},
					},
				});
				dispatch(global.Loader(false));
			});
	};
}
// FUNCTION TO HANDLE MEETING API ERRORS
const handleMeetingError = (type, error) => {
	if (type === "simple") {
		return error.code === "ERR_NETWORK" ? error?.message : error?.response?.data?.error;
	}
};

export const taxForms = {
	moveToNext,
	moveToBack,
	openThisForm,
	openThisSideBar,
	StoreFormData,
	dispatchViewFormDetails,
	markMeCompleted,
	getForms,
	createNewForm,
	deleteFormById,
};
