import { useState } from "react";
import InputMask from "react-input-mask";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { IoShieldCheckmark } from "react-icons/io5";

export default function Input({
	type,
	value,
	name,
	secure,
	placeholder,
	required,
	label = placeholder,
	autoComplete,
	labelClass,
	inputClass,
	wrapperClass,
	fn,
	onLeave,
	hasValidationError,
	isDisabled = false,
	showLabel = true,
	formid = name,
	mask,
	extraSetter,
	max,
	min,
	verified,
	onClick,
	autoFocus = false,
	formatChars,
	
}) {
	console.log("VAL>>>",value);
	const [showPassword, setShowPassword] = useState(true);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className={`clearfix${wrapperClass ? ` ${wrapperClass}` : ""}`}>
			{showLabel && (
				<label htmlFor={name} className={`form-label${labelClass ? ` ${labelClass}` : ""}`}>
					{label + (required ? "*" : "")}
				</label>
			)}
			<InputMask
				type={secure ? (showPassword ? "password" : "text") : type}
				className={`form-control${inputClass ? ` ${inputClass}` : ""}${
					hasValidationError ? " is-invalid" : ""
				}`}
				placeholder={placeholder}
				aria-label={placeholder}
				value={value ? value : ""}
				required={required === false ? false : true}
				disabled={isDisabled}
				onChange={(e) => {
					fn(e.target.value ? e.target.value : "");
					if (extraSetter) {
						extraSetter();
					}
				}}
				onBlur={onLeave}
				id={formid}
				autoComplete={type === "password" ? "off" : autoComplete}
				mask={mask}
				maskChar={null}
				formatChars={formatChars}
				max={max}
				min={min}
				onClick={() => {
					if (onClick) {
						onClick();
					}
				}}
				autoFocus={autoFocus}
			/>
			{secure && (
				<div className='position-absolute top-0 end-0 p-2'>
					{showPassword ? (
						<BsEyeFill
							className="mt-1"
							color="grey"
							role="button"
							size={20}
							onClick={() => {
								togglePasswordVisibility();
							}}
						/>
					) : (
						<BsEyeSlashFill
							className="mt-1"
							color="grey"
							role="button"
							size={20}
							onClick={() => {
								togglePasswordVisibility();
							}}
						/>
					)}
				</div>
			)}
			{verified ? (
				<div className="position-absolute top-0 end-0 p-2">
					<IoShieldCheckmark size={20} color="#00D566" className="mb-1" />
				</div>
			) : (
				""
			)}
		</div>
	);
}
