/* eslint-disable no-unused-vars */
import React,{ Fragment, useEffect, useState } from 'react';
import SweetAlert2 from 'react-sweetalert2';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	setQueryParams,
	getQueryParam,
	removeQueryParam,
} from '../../../utilites';
import allActions from '../../../Store/action';
import ListPageWrapper from '../components/ListPageWrapper';
import Input from '../../../components/forms/fields/Input';
import Message from '../../../components/forms/fields/Message';
import PlusSvg from '../../../assets/img/plussvg.svg'
import editIcon from '../../../assets/img/edit1.svg'
import DeleteIcon2 from '../../../assets/img/delete1.svg'
import CreateNewForm from '../../../components/forms/CreateNewForm';
import {  FaEye } from 'react-icons/fa'; 
import OpenJobForm from '../../../components/forms/OpenJobForm';
import { FaRegClone } from "react-icons/fa6";
import { VscEye } from "react-icons/vsc";
import QuestionaireSvg from '../../../assets/img/quetenionairesvg.svg'
import Modal from '../../../components/Dashboard/Modal';
import Select from '../../../components/forms/fields/Select';
import axios from 'axios';
import { BASEURL } from '../../../utilites';





const ChangeFormStatus = ({handleChangeStatus,formToChangeStatus,setFormToChangeStatus}) => {
	const [status,setStatus] = useState(formToChangeStatus?.status ? formToChangeStatus?.status : '')
	useEffect(() => {
		if(Object.keys(formToChangeStatus)?.length > 0){
			setStatus(formToChangeStatus?.status)
		}
	},[formToChangeStatus])
	return <div className='mb-3'>
	<Select
		// eslint-disable-next-line eqeqeq
		value={status == 1 ? '1' : '0'}
		name='status'
		placeholder='User Status'
		showLabel={false}
		fn={(val) =>  setStatus(val)}
	>
		<option value='1'>Published</option>
		<option value='0'>Pending</option>
	</Select>
	<button className='btn btn-primary mt-3' onClick={() => {
		handleChangeStatus(formToChangeStatus?.id,status)
		setFormToChangeStatus('')
	}}>Submit</button>
</div>
}

const CloneForm = ({handleSaveTemplate,formToClone,setFormToClone}) => {
	const [formTitle,setFormTitle] = useState('')
	return <div className='mb-3'>
	<div htmlFor='clonetitle' className='text-start fw-normal mb-2 text-black fs-5'>New Form Name</div>
	<input className='form-control' id='clonetitle' value={formTitle} type='text' placeholder='Enter...' onChange={(e) => setFormTitle(e.target.value)} />
	<button className='btn btn-primary mt-3' disabled={formTitle?.length < 3} onClick={() => {
	  handleSaveTemplate(formTitle,formToClone)
	  setFormToClone({})
	  setFormTitle('')
	}}>Create Duplicate</button>
</div>
}

const CreateFormTitleModal = ({setCustomModalOpen,formTitle,setFormTitle,setCreateModalOpen}) => {
	return <div className="custom-modal-overlay" onClick={() => setCustomModalOpen(null)}>
		<div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
			<h2 className="custom-modal-heading">New questionnaire</h2>
			<div className="custom-modal-input-cont">
				  <label htmlFor="section-title" className='text-dark txt-sm'>Enter questionnaire name</label>
				  <input type="text" id="section-title" value={formTitle} onChange={(e) => setFormTitle(e.target.value)} />
			</div>
			<div className="custom-modal-buttons">
			  <button className="btn" onClick={() => {
				setCustomModalOpen(null)
			  }}>Cancel</button>
			  <button di className="btn btn-primary" onClick={() => {
				setCustomModalOpen(null)
				setCreateModalOpen({state:true,editmode:false})
			  }} disabled={formTitle?.length < 3}>Create</button>
			</div>
		</div>
	</div>;
  };
  const CreateFormModal = ({children,setCreateModalOpen,formTitle,setFormTitle}) => {
	return <div className="custom-modal-overlay" onClick={() => {
		document.body.style.overflowY = '';
	}}>
		<div className="custom-modal-cont-create-form" onClick={(e) => e.stopPropagation()}>
			{children}
		</div>
	</div>;
  };

const FormList = () => {
	const [{ loginStatus, LoginData }, { services },{forms,formsCreated}] = useSelector(
		(state) => [state.user, state.dashboard, state.taxForms]
	);
	const dispatch = useDispatch();
	const [alertMessage, setAlertMessage] = useState('');
	const [message, setMessage] = useState({ show: false });
	// const [swalProps, setSwalProps] = useState({});
	// const [selectedJob, setSelectedJob] = useState();
	const [keyword, setKeyword] = useState(null);
	const [filteredForms,setFilteredForms] = useState(null)
	const [service, setService] = useState(getQueryParam('service') || '');
	// const [status, setStatus] = useState(getQueryParam('status') || '');
	// const [pro, setPro] = useState(getQueryParam('taxpro') || '');
	// const [createdDate, setCreatedDate] = useState(
	// 	getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	// );
    const [customModalOpen,setCustomModalOpen] = useState(null)
    const [formTitle,setFormTitle] = useState('')
    const [createFormModalOpen,setCreateFormModalOpen] = useState({state:false,editmode:false})
    const [selectedDynamicForms,setSelectedDynamicForms] = useState([])
	const [activeFormData,setActiveFormData] = useState({data:customModalOpen?.data})
	const [selectedJob,setSelectedJob] = useState({})
	const [formToClone,setFormToClone] = useState({})
	const [formToChangeStatus,setFormToChangeStatus] = useState({})
	const [swalProps, setSwalProps] = useState({});


	
	// const [dynamicForms,setDynamicForms] = useState([])

	// const jobStatuses = [0, 1, 2, 3, 4];
	// Pagination
	// const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	// const [page, setPage] = useState(getQueryParam('page') || 1);

	const handleSaveTemplate = async (formTitle,taxForm) => {
		const endpoint =  "/create-dynamic-form-byadmin" 
			// changeLoading(true)
			try { 
			 await axios.post(`${BASEURL}/api/taxform/${endpoint}`,{form_data:{name:formTitle,data:taxForm,byAdmin: true}})
			dispatch(allActions.taxForms.getForms());
			setAlertMessage('The form is cloned')
			document.querySelector('.openCloneFormModal').click()
			} catch (error) {
		    setAlertMessage(error?.response?.data?.error?error?.response?.data?.error:'server error')
			  console.log(error);
			}
		}

		const handleChangeStatus = async (form_id,status) => {
			const endpoint =  "mark-dynamic-form-byadmin" 
				// changeLoading(true)
				try { 
				 await axios.patch(`${BASEURL}/api/taxform/${endpoint}`,{form_id,status_id:status})
				dispatch(allActions.taxForms.getForms());
				setAlertMessage('The form status has changed')
				setFormToChangeStatus('')
				document.querySelector('.openChangeFormStatusModal').click()
				} catch (error) {
				setAlertMessage(error?.response?.data?.error?error?.response?.data?.error:'server error')
				  console.log(error);
				}
			}

	useEffect(() => {
		if (LoginData.isAdmin || LoginData.isAccountManager) {
			dispatch(allActions.dashboard.getTaxProfessionals());
		}
	}, [dispatch, LoginData.isAdmin, LoginData.isAccountManager]);

	useEffect(() => {
		if (loginStatus) {
            dispatch(allActions.global.Loader(true))
			dispatch(allActions.taxForms.getForms());
		}
	}, [loginStatus, dispatch]);
	useEffect(() => {
		if(!createFormModalOpen) {
			dispatch(allActions.taxForms.getForms());
		}
	},[dispatch,createFormModalOpen])

	const deleteFormById = async(id) => {
    setSwalProps((prev) => ({
			...prev,
			[`ud-${id}`]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				text: 'Do you want to delete this form?',
				didClose: () =>{
          setSwalProps({})
        },
				onConfirm: () => {
          dispatch(allActions.global.Loader(true))
		      dispatch(allActions.taxForms.deleteFormById(id))
          setAlertMessage('Form has been deleted')
          setSwalProps({})
        },
			},
		}));
	}

    useEffect(() => {
		if (!forms.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: forms.message,
			});
		}
		if (forms.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, forms]);

    useEffect(() => {
		if (!formsCreated.status) {
			setMessage({
				show: true,
				type: 'Error',
				text: formsCreated.message,
			});
		}
	}, [dispatch, formsCreated]);

	const resetSelectedForms = async () => {
		setSelectedDynamicForms([])
	}
    
	useEffect(() => {
		if (selectedDynamicForms.length > 0 && selectedDynamicForms) {
			const data = selectedDynamicForms[0]?.data
			const name = selectedDynamicForms[0]?.name
			dispatch(allActions.global.Loader(true))
			dispatch(allActions.taxForms.createNewForm(name,data,resetSelectedForms))
		}
	}, [dispatch,selectedDynamicForms]);
	 
	// const moveTo = (page) => {
	// 	setPage(page);
	// 	// Set query param
	// 	setQueryParams('page', page);
	// };

	// const changePerPage = (perPage) => {
	// 	setPerPage(perPage);
	// 	// Set query param
	// 	setQueryParams('per_page', perPage);
	// };

	const filterByService = (serv) => {
		setService(serv);
		// Set query param
		if (!serv) {
			removeQueryParam('service');
		} else {
			setQueryParams('service', serv);
		}
	};

	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
	};

	useEffect(() => {
		if (keyword) {
			const data = forms.status===true && forms?.data && forms.data.filter((form) =>
			form?.form_data?.name
			  ?.toLowerCase()
			  ?.includes(keyword.toLowerCase())
		  )
		  if (data) { setFilteredForms(data) }
		  else { setFilteredForms(null) }
		}
		else { setFilteredForms(null) }

	}, [keyword,forms.status,forms.data])


	useEffect(()=> {
			if(alertMessage){
				setTimeout(() => {
					setAlertMessage('')
				},3000)
			}
	},[alertMessage])

	const Rows = ({forms}) => {
		return (
      forms &&
      forms?.map((form) => (
        <tr className="align-middle" key={form.id}>
          <th scope="row">{form.id}</th>
          <td className="text-md-start text-center ">
            <Link
              className="text-decoration-none d-flex align-items-center"
              onClick={() => {
                setActiveFormData(form?.form_data)
                setCustomModalOpen({
                  state: "openjobform",
                  data: form?.form_data,
                })
              }
            }
            >
              <img src={QuestionaireSvg} alt="file" />
              <div className="text-dark ps-1 mx-3">{`${form.form_data.name}`}</div>
            </Link>
          </td>
          <td width="30%">
            <div className="w-75 text-truncate">{`${form?.user?.firstname} ${form?.user?.lastname}`}</div>
          </td>
          <td width="30%">
            {form?.status === 0 ? (
              <Link
                data-bs-toggle="modal"
                data-bs-target="#openChangeFormStatusModal"
                aria-controls="openChangeFormStatusModal"
                className={`btn btn-grey-400 btn-sm text-white`}
                onClick={() => setFormToChangeStatus(form)}
              >
                Pending
              </Link>
            ) : (
              <Link
                className={`btn btn-success btn-sm text-white`}
                data-bs-toggle="modal"
                data-bs-target="#openChangeFormStatusModal"
                aria-controls="openChangeFormStatusModal"
                onClick={() => setFormToChangeStatus(form)}
              >
                Published
              </Link>
            )}
          </td>
          <td className="">
            <div className="d-flex gap-2">
              <div
                className="action-icon-cont"
                onClick={() => {
                  setActiveFormData(form?.form_data)
                  setCustomModalOpen({
                    state: "openjobform",
                    data: form?.form_data,
                  })
                }
              }
              >
                <VscEye color="#4B57E7" size={13} />
              </div>
              <img
                style={{ height: "30px" }}
                className="cursor-pointer"
                src={editIcon}
                alt="edit"
                onClick={() => {
					if(form?.form_data?.data !== undefined && Object.keys(form?.form_data?.data)?.length > 0) {
						setCreateFormModalOpen({
							state: true,
							editmode: false,
							adminEditMode: true,
							data: form?.form_data?.data,
							formId: form?.id,
							formTitle: form?.form_data?.name,
						})
						}else {
						setAlertMessage('The form is undefined or empty.')
					}
					}
				}

              />
              <Link
                className="action-icon-cont"
                data-bs-toggle="modal"
                data-bs-target="#openCloneFormModal"
                aria-controls="openCloneFormModal"
                onClick={() => setFormToClone(form?.form_data?.data)}
              >
                <FaRegClone color="#4B57E7" size={10} />
              </Link>
              <Link
                to={"#"}
                onClick={() => {
                  deleteFormById(form.id);
                }}
              >
                {swalProps[`ud-${form?.id}`]?.show && (
                  <SweetAlert2 {...swalProps[`ud-${form?.id}`]} />
                )}
                <img
                  style={{ height: "30px" }}
                  className="cursor-pointer"
                  src={DeleteIcon2}
                  alt="edit"
                />
              </Link>
            </div>
          </td>
        </tr>
      ))
    );
	}


	return (
    <>
      <ListPageWrapper
        heading="Forms"
        headerControls={
          <Fragment>
            {customModalOpen?.state === "openjobform" && (
              <CreateFormModal setCreateModalOpen={setCustomModalOpen}>
                <OpenJobForm
                  selectedJob={selectedJob}
                  setIsFormModalOpen={setCustomModalOpen}
                  activeFormData={activeFormData}
                  setActiveFormData={setActiveFormData}
                  previewMode={true}
                />
              </CreateFormModal>
            )}
            <div className="col-md-12">
              {message.show ? <Message message={message} /> : <></>}
              <div className="row g-2 justify-between">
                <div className="col-md-2">
                  <Input
                    type="search"
                    value={keyword}
                    name="name"
                    placeholder="Search by name"
                    fn={filterByKeyword}
                    showLabel={false}
                    inputClass="search-input border h-auto min-height-initial"
                    extraSetter={() => {
                      // if (page !== 1) moveTo(1);
                    }}
                    required={false}
                  />
                </div>
                <div className="col-md-2">
                  <select
                    className="form-select"
                    id="filter"
                    name="services"
                    onChange={(e) => {
                      filterByService(e.target.value);
                      // if (page !== 1) moveTo(1);
                    }}
                    value={service}
                  >
                    <option value="">Service</option>
                    {services?.map((service) => (
                      <option key={service.id} value={service.id}>
                        {service.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className={`col-md-${
                    LoginData.isAdmin ||
                    LoginData.isSupport ||
                    LoginData.isAccountManager
                      ? "8"
                      : "5"
                  }`}
                >
                  <Link
                    className="formButton rounded px-4 float-md-end float-none d-flex justify-content-center align-items-center gap-1"
                    onClick={() =>
                      setCustomModalOpen({ state: "formtitle", data: {} })
                    }
                  >
                    <img style={{ height: "15px" }} src={PlusSvg} alt="plus" />
                    Create new
                  </Link>
                </div>
              </div>
            </div>
          </Fragment>
        }
        alert={{ message: alertMessage, setMessage: setAlertMessage }}
        dataState={{
          loaded: true,
          fetching: false,
        }}
        tableHeader={
          <Fragment>
            <th scope="col">ID</th>
            <th scope="col">Form name</th>
            <th scope="col">Created by</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </Fragment>
        }
        tableBody={
          <Fragment>
            {filteredForms?.length === 0 ||
            (forms.status === true &&
              forms?.data &&
              forms?.data?.length === 0) ? (
              <tr>
                <td className="text-center opacity-50" colSpan={7}>
                  There are no forms available at this time
                </td>
              </tr>
            ) : (
              <Rows
                forms={
                  filteredForms
                    ? filteredForms
                    : forms.status === true && forms?.data
                }
              />
            )}
          </Fragment>
        }
        // paginationProps={{
        // 	pageCount: Math.ceil(forms?.data?.count / perPage),
        // 	currentPage: parseInt(page),
        // 	setCurrentPage: moveTo,
        // 	perPage: perPage,
        // 	setPerPage: (pc) => {
        // 		changePerPage(pc);
        // 		// Move to first page
        // 		if (page !== 1) moveTo(1);
        // 	},
        // }}
        modals={<></>}
      />
      <Modal
        title="Change form status"
        bodyClass="p-4 text-center text-dark text-opacity-50"
        trigger="openChangeFormStatusModal"
        showBtn={false}
        btnClass="openChangeFormStatusModal"
      >
        <ChangeFormStatus setAlertMessage={setAlertMessage} handleChangeStatus={handleChangeStatus} formToChangeStatus={formToChangeStatus} setFormToChangeStatus={setFormToChangeStatus}/>
      </Modal>

	  <Modal
        title="Duplicate Form"
        bodyClass="p-4 text-center text-dark text-opacity-50"
        trigger="openCloneFormModal"
        showBtn={false}
        btnClass="openCloneFormModal"
      >
        <CloneForm handleSaveTemplate={handleSaveTemplate} formToClone={formToClone} setFormToClone={setFormToClone} />
      </Modal>

      {customModalOpen?.state === "formtitle" && (
        <CreateFormTitleModal
          setCustomModalOpen={setCustomModalOpen}
          formTitle={formTitle}
          setFormTitle={setFormTitle}
          setCreateModalOpen={setCreateFormModalOpen}
        />
      )}
      {createFormModalOpen?.state && (
        <CreateFormModal setCreateModalOpen={setCreateFormModalOpen}>
          <CreateNewForm
            setSelectedDynamicForms={setSelectedDynamicForms}
            getDynamicForms={() => {}}
            formTitle={formTitle || createFormModalOpen?.formTitle}
            setFormTitle={setFormTitle}
            setCreateFormModalOpen={setCreateFormModalOpen}
            setCreateF={createFormModalOpen}
            editmode={createFormModalOpen?.editmode}
            adminEditMode={createFormModalOpen?.adminEditMode}
            formToEdit={createFormModalOpen?.data || {}}
            formId={createFormModalOpen?.formId}
            byAdmin={true}
          />
        </CreateFormModal>
      )}
    </>
  );
};

export default FormList;
