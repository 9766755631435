import { DASHBOARD_ACTION_TYPE } from './Type';

const DEFAULT_STATE = {
	services: [],
	roles: [],
	professionals: [],
	totalCounts: { leads: 0, completed: 0, earnings: 0 },
	chartData: {
		leadsCountData: {},
		leadsCountDataCompleted: {},
	},
	chartPeriod: 'year',
	recentLeads: [],
	calendarData: {},
	leads: { data: [], statistics: null, count: 0, fetching: false, loaded: false },
	disputes: { data: [], statistics: null, count: 0, fetching: false, loaded: false },
	jobs: { data: [], count: 0, fetching: false, loaded: false },
	projects: { data: [], count: 0, fetching: false, loaded: false },
	users: { data: [], count: 0, fetching: false, loaded: false },
	allUsers: { data: [], count: 0, fetching: false, loaded: false },
	documents: { data: [], count: 0, fetching: false, loaded: false },
	appointments: { data: [], statistics: null, count: 0, fetching: false, loaded: false },
	allExistingDocuments: [],
	folders: [],
	customFolders: [],
	upcomingAppointments: [],
	usersForAppointment: [],
	currentUserAppointments: [],
	avatars: [],
	documentNames: [],
	accountManagers: [],
	activities: [],
	stripeConnection: 'idle',
	stripeConnectionMessage: '',
	stripeAccountStatus: 'idle',
	stripeAccountStatusMessage: '',
	stripeAccountStatusData: '',
	onBoardUpdateStatus: 'idle',
	onBoardUpdateStatusMessage: '',
	availability: { message: '', state: 'idle' },
	profile: { message: '', state: 'idle' },
	customerModalDone: false,
	taxPlanning: { message: '', state: 'idle' },
	taxPreparation: { message: '', state: 'idle' },
	taxPreparationOnFormSave: { message: '', state: 'idle' },
	leadByUUID: { data: {}, state: 'idle', message: '' },
	calendlyVerification: { message: '', status: 'idle', data: {} },
	outlookCalendarVerification: { message: '', status: 'idle', data: {} },
	googleCalendarVerification: { message: '', status: 'idle', data: {} },
	createFileUpload: { message: '', status: 'idle', data: {} },
	updateJobStatusByTaxPro: { message: '', status: 'idle', data: {} },
	userAppointments: [],

};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const dashboard = (state = INITIAL_STATE, action) => {
	if (action.type === DASHBOARD_ACTION_TYPE.services) {
		return {
			...state,
			services: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.GET_USER_ROLES) {
		return {
			...state,
			roles: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.GET_TAX_PROFESSIONALS) {
		return {
			...state,
			professionals: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.TOTAL_COUNTS) {
		return {
			...state,
			totalCounts: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.CHART_DATA) {
		return {
			...state,
			chartData: action.payload.chartData,
			chartPeriod: action.payload.chartPeriod,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.RECENT_LEADS) {
		return {
			...state,
			recentLeads: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.CALENDAR_DATA) {
		return {
			...state,
			calendarData: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.LEADS_FETCHING_START) {
		return {
			...state,
			leads: { ...state.leads, fetching: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.DISPUTES_FETCHING_START) {
		return {
			...state,
			disputes: { ...state.disputes, fetching: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.JOBS_FETCHING_START) {
		return {
			...state,
			jobs: { ...state.jobs, fetching: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.PROJECTS_FETCHING_START) {
		return {
			...state,
			projects: { ...state.projects, fetching: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.USERS_FETCHING_START) {
		return {
			...state,
			users: { ...state.users, fetching: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.DOCUMENTS_FETCHING_START) {
		return {
			...state,
			documents: { ...state.documents, fetching: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.APPOINTMENTS_FETCHING_START) {
		return {
			...state,
			appointments: { ...state.appointments, fetching: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.LEADS) {
		return {
			...state,
			leads: {
				data: action.payload.data,
				count: action.payload.count,
				statistics: action.payload.statistics,
				fetching: false,
				loaded: true,
			},
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.DISPUTES) {
		return {
			...state,
			disputes: {
				data: action.payload.data,
				count: action.payload.count,
				statistics: action.payload.statistics,
				fetching: false,
				loaded: true,
			},
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.JOBS) {
		return {
			...state,
			jobs: {
				data: action.payload.data,
				count: action.payload.count,
				fetching: false,
				loaded: true,
			},
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.PROJECTS) {
		return {
			...state,
			projects: {
				...action.payload,
				fetching: false,
				loaded: true,
			},
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.USERS) {
		return {
			...state,
			users: { ...action.payload, fetching: false, loaded: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.ALL_USERS) {
		return {
			...state,
			allUsers: { ...action.payload, fetching: false, loaded: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.DOCUMENTS) {
		return {
			...state,
			documents: { ...action.payload, fetching: false, loaded: true },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.APPOINTMENTS) {
		return {
			...state,
			appointments: {
				data: action.payload.data,
				count: action.payload.count,
				statistics: action.payload.statistics,
				fetching: false,
				loaded: true,
			},
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.EXISTING_DOCUMENTS) {
		// Put only the documents that has unique path
		const uniqueDocuments = [];
		action.payload.forEach((document) => {
			if (!uniqueDocuments.find((uniqueDocument) => uniqueDocument.path === document.path)) {
				uniqueDocuments.push(document);
			}
		});
		return {
			...state,
			allExistingDocuments: uniqueDocuments,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.FOLDERS) {
		return {
			...state,
			folders: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.CUSTOM_FOLDERS) {
		return {
			...state,
			customFolders: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.UPCOMING_APPOINTMENTS) {
		return {
			...state,
			upcomingAppointments: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.USERS_FOR_APPOINTMENT) {
		return {
			...state,
			usersForAppointment: action.payload.users,
			currentUserAppointments: action.payload.appointments,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.AVATARS) {
		return {
			...state,
			avatars: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.DOCUMENT_NAMES) {
		return {
			...state,
			documentNames: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.ACCOUNT_MANAGERS) {
		return {
			...state,
			accountManagers: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.ACTIVITIES) {
		return {
			...state,
			activities: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.stripeConnection) {
		return {
			...state,
			stripeConnection: action.status,
			stripeConnectionMessage: action.message,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.stripeConnectionIdle) {
		return {
			...state,
			stripeConnection: 'idle',
			stripeConnectionMessage: '',
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.stripeAccountStatus) {
		return {
			...state,
			stripeAccountStatus: action.status,
			stripeAccountStatusMessage: action.message,
			stripeAccountStatusData: action.data,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.stripeAccountStatusIdle) {
		return {
			...state,
			stripeAccountStatus: 'idle',
			stripeAccountStatusMessage: '',
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.onBoardUpdateStatus) {
		return {
			...state,
			onBoardUpdateStatus: action.status,
			onBoardUpdateStatusMessage: action.message,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.AVAILABILITY) {
		return {
			...state,
			availability: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.AVAILABILITY) {
		return {
			...state,
			availability: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.AVAILABILITYIDLE) {
		return {
			...state,
			availability: DEFAULT_STATE.availability,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.PROFILE) {
		return {
			...state,
			profile: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.PROFILEIDLE) {
		return {
			...state,
			profile: DEFAULT_STATE.profile,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.onBoardUpdateStatusIdle) {
		return {
			...state,
			onBoardUpdateStatus: 'idle',
			onBoardUpdateStatusMessage: '',
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.customerModalDone) {
		return {
			...state,
			customerModalDone: true,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.taxPlanning) {
		return {
			...state,
			taxPlanning: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.taxPlanningIdle) {
		return {
			...state,
			taxPlanning: { message: '', state: 'idle' },
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.taxPreparation) {
		console.log('PAYLOAD', action.payload);
		return {
			...state,
			taxPreparation: action.payload,
		};
	}
	else if (action.type === DASHBOARD_ACTION_TYPE.taxPreparationOnFormSave) {
		console.log('PAYLOAD', action.payload);
		return {
			...state,
			taxPreparationOnFormSave: action.payload,
		};
	}
	else if (action.type === DASHBOARD_ACTION_TYPE.taxPreparationIdle) {
		return {
			...state,
			taxPreparation: { message: '', state: 'idle' },
		};

	}else if (action.type === DASHBOARD_ACTION_TYPE.taxPreparationOnFormSaveIdle) {
		return {
			...state,
			taxPreparationOnFormSave: { message: '', state: 'idle' },
		};

	}else if (action.type === DASHBOARD_ACTION_TYPE.leadByUUID) {
		return {
			...state,
			leadByUUID: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.leadByUUIDIdle) {
		return {
			...state,
			leadByUUID: DEFAULT_STATE.leadByUUID,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.RESET) {
		return { ...state, ...DEFAULT_STATE };
	} else if (action.type === DASHBOARD_ACTION_TYPE.calendlyVerificarionSuccess) {
		return {
			...state,
			calendlyVerification: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.calendlyVerificarionIDLE) {
		return {
			...state,
			calendlyVerification: DEFAULT_STATE.calendlyVerification,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.calendlyVerificarionError) {
		return {
			...state,
			calendlyVerification: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.outlookCalendarVerificarionSuccess) {
		return {
			...state,
			outlookCalendarVerification: action.payload,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.outlookCalendarVerificarionIDLE) {
		return {
			...state,
			outlookCalendarVerification: DEFAULT_STATE.outlookCalendarVerification,
		};
	} else if (action.type === DASHBOARD_ACTION_TYPE.outlookCalendarVerificarionError) {
		return {
			...state,
			outlookCalendarVerification: action.payload,
		};
	}
	//////////////////
    else if (action.type === DASHBOARD_ACTION_TYPE.googleCalendarVerificationSuccess) {
		return {
			...state,
			googleCalendarVerification: action.payload,
	};

    } else if (action.type === DASHBOARD_ACTION_TYPE.googleCalendarVerificationIDLE) {
		return {
			...state,
			googleCalendarVerification: DEFAULT_STATE.googleCalendarVerification,
	};	

    } else if (action.type === DASHBOARD_ACTION_TYPE.googleCalendarVerificationError) {
		return {
			...state,
			googleCalendarVerification: action.payload,
	};
}
	else if (action.type === DASHBOARD_ACTION_TYPE.USER_APPOINTMENT) {
		return {
			...state,
			userAppointments: action.payload,
		};
	}
	else if (action.type === DASHBOARD_ACTION_TYPE.createFileUpload) {
		return {
			...state,
			createFileUpload: action.payload,
		};
	}
	else if (action.type === DASHBOARD_ACTION_TYPE.updateJobStatusByTaxPro) {
		return {
			...state,
			updateJobStatusByTaxPro: action.payload,
		};
	}
	else if (action.type === DASHBOARD_ACTION_TYPE.updateJobStatusByTaxProIdle) {
		return {
			...state,
			updateJobStatusByTaxPro: DEFAULT_STATE.updateJobStatusByTaxPro,
		};
	}
	else if (action.type === DASHBOARD_ACTION_TYPE.createFileUploadIdle) {
		return {
			...state,
			createFileUpload: DEFAULT_STATE.createFileUpload,
		};
	}
	
	return state;
};

export default dashboard;
