/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
// Components
import Button from './fields/Button';
// Store
import { JOBS_ACTION_TYPE } from '../../Store/jobs/Type';
import allActions from '../../Store/action';
// Utilities
// PDFJS worker from an external cdn
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

export default function ReviewAndAcceptDraftDocumentsForm({ drafts, uuid, reload, setMessage }) {
	const dispatch = useDispatch();
	const button = useRef();
	const [{ markJobAsCompleted }] = useSelector((state) => [state.jobs]);

	useEffect(() => {
		if (markJobAsCompleted.status === true) {
			dispatch(allActions.global.Loader(false));
			document.querySelector('.ReviewAndAcceptDraftDocumentsModalClose').click();
		}
		else if (markJobAsCompleted.status === false) {
			console.log("@555555",markJobAsCompleted);
			
			dispatch(allActions.global.Loader(false));			
			setMessage({
				show: true,
				type: 'Error',
				text: markJobAsCompleted?.message,
			});
		}
	}, [markJobAsCompleted.status]);
	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		setMessage({ show: false });
		dispatch(allActions.global.Loader(true));
		dispatch({ type: JOBS_ACTION_TYPE.markJobAsCompletedIdle });
		dispatch(allActions.jobs.markJobAsCompleted({ uuid }));
	};
	return (
		<form className="documentsform needs-validation" onSubmit={handleSubmit}>
			<div className="clearfix">
				<p className="text-center text-black">
					The operator has submitted a request for final project completion. Please review the final
					deliverables and approve the request. Once submitted, the final payment in escrow will be
					released to the provider.
				</p>
			</div>
			{drafts && drafts.length > 0 ? (
				<div className="row justify-content-center mb-3">
					{drafts?.map((draft, index) => (
						<div className="col-md-3" key={index}>
							<div className="clearfix border border-light overflow-hidden">
								{draft.location.includes('.pdf') ? (
									<Document file={draft.path} className="card-img-top">
										<Page pageNumber={1} height={220} />
									</Document>
								) : (
									<img
										src={draft.path}
										className="card-img-top"
										alt={draft.name}
										style={{ height: 220, objectFit: 'cover' }}
									/>
								)}
								{/*<div className="card-body">
									<div className="clearfix">
										<FileTypeIcon extension={draft?.extension} size={20} />
										<h6
											className="card-title m-0 ms-1 d-inline-block text-truncate"
											style={{ maxWidth: "80%" }}
										>
											{draft.name}
										</h6>
								</div>
								</div>*/}
							</div>
						</div>
					))}
				</div>
			) : null}
			<div className="clearfix w-75 mx-auto my-4">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
					<label class="form-check-label text-black" for="flexCheckChecked">
						I've reviewed the final deliverables and approve the request for final project
					</label>
				</div>
			</div>
			<div className="d-flex justify-content-between align-items-center">
				<div className="btn btn-outline-primary" data-bs-dismiss="modal">
					Cancel
				</div>
				<div className="d-flex">
					<Button buttonRef={button} mainClass="btn btn-primary" buttonText="Approve Job" />
				</div>
			</div>
		</form>
	);
}
