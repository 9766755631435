/* eslint-disable no-unused-vars */
import { useState } from "react";

const EditGridRowTitleModal = ({
    editGridRowTitleModalOpen,
    setEditGridRowTitleModalOpen,
    gridData,
    setGridData,
   }) => {
     const [row, setRow] = useState(gridData?.rows?.find((_,idx) => idx === editGridRowTitleModalOpen?.data)?.label || '')
  
     const [errorMessage, setErrorMessage] = useState("");
     return (
       <div
         className="custom-modal-overlay"
         onClick={() => setEditGridRowTitleModalOpen(false)}
       >
         <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
           <h2 className="custom-modal-heading">Edit Row Title</h2>
           <p className="custom-modal-description">
           Enter new row title
           </p>
           <div className="custom-modal-input-cont">
             {errorMessage && <div className="text-danger">{errorMessage}</div>}
             <textarea
              className={errorMessage ? "border-1 border-danger form-control" : "form-control"}
              type="text"
              id="section-title"
              value={row}
              onChange={(e) => {
               setRow(e.target.value);
              }}
             >
  
             </textarea>
           </div>
           <div className="custom-modal-buttons">
             <button
               className="btn"
               onClick={() => {
                setEditGridRowTitleModalOpen(false);
               }}
             >
               Cancel
             </button>
             <button
               className="btn btn-primary"
               onClick={() => {
                const updated = gridData?.rows?.map((prev,idx) => {
                  if(idx === editGridRowTitleModalOpen?.data){
                    return {
                      ...prev,
                      label:row
                    }
                  }
                  return prev
                 })
                 setGridData((prev) => {
                  return {...prev,rows:updated}
                 })
                 setRow('')
                setEditGridRowTitleModalOpen(false)
               }}
               disabled={row?.length < 3}
             >
               Save
             </button>
           </div>
         </div>
       </div>
     );
   };


   export default EditGridRowTitleModal
  