export const CreateSubSectionTitleModal = ({
    setCustomModalOpen,
    customModalOpen,
    subSectionTitle,
    setSubSectionTitle,
    createNewSubSection,
  }) => {
    return (
      <div
      className="custom-modal-overlay"
      onClick={() => setCustomModalOpen(null)}
        >
        <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
          <h2 className="custom-modal-heading">Create a Sub-section</h2>
          <p className="custom-modal-description">
            Enter a single sentence description that best describes the purpose of
            this section.
          </p>
          <div className="custom-modal-input-cont">
            <label htmlFor="section-title">Enter section tittle</label>
            <input
              type="text"
              id="section-title"
              value={subSectionTitle}
              onChange={(e) => setSubSectionTitle(e.target.value)}
              />
          </div>
          <div className="custom-modal-buttons">
            <button
              className="btn"
              onClick={() => {
                setCustomModalOpen(null);
              }}
              >
              Cancel
            </button>
            <button
              di
              className="btn btn-primary"
              onClick={() => {
                createNewSubSection(customModalOpen?.data);
              }}
              disabled={subSectionTitle?.length < 3}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };


  export default CreateSubSectionTitleModal