import user from "./user/reducer";
import dashboard from "./dashboard/reducer";
import factAuth from "./2fact/reducer";
import payment from "./payment/reducer";
import global from "./global/reducer";
import invoice from "./invoice/reducer";
import firebase from "./firebase/reducer";
import call from "./call/reducer";
import plaid from "./plaid/reducer";
import jobs from "./jobs/reducer";
import taxForms from "./taxForms/reducer";
import payout from "./payout/reducer";
import sgMail from "./payout/reducer";
import { combineReducers } from "redux";
import Admin from "./Admin/reducer";
import membership from "./membership/reducer";
import tasks from "./tasks/reducer";
import recordings from "./recordings/reducer";
import documentGroup from "./documentsGroup/reducer";
import milestone from "./milestone/reducer";
const appReducer = combineReducers({
	user,
	dashboard,
	factAuth,
	payment,
	global,
	invoice,
	firebase,
	call,
	plaid,
	jobs,
	taxForms,
	payout,
	sgMail,
	Admin,
	membership,
	tasks,
	recordings,
	documentGroup,
	milestone
});
const rootReducer = (state, action) => {
	if (action.type === "USER_LOGOUT") {
		return appReducer(undefined, action);
	}
	return appReducer(state, action);
};
export default rootReducer;
