import {useState} from "react";

 const CustomAddCardOptionModal = ({
    CardFields,
    setCardFields,
    setAddCardDropdownOptionModalOpen,
    addCardDropdownOptionModalOpen
  }) => {
    const [optionName, setOptionName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    return (
      <div
        className="custom-modal-overlay"
        onClick={() => setAddCardDropdownOptionModalOpen(false)}
        >
        <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
          <h2 className="custom-modal-heading">Add option</h2>
          <p className="custom-modal-description">
            Enter new option for question
          </p>
          <div className="custom-modal-input-cont">
            <label htmlFor="section-title">Enter option tittle</label>
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
            <input
              className={errorMessage ? "border-1 border-danger" : ""}
              type="text"
              id="section-title"
              value={optionName}
              onChange={(e) => {
                setOptionName(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="custom-modal-buttons">
            <button
              className="btn"
              onClick={() => {
                setAddCardDropdownOptionModalOpen(false);
              }}
              >
              Cancel
            </button>
            <div
              di
              className="btn btn-primary"
              onClick={() => {
                let updatedCardFields = [...CardFields]
                 updatedCardFields = updatedCardFields?.map((field) => {
                  if(field?.label === addCardDropdownOptionModalOpen?.data){
                      return {
                        ...field,
                        questionData:[...field?.questionData,optionName]
                      }
                  }
                  return field
                 })
                 setCardFields(updatedCardFields)
                 setAddCardDropdownOptionModalOpen(false);
                 setOptionName('')
  
                // const isOptionAdded = customDropdownOptions?.find(
                //   (option) => option === optionName
                // );
  
                // if (!isOptionAdded) {
                //   setCustomDropdownOptions((prev) => [...prev, optionName]);
                //   setErrorMessage("");
                // } else {
                //   setErrorMessage("The option is added already!");
                // }
              }
            }
              disabled={optionName?.length < 3}
              >
              Save
            </div>
          </div>
        </div>
      </div>
    );
  };


  export default CustomAddCardOptionModal