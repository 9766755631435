import React, {useEffect, useState} from "react";
import EditMilestoneModal from "./EditMilestoneModal";
import DeleteIcon from "../../../assets/img/delete1.svg";
import EditIcon from "../../../assets/img/edit.svg";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import allActions from "../../../Store/action";
import Message from "../fields/Message";

export default function ReviewMilestone({ milestones,job_id,taxPro }) {
    const [{deleteMilestone}] = useSelector((state) => [state.milestone])
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentMilestone, setCurrentMilestone] = useState(null);
    const [isDeleteModalOpen,setIsDeleteModalOpen] = useState(false)
    const [message,setMessage] = useState(false)
    const dispatch = useDispatch();

    const handleEditMilestone = (milestone) => {
        setCurrentMilestone(milestone);
        setIsEditModalOpen(true);
    };

    const handleSaveMilestone = (updatedMilestone) => {
        // setMilestones(updatedMilestones);
        setIsEditModalOpen(false);
    };


    const handleSubmit = () => {
        console.log("Submitting milestones:", milestones);
        // Add your submit logic here
    };


    const handleDeleteMilestone = (milestone) => {
        setCurrentMilestone(milestone);
        setIsDeleteModalOpen(true);
    };

    const confirmDeleteMilestone = () => {
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.milestone.deleteMilestoneReset())
       dispatch(allActions.milestone.deleteMilestone(currentMilestone,taxPro));
    };

    useEffect(() => {
        if (deleteMilestone?.status === true) {
            setIsDeleteModalOpen(false)
            document.querySelector('.reviewMilestoneModalClose').click();
            dispatch(allActions.milestone.getJobMilestone(job_id))
            dispatch(allActions.global.Loader(false));

        }
       else if (deleteMilestone?.status === false) {
        setMessage({
			show: true,
			type: 'Error',
			text: deleteMilestone.message,
		});
            dispatch(allActions.global.Loader(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ deleteMilestone?.message, deleteMilestone?.state]);




    return (
			<div className="p-3">
				{message.show ? <Message message={message} /> : <></>}
				<div className="d-flex flex-column align-items-start gap-3">
					<h5 className="fw-bold">Review Milestones</h5>
					
					{milestones?.map((milestone) => (
						<div
							key={milestone.id}
							className="d-flex justify-content-between w-100 align-items-center border-bottom pb-2 mb-3"
						>
							<div className="d-flex flex-column text-start">
								<span className="fw-bold text-black">{milestone?.name}</span>
								<small className={'text-start'}>
									{dayjs(milestone?.invoices?.due_date).format('DD MMMM YYYY')}
								</small>
							</div>
							<div className="fw-bold">${milestone?.invoices?.amount}</div>
							<div>
								<span
									className={`badge ${
										milestone.status === 0 || milestone.status === 'pending-payment'
											? 'bg-warning text-dark'
											: milestone.status === 'paid'
											? 'bg-black'
											: milestone.status === 'escrow'
											? 'bg-dark-gradient'
											: 'bg-secondary'
									}`}
								>
									{milestone.status === 0 ? 'Escrow' : ''}
								</span>
							</div>
							{console.log('@99999', milestone)}
								<div className="d-flex gap-2">
							{!milestone.invoices?.payments[0]?.amount_paid && (
									<>
										<div onClick={() => handleEditMilestone(milestone)}>
											<img
												style={{ height: '35px' }}
												src={EditIcon}
												alt="edit"
												className="cursor-pointer"
											/>
										</div>
										<div onClick={() => handleDeleteMilestone(milestone.id)}>
											<img
												style={{ height: '35px' }}
												src={DeleteIcon}
												alt="delete"
												className="cursor-pointer"
											/>
										</div>
									</>
							)}
								</div>
						</div>
					))}

					<button
						data-bs-toggle="modal"
						data-bs-target="#addMilestoneModal"
						className="btn btn-outline-primary mt-2"
					>
						Create a new Milestone
					</button>

					<div className="w-100 mt-3 text-start text-black">
						<label htmlFor="additionalNotes" className="form-label text-black">
							Additional notes (Optional)
						</label>
						<textarea
							id="additionalNotes"
							className="form-control pb-5"
							placeholder="...."
						></textarea>
					</div>
					<div className="d-flex justify-content-end w-100 align-items-center mt-4">
						<button className="btn text-primary fw-bold" data-bs-dismiss="modal">
							Cancel
						</button>
						<button onClick={handleSubmit} className="btn btn-primary" data-bs-dismiss="modal">
							Submit
						</button>
					</div>
				</div>

				{isEditModalOpen && (
					<EditMilestoneModal
						milestone={currentMilestone}
						onSave={handleSaveMilestone}
						onCancel={() => setIsEditModalOpen(false)}
						job_id={job_id}
						taxPro={taxPro}
					/>
				)}
				{isDeleteModalOpen && (
					<div
						className="modal fade show"
						tabIndex="-1"
						style={{
							display: 'block',
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							zIndex: 1055,
						}}
						role="dialog"
					>
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div className="modal-content p-3">
								<div className="d-flex justify-content-between">
									<h5 className="modal-title text-black fw-bold">Delete Milestone</h5>
									<button
										type="button"
										className="btn-close"
										onClick={() => setIsDeleteModalOpen(false)}
									></button>
								</div>
								<div className="mt-3 text-start">
									<p className={'text-black p-0 mt-0'}>Are you sure you want to delete?</p>
								</div>
								<div className="d-flex justify-content-end">
									<button
										type="button"
										className="btn text-primary"
										onClick={() => setIsDeleteModalOpen(false)}
									>
										Cancel
									</button>
									<button type="button" className="btn btn-danger" onClick={confirmDeleteMilestone}>
										Yes
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
}
