import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
const MilestoneMessageCard = ({ data, index, milestoneData }) => {
	const [{ LoginData }] = useSelector((state) => [state.user]);
	const sender = data?.senderId === LoginData?.uuid;
	return (
		<div
			className={
				sender
					? 'bg-dark text-white  chat-item d-flex flex-column message-width'
					: 'bg-primary  bg-opacity-10  chat-item d-flex flex-column w-75 message-width'
			}
		>
			<font
				style={{ width: 'fit-content' }}
				className={
					sender
						? 'bg-dark text-white px-3 py-2 chat-item text-break fold-Text-small message-width'
						: 'bg-primary bg-opacity-10 px-3 py-2 chat-item text-break fold-Text-small message-content message-width'
				}
			>
				{milestoneData?.milestone_action === 'create-contract' ? (
					milestoneData?.milestones.map((v) => (
						<div className="milesstone-message-bg" style={{ marginTop: '6px' }}>
							<div className="d-flex gap-2">
								<p className="fw-bold">Milestone cost:</p>
								<span>$ {v?.amount}</span>
							</div>
							<div className="d-flex gap-2">
								<p className="fw-bold">Milestone:</p>
								<span>{v?.name}</span>
							</div>
							<div className="d-flex gap-2">
								<p className="fw-bold">DueDate:</p>
								<span>{moment(v.dueDate).format('D MMM YYYY')}</span>
							</div>
							<a href={LoginData.isCustomer ? `projects` : `jobs`} className="fw-bold">
								View Details
							</a>
						</div>
					))
				) : milestoneData?.milestone_action === 'contract_signed' || milestoneData?.milestone_action === 'contract-update' ? (
					<div className="milesstone-message-bg d-flex align-items-end height-100">
						<a href={LoginData.isCustomer ? `projects` : `jobs`} className="fw-bold">
							View Contract
						</a>
					</div>
				) : (
					<div className="milesstone-message-bg">
						<div className="d-flex gap-2">
							<p className="fw-bold">Milestone cost:</p>
							<span>$ {milestoneData?.amount}</span>
						</div>
						<div className="d-flex gap-2">
							<p className="fw-bold">Milestone:</p>
							<span>{milestoneData?.name} </span>
						</div>
						<div className="d-flex gap-2">
							<p className="fw-bold">DueDate:</p>
							<span>{moment(milestoneData?.due_date).format('D MMM YYYY')}</span>
						</div>
						<a href={LoginData.isCustomer ? `projects` : `jobs`} className="fw-bold">
							View Details
						</a>
					</div>
				)}
			</font>
		</div>
	);
};
export default MilestoneMessageCard;
