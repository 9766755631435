import React, { useState } from 'react'
import ReactSelect from 'react-select'
import { americanStatesData, countriesData } from '../StaticData'
import Input from '../../../fields/Input'

const ShareholdersList = ({corporation,
                              setAddShareholder,
                          question,shareholderToEdit,setShareholderToEdit,handleSaveShareholder,handleSaveEditedShareholder
                      }) => {

    const [partner, setPartner] = useState(shareholderToEdit?.state ? shareholderToEdit?.data : {})

    const handleChange = (field, value) => {
        setPartner(prevPartner => ({
            ...prevPartner,
            [field]: value
        }))
    }


    return (
        <div className="mt-3">
            <div className="fs-5">Shareholder information</div>
            <hr />
            <div className="fw-bold">Shareholder type</div>
            <div className="w-50 mt-2">
                <ReactSelect
                    options={corporation === 'S-Corporation' ? [
                        { label: "Individual", value: "Individual" },
                        { label: "Estate", value: "Estate" },
                        { label: "Trust", value: "Trust" },
                        { label: "Exempt organization", value: "Exempt organization" },
                        { label: "Disregarded entity", value: "Disregarded entity" },

                    ] : [
                        { label: "Individual", value: "Individual" },
                        { label: "Corporation", value: "Corporation" },
                        { label: "Estate", value: "Estate" },
                        { label: "Partnership", value: "Partnership" },
                        { label: "Trust", value: "Trust" },
                        { label: "Other", value: "Other" },

                    ]}
                    className="question-input"
                    onChange={(option) => handleChange("type_of_entity", option.value)}
                    value={
                        partner?.type_of_entity
                            ? {
                                label: partner.type_of_entity,
                                value: partner.type_of_entity,
                            }
                            : null
                    }
                />
            </div>
            {(partner?.type_of_entity === "Individual" || partner?.type_of_entity === 'Disregarded entity') && (
                <>
                    <div className="fw-bold mt-3">First name</div>
                    <div className="w-50 mt-2">
                        <input
                            type="text"
                            className="w-full form-control"
                            onChange={(e) => handleChange("firstname", e.target.value)}
                            value={partner?.firstname || ""}
                        />
                    </div>
                    <div className="fw-bold mt-3">Middle initial</div>
                    <div className="w-50 mt-2">
                        <input
                            type="text"
                            className="w-full form-control"
                            onChange={(e) => handleChange("middle_initial", e.target.value)}
                            value={partner?.middle_initial || ""}
                        />
                    </div>
                    <div className="fw-bold mt-3">Last name</div>
                    <div className="w-50 mt-2">
                        <input
                            type="text"
                            className="w-full form-control"
                            onChange={(e) => handleChange("lastname", e.target.value)}
                            value={partner?.lastname || ""}
                        />
                    </div>
                </>
            )}
            {(partner?.type_of_entity === "Disregarded entity") && <>
                <div className="fw-bold mt-3">Country of citizenship</div>
                <div className="w-50 mt-2">
                    <ReactSelect
                        options={countriesData?.map((country) => ({
                            label: country,
                            value: country,
                        }))}
                        className="question-input"
                        onChange={(option) => handleChange("citizenship", option.value)}
                        value={
                            partner?.citizenship
                                ? {label: partner.citizenship, value: partner.citizenship}
                                : null
                        }
                    />
                </div>
            </>}
            {(partner?.type_of_entity === "Other") && (
                <>
                    <div className="fw-bold mt-3">Specify type of entity</div>
                    <div className="w-50 mt-2">
                        <input
                            type="text"
                            className="w-full form-control"
                            onChange={(e) => handleChange("specified_entity_type", e.target.value)}
                            value={partner?.specified_entity_type || ""}
                        />
                    </div>
                </>
            )}

            {(partner?.type_of_entity !== "Individual" || partner?.type_of_entity === 'Disregarded Entity') && (
                <>
                    <div className="fw-bold mt-3">Entity name</div>
                    <div className="w-50 mt-2">
                        <input
                            type="text"
                            className="w-full form-control"
                            onChange={(e) => handleChange("entity_name", e.target.value)}
                            value={partner?.entity_name || ""}
                        />
                    </div>
                </>
            )}
            {partner?.type_of_entity !== "Individual" && (
                <>
                    <div className="fw-bold mt-3">EIN</div>
                    <div className="w-50 mt-2">
                        <Input
                            className={`form-control`}
                            value={partner?.ein || ""}
                            fn={(e) => {
                                const inputValue = e;
                                handleChange("ein", inputValue);
                            }}
                            placeholder=""
                            showLabel={false}
                            mask={"99-99999999"}
                            max={10}
                        />
                    </div>
                </>
            )}
            {(partner?.type_of_entity === "Individual") && (
                <>
                    <div className="fw-bold mt-3">SSN</div>
                    <div className="w-50 mt-2">
                        <Input
                            className={`form-control`}
                            value={partner?.ssn || ""}
                            fn={(e) => {
                                const inputValue = e;
                                handleChange("ssn", inputValue);
                            }}
                            //   type={"number"}
                            //   hasValidationError={validationErrors[index]}
                            placeholder=""
                            showLabel={false}
                            mask={"999-99-9999"}
                            //   formatChars={form?.validation === 'zipcode' ? {
                            //     9: "[0-9-]",
                            //   } : null}
                            max={10}
                        />
                    </div>
                </>
            )}
            {(partner?.type_of_entity === "Individual") ? (
                <>
                    <div className="fw-bold mt-3">Country of citizenship</div>
                    <div className="w-50 mt-2">
                        <ReactSelect
                            options={countriesData?.map((country) => ({
                                label: country,
                                value: country,
                            }))}
                            className="question-input"
                            onChange={(option) => handleChange("citizenship", option.value)}
                            value={
                                partner?.citizenship
                                    ? { label: partner.citizenship, value: partner.citizenship }
                                    : null
                            }
                        />
                    </div>
                </>
            ) : <>
                <div className="fw-bold mt-3">Country of organization</div>
                <div className="w-50 mt-2">
                    <ReactSelect
                        options={countriesData?.map((country) => ({
                            label: country,
                            value: country,
                        }))}
                        className="question-input"
                        onChange={(option) => handleChange("countryOfOrganization", option.value)}
                        value={
                            partner?.citizenship
                                ? { label: partner.countryOfOrganization, value: partner.countryOfOrganization }
                                : null
                        }
                    />
                </div>
            </> }

            {partner?.partner_nationality === "foreign_partner" && (
                <>
                    <div className="fw-bold mt-3">Country of citizenship</div>
                    <div className="w-50 mt-2">
                        <ReactSelect
                            options={countriesData?.map((country) => ({
                                label: country,
                                value: country,
                            }))}
                            className="question-input"
                            onChange={(option) => handleChange("citizenship", option.value)}
                            value={
                                partner?.citizenship
                                    ? { label: partner.citizenship, value: partner.citizenship }
                                    : null
                            }
                        />
                    </div>
                </>
            )}
            {partner?.type_of_entity === "individual" && (
                <div className="d-flex flex-column gap-2">
                    <div className="fw-bold mt-3">Select if applicable</div>
                    <div className="d-flex align-items-center gap-2">
                        <input
                            className="cursor-pointer"
                            type="checkbox"
                            onChange={(e) =>
                                handleChange("will_sign_tax_return", e.target.checked)
                            }
                            checked={partner?.will_sign_tax_return || false}
                        />
                        <div>This partner will sign the tax return</div>
                    </div>
                </div>
            )}
            <div className="fs-5 mt-5">Shareholder Contact</div>
            <hr />
            <div className="fw-bold mt-3">Email</div>
            <div className="w-50 mt-2">
                <input
                    type="text"
                    className="w-full form-control"
                    onChange={(e) => handleChange("email", e.target.value)}
                    value={partner?.email || ""}
                />
            </div>
            <div className="fw-bold mt-3">Phone number</div>
            <div className="w-50 mt-2">
                <Input
                    className={`form-control`}
                    value={partner?.phone_number || ""}
                    fn={(e) => {
                        const inputValue = e;
                        handleChange("phone_number", inputValue);
                    }}
                    placeholder=""
                    showLabel={false}
                    mask={"(999) 999-9999"}
                    max={10}
                />
            </div>
            <div className="fw-bold mt-3">Type of address</div>
            <div className="w-50 mt-2">
                <ReactSelect
                    options={[
                        { label: "US", value: "US" },
                        { label: "Foreign", value: "Foreign" },
                    ]}
                    className="question-input"
                    onChange={(option) => handleChange("address_type", option.value)}
                    value={
                        partner?.address_type
                            ? { label: partner.address_type, value: partner.address_type }
                            : null
                    }
                />
            </div>
            <div className="fw-bold mt-3">Street</div>
            <div className="w-50 mt-2">
                <input
                    type="text"
                    className="w-full form-control"
                    onChange={(e) => handleChange("street", e.target.value)}
                    value={partner?.street || ""}
                />
            </div>
            <div className="fw-bold mt-3">City</div>
            <div className="w-50 mt-2">
                <input
                    type="text"
                    className="w-full form-control"
                    onChange={(e) => handleChange("city", e.target.value)}
                    value={partner?.city || ""}
                />
            </div>
            {partner?.address_type === "Foreign" && (
                <>
                    <div className="fw-bold mt-3">Province</div>
                    <div className="w-50 mt-2">
                        <input
                            type="text"
                            className="w-full form-control"
                            onChange={(e) => handleChange("province", e.target.value)}
                            value={partner?.province || ""}
                        />
                    </div>
                    <div className="fw-bold mt-3">Postal code</div>
                    <div className="w-50 mt-2">
                        <input
                            type="number"
                            className="w-full form-control"
                            onChange={(e) => handleChange("postal_code", e.target.value)}
                            value={partner?.postal_code || ""}
                        />
                    </div>
                    <div className="fw-bold mt-3">Country</div>
                    <div className="w-50 mt-2">
                        <ReactSelect
                            options={countriesData?.map((country) => ({
                                label: country,
                                value: country,
                            }))}
                            className="question-input"
                            onChange={(option) => handleChange("country", option.value)}
                            value={
                                partner?.country
                                    ? { label: partner.country, value: partner.country }
                                    : null
                            }
                        />
                    </div>
                </>
            )}
            {partner?.address_type === "US" && (
                <>
                    <div className="fw-bold mt-3">State</div>
                    <div className="w-50 mt-2">
                        <ReactSelect
                            options={americanStatesData?.map((state) => ({
                                label: state,
                                value: state,
                            }))}
                            className="question-input"
                            onChange={(option) => handleChange("state", option.value)}
                            value={
                                partner?.state
                                    ? { label: partner.state, value: partner.state }
                                    : null
                            }
                        />
                    </div>
                    <div className="fw-bold mt-3">ZIP Code</div>
                    <div className="w-50 mt-2">
                        <Input
                            className={`form-control`}
                            value={partner?.zip_code || ""}
                            fn={(e) => {
                                const inputValue = e;
                                handleChange("zip_code", inputValue);
                            }}
                            placeholder=""
                            showLabel={false}
                            mask={"99999999"}
                            formatChars={{
                                9: "[0-9-]",
                            }}
                            max={10}
                        />
                    </div>
                </>
            )}
            <div className="fs-5 mt-5">Shareholder ownership</div>
            <hr />
            {corporation === 'S-Corporation' ? (
                <>
                    <div className="fw-bold mt-3">
                        Shares owned at beginning of year
                    </div>
                    <div className="w-50 mt-2">
                        <input
                            type="number"
                            className="w-full form-control"
                            onChange={(e) =>
                                handleChange("sharesStartYear", e.target.value)
                            }
                            value={partner?.sharesStartYear || ""}
                        />
                    </div>
                    <div className="fw-bold mt-3">
                        Shares owned at end of year
                    </div>
                    <div className="w-50 mt-2">
                        <input
                            type="number"
                            className="w-full form-control"
                            onChange={(e) =>
                                handleChange("sharesEndYear", e.target.value)
                            }
                            value={partner?.sharesEndYear || ""}
                        />
                    </div>
                    <div className="fw-bold mt-3">
                        Capital contributions during the year
                    </div>
                    <div className="w-50 mt-2">
                        <input
                            type="number"
                            className="w-full form-control"
                            onChange={(e) =>
                                handleChange("contributionsStartYear", e.target.value)
                            }
                            value={partner?.contributionsStartYear || ""}
                        />
                    </div>
                    <div className="fw-bold mt-3">
                        Distributions during the year
                    </div>
                    <div className="w-50 mt-2">
                        <input
                            type="number"
                            className="w-full form-control"
                            onChange={(e) =>
                                handleChange("contributionsDuringYear", e.target.value)
                            }
                            value={partner?.contributionsDuringYear || ""}
                        />
                    </div>
                </>
            ) : <>
                <div className="fw-bold mt-3">
                    Percentage of voting stock owned
                </div>
                <div className="w-50 mt-2">
                    <input
                        type="number"
                        className="w-full form-control"
                        onChange={(e) =>
                            handleChange("percentageStockOwned", e.target.value)
                        }
                        value={partner?.percentageStockOwned || ""}
                    />
                </div>
            </>}


            <div className="d-flex gap-3 justify-content-center py-3">
                {!shareholderToEdit?.state ? (
                    <div
                        className="btn btn-primary text-center mt-3 fw-bold cursor-pointer"
                        onClick={() => handleSaveShareholder(partner, question)}
                    >
                        Save
                    </div>
                ) : (
                    <div
                        className="btn btn-primary text-center mt-3 fw-bold cursor-pointer"
                        onClick={() => handleSaveEditedShareholder(partner, question)}
                    >
                        Save edited
                    </div>
                )}
                <div
                    className="btn btn-danger text-center mt-3 fw-bold cursor-pointer"
                    onClick={() => {
                        setAddShareholder(false);
                        setShareholderToEdit({ state: false, data: null });
                    }}
                >
                    Cancel
                </div>
            </div>
        </div>
    );
}

export default ShareholdersList
