import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
// Message Cards import
import TextCard from './MessageCards/text';
import VideoCall from './MessageCards/videoCall';
import Modal from '../Dashboard/Modal';
import { Link } from 'react-router-dom';
import { BsThreeDots } from 'react-icons/bs';
import MilestoneMessageCard from './MessageCards/milestoneMessage';




const MessageCard = ({ data, index, chatUsers, group, editId, setEditId, setEditText }) => {
	const [{ LoginData }] = useSelector((state) => [state.user]);
	const sender = data?.senderId === LoginData?.uuid;
	if (document.getElementById(`mesasage-Input-area${index}`)) {
		document.getElementById(`mesasage-Input-area${index}`).innerHTML = data?.body;
	}
const senderDetail=()=>{
	// RETRIVE SENDER USER DETAILS
	const senderUser = chatUsers?.find((user) => user?.uuid === data?.senderId);
	return  senderUser
//
}

const mileStoneJsonData=(data)=>{
	const decodedDataBody = JSON.parse(window.atob(data?.body));
     return decodedDataBody
}
	  const groupsender = chatUsers.find((value) => value?.uuid === data?.senderId);
	return (
		<article className="clearfix d-block mb-3 " key={index}>
			<div className={sender ? 'clearfix float-end chats' : 'clearfix float-start chats'}>
				<div className="clearfix mb-2">
					<small
						className={
							sender
								? 'float-end small-text text-muted text-responsive fold-Text-small'
								: 'float-start small-text text-muted text-responsive fold-Text-small'
						}
					>
						{group && groupsender?.firstname + ' ' + groupsender?.lastname}{' '}
						<div className={`d-flex gap-2 align-items-center ${sender ? 'mx-4' : ''}`}>
						{editId === data?.docId ? <div>Edit mode</div> : ''}
						<div className='edit-text'>{data?.updatedAt && 'edited'}</div>
						{ data?.messageType === 'milestone' &&
						<>
							{ mileStoneJsonData(data)?.milestone_action  === 'create' && <div className={'fs-6'}><span className={'fw-bold'}>{senderDetail(data)?.firstname || (!LoginData.isAdmin && (LoginData?.firstname))}</span>has added a new milestone</div>}
						    { mileStoneJsonData(data)?.milestone_action  === 'fund' && <div className={'fs-6'}><span className={'fw-bold'}>{senderDetail(data)?.firstname || (!LoginData.isAdmin && (LoginData?.firstname))}</span> funded the milestone</div>}
							{ mileStoneJsonData(data)?.milestone_action  === 'submit' && <div className={'fs-6'}><span className={'fw-bold'}>{senderDetail(data)?.firstname || (!LoginData.isAdmin && (LoginData?.firstname))}</span> submitted a milestone for approval</div>}
							{ mileStoneJsonData(data)?.milestone_action  === 'approve' && <div className={'fs-6'}><span className={'fw-bold'}>{senderDetail(data)?.firstname || (!LoginData.isAdmin && (LoginData?.firstname))}</span> approved the milestone</div>}
							{mileStoneJsonData(data)?.milestone_action  === 'create-contract' && <div className={'fs-6'}><span className={'fw-bold'}>{senderDetail(data)?.firstname || (!LoginData.isAdmin && (LoginData?.firstname))}</span> sent a contract</div>}
							{mileStoneJsonData(data)?.milestone_action  === 'contract_signed' && <div className={'fs-6'}><span className={'fw-bold'}>{senderDetail(data)?.firstname || (!LoginData.isAdmin && (LoginData?.firstname))}</span> signed & accepted a contract</div>}
							{ mileStoneJsonData(data)?.milestone_action  === 'contract-update' && <div className={'fs-6'}><span className={'fw-bold'}>{senderDetail(data)?.firstname || (!LoginData.isAdmin && (LoginData?.firstname))}</span> updated the contract</div>}

						</>
						}
						<div>{data?.updatedAt ? moment(new Date(data?.updatedAt.seconds * 1000 + data.updatedAt.nanoseconds / 1000000)).fromNow() : moment(data?.createdAt).fromNow()}</div>
						</div>
						
					</small>
				</div>
				<div
					className={
						sender
							? 'clearfix d-flex align-items-center justify-content-end '
							: 'clearfix d-flex align-items-center justify-content-start '
					}
				>
					{/* Differentiate according to message Type  */}
					{data?.messageType === 'simple' && <TextCard data={data} index={index} />}
					{data?.messageType === 'meeting/video' && (
						<VideoCall data={data} index={index} chatUsers={chatUsers} />
					)}
						{data?.messageType === 'milestone' && (
						<MilestoneMessageCard data={data} index={index} chatUsers={chatUsers} milestoneData={mileStoneJsonData(data)}/>
					)}
				{sender && <button
					type="button"
					className={`border-0 bg-transparent`}
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<BsThreeDots className="" style={{transform:'rotate(90deg)'}} />
				</button>}
				<ul className="dropdown-menu border-light">
					<li>
						<Link className={`dropdown-item py-2`} onClick={() => {
							setEditId(data?.docId)
							setEditText(data?.body)
						}}>
							Edit
						</Link>
					</li>
					{/* <li>
						<Link className={`dropdown-item text-danger py-2`}
							to="#"
							data-bs-toggle="modal"
							data-bs-target="#removeCard"
						>
							Remove
						</Link>
					</li> */}
				</ul>
				</div>
				<div
					className={
						sender
							? 'w-100 d-flex flex-wrap flex-row justify-content-end'
							: 'w-100 d-flex flex-wrap flex-row justify-content-start'
					}
				>
					{data.messageType === 'simple' &&
						data.images &&
						data.images?.map((imgObj) => {
							const modalID = `viewDocumentModal-${index}`;
							return (

								<div>

									<Modal
										title="View Document"
										bodyClass="px-5 py-4 text-center"
										trigger={modalID}
										showBtn={false}
										backdrop={''}
										size="lg"
									>
										<img
											src={imgObj.downloadURL}
											alt={imgObj.path}
											className="w-100 img-fluid rounded"
											style={{ objectFit: 'contain' }}
										/>
									</Modal>
										<Link
											to="#"
											className={`dropdown-item`}
											data-bs-toggle="modal"
											data-bs-target={`#${modalID}`}
											
										>
											<img
												src={imgObj.downloadURL}
												alt={imgObj.path}
												className="image-message  bg-transparent rounded cursor-pointer"
											/>
										</Link>
									</div>
							);
						})}
				</div>
			</div>
			
		</article>
	);
};

export default MessageCard;
