import {useEffect, useState} from 'react';
import dayjs from "dayjs";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import allActions from "../../../Store/action";
import { useDispatch } from "react-redux";
import Message from '../fields/Message';
import { useAutoReplyHook } from '../../../utilites';

export default function AddMilestone({ lead, job, milestone }) {
    const [{ createMilestone },{ LoginData }, { personalConnections }] = useSelector((state) => [ state.milestone,state.user,state.firebase]);
    const [amount, setAmount] = useState('');
    const [dueDate, setDueDate] = useState(null);
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');

    const [errors, setErrors] = useState(false);
    const dispatch = useDispatch();
    const [message, setMessage] = useState({ show: false });

    const handleAutoReply = useAutoReplyHook(dispatch);


    const validateInputs = () => {
        if (!amount || amount <= 0 || !dueDate || !description.trim() || !name.trim()) {
            setErrors(true);
            return false;
        }
        setErrors(false);
        return true;
    };

    const handleSaveMilestone = (e) => {
        e.preventDefault();
        if (!validateInputs()) return;
        dispatch(allActions.milestone.createMilestoneReset())
        dispatch(allActions.global.Loader(true));
        dispatch(allActions.milestone.createMilestone({
            job_id: job?.id,
            status_id: "0",
            user_id: lead?.user?.id,
            amount: amount,
            dueDate: dueDate,
            name: name,
            description:description,
            pro_id:LoginData.isAdmin &&job?.pro_id
        }));
    };

    useEffect(() => {
        if(createMilestone?.status === true){
            if(amount && description &&   dueDate && name){          
            var encodedString = window.btoa(
				JSON.stringify({
					amount: amount,
					name: name,
                    description:description,
					due_date: dueDate,
                    milestone_action:'create'				
				})
			);
            handleAutoReply({
                personalConnections,
                LoginData,
                receiver: lead?.user,
                type: "milestone",
                encodedString: encodedString,
              });
            }
        dispatch(allActions.global.Loader(false));
            setAmount('')
            setDueDate(null)
            setDescription('')
            setName('')
            document.querySelector('.addMilestoneModalClose').click();
            dispatch(allActions.milestone.getJobMilestone(job?.id,job?.pro_id))
            dispatch(allActions.milestone.createMilestoneReset())
}
        else if(createMilestone?.status === false){
            dispatch(allActions.global.Loader(false));
            setMessage({
                show: true,
                type: 'Error',
                text: createMilestone.message,
            });
        }       

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[createMilestone])


    return (
        <div className={'p-3'}>
            <form>
            {message.show ? <Message message={message} /> : <></>}

                {errors && (
                    <div className="alert alert-danger w-100" role="alert">
                        Please ensure all fields are filled.
                    </div>
                )}
                <div className="d-flex flex-column align-items-start gap-3">
                    <div className="">
                        <p className="text-start text-black mt-0 p-0 fs-5 text-secondary-emphasis fw-bold">
                            {milestone?.name}
                        </p>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start gap-2">
                        <label className="text-start fs-5 text-black" htmlFor="milestonePrice">
                            Name
                        </label>
                        <input
                            className={`form-control w-100 ${errors && (!amount || amount <= 0) ? 'is-invalid' : ''}`}
                            placeholder={`...`}
                            id="milestonePrice"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start gap-2">
                        <label className="text-start fs-5 text-black" htmlFor="milestonePrice">
                            Amount
                        </label>
                        <input
                            className={`form-control w-100 ${errors && (!amount || amount <= 0) ? 'is-invalid' : ''}`}
                            placeholder={`...`}
                            type="number"
                            id="milestonePrice"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </div>
                    <div className="w-100 d-flex flex-column align-items-start gap-2 mt-3">
                        <label className="text-start fs-5 text-black" htmlFor="milestoneDueDate">
                            Milestone due date
                        </label>
                        <DatePicker
                            selected={dueDate}
                            onChange={(date) => setDueDate(dayjs(date).format("YYYY-MM-DD"))}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            className={`form-control custom-datepicker-popper ${errors && !dueDate ? 'is-invalid' : ''}`}
                            wrapperClassName="d-block"
                            disabledDate={(current) => {
                                return current && current.isBefore(dayjs().startOf('day'));
                            }}
                        />

                    </div>
                    <div className="w-100 d-flex flex-column align-items-start gap-2 mt-3">
                        <label className="text-start fs-5 text-black" htmlFor="milestoneDescription">
                            Description
                        </label>
                        <textarea
                            placeholder="..."
                            className={`form-control pb-5 ${errors && !description.trim() ? 'is-invalid' : ''}`}
                            id="milestoneDescription"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <div className="d-flex justify-content-end w-100 align-items-center">
                        <button type="button" className="btn text-primary fw-bold" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            onClick={handleSaveMilestone}
                            className="btn btn-primary"
                            type="submit"
                            // data-bs-dismiss={errors ? undefined : "modal"}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
