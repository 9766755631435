/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert2 from 'react-sweetalert2';
import allActions from '../../../../Store/action';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GrDocumentUser } from 'react-icons/gr';
import { AiOutlineCheck } from 'react-icons/ai';
import { FileTypeIcon } from '../../../../utilites';
import Alert from '../../components/Alert';
import Modal from '../../../../components/Dashboard/Modal';
// import RequestJobDocumentForm from '../../../../components/forms/RequestJobDocumentForm';
import RenderMultiPagePDF from '../../components/RenderMultiPagePDF';
import Message from '../../../../components/forms/fields/Message';

const CustomerDocuments = ({ pro_id, customer_id, data, reloadData }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [alertMessage, setAlertMessage] = useState('');
	// const [selectedData] = useState({});
	const [documentToView, setDocumentToView] = useState();
	const [message, setMessage] = useState({ show: false });
	const [swalProps, setSwalProps] = useState({});
	const [{ jobs }] = useSelector((state) => [state.jobs, state.user]);

	useEffect(() => {
		// if Status True
		if (jobs.status === true) {
			changeLoading(false);
		}

		// if Status False
		if (jobs.status === false) {
			changeLoading(false);
			setMessage({
				show: true,
				type: 'Error',
				text: jobs?.message,
			});
		}
		if (jobs.status === 'idle') {
			dispatch(allActions.jobs.getJobsByTaxPro({ uuid: data?.uuid }));
		}
	}, [jobs.status, jobs.message, jobs?.data]);

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	const deleteDocument = (uuid) => {
		dispatch(allActions.dashboard.deleteDocument(uuid, customer_id))
			.then(() => {
				// TODO: Improve this
				setTimeout(() => {
					reloadData();
				}, 500);
			})
			.then(() => setAlertMessage('Document deleted successfully!'))
			.catch((error) => console.error(error));
	};

	const handleDeleteDocumentClick = (uuid) => {
		setSwalProps((prev) => ({
			...prev,
			[uuid]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this document!',
				confirmButtonText: 'Yes, delete it!',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, [uuid]: { show: false } })),
				onConfirm: () => deleteDocument(uuid),
			},
		}));
	};

	const openJobData = (selectedLeadData) => {
		const selectedDocument = jobs?.data.find(
			(data) => data.service_id === 1 && selectedLeadData.id === data.id
		);
		if (selectedDocument) selectedDocument.form_data[0].lead_id = selectedDocument.lead_id;
		dispatch(allActions.dashboard.resetTaxPreparation());
		dispatch(
			allActions.taxForms.dispatchViewFormDetails({
				document: selectedDocument.form_data[0],
				navigate,
			})
		);
	};

	return (
		<div className="clearfix">
			{data.jobs?.length > 0 && jobs.status === true ? (
				<div className="p-4">
					{alertMessage && (
						<Alert message={alertMessage} clickHandler={() => setAlertMessage('')} />
					)}
					{message.show ? <Message message={message} /> : <></>}
					{data.jobs?.map((job, index) => {
						return (
							<div className="clearfix" key={index}>
								{job.service_id === 1 && (
									<div className="row align-items-center px-2 py-4 border border-light rounded-3 mb-3">
										<div className="col-md-1 d-flex justify-content-center">
											<div
												className={`rounded-circle bg-grey-200 text-white d-flex justify-content-center align-items-center`}
												style={{
													width: 20,
													height: 20,
												}}
											>
												<AiOutlineCheck />
											</div>
										</div>
										<div className="col-md-9 d-flex align-items-center" style={{ gap: 10 }}>
											<Link
												className="text-dark text-decoration-none"
												onClick={() => {
													setMessage({ show: false });
													dispatch(
														allActions.dashboard.getLeadsByUUID({
															UUID: data.uuid,
														})
													);
													openJobData(job);
												}}
											>
												<GrDocumentUser /> {job.form_data[0]?.name}
											</Link>
										</div>
										<div className="col text-end dropdown-center">
											<button
												type="button"
												className={`btn btn-sm text-primary border-0 rounded-circle`}
												data-bs-toggle="dropdown"
												aria-expanded="false"
												style={{
													width: 30,
													height: 30,
												}}
											>
												<BsThreeDotsVertical />
											</button>
											<ul className="dropdown-menu">
												<li>
													<Link
														className="dropdown-item"
														onClick={() => {
															setMessage({ show: false });
															dispatch(
																allActions.dashboard.getLeadsByUUID({
																	UUID: data.uuid,
																})
															);
															openJobData(job);
														}}
													>
														View
													</Link>
												</li>
											</ul>
										</div>
									</div>
								)}
								<div className="clearfix mb-4">
									{job.documents?.map((document, index) => (
										<div
											className="row align-items-center px-2 py-4 border border-light rounded-3 mb-3"
											key={document.id}
										>
											<div className="col-md-1 d-flex justify-content-center">
												<div
													className={`rounded-circle bg-${
														document?.path ? 'success' : 'grey-200'
													} text-white d-flex justify-content-center align-items-center`}
													style={{
														width: 20,
														height: 20,
													}}
												>
													<AiOutlineCheck />
												</div>
											</div>
											<div className="col-md-9 d-flex align-items-center" style={{ gap: 10 }}>
												<FileTypeIcon extension={document?.extension} /> {document?.name}
												<span className="text-dark text-opacity-50">
													Added{' '}
													{new Date(document.created_at).toLocaleDateString('en-US', {
														year: 'numeric',
														month: 'short',
														day: 'numeric',
													})}
												</span>
											</div>
											<div className="col text-end dropdown-center">
												<button
													type="button"
													className={`btn btn-sm text-primary border-0 rounded-circle`}
													data-bs-toggle="dropdown"
													aria-expanded="false"
													style={{
														width: 30,
														height: 30,
													}}
												>
													<BsThreeDotsVertical />
												</button>
												<ul className="dropdown-menu">
													<li>
														<Link
															to="#"
															className={`dropdown-item${document?.path ? '' : ' disabled'}`}
															data-bs-toggle="modal"
															data-bs-target="#viewDocumentModal"
															onClick={() => setDocumentToView(document)}
														>
															View
														</Link>
													</li>
													<li>
														<hr className="dropdown-divider border-light" />
													</li>
													<li>
														<Link
															to="#"
															className="dropdown-item text-danger"
															onClick={() => handleDeleteDocumentClick(document.uuid)}
														>
															Delete
														</Link>
														<SweetAlert2 {...swalProps[document.uuid]} />
													</li>
												</ul>
											</div>
										</div>
									))}
									{/*<div className='row mt-4'>
										<Link
											to='#'
											className='btn btn-outline-primary rounded-5 col-md-4'
											data-bs-toggle='modal'
											data-bs-target='#requestDocuments'
											onClick={() =>
												setSelectedData({
													job_id: job.id,
													pro_id,
													customer_id,
												})
											}
										>
											Request Additional Documents
										</Link>
									</div>*/}
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<div className="card p-5 border-1 border-secondary-100 text-center rounded shadow-hover">
					<h4 className="text-dark text-opacity-25">
						There are no documents available for this lead yet.
					</h4>
				</div>
			)}

			<Modal
				title="View Document"
				bodyClass="px-5 py-4 text-center"
				trigger="viewDocumentModal"
				showBtn={false}
				size="lg"
			>
				{documentToView &&
					(documentToView.location.includes('.pdf') ? (
						<>
							<RenderMultiPagePDF file={documentToView.path} />
						</>
					) : (
						<img
							src={documentToView.path}
							className="w-100 img-fluid rounded"
							alt={documentToView.name}
							style={{ objectFit: 'contain' }}
						/>
					))}
			</Modal>
			{/* <Modal
				title="Request Documents"
				bodyClass="px-5 py-4 text-center"
				trigger="requestDocuments"
				btnClass="uploadDraftDocumentClose"
				showBtn={false}
			>
				<RequestJobDocumentForm
					closeClass={'uploadDraftDocumentClose'}
					data={selectedData}
					setAlert={setAlertMessage}
					reloadData={reloadData}
					customer_id={customer_id}
					leadData={data?.user}
				/>
			</Modal> */}
		</div>
	);
};

export default CustomerDocuments;
