import {useState} from "react";

const CustomAddListOptionModal = ({
     addListDropdownOptionModalOpen,
     setAddListDropdownOptionModalOpen,
    listFields,
    setListFields
  }) => {
    const [optionName, setOptionName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    return (
      <div
        className="custom-modal-overlay"
        onClick={() => setAddListDropdownOptionModalOpen(false)}
        >
        <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
          <h2 className="custom-modal-heading">Add option</h2>
          <p className="custom-modal-description">
            Enter new option for question
          </p>
          <div className="custom-modal-input-cont">
            <label htmlFor="section-title">Enter option tittle</label>
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
            <input
              className={errorMessage ? "border-1 border-danger" : ""}
              type="text"
              id="section-title"
              value={optionName}
              onChange={(e) => {
                setOptionName(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="custom-modal-buttons">
            <button
              className="btn"
              onClick={() => {
                setAddListDropdownOptionModalOpen(false);
              }}
              >
              Cancel
            </button>
            <div
              di
              className="btn btn-primary"
              onClick={() => {
                let updatedListFields = {...listFields}
                 updatedListFields = updatedListFields?.columns?.map((field,idx) => {
                  if(idx === addListDropdownOptionModalOpen?.data){
                    console.log('goes here');
                      return {
                        ...field,
                        questionData:[...field?.questionData,optionName]
                      }
                  }
                  return field
                 })
                 setListFields((prev) => {
                    return {
                        ...prev,
                        columns:updatedListFields
                    }
                 })
                 setAddListDropdownOptionModalOpen(false);
                 setOptionName('')
              }
            }
              disabled={optionName?.length < 3}
              >
              Save
            </div>
          </div>
        </div>
      </div>
    );
  };


  export default CustomAddListOptionModal