import { BASEURL } from "../../utilites";
import axios from "axios";
import { MILESTONE_TYPE } from "./Type";

const createMilestone = (createObJ) => {
	return async (dispatch) => {
	
		await axios
			.post(`${BASEURL}/api/jobs/milestone/create`, createObJ)
			.then((response) => {
				// HANDLE RESPONSE
					dispatch({
						type: MILESTONE_TYPE.createMilestoneSuccess,
						payload: {
							message: "success",
							status: true,
							data: response?.data?.data,
						},
					});					
				
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MILESTONE_TYPE.createMilestoneError,
					payload: {
						message: handleMilestoneErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const getJobMilestone = (job_id,pro_id) => {
	return async (dispatch) => {
		await axios
			.get(`${BASEURL}/api/jobs/milestone/get-byjobId?jobId=${job_id}&proId=${pro_id}`)
			.then((response) => {
				// HANDLE RESPONSE
					dispatch({
						type: MILESTONE_TYPE.getJobMilestoneSuccess,
						payload: {
							message: "success",
							status: true,
							data: response?.data?.data,
						},
					});					
				
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MILESTONE_TYPE.getJobMilestoneError,
					payload: {
						message: handleMilestoneErrors("simple", error),
						status: false,
						data: [ ],
					},
				});
			});
	};
};



const updateStatusMilestone = (updateObj) => {
	return async (dispatch) => {
		await axios
			.patch(`${BASEURL}/api/jobs/milestone/update-status`,updateObj)
			.then((response) => {
				// HANDLE RESPONSE
					dispatch({
						type: MILESTONE_TYPE.updateStatusMilestoneSuccess,
						payload: {
							message: "success",
							status: true,
							data: response?.data?.data,
						},
					});					
				
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MILESTONE_TYPE.updateStatusMilestoneError,
					payload: {
						message: handleMilestoneErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const updateMilestone = (updateObj) => {
	return async (dispatch) => {
		await axios
			.patch(`${BASEURL}/api/jobs/milestone/edit`,updateObj)
			.then((response) => {
				// HANDLE RESPONSE
					dispatch({
						type: MILESTONE_TYPE.updateMilestoneSuccess,
						payload: {
							message: "success",
							status: true,
							data: response?.data?.data,
						},
					});					
				
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MILESTONE_TYPE.updateMilestoneError,
					payload: {
						message: handleMilestoneErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const updateMilestoneReset = () => {
	return async (dispatch) => {
		dispatch({
			type: MILESTONE_TYPE.updateMilestoneIdle,
		});
	};
};
const updateStatusMilestoneReset = () => {
	return async (dispatch) => {
		dispatch({
			type: MILESTONE_TYPE.updateStatusMilestoneIdle,
		});
	};
};
const getJobMilestonereset = () => {
	return async (dispatch) => {
	  dispatch({
		type: MILESTONE_TYPE.getJobMilestoneIdle,
	  });
	};
  };

const handleMilestoneErrors = (type, error) => {
	if (type === "simple") {
		return error.code === "ERR_NETWORK" ? error?.message : error?.response?.data?.error;

	}
};

const fundMilestone = (fundObj) => {
	return async (dispatch) => {
		await axios
			.post(`${BASEURL}/api/jobs/milestone/pay`,fundObj)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: MILESTONE_TYPE.fundMilestoneSuccess,
					payload: {
						message: "success",
						status: true,
						data: response?.data?.data,
					},
				});

			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MILESTONE_TYPE.fundMilestoneError,
					payload: {
						message: handleMilestoneErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};


const fundMilestoneReset = () => {
	return async (dispatch) => {
		dispatch({
			type: MILESTONE_TYPE.fundMilestoneIdle,
		});
	};
};
const deleteMilestone = (milestone_id,pro_id) => {
	return async (dispatch) => {
		await axios
			.delete(`${BASEURL}/api/jobs/milestone/delete?milestone_id=${milestone_id}&pro_id=${pro_id}`)
			.then((response) => {
				// HANDLE RESPONSE
				dispatch({
					type: MILESTONE_TYPE.deleteMilestoneSuccess,
					payload: {
						message: "success",
						status: true,
						data: response?.data?.data,
					},
				});

			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MILESTONE_TYPE.deleteMilestoneError,
					payload: {
						message: handleMilestoneErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};

const deleteMilestoneReset = () => {
	return async (dispatch) => {
		dispatch({
			type: MILESTONE_TYPE.deleteMilestoneIdle,
		});
	};
};


const milestoneMarkAsPaid = (updateObj) => {
	return async (dispatch) => {
		await axios
			.patch(`${BASEURL}/api/jobs/milestone/mark-milestone-completed`,updateObj)
			.then((response) => {
				// HANDLE RESPONSE
					dispatch({
						type: MILESTONE_TYPE.milestoneMarkAsPaidSuccess,
						payload: {
							message: "success",
							status: true,
							data: response?.data?.data,
						},
					});					
				
			})
			.catch((error) => {
				// HANDLE ERROR MESSAGE
				dispatch({
					type: MILESTONE_TYPE.milestoneMarkAsPaidError,
					payload: {
						message: handleMilestoneErrors("simple", error),
						status: false,
						data: {},
					},
				});
			});
	};
};
const milestoneMarkAsPaidReset = () => {
	return async (dispatch) => {
		dispatch({
			type: MILESTONE_TYPE.milestoneMarkAsPaidIdle,
		});
	};
};
const createMilestoneReset = () => {
	return async (dispatch) => {
		dispatch({
			type: MILESTONE_TYPE.createMilestoneIdle,
		});
	};
};

export const milestone = {
	createMilestone,
	getJobMilestone,
	getJobMilestonereset,
	fundMilestone,
	fundMilestoneReset,
	updateStatusMilestone,
	updateMilestone,
	updateMilestoneReset,
	deleteMilestone,
	deleteMilestoneReset,
	updateStatusMilestoneReset,
	milestoneMarkAsPaid,
	milestoneMarkAsPaidReset,
	createMilestoneReset
	
};
