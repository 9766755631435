import { user } from "./user/action";
import { dashboard } from "./dashboard/action";
import { factAuth } from "./2fact/action";
import { payment } from "./payment/action";
import { global } from "./global/action";
import { invoice } from "./invoice/action";
import { firebase } from "./firebase/action";
import { call } from "./call/action";
import { plaid } from "./plaid/action";
import { jobs } from "./jobs/action";
import {taxForms} from "./taxForms/action"
import {payout} from "./payout/action"
import {sgMail} from "./sgMail/action"
import { Admin } from "./Admin/action";
import { membership } from "./membership/action";
import { tasks } from "./tasks/action";
import {recordings} from './recordings/action'
import { documentsgroup } from "./documentsGroup/action";
import {milestone} from './milestone/action'
const allActions = {
	user,
	dashboard,
	factAuth,
	payment,
	global,
	invoice,
	firebase,
	call,
	plaid,
	jobs,
	taxForms,
	payout,
	sgMail,
	Admin,
	membership,
	tasks,
	recordings,
	documentsgroup,
	milestone
};

export default allActions;
