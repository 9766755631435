import React from 'react'

const ItemizedDeductions = ({question}) => {
  return (
   <div>
    <div className='mx-2 fs-5 fw-bold'>{question}</div>
    <ul className='deductions-list mt-2'>
      <li>Medical and dental expenses</li>
      <li>State and local income taxes</li>
      <li>Sales taxes</li>
      <li>Real estate taxes</li>
      <li>Personal property taxes</li>
      <li>Mortgage interest</li>
      <li>Donations to charity (cash)</li>
      <li>Donations to charity (non-cash)</li>
      <li>Casualty and theft losses</li>
      <li>Gambling Losses</li>
    </ul>
   </div>
  )
}

export default ItemizedDeductions