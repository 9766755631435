/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

const GroupDocumentTable = ({Heading,selectedGroupDocument}) => {
	return (
		<div className="my-2">
			<table className="table">
				<thead>
					<tr >
						{Heading?.map((data) => (
							<th scope="col fw-bolder">{data.heading}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{selectedGroupDocument?.docs_data?.documents?.map((data) => {
						return (
							<tr>
								<td>{data?.name}</td>
								<td>{data?.description}</td></tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default GroupDocumentTable;
