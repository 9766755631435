import React, { useState, useRef, useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import ReactSelect from 'react-select';
import axios from 'axios';
import Input from './fields/Input';
import Button from './fields/Button';
import { BASEURL, useAutoReplyHook } from '../../utilites';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../Store/action';
import DeleteIcon from '../../assets/img/x.svg';
import PdfIcon from '../../assets/img/pdf.jpg';

const customStyles = {
	option: (provided) => ({
		...provided,
		textAlign: 'left',
	}),
	singleValue: (provided) => ({
		...provided,
		textAlign: 'left',
	}),
	placeholder: (provided) => ({
		...provided,
		textAlign: 'left',
		fontSize: 14,
	}),
};

export default function AddDocumentForm({
	isModal,
	userId,
	setAlert,
	reloadDocuments,
	multiple = true,
	types = ['PNG', 'JPG', 'PDF', 'JPEG'],
	documentId,
	showDocumentNameField = true,
	targetUserUuid,
	droppedDocuments
}) {
	const [{ customFolders }] = useSelector((state) => [state.dashboard]);

	const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const [documents, setDocuments] = useState(droppedDocuments?.length > 0 ? droppedDocuments : multiple?[]:null);
	const [documentName, setDocumentName] = useState('');
	const [selectedFolder, setSelectedFolder] = useState();
	const [createNewFolder, setCreateNewFolder] = useState(false);
	const [newFolderName, setNewFolderName] = useState('');
	const [uploading, setUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [{ personalConnections }, { LoginData }] = useSelector((state) => [
		state.firebase,
		state.user,
	]);
	
	useEffect(() => {
		if(droppedDocuments?.length > 0){
			setDocuments(droppedDocuments[0])
			setDocumentName(droppedDocuments[0]?.Filename)
		}
	},[droppedDocuments])
	
	const [errors, setErrors] = useState({
		documents: false,
		documentName: false,
		general: '',
	});
	const button = useRef();

	useEffect(() => {
		dispatch(allActions.dashboard.getCustomFolders());
	}, [dispatch]);

	// function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(documents);
			if (documents) {
				setUploading(true);

				button.current.disabled = true;
				if (errors.general !== '') setErrors({ errors, general: '' });

				// Create a new FormData instance
				const formData = new FormData();
				// Append all properties to FormData
				if (multiple) {
					for (let i = 0; i < documents.length; i++) {
						formData.append('documents', documents[i]);
					}
				} else {
					formData.append('documents', documents);
					console.log(formData);
				}
				formData.append('user_id', userId);
				targetUserUuid && formData.append('target_user_uuid', targetUserUuid);
				formData.append('type', 1);
				if (documents.length === 1 && documentName) {
					formData.append('document_name', documentName);
				}
				if (createNewFolder && newFolderName) {
					formData.append('folder_name', newFolderName);
				} else if (selectedFolder) {
					formData.append('folder_id', selectedFolder?.value);
				}
				if (documentId) formData.append('document_id', documentId);
				
				try {
					// Make an Axios request to the backend API endpoint for each file individually
					await axios
						.post(`${BASEURL}/api/documents`, formData, {
							headers: {
								'Content-Type': 'multipart/form-data',
							},
							onUploadProgress: (progressEvent) => {
								// Calculate the upload progress percentage
								const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

								// Update the uploadProgress state for the current file
								setUploadProgress(progress);
							},
						})
						.then(() => {
							handleAutoMessaging();
							button.current.disabled = false;
							// Close Modal
							if(isModal){
								document.querySelector('.addDocumentModalClose').click();
							}
							// Reload documents
							reloadDocuments();
							setAlert &&
								setAlert(`Document${documents.length > 1 ? 's' : ''} has been added successfully!`);
						});
				} catch (error) {
					button.current.disabled = false;
					console.error(error.message);
					// Set general error
					if (errors.general === '')
						setErrors({ errors, general: error?.response?.data?.message || error.message });
				}
				// Reset the form
				setUploading(false);
				setUploadProgress(0);
				setDocuments('');
				setDocumentName('');
			}
	};

	const handleAutoMessaging = () => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: LoginData?.lead[0]?.userpro?.user,
			type: 'simple',
			encodedString: `<p>I just uploaded one of the documents you requested.</p>`,
		});
	};

	const handleFolderSelect = (selected) => {
		setSelectedFolder(selected);
	};

const changeURL = (file) => {
	if (file) {
		let objectUrl = URL.createObjectURL(file);
		if (file?.type === 'application/pdf') {
			objectUrl = PdfIcon;
		}

		return objectUrl;
	}
};
	return (
		<form className="documentsform needs-validation px-5 px-md-0" onSubmit={handleSubmit}>
			{!uploading && (
				<>
					<div className="row">
						<div className="col-md-12 mb-3 w-100 min-w-100">
							<FileUploader
								classes="form-control mx-auto custom-min-width file-upload"
								multiple={multiple}
								values={documents}
								handleChange={(files) => {
									setDocuments(multiple ? [...documents, ...files] : files);
								}}
								name="documents"
								types={types}
								onTypeError={() => {
									setErrors({ ...errors, documents: true });
								}}
							/>
						</div>
					</div>
					{(documents && documents.length > 1) || !showDocumentNameField ? null : (
						<div className="row">
							<div className="col-md-12 mb-3">
								<Input
									type="text"
									value={documentName}
									name="documentName"
									placeholder="Document name"
									fn={setDocumentName}
									hasValidationError={errors.documentName}
									showLabel={true}
								/>
							</div>
						</div>
					)}
					{multiple && (
						<div className="row d-flex justify-content-start  card-con">
							{documents &&
								documents?.map((value) => (
									<div
										className={`dynamic-form-card px-5 text-center border-solid  d-flex flex-column justify-content-center align-items-center gap-2 custom-card rounded`}
									>
										<img
											onClick={(e) => {
												e.stopPropagation();
												setDocuments(documents.filter((file) => file !== value));
											}}
											className="delete"
											src={DeleteIcon}
											alt="delete-icon"
										/>

										<img style={{ height: '100px' }} src={changeURL(value)} alt="plus" />

										{/* Dynamic */}
									</div>
									// </div>
								))}
						</div>
					)}
				</>
			)}
			{!uploading && (
				<div className="row">
					<div className="col-md-12 mb-3 d-flex justify-content-center">
						<div className="form-check text-start">
							<input
								className="form-check-input"
								type="checkbox"
								checked={createNewFolder}
								id="newFolderCheck"
								onChange={(e) => setCreateNewFolder(e.target.checked)}
							/>
							<label className="form-check-label fw-semibold text-start" htmlFor="newFolderCheck">
								Create new folder and place the documents in it
							</label>
						</div>
					</div>
				</div>
			)}
			{!uploading && createNewFolder && (
				<div className="row">
					<div className="col-md-12 mb-3">
						<Input
							type="text"
							value={newFolderName}
							name="newFolderName"
							placeholder="Folder name"
							fn={setNewFolderName}
							hasValidationError={errors.newFolderName}
							showLabel={true}
						/>
					</div>
				</div>
			)}
			{!uploading && !createNewFolder && customFolders && customFolders.length > 0 && (
				<div className="row">
					<div className="col-md-12 mb-3">
						<label htmlFor="folders" className="form-label">
							Select folder
						</label>
						<ReactSelect
							id="folders"
							className="p-0 basic-single"
							placeholder="Select folder"
							styles={customStyles}
							closeMenuOnSelect={true}
							options={customFolders?.map((u) => ({
								value: u.id,
								label: u.name,
							}))}
							onChange={handleFolderSelect}
							value={selectedFolder}
						/>
					</div>
				</div>
			)}
			{uploading ? (
				<div className="progress">
					<div
						className="progress-bar progress-bar-striped progress-bar-animated"
						role="progressbar"
						style={{ width: `${uploadProgress}%` }}
						aria-valuenow={uploadProgress}
						aria-valuemin="0"
						aria-valuemax="100"
					>
						{uploadProgress}%
					</div>
				</div>
			) : null}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-primary btn-lg px-4${ 
					Object.values(errors).indexOf(true) > -1 || !documents ? ' disabled' : ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText={`Add Document${multiple ? 's' : ''}`}
			/>
		</form>
	);
}
