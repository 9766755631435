/* eslint-disable react-hooks/exhaustive-deps */
// import Visa from '../../../assets/img/visa.svg'


import allActions from "../../../Store/action";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Message from "../fields/Message";
import { useAutoReplyHook } from "../../../utilites";

export default function ApproveMilestone({milestone,job,user}) {
    const dispatch = useDispatch();
    const [{milestoneMarkAsPaid},{personalConnections},{LoginData}] = useSelector(state => [state.milestone,state.firebase, state.user]);
    const [message,setMessage] = useState({show:false});
    const [checkTerm, setcheckTerm] = useState(false);
	const handleAutoReply = useAutoReplyHook(dispatch);


    const handleApproveMilestone = () => {
        dispatch(allActions.global.Loader(true));
        let obj = {
            milestone_id: milestone?.id,
            status_id: 3,
            job_id:job?.id
        }
        dispatch(allActions.milestone.milestoneMarkAsPaidReset())
        dispatch(allActions.milestone.milestoneMarkAsPaid(obj))

    }
  


    useEffect(() => {
        // if Success
        if (milestoneMarkAsPaid?.status === true) {
            dispatch(allActions.milestone.getJobMilestone(job?.id))
            document.querySelector('.approveMilestoneModalClose').click()
            setMessage({ show: false });
            dispatch(allActions.global.Loader(false));
            var encodedString = window.btoa(
				JSON.stringify({
					amount: milestone?.invoices?.amount,
					name: milestone?.name,
					due_date: milestone?.invoices?.due_date,
                    milestone_action:'approve'				
				
				})
			);
            handleAutoReply({
                personalConnections,
                LoginData,
                receiver: user,
                type: "milestone",
                encodedString: encodedString,
              });
              dispatch(allActions.milestone.fundMilestoneReset())
        }

        // if Error
        if (milestoneMarkAsPaid?.status === false) {
            setMessage({
                show: true,
                type: 'Error',
                text: milestoneMarkAsPaid.message,
            });
            dispatch(allActions.global.Loader(false));
        }
    }, [milestoneMarkAsPaid?.status]);




    return (
        <div  >
            {message.show ? <Message message={message} /> : <></>}
            <div className="d-flex flex-column align-items-start gap-3">
                <div className="">
                    <p className="text-start text-black mt-0 p-0 fs-5 text-secondary-emphasis fw-bold">
                        {milestone?.name}
                    </p>
                </div>
                <div className="w-50 d-flex flex-column align-items-start gap-2">
                    <label className="text-start fs-5 text-black" htmlFor="milestonePrice">
                        Payment amount
                    </label>
                    <input disabled={false} className="form-control w-100"  type="number" value={milestone?.invoices?.amount}
                           id="milestonePrice" checked/>
                </div>
                <div className={'text-black w-75 text-start text-secondary-emphasis'}>
                    You have ${milestone?.invoices?.amount} in escrow
                </div>
                <div className={'d-flex gap-3 align-items-center gap-2'}>
                <div className="clearfix p-2 mt-4">
						<div className="form-check">
							<input
								type="checkbox"
								name="agreed"
								className="form-check-input"
								id="agreed2"
								value="1"
								checked={checkTerm}
								onChange={(e) => setcheckTerm(!checkTerm)}
							/>
							<label className="form-check-label text-black text-start" htmlFor="agreed2">
                                   Yes, I approved the completion of this milestone and authorized Unclekam to release the payment amount stated above to the Tax Professional.
								{/* <Link to="/user-agreement">User Agreement</Link> and{' '}
								<Link to="/privacy-policy">Privacy Policy</Link>. */}
							</label>
						</div>
					</div>
                </div>
                <div className="d-flex justify-content-end w-100 align-items-center">
                    <button className="btn text-primary fw-bold" data-bs-dismiss="modal">
                        Cancel
                    </button>
                    <button disabled={!checkTerm} onClick={handleApproveMilestone}  className="btn btn-primary" >
                        Approve
                    </button>
                </div>
            </div>
        </div>
    );
}
