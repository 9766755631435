export const MILESTONE_TYPE = {
	
		// create milestone
		createMilestoneIdle: 'createMilestoneIdle',
		createMilestoneSuccess: 'createMilestoneSuccess',
		createMilestoneError: 'createMilestoneError',

		// get jobmilestone
		getJobMilestoneIdle: 'getJobMilestoneIdle',
		getJobMilestoneSuccess: 'getJobMilestoneSuccess',
		getJobMilestoneError: 'getJobMilestoneError',

		// fund milestone
		fundMilestoneIdle: 'fundMilestoneIdle',
		fundMilestoneSuccess: 'fundMilestoneSuccess',
		fundMilestoneError: 'fundMilestoneError',

		// milestone update status
		updateStatusMilestoneIdle: 'updateStatusMilestoneIdle',
		updateStatusMilestoneSuccess: 'updateStatusMilestoneSuccess',
		updateStatusMilestoneError: 'updateStatusMilestoneError',


		// milestone update
		updateMilestoneIdle: 'updateMilestoneIdle',
		updateMilestoneSuccess: 'updateMilestoneSuccess',
		updateMilestoneError: 'updateMilestoneError',

		// milestone delete
		deleteMilestoneIdle: 'deleteMilestoneIdle',
		deleteMilestoneSuccess: 'deleteMilestoneSuccess',
	    deleteMilestoneError: 'deleteMilestoneError',

		// milestone paid
		milestoneMarkAsPaidIdle: 'milestoneMarkAsPaidIdle',
		milestoneMarkAsPaidSuccess: 'milestoneMarkAsPaidSuccess',
	    milestoneMarkAsPaidError: 'milestoneMarkAsPaidError',
};
