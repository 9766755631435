const CustomDeleteSectionModal = ({
    setCustomModalOpen,
    customModalOpen,
    setTaxForm,
  }) => {
    return (
      <div
        className="custom-modal-overlay"
        onClick={() => setCustomModalOpen(null)}
        >
        <div
          className="custom-modal-cont-delete"
          onClick={(e) => e.stopPropagation()}
        >
          <h2 className="custom-modal-heading">Delete Section</h2>
          <p className="custom-modal-description">
            Are you sure you want to delete this section?
          </p>
          <div className="custom-modal-buttons">
            <button
              className="btn"
              onClick={() => {
                setCustomModalOpen(null);
              }}
            >
              Cancel
            </button>
            <div
              className="btn btn-danger"
              onClick={() => {
                setTaxForm((prevForm) => {
                  return {
                    ...prevForm,
                    sections: prevForm?.sections?.filter((section) => section?.id !== customModalOpen?.data?.sectionId )
                  }
                })
                setCustomModalOpen(null);
              }}
            >
              Delete
            </div>
          </div>
          <div className="custom-modal-buttons"></div>
        </div>
      </div>
    );
  };



  export default CustomDeleteSectionModal