import {useState} from "react";

const CustomAddGridOptionsModal = ({
                                      gridData,
                                      setGridData,
                                      setAddGridDropdownOptionModalOpen,
                                      addGridDropdownOptionModalOpen
                                  }) => {
    const [optionName, setOptionName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    return (
        <div
            className="custom-modal-overlay"
            onClick={() => setAddGridDropdownOptionModalOpen(false)}
        >
            <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
                <h2 className="custom-modal-heading">Add option</h2>
                <p className="custom-modal-description">
                    Enter new option for question
                </p>
                <div className="custom-modal-input-cont">
                    <label htmlFor="section-title">Enter option tittle</label>
                    {errorMessage && <div className="text-danger">{errorMessage}</div>}
                    <input
                        className={errorMessage ? "border-1 border-danger" : ""}
                        type="text"
                        id="section-title"
                        value={optionName}
                        onChange={(e) => {
                            setOptionName(e.target.value);
                            setErrorMessage("");
                        }}
                    />
                </div>
                <div className="custom-modal-buttons">
                    <button
                        className="btn"
                        onClick={() => {
                            setAddGridDropdownOptionModalOpen(false);
                        }}
                    >
                        Cancel
                    </button>
                    <div
                        className="btn btn-primary"
                        onClick={() => {
                            let updatedGridFields = {...gridData}

                            updatedGridFields = {
                                ...updatedGridFields,
                                columns:updatedGridFields?.columns?.map((col) => {
                                    if(col?.label === addGridDropdownOptionModalOpen?.data){
                                        return {
                                            ...col,
                                            questionData: [...col?.questionData,optionName]
                                        }
                                    }

                                    return col
                                } )
                            }
                            setGridData(updatedGridFields)
                            setAddGridDropdownOptionModalOpen(false);
                            setOptionName('')

                            // const isOptionAdded = customDropdownOptions?.find(
                            //   (option) => option === optionName
                            // );

                            // if (!isOptionAdded) {
                            //   setCustomDropdownOptions((prev) => [...prev, optionName]);
                            //   setErrorMessage("");
                            // } else {
                            //   setErrorMessage("The option is added already!");
                            // }
                        }
                        }
                        disabled={optionName?.length < 3}
                    >
                        Save
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CustomAddGridOptionsModal