import EmplymentIcon from "../../../../assets/img/empoyment.svg";
import SocyalSecurityIcon from "../../../../assets/img/social-security 1.svg";
import StatesOrLocalTaxIcon from "../../../../assets/img/tax 1.svg";
import InterestRateIcon from "../../../../assets/img/interest-rate 1.svg";
import RentsIcon from "../../../../assets/img/rent 1.svg";
import EducationCostIcon from "../../../../assets/img/education-cost 1.svg";
import PartnershipIcon from "../../../../assets/img/partnership.svg";
import EstateOrTrustIcon from "../../../../assets/img/estateortrust.svg";
import RetirementIcon from "../../../../assets/img/retirement.svg";
import SelfEmploymentIcon from "../../../../assets/img/self-employed 1.svg";
import CancelDebtIcon from "../../../../assets/img/cancel 1.svg";
import UnemploymentIcon from "../../../../assets/img/unemployment.svg";
import DividendsIcon from "../../../../assets/img/dividends 1.svg";
import HsaOrMsaIcon from "../../../../assets/img/hsaormsa.svg";
import RoyalitiesIcon from "../../../../assets/img/royalities.svg";
import GamblingIcon from "../../../../assets/img/gambling.svg";
import FarmingIcon from "../../../../assets/img/planting 1.svg";
import OtherIncomeIcon from "../../../../assets/img/otherincome.svg";
import SoldStockIcon from "../../../../assets/img/soldstock.svg";
import SectxporationIcon from "../../../../assets/img/sectxporation.svg";

export const fieldOptions = [
    {
      label: "Text",
      value: "text-one-line",
      children: [
        { label: "One line", value: "text-one-line" },
        { label: "Multi line", value: "text-multi-line" },
        { label: "Email", value: "text-email" },
        { label: "Comment", value: "text-comment" },
        { label: "Header", value: "text-header" },
      ],
    },
    {
      label: "Number",
      value: "number",
      children: [
        { label: "Any number", value: "number-any-number" },
        { label: "Percentage", value: "number-percentage" },
        { label: "Amount", value: "number-amount" },
        { label: "Phone", value: "number-phone" },
        { label: "Zip Code", value: "number-zipcode" },
      ],
    },
    { label: "Yes/No", value: "yes-no" },
    { label: "Date", value: "date" },
    { label: "File Upload", value: "file-upload" },
    {
      label: "Dropdown",
      value: "dropdown",
      children: [
        { label: "States", value: "dropdown-states" },
        { label: "Filing status", value: "dropdown-filing-status" },
        { label: "Countries", value: "dropdown-countries" },
        { label: "Custom (Single select)", value: "dropdown-custom-single-select" },
        { label: "Custom (multi select)", value: "dropdown-custom-multy-select" },
      ],
    },
    { label: "List", value: "list" },
    { label: "Card", value: "card" },
    { label: "Grid", value: "grid" },
    { label: "Section Divider", value: "divider" },
    { label: "Horizontal line", value: "horizontal-line" },
    {
      label: "Others",
      value: "Others",
      children: [
        { label: "Dependents (List)", value: "pre-defined-dependents" },
        { label: "Partners (List)", value: "pre-defined-partners" },
        { label: "Officers (List)", value: "pre-defined-officers" },
          { label: "Shareholders (List)", value: "pre-defined-shareholders" },
        { label: "Standard deduction (List)", value: "pre-defined-deductions-list" },
        { label: "Income sources", value: "pre-defined-income-sources" },
        { label: "Expenses (List)", value: "expenses-list"},
        { label: "Expenses (Select)", value: "expenses-select"},
        { label: "Name (Fields Group)", value: "pre-defined-name-group" },
        { label: "Address (Fields Group)", value: "pre-defined-address-group" },
        { label: "Filing status", value: "dropdown-filing-status" },
        { label: "Bank Information", value: "pre-defined-bank-information" },
      ],
    },
  ];
  
  export const CustomfieldOptions = [
    {
      label: "Text",
      value: "text-one-line",
      children: [
        { label: "One line", value: "text-one-line" },
        { label: "Email", value: "text-email" },
      ],
    },
    {
      label: "Number",
      value: "number",
      children: [
        { label: "Any number", value: "number-any-number" },
        { label: "Percentage", value: "number-percentage" },
        { label: "Amount", value: "number-amount" },
      ],
    },
    { label: "Yes/No", value: "yes-no" },
    { label: "Date", value: "date" },
    {
      label: "Dropdown",
      value: "dropdown",
      children: [
        { label: "States", value: "dropdown-states" },
        { label: "Filing status", value: "dropdown-filing-status" },
        { label: "Countries", value: "dropdown-countries" },
        { label: "Custom (Single select)", value: "dropdown-custom-single-select" },
        { label: "Custom (Multy select)", value: "dropdown-custom-multy-select" },
      ],
    },
    { label: "Horizontal line", value: "horizontal-line" },
  ];

  export const countriesData = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria", 
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", 
    "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", 
    "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo (Congo-Brazzaville)", 
    "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czechia (Czech Republic)", "Denmark", "Djibouti", "Dominica", "Dominican Republic", 
    "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini (fmr. 'Swaziland')", "Ethiopia", 
    "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", 
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", 
    "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", 
    "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", 
    "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", 
    "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar (formerly Burma)", "Namibia", "Nauru", "Nepal", 
    "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", 
    "Pakistan", "Palau", "Palestine State", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", 
    "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", 
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", 
    "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", 
    "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", 
    "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", 
    "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States of America", "Uruguay", "Uzbekistan", 
    "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];



  export const filinigStatusData = [
    "Single",
    "Married filing jointly",
    "Married filing separately",
    "Head of household",
    "Qualifying widow(er)",
  ];


   
  export const americanStatesData = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  

  export const expensesData = [
    'Adoption expenses',
    'Alimony from a divorce finalized on or before December 31, 2018',
    'Child or dependent care expenses',
    'Educator expenses',
    'Higher education expenses',
    'Household worker expenses',
    'Contributions to a health savings account (HSA)',
    'Contributions to an individual retirement arrangement (IRA)',
    'Student loan interest',
    'Energy-efficient home improvements'
  ]

export const incomeSources = [
  { name: "Employment (W-2)", icon: EmplymentIcon },
  { name: "Social Security (SSA-1099)", icon: SocyalSecurityIcon },
  { name: "State or local tax refund (1099-G)", icon: StatesOrLocalTaxIcon },
  { name: "Interest (1099-INTJ)", icon: InterestRateIcon },
  { name: "Rents (1099-IASC)", icon: RentsIcon },
  { name: "ESA or 529 distribution (1099-Q)", icon: EducationCostIcon },
  { name: "Partnership (Schedule K-1)", icon: PartnershipIcon },
  { name: "Estate or trust (Schedule K-1)", icon: EstateOrTrustIcon },
  { name: "Retirement distribution (1099-RI)", icon: RetirementIcon },
  { name: "Self-employment (1099-NEC or 1099-K)", icon: SelfEmploymentIcon },
  { name: "Canceled debt (1099-C)", icon: CancelDebtIcon },
  { name: "Unemployment 11099-G)", icon: UnemploymentIcon },
  { name: "Dividends 11099-D(V)", icon: DividendsIcon },
  { name: "HS.A or MSA distribution (1099-SA1)", icon: HsaOrMsaIcon },
  { name: "Royalties (1099-MISC)", icon: RoyalitiesIcon },
  { name: "Gambling 1W-2G1", icon: GamblingIcon },
  { name: "Farming", icon: FarmingIcon },
  { name: "Other income", icon: OtherIncomeIcon },
  { name: "Sold stock or investments (1099-B)", icon: SoldStockIcon },
  { name: "S-Corporation (Schedule K-1)", icon: SectxporationIcon },
];
