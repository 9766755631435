/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
// import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { FileUploader } from 'react-drag-drop-files';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {BASEURL, useAutoReplyHook} from "../../../utilites";
import allActions from '../../../Store/action';
import Message from '../fields/Message';

export default function SubmitMilestone({ currentMilestone,targetUser,jobId }) {
    const [{ LoginData },{updateStatusMilestone},{personalConnections}] = useSelector((state) => [
        state.user,
        state.milestone,
		state.firebase
    ]);
    const [description, setDescription] = useState('');
    const [uploading,setUploading] = useState(false)
    const [uploadProgress,setUploadProgress] = useState(0)
    const [selectedDocument] = useState({})
    const [milestoneToSubmit,setMilestoneToSubmit] = useState(currentMilestone || {})
    const [file, setFile] = useState();
    const [message, setMessage] = useState({ show: false });
    const [checkTerm, setcheckTerm] = useState(false);

    const dispatch = useDispatch();
	const handleAutoReply = useAutoReplyHook(dispatch);


//   change milestone status 
useEffect(() => {
	console.log("@55555",updateStatusMilestone);
	
	// if Success
	if (updateStatusMilestone?.status === true) {
        resetForm()
		  document.getElementById('modalClose').click() 
		setMessage({ show: false });
		dispatch(allActions.global.Loader(false));
        dispatch(allActions.milestone.getJobMilestone(jobId))
		var encodedString = window.btoa(
			JSON.stringify({
				amount: milestoneToSubmit?.invoices?.amount,
				name: milestoneToSubmit?.name,
				due_date: milestoneToSubmit?.invoices?.due_date,
				milestone_action:'submit'				
			
			})
		);
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: {uuid:targetUser?.uuid},
			type: "milestone",
			encodedString: encodedString,
		  });
		  dispatch(allActions.milestone.fundMilestoneReset())
		

	}

	// if Error
	if (updateStatusMilestone?.status === false) {
		// Show Message Alert
		setMessage({
			show: true,
			type: 'Error',
			text: updateStatusMilestone.message,
		});
		dispatch(allActions.global.Loader(false));
	}
}, [updateStatusMilestone.message, updateStatusMilestone?.state]);


    console.log(selectedDocument)
 
    const handleFileUpload = async () => {
        if (file) {
            setUploading(true);
            const formData = new FormData();
			// Append properties to FormData
			formData.append('documents', file);
			formData.append('user_id', LoginData?.id);
			formData.append('job_id', jobId);
			formData.append('type', 3);
			if (description) formData.append('document_name', description);
			if (targetUser?.id) formData.append('target_user_uuid', targetUser?.id);
            try {
                await axios
                    .post(`${BASEURL}/api/documents`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: (progressEvent) => {
                            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            setUploadProgress(progress);
                        },
                    })
                    .then((res) => {
                        dispatch(allActions.global.Loader(true));
                        
                        setUploading(false)
                        let obj={
                            milestone_id:milestoneToSubmit?.id,
                            status_id:2,
                            document_id:res?.data?.data?.docArr[0]?.id,
							job_id:jobId
                        }
					 dispatch(allActions.milestone.updateStatusMilestoneReset())
                     dispatch(allActions.milestone.updateStatusMilestone(obj))
                    })
                    .catch((error) => {
						dispatch(allActions.global.Loader(false));

                        setMessage({
                            show: true,
                            type: "Error",
                            text:  error?.response?.data?.message || 'image is not uploaded' ,
                          });
                    });
         
            setUploading(false)
            } catch (error) {
                console.error(error.message);

            }
        }else{
			dispatch(allActions.milestone.updateStatusMilestoneReset())
            dispatch(allActions.global.Loader(true));
            let obj = { milestone_id: milestoneToSubmit?.id, status_id: 2, job_id: jobId };
            dispatch(allActions.milestone.updateStatusMilestone(obj))

        }
    }

 

const resetForm=()=>{
    setDescription('')
    setFile('')
}
    useEffect(() => {
        setMilestoneToSubmit(currentMilestone)
    },[currentMilestone])
    return (
			<div className={'p-3'}>
					{message.show ? <Message message={message} /> : <></>}
				<div className="d-flex flex-column align-items-start gap-3">

					<div className="">
						<p className="text-start text-black mt-0 p-0 fs-5 text-secondary-emphasis fw-bold">
							{milestoneToSubmit?.name}
						</p>
					</div>
					<div className={'d-flex justify-content-between w-100'}>
						<div className={'text-black fw-medium fs-5'}>Amount:</div>
						<div className={'text-black fw-bold fs-5'}>${milestoneToSubmit?.invoices?.amount}</div>
					</div>
					<div className={'d-flex justify-content-between align-items-center w-100'}>
						<div className={'text-black fw-medium fs-5'}>Attachment (Optional)</div>

						{!uploading && (
							<div className="col-sm-6 d-flex gap-3 justify-content-end">
								<FileUploader
									classes="form-control file-upload file-upload-custom-styles"
									multiple={false}
									values={[]}
									handleChange={(f) => setFile(f)}
									name="documents"
									types={['jpg', 'png', 'jpeg', 'pdf']}
									maxSize={5} // Size in MB
									onSizeError={() => {
										console.log('File size exceeds the limit');
									}}
									onTypeError={() => {
										console.log('Incorrect file type');
									}}
									label="Click here or Drag & Drop files to upload documents"
								/>
							</div>
						)}
						{uploading && (
							<div className="col-sm-6 d-flex gap-3 justify-content-end">
								<div className="progress w-100 custom-progress-styles">
									<div
										className="progress-bar progress-bar-striped progress-bar-animated"
										role="progressbar"
										style={{
											width: `${uploadProgress}%`,
										}}
										aria-valuenow={uploadProgress}
										aria-valuemin="0"
										aria-valuemax="100"
									>
										{uploadProgress}%
									</div>
								</div>
							</div>
						)}
					</div>

					<div className="w-100 d-flex flex-column align-items-start gap-2 mt-3">
						<label className="text-start fs-5 text-black" htmlFor="milestoneDescription">
							Additional notes (Optional)
						</label>
						<textarea
							placeholder="..."
							className="form-control pb-5"
							id="milestoneDescription"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</div>
					<div className="clearfix p-2 mt-4">
						<div className="form-check text-start">
							<input
								type="checkbox"
								name="agreed"
								className="form-check-input cursor-pointer"
								id="agreed2"
								value="1"
								checked={checkTerm}
								onChange={(e) => setcheckTerm(!checkTerm)}
							/>
							<label className="form-check-label text-black text-start w-75" htmlFor="agreed2">
								By clicking this form, I agreed to inform the customer that all part of this milestone is completed so that they can review and release the funds associated to this milestone.
								{/* <Link to="/user-agreement">User Agreement</Link> and{' '}
								<Link to="/privacy-policy">Privacy Policy</Link>. */}
							</label>
						</div>
					</div>
					<div className="d-flex justify-content-end w-100 align-items-center">
						<button className="btn text-primary fw-bold" id="modalClose" data-bs-dismiss="modal">
							Cancel
						</button>
						<button disabled={!checkTerm} onClick={handleFileUpload} className="btn btn-primary">
							Save
						</button>
					</div>
				</div>
			</div>
		);
}
