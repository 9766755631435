/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useState} from "react";
import CloseIcon from "../../assets/img/close.svg";
import DeleteIcon2 from "../../assets/img/delete1.svg";
import ArrowIcon from "../../assets/img/drop-down-arrow.svg";
import CircleIcon from "../../assets/img/circle.svg";
import ActiveCircleIcon from "../../assets/img/active-circle.svg";
import LockerSvg from "../../assets/img/locker.svg";
import EditIcon from "../../assets/img/edit.svg";
import { useDispatch } from "react-redux";
import allActions from "../../Store/action";
import ReactSelect from "react-select";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Message from "./fields/Message";
import Input from "./fields/Input";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { FileUploader } from 'react-drag-drop-files';
import axios from "axios";
import { BASEURL } from "../../utilites";
import { VscEye } from "react-icons/vsc";
import Modal from '../Dashboard/Modal';
import RenderMultiPagePDF from '../../pages/dashboard/components/RenderMultiPagePDF';
import { Link } from "react-router-dom";
import ItemizedDeductions from "./Modal/DynamicFormModals/Fields/ItemizedDeductions";
import PartnersList from "./Modal/DynamicFormModals/AdminSideComponents/PartnersList";
import OfficersList from "./Modal/DynamicFormModals/AdminSideComponents/OfficersList";
import ShareholdersList from "./Modal/DynamicFormModals/AdminSideComponents/ShareholdersList";
import RenderInputList from "./Modal/DynamicFormModals/ClientSideComponents/RenderInputList";
import {incomeSources,expensesData} from "./Modal/DynamicFormModals/StaticData";

const generateUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const randomString = Math.random().toString(36).substring(2, 15);
  const randomSegment = Math.random().toString(36).substring(2, 15);
  return timestamp + randomString + randomSegment;
};

const OpenJobForm = ({
  setIsFormModalOpen,
  activeFormData,
  setActiveFormData,
  previewMode,
  selectedJob,
  handleGetJobForms,
  job
}) => {
  const [{ LoginData }] = useSelector((state) => [
		state.user,
	]);
  const [activeSection, setActiveSection] = useState(
    Object.keys(activeFormData?.data)[0]
  );
  const [activeSubSection, setActiveSubSection] = useState();
  const [activeQustionId, setActiveQuestionId] = useState("");
  const [activeDivider, setActiveDivider] = useState(0);
  const [dependent, setDependent] = useState({
    firstName: "",
    middleInitial: "",
    lastName: "",
    prefix: "",
    ssnOrItin: "",
    dateOfBirth: "",
    relationship: "",
    amountPaidToCareProvider: "",
    yearsLivedWithTaxpayer: "",
    fullTimeStudent: false,
    selectAllthatApplayToThisPerson: [],
  });
  const dispatch = useDispatch();
  const [{ taxPreparation, taxPreparationOnFormSave }] = useSelector((state) => [state.dashboard]);
  const [message, setMessage] = useState({ show: false });
  const [dependentFielderrors, setDependentFieldErrors] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [dividerErrors, setDividerErrors] = useState({});
  const [formSubmit, setformSubmit] = useState(false);
  const [autoSave,setAutoSave] = useState(false)
  const [activeFileID,setActiveFileId] = useState('')
  const [uploading,setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedDocument,setSelectedDocument] = useState({})
  const [addPartner,setAddPartner] = useState(false)
  const [addShareholder,setAddShareholder] = useState(false)
  const [partnerToEdit,setPartnerToEdit] = useState({})
  const [shareholderToEdit,setShareholderToEdit] = useState({})
  const [addOfficer,setAddOfficer] = useState(false)
  const [officerToEdit,setOfficerToEdit] = useState({})
  const [lastErrorSection,setLastErrorSection] = useState(null)
  const [debouncedData, setDebouncedData] = useState(activeFormData?.data);
  const [isFormDataSaveing,setIsFormDataSaveing] = useState(false)



  useEffect(() => {
    setDebouncedData(activeFormData?.data);
  }, [activeFormData?.data]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedData && !previewMode) {
        dispatch(allActions.dashboard.resetTaxPlanning());
        dispatch(allActions.dashboard.resetTaxPreparation());
        const taxPlanObj = {
          formData: activeFormData?.data,
          formName: {
            name: activeFormData?.name,
            message: "",
            formData: activeFormData?.data,
          },
          service_id: activeFormData?.service_id,
          form_id: activeFormData?.id,
          method: "upload",
          job_uuid: selectedJob,
          lead_id: activeFormData?.lead_id,
        };
        taxPlanObj.job_uuid = selectedJob;
        setAutoSave(true)
        setIsFormDataSaveing(true)
        callTaxPreparationOnFormSave(taxPlanObj);
      }
    }, 60000);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedData]);



  useEffect(() => {
    if (taxPreparation?.state === true) {
        setIsFormDataSaveing(false)
      // if Success
      if (formSubmit) {
        setIsFormModalOpen(null);
        setformSubmit(false);
      } else if(!formSubmit && !autoSave) {
        proceedNextStep();
        dispatch(allActions.global.Loader(false));
      }
      dispatch(allActions.dashboard.resetTaxPreparation());
    }

    // if Error
    if (taxPreparation?.state === false) {
      // Show Message Alert
      setMessage({
        show: true,
        type: "Error",
        text:
          typeof taxPreparation.message === "string"
            ? taxPreparation.message
            : "Something Went Wrong !",
      });
    }
    if (taxPreparation?.state === "idle") setMessage({ show: false });

    return () => {
      dispatch(allActions.global.Loader(false));
    };
  }, [taxPreparation.state]);

  useEffect(() => {
    setDividerErrors({})
  }, [activeDivider]);

  useEffect(() => {
    if (taxPreparationOnFormSave?.state === true) {
      setIsFormDataSaveing(false)
      if (formSubmit) {
        setIsFormModalOpen(null);
        setformSubmit(false);
      } else if(!formSubmit && !autoSave) {
        proceedNextStep();
        handleGetJobForms(job?.id)
        dispatch(allActions.global.Loader(false));
      }
      dispatch(allActions.dashboard.resetTaxPreparationOnFormSave());
    }

    // if Error
    if (taxPreparationOnFormSave?.state === false) {
      // Show Message Alert
      setMessage({
        show: true,
        type: "Error",
        text:
            typeof taxPreparationOnFormSave.message === "string"
                ? taxPreparationOnFormSave.message
                : "Something Went Wrong !",
      });
    }
    if (taxPreparationOnFormSave?.state === "idle") setMessage({ show: false });

    return () => {
      dispatch(allActions.global.Loader(false));
    };
  }, [taxPreparationOnFormSave.state]);

  const groupedData = activeFormData?.data?.sections
  .find((sec) => sec?.id === activeSection)
  ?.subsections?.find((sub) => sub?.id === activeSubSection)
  ?.fields?.reduce((acc, item) => {
    if (item && item.type === "divider") {
        acc.push({ header: item.question, children: [] });
      } else if (acc.length > 0) {
        acc[acc.length - 1].children.push(item);
      }
      return acc;
    }, []);

    useEffect(() => {
    if(groupedData?.length > 0){
    setActiveDivider(0)
    }else{
      setActiveDivider(null)
    }
    },[activeSubSection])

  const callTaxPreparation = (taxPreparationObj) => {
    dispatch(allActions.dashboard.resetTaxPreparation());
    dispatch(allActions.dashboard.taxPreparation({ obj: taxPreparationObj }));
  };
  const callTaxPreparationOnFormSave = (taxPreparationObj) => {
    dispatch(allActions.dashboard.resetTaxPreparationOnFormSave());
    dispatch(allActions.dashboard.taxPreparationOnFormSave({ obj: taxPreparationObj }));
  };


  const findSectionIndex = (id) =>
    activeFormData?.data?.sections?.findIndex((section) => section.id === id);
  const findSubSectionIndex = (section, id) =>
    section?.subsections?.findIndex((subsection) => subsection?.id === id);

  const proceedNextStep = () => {
    const currentSectionIndex = findSectionIndex(activeSection);

    if (currentSectionIndex === -1 || !activeFormData?.data?.sections?.[currentSectionIndex]) {
      return;
    }

    const currentSection = activeFormData?.data?.sections[currentSectionIndex];
    const currentSubSectionIndex = findSubSectionIndex(currentSection, activeSubSection);

    if (currentSubSectionIndex === -1 || !currentSection?.subsections?.[currentSubSectionIndex]) {
      return;
    }

    if (currentSubSectionIndex === currentSection?.subsections?.length - 1) {
      if (currentSectionIndex < activeFormData?.data?.sections?.length - 1) {
        const nextSection = activeFormData?.data?.sections[currentSectionIndex + 1];
        if (nextSection?.id && nextSection?.subsections?.[0]?.id) {
          setActiveSection(nextSection.id);
          setActiveSubSection(nextSection.subsections[0].id);
        }
      }
    } else {
      if (currentSection?.subsections?.[currentSubSectionIndex + 1]?.id) {
        setActiveSubSection(currentSection.subsections[currentSubSectionIndex + 1].id);
      }
    }
  };



  const handleBack = () => {
    const currentSectionIndex = findSectionIndex(activeSection);
    const currentSection = activeFormData?.data.sections[currentSectionIndex];
    const currentSubSectionIndex = findSubSectionIndex(
      currentSection,
      activeSubSection
    );

    if (currentSubSectionIndex === 0) {
      if (currentSectionIndex > 0) {
        const prevSection =
          activeFormData?.data?.sections[currentSectionIndex - 1];
        setActiveSection(prevSection.id);
        setActiveSubSection(
          prevSection.subsections[prevSection.subsections.length - 1].id
        );
      }
    } else {
      setActiveSubSection(
        currentSection.subsections[currentSubSectionIndex - 1].id
      );
    }
  };

  // useEffect(() => {
  //   setLastErrorSection(null)
  // },[activeDivider])

  const handleContinue = () => {
    setAutoSave(false)
    const subsectionFields = activeFormData?.data?.sections
      ?.find((sec) => sec?.id === activeSection)
      ?.subsections?.find((sub) => sub?.id === activeSubSection)?.fields;
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    let allValid = true;
    let allDividerValid = true;

    const newValidationErrors = {};
    const dividerVildationErros = {}

    if(typeof activeDivider === 'number' && groupedData?.length > activeDivider){
      groupedData[activeDivider]?.children?.forEach((question,index) => {
        const { answer, type, required, identifier,validation, condition} = question;
        if (
            type === "horizontal-line" ||
            type === "text-header" ||
            type === "file-upload" ||
            type === "list" ||
            type === "divider" ||
            type === "card" ||
            type === "grid" ||
            (type === 'text-email' && type === 'number-phone' && required === false )

        ) {
          return
        }
        if(condition && !conditionCheck(question)){
          return
        }
        if (
            (required && !answer) ||
            (validation === 'phone' && !required && answer && answer?.length !== 14) ||
            (validation === 'phone' && required && answer?.length !== 14) ||
            (type === 'number-phone' && !required && answer && answer?.length !== 14) ||
            (type === 'number-phone' && required && answer?.length !== 14) ||
            //////////////
            (validation === 'zipcode' && !required && answer && answer?.length !== 5) ||
            (validation === 'zipcode' && required && answer?.length !== 5) ||
            (type === 'number-zipcode' && !required && answer && answer?.length !== 5) ||
            (type === 'number-zipcode' && required && answer?.length !== 5) ||
            /////////////////
            (validation === 'ssn' && !required && answer && answer?.length !== 11) ||
            (validation === 'ssn' && required && answer?.length !== 11) ||
            /////////////////
            /////////////////
            (validation === 'ein' && !required && answer && answer?.length !== 11) ||
            (validation === 'ein' && required && answer?.length !== 11) ||
            /////////////////
            (validation === 'email' && answer?.length > 0  && !required && !validateEmail(answer)) ||
            (validation === 'email'  && required && !validateEmail(answer)) ||
            (required && type === 'text-email' && !validateEmail(answer)) ||
            (type === 'text-email' && answer?.length > 0  && !required && !validateEmail(answer)) ||
            (required && typeof answer === "string" && answer?.trim() === "") ||
            (required && Array.isArray(answer) && answer.length === 0) ||
            (required && typeof answer === "object" &&
                !Array.isArray(answer) &&
                Object.keys(answer).length === 0)
        ) {
          allDividerValid = false;
          dividerVildationErros[index] = true;
          dividerVildationErros["name"] = identifier;
        } else {
          dividerVildationErros[index] = false;
        }
      })
      setDividerErrors(dividerVildationErros)
      if(allDividerValid){
        setActiveDivider(prev => prev + 1)
      }
    }

    subsectionFields.forEach((question, index) => {
      const { answer, type, required, identifier,validation, condition} = question;
      if (
        type === "horizontal-line" ||
        type === "text-header" ||
        type === "file-upload" ||
        type === "list" ||
        type === "divider" ||
        type === "card" ||
        type === "grid" ||
        (type === 'text-email' && type === 'number-phone' && required === false )

      ) {
        return
      }
      if(condition && !conditionCheck(question)){
        return
      }
      if (
        (required && !answer) ||
        (validation === 'phone' && !required && answer && answer?.length !== 14) ||
        (validation === 'phone' && required && answer?.length !== 14) ||
        (type === 'number-phone' && !required && answer && answer?.length !== 14) ||
        (type === 'number-phone' && required && answer?.length !== 14) ||
        //////////////
        (validation === 'zipcode' && !required && answer && answer?.length !== 5) ||
        (validation === 'zipcode' && required && answer?.length !== 5) ||
        (type === 'number-zipcode' && !required && answer && answer?.length !== 5) ||
        (type === 'number-zipcode' && required && answer?.length !== 5) ||
        /////////////////
        (validation === 'ssn' && !required && answer && answer?.length !== 11) ||
        (validation === 'ssn' && required && answer?.length !== 11) ||
        /////////////////
         /////////////////
         (validation === 'ein' && !required && answer && answer?.length !== 11) ||
         (validation === 'ein' && required && answer?.length !== 11) ||
         /////////////////
        (validation === 'email' && answer?.length > 0  && !required && !validateEmail(answer)) ||
        (validation === 'email'  && required && !validateEmail(answer)) ||
        (required && type === 'text-email' && !validateEmail(answer)) ||
        (type === 'text-email' && answer?.length > 0  && !required && !validateEmail(answer)) ||
        (required && typeof answer === "string" && answer?.trim() === "") ||
        (required && Array.isArray(answer) && answer.length === 0) ||
        (required && typeof answer === "object" &&
          !Array.isArray(answer) &&
          Object.keys(answer).length === 0)
      ) {
        allValid = false;
        setLastErrorSection(question?.id)
        newValidationErrors[index] = true;
        newValidationErrors["name"] = identifier;
      } else {
        newValidationErrors[index] = false;
      }

    }
  );

     setValidationErrors(newValidationErrors);
    if (allValid) {
      dispatch(allActions.global.Loader(true));
      dispatch(allActions.dashboard.resetTaxPlanning());
      dispatch(allActions.dashboard.resetTaxPreparationOnFormSave());
      const taxPlanObj = {
        formData: activeFormData?.data,
        formName: {
          name: activeFormData?.name,
          message: "",
          formData: activeFormData?.data,
        },
        service_id: activeFormData?.service_id,
        form_id: activeFormData?.id,
        method: "upload",
        job_uuid: selectedJob,
        lead_id: activeFormData?.lead_id,
      };
      taxPlanObj.job_uuid = selectedJob;
      callTaxPreparation(taxPlanObj);
      setActiveDivider(null)

      /// Remove subsection data from localStorage
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key.startsWith("subsection")) {
            localStorage.removeItem(key);
          }
        }
    }
  };

  useEffect(() => {
    setValidationErrors({})
    setDividerErrors({})
  }, [activeSubSection,activeSection]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAutoSave(false)
    const subsectionFields = activeFormData?.data?.sections
      ?.find((sec) => sec?.id === activeSection)
      ?.subsections?.find((sub) => sub?.id === activeSubSection)?.fields;


    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    let allValid = true;
    let allDividerValid = true;
    const newValidationErrors = {};
    const dividerVildationErros = {}

    if(typeof activeDivider === 'number' && groupedData?.length - 1 > activeDivider){
      groupedData[activeDivider]?.children?.forEach((question,index) => {
        const { answer, type, required, identifier,validation, condition} = question;
        if (
            type === "horizontal-line" ||
            type === "text-header" ||
            type === "file-upload" ||
            type === "list" ||
            type === "divider" ||
            type === "card" ||
            type === "grid" ||
            (type === 'text-email' && type === 'number-phone' && required === false )

        ) {
          return
        }
        if(condition && !conditionCheck(question)){
          return
        }
        if (
            (required && !answer) ||
            (validation === 'phone' && !required && answer && answer?.length !== 14) ||
            (validation === 'phone' && required && answer?.length !== 14) ||
            (type === 'number-phone' && !required && answer && answer?.length !== 14) ||
            (type === 'number-phone' && required && answer?.length !== 14) ||
            //////////////
            (validation === 'zipcode' && !required && answer && answer?.length !== 5) ||
            (validation === 'zipcode' && required && answer?.length !== 5) ||
            (type === 'number-zipcode' && !required && answer && answer?.length !== 5) ||
            (type === 'number-zipcode' && required && answer?.length !== 5) ||
            /////////////////
            (validation === 'ssn' && !required && answer && answer?.length !== 11) ||
            (validation === 'ssn' && required && answer?.length !== 11) ||
            /////////////////
            /////////////////
            (validation === 'ein' && !required && answer && answer?.length !== 11) ||
            (validation === 'ein' && required && answer?.length !== 11) ||
            /////////////////
            (validation === 'email' && answer?.length > 0  && !required && !validateEmail(answer)) ||
            (validation === 'email'  && required && !validateEmail(answer)) ||
            (required && type === 'text-email' && !validateEmail(answer)) ||
            (type === 'text-email' && answer?.length > 0  && !required && !validateEmail(answer)) ||
            (required && typeof answer === "string" && answer?.trim() === "") ||
            (required && Array.isArray(answer) && answer.length === 0) ||
            (required && typeof answer === "object" &&
                !Array.isArray(answer) &&
                Object.keys(answer).length === 0)
        ) {
          allDividerValid = false;
          dividerVildationErros[index] = true;
          dividerVildationErros["name"] = identifier;
        } else {
          dividerVildationErros[index] = false;
        }
      })
      setDividerErrors(dividerVildationErros)
      if(allDividerValid){
        setActiveDivider(prev => prev + 1)
      }
    }

    subsectionFields.forEach((question, index) => {
      const { answer, type, required, identifier,validation,condition} = question;
      if (
        type === "horizontal-line" ||
        type === "text-header" ||
        type === "file-upload" ||
        type === "list" ||
        type === "divider" ||
        type === "card" ||
        type === "grid" ||
        (type === 'text-email' && type === 'number-phone' && required === false ) 
      ) {
        return;
      }

      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      if(condition && !conditionCheck(question)){
        return
      }
      if (
        (required && !answer) ||
        (validation === 'phone' && !required && answer && answer?.length !== 14) ||
        (validation === 'phone' && required && answer?.length !== 14) ||
        (type === 'number-phone' && !required && answer && answer?.length !== 14) ||
        (type === 'number-phone' && required && answer?.length !== 14) ||
        //////////////
        (validation === 'zipcode' && !required && answer && answer?.length !== 5) ||
        (validation === 'zipcode' && required && answer?.length !== 5) ||
        (type === 'number-zipcode' && !required && answer && answer?.length !== 5) ||
        (type === 'number-zipcode' && required && answer?.length !== 5) ||
        /////////////////
        (validation === 'ssn' && !required && answer && answer?.length !== 11) ||
        (validation === 'ssn' && required && answer?.length !== 11) ||
        /////////////////
         /////////////////
         (validation === 'ein' && !required && answer && answer?.length !== 11) ||
         (validation === 'ein' && required && answer?.length !== 11) ||
         /////////////////
        (validation === 'email' && answer?.length > 0  && !required && !validateEmail(answer)) ||
        (validation === 'email'  && required && !validateEmail(answer)) ||
        (required && type === 'text-email' && !validateEmail(answer)) ||
        (type === 'text-email' && answer?.length > 0  && !required && !validateEmail(answer)) ||
        (required && typeof answer === "string" && answer?.trim() === "") ||
        (required && Array.isArray(answer) && answer.length === 0) ||
        (required && typeof answer === "object" &&
          !Array.isArray(answer) &&
          Object.keys(answer).length === 0)
      ) {
        allValid = false;
        newValidationErrors[index] = true;
        newValidationErrors["name"] = identifier;
      } else {
        newValidationErrors[index] = false;
      }
    });

    setValidationErrors(newValidationErrors);
    if (allValid) {
      if(typeof activeDivider === 'number' && groupedData?.length-1 > activeDivider){
        setActiveDivider(prev => prev + 1)
        return
      }
      dispatch(allActions.global.Loader(true));
      dispatch(allActions.dashboard.resetTaxPlanning());
      dispatch(allActions.dashboard.resetTaxPreparation());
      const taxPlanObj = {
        formData: activeFormData?.data,
        formName: {
          name: activeFormData?.name,
          message: "",
          formData: activeFormData?.data,
        },
        service_id: activeFormData?.service_id,
        form_id: activeFormData?.id,
        job_uuid: selectedJob,
        method: "upload",
        lead_id: activeFormData?.lead_id,
      };
      console.log("Validation failed. Please fill out all required fields.");
      setformSubmit(true);
      callTaxPreparation(taxPlanObj);
      setActiveDivider(null)
      /// Remove subsection data from localStorage
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith("subsection")) {
          localStorage.removeItem(key);
        }
      }
    }
  };

  const validateDependentForm = () => {
    let formErrors = {};
    if (!dependent.firstName) formErrors.firstName = "First name is required";
    if (!dependent.middleInitial)
      formErrors.middleInitial = "MiddleI nitial is required";
    if (!dependent.lastName) formErrors.lastName = "Last name is required";
    if (!dependent.ssnOrItin) formErrors.ssnOrItin = "SSN/ITIN is required";
    if (!dependent.dateOfBirth)
      formErrors.dateOfBirth = "Date of Birth is required";
    if (
      !dependent.amountPaidToCareProvider ||
      isNaN(dependent.amountPaidToCareProvider)
    )
      formErrors.amountPaidToCareProvider = "Valid amount is required";
    if (!dependent.yearsLivedWithTaxpayer)
      formErrors.yearsLivedWithTaxpayer =
        "Years lived with taxpayer is required";

    setDependentFieldErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleAddDependent = (e) => {
    e.preventDefault();
    if (validateDependentForm()) {
      setActiveFormData((prevForm) => {
        return {
          ...prevForm,
          data: {
            ...prevForm?.data,
            sections: activeFormData?.data?.sections?.map((section) => {
              return {
                ...section,
                subsections: section?.subsections?.map((sub) => {
                  if (sub?.id === activeSubSection) {
                    return {
                      ...sub,
                      fields: sub?.fields?.map((field) => {
                        if (field?.id === activeQustionId) {
                          return {
                            ...field,
                            answer: [...field?.answer, dependent],
                          };
                        }
                        return { ...field };
                      }),
                    };
                  }
                  return sub;
                }),
              };
            }),
          },
        };
      });

      setDependent({
        firstName: "",
        middleInitial: "",
        lastName: "",
        prefix: "",
        ssnOrItin: "",
        dateOfBirth: "",
        relationship: "",
        amountPaidToCareProvider: "",
        yearsLivedWithTaxpayer: "",
        selectAllthatApplayToThisPerson: [],
      });
    }
  };

  const handleDependentChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDependent((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const renderSubmitButton = () => {
    const lastSection =
      activeFormData?.data?.sections[activeFormData?.data?.sections.length - 1];
    const lastSubSection =
      lastSection.subsections[lastSection.subsections.length - 1];
    return activeSubSection === lastSubSection.id;
  };

  const conditionCheck = (question) => {
    const answer = activeFormData?.data?.sections
        ?.find((sec) => sec?.id === activeSection)
        ?.subsections?.find((sub) => sub?.id === activeSubSection)
        ?.fields?.find(
            (field) => field?.question === question?.conditionQuestion
        )
        ?.answer;

    if (typeof answer !== 'string') {
        return false;
    } else {
        const isConditionMet = answer?.toLowerCase() === question?.condition?.toLowerCase();

        if (question?.conditionType === "show") {
            return isConditionMet;
        } else if (question?.conditionType === "hide") {
            return !isConditionMet;
        } else {
            return false;
        }
    }
};


  const handleCardInputChange = (answer, form, q) => {
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data: {
          ...prevForm?.data,
          sections: activeFormData?.data?.sections?.map((section) => {
            return {
              ...section,
              subsections: section?.subsections?.map((sub) => {
                if (sub?.id === activeSubSection) {
                  return {
                    ...sub,
                    fields: sub?.fields?.map((field) => {
                      if (field?.id === form?.id) {
                        return {
                          ...field,
                          questionData: field?.questionData?.map((question) => {
                            if (question?.id === q?.id) {
                              return {
                                ...question,
                                answer: answer,
                              };
                            }
                            return question;
                          }),
                        };
                      }
                      return { ...field };
                    }),
                  };
                }
                return sub;
              }),
            };
          }),
        },
      };
    });
  };

  const handleQuestionChange = (value, id) => {
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data: {
          ...prevForm?.data,
          sections: activeFormData?.data?.sections?.map((section) => {
            return {
              ...section,
              subsections: section?.subsections?.map((sub) => {
                if (sub?.id === activeSubSection) {
                  return {
                    ...sub,
                    fields: sub?.fields?.map((field) => {
                      if (field?.id === id) {
                        return {
                          ...field,
                          answer: value,
                        };
                      }
                      return { ...field };
                    }),
                  };
                }
                return sub;
              }),
            };
          }),
        },
      };
    });
  };



  const handleGridInputChange = (rowId, columnId, fieldQuestion, value) => {
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data: {
          ...prevForm?.data,
          sections: activeFormData?.data?.sections?.map((section) => {
            return {
              ...section,
              subsections: section?.subsections?.map((sub) => {
                if (sub?.id === activeSubSection) {
                  return {
                    ...sub,
                    fields: sub?.fields?.map((field) => {
                      if (field?.question === fieldQuestion) {
                        return {
                          ...field,
                          questionData: {
                            ...field?.questionData,
                            answers: {
                              ...field.questionData?.answers,
                              [rowId]: {
                                ...field.questionData?.answers[rowId],
                                [columnId]: value,
                              },
                            },
                          },
                        };
                      }
                      return { ...field };
                    }),
                  };
                }
                return sub;
              }),
            };
          }),
        },
      };
    });
  };
  const goToStart = () => {
    const sectionId = activeFormData?.data?.sections[0]?.id;
    const subsectionId = activeFormData?.data?.sections[0]?.subsections[0]?.id;
    setActiveSection(sectionId);
    setActiveSubSection(subsectionId);
  };

  const addRow = (question) => {
    const newRow = {};
    question?.questionData?.columns.forEach(col => {
      newRow[col.name] = ''; 
    });
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data:{
            ...prevForm?.data,
            sections:prevForm?.data?.sections?.map(sec => {
              if(sec?.id === activeSection){
                return {
                  ...sec,
                  subsections: sec?.subsections?.map(sub => {
                    if(sub?.id === activeSubSection){
                      return {
                        ...sub,
                        fields: sub?.fields?.map((field) => {
                          if(field?.id === question?.id){
                            return {
                              ...field,
                              questionData: {
                                ...field?.questionData,
                                rows:[...field?.questionData?.rows,newRow]
                              }
                            }
                          }
                          return field
                        })
                      }
                    }
                    return sub
                  })
                }
              }
              return sec
            })
        }
        }
    })

  };

  const handleListFieldChange = (question,rowIndex, columnName, value) => {
    const updatedRows = [...question?.questionData?.rows];
    updatedRows[rowIndex][columnName] = value;
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data:{
            ...prevForm?.data,
            sections:prevForm?.data?.sections?.map(sec => {
              if(sec?.id === activeSection){
                return {
                  ...sec,
                  subsections: sec?.subsections?.map(sub => {
                    if(sub?.id === activeSubSection){
                      return {
                        ...sub,
                        fields: sub?.fields?.map((field) => {
                          if(field?.id === question?.id){
                            return {
                              ...field,
                              questionData: {
                                ...field?.questionData,
                                rows:updatedRows
                              }
                            }
                          }
                          return field
                        })
                      }
                    }
                    return sub
                  })
                }
              }
              return sec
            })
        }
        }
    })
  };

  const handleFileUpload = async (file,questionId,setActiveFileId) => {
    if (file) {
      setUploading(true);
      setActiveFileId(questionId)
  
      const formData = new FormData();
      
      formData.append('documents', file);
  
      formData.append('user_id', LoginData?.id);
      formData.append('type', 1);
        formData.append('document_name', 'Custom form file');
      try {
       const resp = await axios
          .post(`${BASEURL}/api/documents`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setUploadProgress(progress);
            },
          })
          setActiveFileId('')
          const document = resp?.data?.data?.document
          setActiveFormData((prevForm) => {
            return {
              ...prevForm,
              data: {
                ...prevForm?.data,
                sections:
                activeFormData?.data?.sections?.map(
                  (section) => {
                    return {
                      ...section,
                      subsections:
                        section?.subsections?.map(
                          (sub) => {
                            if (
                              sub?.id ===
                              activeSubSection
                            ) {
                              return {
                                ...sub,
                                fields:
                                  sub?.fields?.map(
                                    (
                                      field
                                    ) => {
                                      if (
                                        field?.id ===
                                        questionId
                                      ) {
                                        return {
                                          ...field,
                                          file: [...field?.file,document],
                                        };
                                      }
                                      return {
                                        ...field,
                                      };
                                    }
                                  ),
                              };
                            }
                            return sub;
                          }
                        ),
                    };
                  }
                ),
              }
            
            };
          });
      setUploading(false);
      setUploading(false);
      setUploadProgress(0);
      setSelectedDocument({})    
      } catch (error) {
        console.error(error.message);
      setUploading(false);
      setUploadProgress(0);
    }
   }
  }

  const handleDeleteFile = async (uuid,questionId) => {
    if (uuid) {
      setUploading(true);
      setActiveFileId(questionId)
      setUploadProgress(50)
      try {
         await axios
          .delete(`${BASEURL}/api/documents/${uuid}`)
          setActiveFileId('')
          setUploadProgress(100)
          setActiveFormData((prevForm) => {
            return {
              ...prevForm,
              data: {
                ...prevForm?.data,
                sections:
                activeFormData?.data?.sections?.map(
                  (section) => {
                    return {
                      ...section,
                      subsections:
                        section?.subsections?.map(
                          (sub) => {
                            if (
                              sub?.id ===
                              activeSubSection
                            ) {
                              return {
                                ...sub,
                                fields:
                                  sub?.fields?.map(
                                    (
                                      field
                                    ) => {
                                      if (
                                        field?.id ===
                                        questionId
                                      ) {
                                        return {
                                          ...field,
                                          file: field?.file?.filter((file) => file?.uuid !== uuid),
                                        };
                                      }
                                      return {
                                        ...field,
                                      };
                                    }
                                  ),
                              };
                            }
                            return sub;
                          }
                        ),
                    };
                  }
                ),
              }
            
            };
          });
      setUploading(false);
      setUploading(false);
      setUploadProgress(0);
      setSelectedDocument({})    
      } catch (error) {
        console.error(error.message);
      setUploading(false);
      setUploadProgress(0);
    }
   }
  }


  const handleSavePartner = (partner,question) => {
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data: {
          ...prevForm?.data,
          sections:
          activeFormData?.data?.sections?.map(
            (section) => {
              return {
                ...section,
                subsections:
                  section?.subsections?.map(
                    (sub) => {
                      if (
                        sub?.id ===
                        activeSubSection
                      ) {
                        return {
                          ...sub,
                          fields:
                            sub?.fields?.map(
                              (
                                field
                              ) => {
                                if (field?.id === question?.id) {
                                  return {
                                    ...field,
                                    answer: [...field?.answer, {id:generateUniqueId(),...partner}], 
                                  };
                                }
                                return {
                                  ...field,
                                };
                              }
                            ),
                        };
                      }
                      return sub;
                    }
                  ),
              };
            }
          ),
        }
      
      };
    });
    setAddPartner(false)
    setAddShareholder(false)
    setPartnerToEdit({state:false,data:null})
    setShareholderToEdit({state:false,data:null})
  }
  const handleSaveOfficer = (partner,question) => {
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data: {
          ...prevForm?.data,
          sections:
              activeFormData?.data?.sections?.map(
                  (section) => {
                    return {
                      ...section,
                      subsections:
                          section?.subsections?.map(
                              (sub) => {
                                if (
                                    sub?.id ===
                                    activeSubSection
                                ) {
                                  return {
                                    ...sub,
                                    fields:
                                        sub?.fields?.map(
                                            (
                                                field
                                            ) => {
                                              if (field?.id === question?.id) {
                                                return {
                                                  ...field,
                                                  answer: [...field?.answer, {id:generateUniqueId(),...partner}],
                                                };
                                              }
                                              return {
                                                ...field,
                                              };
                                            }
                                        ),
                                  };
                                }
                                return sub;
                              }
                          ),
                    };
                  }
              ),
        }

      };
    });
    setAddOfficer(false)
    setOfficerToEdit({state:false,data:null})
  }


  const handleSaveEditedPartner = (partner,question) => {
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data: {
          ...prevForm?.data,
          sections:
          activeFormData?.data?.sections?.map(
            (section) => {
              return {
                ...section,
                subsections:
                  section?.subsections?.map(
                    (sub) => {
                      if (
                        sub?.id ===
                        activeSubSection
                      ) {
                        return {
                          ...sub,
                          fields:
                            sub?.fields?.map(
                              (
                                field
                              ) => {
                                if (field?.id === question?.id) {
                                  return {
                                    ...field,
                                     answer: field?.answer?.map((part) => {
                                      if(part?.id === partner?.id ){
                                        return partner
                                      }
                                      return part
                                    }), 
                                  };
                                }
                                return {
                                  ...field,
                                };
                              }
                            ),
                        };
                      }
                      return sub;
                    }
                  ),
              };
            }
          ),
        }
      
      };
    });
    setAddPartner(false)
    setAddShareholder(false)
    setPartnerToEdit({state:false,data:null})
    setShareholderToEdit({state:false,data:null})
  }
  const handleSaveEditedOfficer = (partner,question) => {
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data: {
          ...prevForm?.data,
          sections:
              activeFormData?.data?.sections?.map(
                  (section) => {
                    return {
                      ...section,
                      subsections:
                          section?.subsections?.map(
                              (sub) => {
                                if (
                                    sub?.id ===
                                    activeSubSection
                                ) {
                                  return {
                                    ...sub,
                                    fields:
                                        sub?.fields?.map(
                                            (
                                                field
                                            ) => {
                                              if (field?.id === question?.id) {
                                                return {
                                                  ...field,
                                                  answer: field?.answer?.map((part) => {
                                                    if(part?.id === partner?.id ){
                                                      return partner
                                                    }
                                                    return part
                                                  }),
                                                };
                                              }
                                              return {
                                                ...field,
                                              };
                                            }
                                        ),
                                  };
                                }
                                return sub;
                              }
                          ),
                    };
                  }
              ),
        }

      };
    });
    setAddOfficer(false)
    setOfficerToEdit({state:false,data:null})
  }

  const handlePrimaryContactInfoChange = (fieldName,value,question) => {
    setActiveFormData((prevForm) => {
      return {
        ...prevForm,
        data: {
          ...prevForm?.data,
          sections:
              activeFormData?.data?.sections?.map(
                  (section) => {
                    return {
                      ...section,
                      subsections:
                          section?.subsections?.map(
                              (sub) => {
                                if (
                                    sub?.id ===
                                    activeSubSection
                                ) {
                                  return {
                                    ...sub,
                                    fields:
                                        sub?.fields?.map(
                                            (
                                                field
                                            ) => {
                                              if (field?.id === question?.id) {
                                                return {
                                                  ...field,
                                                  primaryContactInfo:{
                                                    ...field?.primaryContactInfo,
                                                    [fieldName]:value
                                                  }
                                                };
                                              }
                                              return {
                                                ...field,
                                              };
                                            }
                                        ),
                                  };
                                }
                                return sub;
                              }
                          ),
                    };
                  }
              ),
        }

      };
    });

  }

  useEffect(() => {
    goToStart();
  }, []);

  useEffect(() => {
    setLastErrorSection(null)
  }, [activeSubSection]);

  return (
    <div class="position-relative">
      {message.show ? <Message message={message} /> : <></>}

      <div className="custom-modal-close-button">
        <img
          src={CloseIcon}
          alt="img"
          onClick={() => {
            setIsFormModalOpen(false)
             document.body.style.overflowY = '';
          }
          }
        />
      </div>
      <Modal
				title="Document Viewer"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="openDocumentModal"
				showBtn={false}
				btnClass="documentModalClose"
				size="lg"
        zIndex='none'
			>
				<div className="clearfix">
					{selectedDocument &&
						(selectedDocument?.location?.includes('.pdf') ? (
							<>
								<RenderMultiPagePDF file={selectedDocument.path} />
							</>
						) : (
							<img
								src={selectedDocument?.path}
								className="w-100 img-fluid rounded"
								alt={selectedDocument?.name}
								style={{ objectFit: 'contain' }}
							/>
						))}
					<Link
						to={selectedDocument?.path}
						className="btn btn-outline-primary btn-md d-inline-block mt-3"
						target="_blank"
						download
					>
						Download
					</Link>
				</div>
			</Modal>
      <div className="row g-5 justify-content-center">
      {previewMode && <div className="text-center fw-bold text-danger w-100">This form is in preview mode for testing purposes. Inputs entered will not be saved.</div>}
        <div className="col-md-4">
          <div className="form-name">{activeFormData?.name}</div>
          <div className="left-bar-cont">
            {activeFormData?.data?.sections?.length > 0 &&
              activeFormData?.data?.sections?.map((section, index) => {
                return (
                  <div key={section?.id}>
                    <div
                      className="d-flex gap-4 my-3 cursor-pointer"
                      onClick={() => {
                        setActiveSection(section?.id);
                        setActiveSubSection(section?.subsections[0]?.id);
                      }}
                    >
                      <img
                        className={`${
                          activeSection === section?.id
                            ? "arrow-down"
                            : "arrow-right"
                        }`}
                        src={ArrowIcon}
                        alt="arrow-down"
                      />
                      <div className="section-title">{section?.name}</div>
                    </div>
                    {activeSection === section?.id &&
                      section?.subsections?.map((subsection) => {
                        return (
                          <div
                            className={`sub-section-title ${
                              activeSubSection === subsection?.id
                                ? "sub-section-title-active"
                                : ""
                            }`}
                            onClick={() => setActiveSubSection(subsection?.id)}
                          >
                            {subsection?.name}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </div>
        <form className="col-md-8">
          <div className="mt-5 customer-form-section-title">
            {
              activeFormData?.data?.sections?.find(
                (sec) => sec?.id === activeSection
              )?.name
            }
          </div>
          <div className="form-questoins-cont">
            <div className="customer-form-subsection-title">
              {
                activeFormData?.data?.sections
                  ?.find((sec) => sec?.id === activeSection)
                  ?.subsections?.find((sub) => sub?.id === activeSubSection)
                  ?.name
              }
            </div>
            <div className="p-4">
              {groupedData?.length > 0 &&
                groupedData.map((group, index) => (
                  <div
                    key={index}
                    className={`form-questoins-cont px-4 ${groupedData.some((group,idx) =>
                        group?.children?.some(child => (index === idx) && (child?.id === lastErrorSection))
                    ) ? "border border-danger" : ''} `}
                    onClick={() => setActiveDivider(index)}
                  >
                    <div className="d-flex align-items-center py-2  ">
                      {group?.children?.find(
                        (field) => field?.answer === ""
                      ) ? (
                        <img src={CircleIcon} alt="circle" />
                      ) : (
                        <img src={ActiveCircleIcon} alt="circle" />
                      )}
                      <div className="p-3 segment-header cursor-pointer">
                        {group.header}
                      </div>
                    </div>
                    <div
                      className={`${
                        activeDivider === index ? "d-flex" : "d-none"
                      }  flex-column flex-wrap `}
                    >
                      {(groupedData.some((group,idx) =>
                              group?.children?.some(child => (index === idx) && (child?.id === lastErrorSection))) || Object?.keys(dividerErrors)?.length > 0 ) &&
                          Object?.values(dividerErrors)?.find(value => value === true) &&
                          <div className={'text-danger'}>Please make sure to fill out all fields with red *</div>
                      }

                      {group.children.map((child, i) => {
                        if (
                          child?.type === "text-one-line" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3 mt-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                                  <span className="text-danger"> *</span>
                                )}
                              </label>
                              <div className={`col-sm-6`}>
                                <Input
                                  className={`form-control`}
                                  value={child?.answer}
                                  fn={(e) => {
                                    const inputValue = e;
                                    if (!child?.validation && child?.maxLength) {
                                      if (inputValue.length <= child?.maxLength || inputValue.length < child?.answer?.length) {
                                        handleQuestionChange(e, child.id);
                                      }
                                    } else {
                                      handleQuestionChange(e, child.id);
                                    }
                                  }}
                                  type="text"
                                  hasValidationError={
                                    dividerErrors[i]
                                  }
                                  placeholder="Enter your answer here..."
                                  showLabel={false}
                                  mask={
                                    child?.validation === "ssn"
                                      ? "999-99-9999"
                                      : child?.validation === "ein"
                                      ? "99-99999999"
                                      : null
                                  }
                                  max={10}
                                />
                              </div>
                            </div>
                          );
                        }
                        if(child?.type === 'horizontal-line'){
                          return <hr />
                        }
                        if (child?.type === "text-header" &&
                          (!child?.condition || conditionCheck(child))) {
                          return (
                            <div
                            style={{padding:'10px 0px'}}
                              className={` d-flex mt-2  gap-2 `}
                            >
                              <div 
                               className='w-100'
                              style={{
                                fontSize:child?.fontSize ? `${child?.fontSize}px` : '20px',
                                color:child?.color || 'black',
                                fontStyle:child?.fontStyle?.value || 'normal',
                                fontWeight:child?.fontWeight?.value || 'normal',
                                textAlign: child?.textPosition?.value ||  'center',
                              }}
                              >{child?.question}</div>
                            </div>
                          );
                        }
                        if (
                          child?.type === "text-multi-line" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                                  <span className="text-danger"> *</span>
                                )}
                              </label>
                              <div className="col-sm-6">
                                <textarea
                                  value={child?.answer}
                                  className={`form-control ${
                                    dividerErrors[i] ? "is-invalid" : ""
                                  }`}
                                  onChange={(e) =>
                                    handleQuestionChange(e.target.value, child?.id)
                                  }
                                  type="text"
                                  placeholder="Enter your answer here..."
                                ></textarea>
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type === "pre-defined-deductions-list" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                             <ItemizedDeductions question={child?.question}/>
                             
                            </div>
                          );
                        }
                        if (
                          child?.type === "expenses-list" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div className="income-sources-cont mt-3">
                              <div className="d-flex flex-column gap-3">
                                {expensesData?.map((expense) => {
                                  return (
                                    <div className="d-flex align-items-center gap-3">
                                      <img
                                        className="expenses-list-source-icon"
                                        src={LockerSvg}
                                        alt={expense}
                                      />
                                      <div className="fw-bold expenses-list-title">
                                        {expense}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type === "text-comment" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                              <span className="text-danger"> *</span>
                            )}
                              </label>
                              <div className="col-sm-6">
                                <textarea
                                   className={`form-control ${
                                       dividerErrors[i] ? "is-invalid" : ""
                                  }`}
                                  value={child?.answer}
                                  onChange={(e) =>
                                    handleQuestionChange(
                                      e.target.value,
                                      child.id
                                    )
                                  }
                                  type="text"
                                  placeholder="Enter your answer here..."
                                ></textarea>
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type === "text-email" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                                  <span className="text-danger"> *</span>
                                )}
                              </label>
                              <div className="col-sm-6">
                                <input
                                  value={child?.answer}
                                  className={`form-control ${
                                      dividerErrors[i]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={(e) =>
                                    handleQuestionChange(
                                      e.target.value,
                                      child.id
                                    )
                                  }
                                  type="email"
                                  placeholder="Enter your answer here..."
                                />
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type === "number-any-number" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                                  <span className="text-danger"> *</span>
                                )}
                              </label>
                              <div className="col-sm-6">
                                <Input
                                  className={`form-control`}
                                  value={child?.answer}
                                  fn={(e) => {
                                    const inputValue = e;
                                    if (
                                      !child?.validation &&
                                      child?.maxLength
                                    ) {
                                      if (
                                        inputValue.length <= child?.maxLength ||
                                        inputValue.length <
                                          child?.answer?.length
                                      ) {
                                        handleQuestionChange(e, child.id);
                                      }
                                    } else {
                                      handleQuestionChange(e, child.id);
                                    }
                                  }}
                                  type={child?.validation ? "text" : "number"}
                                  hasValidationError={
                                    dividerErrors[i]
                                  }
                                  placeholder="Enter your answer here..."
                                  showLabel={false}
                                  mask={
                                    child?.validation === "ssn"
                                      ? "999-99-9999"
                                      : child?.validation === "ein"
                                      ? "99-99999999"
                                      : child?.validation === "phone"
                                      ? "(999) 999-9999"
                                      : child?.validation === "zipcode"
                                      ? "99999999"
                                      : null
                                  }
                                  formatChars={
                                    child?.validation === "zipcode"
                                      ? {
                                          9: "[0-9-]",
                                        }
                                      : null
                                  }
                                />
                              </div>
                            </div>
                          );
                        }
                        if (child?.type === "pre-defined-partners" && (!child?.condition || conditionCheck(child))) {
                          return (
                            <div className="border border-1 rounded  p-3">
                              <div className="my-3">
                                <table className="table">
                                  <thead className="table-cont">
                                    <tr>
                                      <th scope="col">Partner name</th>
                                      <th scope="col">Partner type</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {child?.answer?.map((partner) => {
                                      return (
                                        <tr
                                          key={index}
                                          className="dependant-rows"
                                        >
                                          <td>
                                            {partner?.type_of_entity ===
                                            "Individual"
                                              ? partner?.firstname
                                              : partner?.entity_name}
                                          </td>
                                          <td>{partner?.type_of_entity}</td>
                                          <td className="d-flex align-items-center gap-2">
                                            <img
                                              onClick={() => {
                                                setPartnerToEdit({
                                                  state: true,
                                                  data: partner,
                                                });
                                              }}
                                              className="cursor-pointer"
                                              style={{
                                                height: "24px",
                                              }}
                                              src={EditIcon}
                                              alt="edit"
                                            />
                                            <img
                                              className="cursor-pointer"
                                              style={{
                                                height: "24px",
                                              }}
                                              src={DeleteIcon2}
                                              alt="delete"
                                              onClick={() => {
                                                setActiveFormData(
                                                  (prevForm) => {
                                                    return {
                                                      ...prevForm,
                                                      data: {
                                                        ...prevForm?.data,
                                                        sections:
                                                          activeFormData?.data?.sections?.map(
                                                            (section) => {
                                                              return {
                                                                ...section,
                                                                subsections:
                                                                  section?.subsections?.map(
                                                                    (sub) => {
                                                                      if (
                                                                        sub?.id ===
                                                                        activeSubSection
                                                                      ) {
                                                                        return {
                                                                          ...sub,
                                                                          fields:
                                                                            sub?.fields?.map(
                                                                              (
                                                                                field
                                                                              ) => {
                                                                                if (
                                                                                  field?.id ===
                                                                                  child?.id
                                                                                ) {
                                                                                  return {
                                                                                    ...field,
                                                                                    answer:
                                                                                      field?.answer?.filter(
                                                                                        (
                                                                                          part
                                                                                        ) =>
                                                                                          part?.id !==
                                                                                          partner?.id
                                                                                      ),
                                                                                  };
                                                                                }
                                                                                return {
                                                                                  ...field,
                                                                                };
                                                                              }
                                                                            ),
                                                                        };
                                                                      }
                                                                      return sub;
                                                                    }
                                                                  ),
                                                              };
                                                            }
                                                          ),
                                                      },
                                                    };
                                                  }
                                                );
                                                setAddPartner(false);
                                                setPartnerToEdit({
                                                  state: false,
                                                  data: null,
                                                });
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                                {addPartner === false && (
                                  <div
                                    className="text-primary fw-bold text-center cursor-pointer"
                                    onClick={() => {
                                      setAddPartner(true);
                                    }}
                                  >
                                    + Add partner
                                  </div>
                                )}
                              </div>
                              <div className="">
                                {(addPartner || partnerToEdit?.state) && (
                                  <PartnersList
                                    setAddPartner={setAddPartner}
                                    taxForm={activeFormData?.data}
                                    setTaxForm={setActiveFormData}
                                    question={child}
                                    activeSection={activeSection}
                                    activeSubSection={activeSubSection}
                                    partnerToEdit={partnerToEdit}
                                    setPartnerToEdit={setPartnerToEdit}
                                    handleSavePartner={handleSavePartner}
                                    handleSaveEditedPartner={
                                      handleSaveEditedPartner
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                        if (
                            child?.type === "pre-defined-shareholders" && (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                              <div className="border border-1 rounded p-3 mt-3">
                                <div className="my-3">
                                  <table className="table">
                                    <thead className="table-cont">
                                    <tr>
                                      <th scope="col">
                                        Shareholder name
                                      </th>
                                      <th scope="col">
                                        Taxpayer identification number
                                      </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {child?.answer?.map(
                                        (partner) => {
                                          return (
                                              <tr
                                                  key={index}
                                                  className="dependant-rows"
                                              >
                                                <td>
                                                  {partner?.type_of_entity === 'Individual' ? partner?.firstname : partner?.entity_name}
                                                </td>
                                                <td>
                                                  {partner?.type_of_entity === 'Individual' ? partner?.ssn : partner?.ein}
                                                </td>
                                                <td className="d-flex align-items-center gap-2">
                                                  <img
                                                      onClick={() => {
                                                        setShareholderToEdit({
                                                          state: true,
                                                          data: partner,
                                                        });
                                                      }}
                                                      className="cursor-pointer"
                                                      style={{
                                                        height: "24px",
                                                      }}
                                                      src={EditIcon}
                                                      alt="edit"
                                                  />
                                                  <img
                                                      className="cursor-pointer"
                                                      style={{
                                                        height: "24px",
                                                      }}
                                                      src={DeleteIcon2}
                                                      alt="delete"
                                                      onClick={() => {
                                                        setActiveFormData((prevForm) => {
                                                          return {
                                                            ...prevForm,
                                                            data: {
                                                              ...prevForm?.data,
                                                              sections:
                                                                  activeFormData?.data?.sections?.map(
                                                                      (section) => {
                                                                        return {
                                                                          ...section,
                                                                          subsections:
                                                                              section?.subsections?.map(
                                                                                  (sub) => {
                                                                                    if (
                                                                                        sub?.id ===
                                                                                        activeSubSection
                                                                                    ) {
                                                                                      return {
                                                                                        ...sub,
                                                                                        fields:
                                                                                            sub?.fields?.map(
                                                                                                (
                                                                                                    field
                                                                                                ) => {
                                                                                                  if (field?.id === child?.id) {
                                                                                                    return {
                                                                                                      ...field,
                                                                                                      answer: field?.answer?.filter((part) => part?.id !== partner?.id ),
                                                                                                    };
                                                                                                  }
                                                                                                  return {
                                                                                                    ...field,
                                                                                                  };
                                                                                                }
                                                                                            ),
                                                                                      };
                                                                                    }
                                                                                    return sub;
                                                                                  }
                                                                              ),
                                                                        };
                                                                      }
                                                                  ),
                                                            }

                                                          };
                                                        });
                                                        setAddPartner(false)
                                                        setPartnerToEdit({state:false,data:null})
                                                      }}
                                                  />
                                                </td>
                                              </tr>
                                          );
                                        }
                                    )}
                                    </tbody>
                                  </table>
                                  {addPartner === false && (
                                      <div
                                          className="text-primary fw-bold text-center cursor-pointer"
                                          onClick={() => {
                                            setAddShareholder(true);
                                          }}
                                      >
                                        + Add shareholder
                                      </div>
                                  )}
                                </div>
                                {(addShareholder ||
                                    shareholderToEdit?.state) && (
                                    <ShareholdersList
                                        corporation={child?.corporation || ''}
                                        setAddShareholder={setAddShareholder}
                                        taxForm={activeFormData?.data}
                                        question={child}
                                        activeSection={activeSection}
                                        handleSaveShareholder={handleSavePartner}
                                        handleSaveEditedShareholder={handleSaveEditedPartner}
                                        activeSubSection={
                                          activeSubSection
                                        }
                                        shareholderToEdit={shareholderToEdit}
                                        setShareholderToEdit={
                                          setShareholderToEdit
                                        }
                                    />
                                )}
                              </div>
                          );
                        }
                        if (
                            child?.type === "pre-defined-officers" && (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                              <div className="border border-1 rounded p-3 mt-3">
                                <div
                                    className='d-flex justify-content-between align-items-center gap-2 deductions-cont'>
                                </div>
                                <div className="my-3">
                                  <table className="table">
                                    <thead className="table-cont">
                                    <tr>
                                      <th scope="col">
                                        Officer name
                                      </th>
                                      <th scope="col">
                                        Title
                                      </th>
                                      <th scope="col">
                                        Compensation
                                      </th>
                                      <th scope="col">
                                        Shareholder
                                      </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {child?.answer?.map(
                                        (officer) => {
                                          return (
                                              <tr
                                                  key={index}
                                                  className="dependant-rows"
                                              >
                                                <td>
                                                  {officer?.firstname}
                                                </td>
                                                <td>
                                                  {
                                                    officer?.title
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    officer?.compensation
                                                  }
                                                </td>
                                                <td>
                                                  {
                                                    officer?.shareholder ? 'Yes' : "No"
                                                  }
                                                </td>
                                                <td className="d-flex align-items-center gap-2">
                                                  <img
                                                      onClick={() => {
                                                        setOfficerToEdit({
                                                          state: true,
                                                          data: officer,
                                                        });
                                                      }}
                                                      className="cursor-pointer"
                                                      style={{
                                                        height: "24px",
                                                      }}
                                                      src={EditIcon}
                                                      alt="edit"
                                                  />
                                                  <img
                                                      className="cursor-pointer"
                                                      style={{
                                                        height: "24px",
                                                      }}
                                                      src={DeleteIcon2}
                                                      alt="delete"
                                                      onClick={() => {
                                                        setActiveFormData((prevForm) => {
                                                          return {
                                                            ...prevForm,
                                                            data: {
                                                              ...prevForm?.data,
                                                              sections:
                                                                  activeFormData?.data?.sections?.map(
                                                                      (section) => {
                                                                        return {
                                                                          ...section,
                                                                          subsections:
                                                                              section?.subsections?.map(
                                                                                  (sub) => {
                                                                                    if (
                                                                                        sub?.id ===
                                                                                        activeSubSection
                                                                                    ) {
                                                                                      return {
                                                                                        ...sub,
                                                                                        fields:
                                                                                            sub?.fields?.map(
                                                                                                (
                                                                                                    field
                                                                                                ) => {
                                                                                                  if (field?.id === child?.id) {
                                                                                                    return {
                                                                                                      ...field,
                                                                                                      answer: field?.answer?.filter((part) => part?.id !== officer?.id ),
                                                                                                    };
                                                                                                  }
                                                                                                  return {
                                                                                                    ...field,
                                                                                                  };
                                                                                                }
                                                                                            ),
                                                                                      };
                                                                                    }
                                                                                    return sub;
                                                                                  }
                                                                              ),
                                                                        };
                                                                      }
                                                                  ),
                                                            }

                                                          };
                                                        });
                                                        setAddPartner(false)
                                                        setPartnerToEdit({state:false,data:null})
                                                      }}
                                                  />
                                                </td>
                                              </tr>
                                          );
                                        }
                                    )}
                                    </tbody>
                                  </table>
                                  {addPartner === false && (
                                      <div
                                          className="text-primary fw-bold text-center cursor-pointer"
                                          onClick={() => {
                                            setAddOfficer(true);
                                          }}
                                      >
                                        + Add officer
                                      </div>
                                  )}
                                </div>
                                {(addOfficer ||
                                    officerToEdit?.state) && (
                                    <OfficersList
                                        corporation={child?.corporation || ''}
                                        setAddOfficer={setAddOfficer}
                                        taxForm={activeFormData?.data}
                                        setTaxForm={setActiveFormData}
                                        question={child}
                                        activeSection={activeSection}
                                        activeSubSection={
                                          activeSubSection
                                        }
                                        officerToEdit={officerToEdit}
                                        setOfficerToEdit={
                                          setOfficerToEdit
                                        }
                                        handleSaveOfficer={handleSaveOfficer}
                                        handleSaveEditedOfficer={handleSaveEditedOfficer}
                                    />
                                )}
                                {child?.answer?.find(off => off?.will_sign_tax_return) ? <>
                                  <hr className={'mt-5'}/>
                                  <div className="fw-bold">The following officer has been selected to sign
                                    the
                                    return
                                  </div>
                                  <div>{child?.answer?.find(off => off?.will_sign_tax_return)?.firstname}</div>
                                  <div
                                      className="fw-bold d-flex justify-content-between align-items-center gap-2">
                                    <div>Is this officer the primary contact person for the corporation?
                                    </div>
                                    <div className="d-flex gap-3">
                                      {['yes', 'no']?.map((answer) => {
                                        return (
                                            <div
                                                className={`yes-no-cont ${
                                                    child?.isPrimaryContact === answer
                                                        ? "yes-no-cont-active"
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                  setActiveFormData((prevForm) => {
                                                    return {
                                                      ...prevForm,
                                                      data: {
                                                        ...prevForm?.data,
                                                        sections:
                                                            activeFormData?.data?.sections?.map(
                                                                (section) => {
                                                                  return {
                                                                    ...section,
                                                                    subsections:
                                                                        section?.subsections?.map(
                                                                            (sub) => {
                                                                              if (
                                                                                  sub?.id ===
                                                                                  activeSubSection
                                                                              ) {
                                                                                return {
                                                                                  ...sub,
                                                                                  fields:
                                                                                      sub?.fields?.map(
                                                                                          (
                                                                                              field
                                                                                          ) => {
                                                                                            if (field?.id === child?.id) {
                                                                                              return {
                                                                                                ...field,
                                                                                                isPrimaryContact:answer
                                                                                              };
                                                                                            }
                                                                                            return {
                                                                                              ...field,
                                                                                            };
                                                                                          }
                                                                                      ),
                                                                                };
                                                                              }
                                                                              return sub;
                                                                            }
                                                                        ),
                                                                  };
                                                                }
                                                            ),
                                                      }

                                                    };
                                                  });

                                                }}
                                            >
                                              {answer}
                                            </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  {child?.isPrimaryContact === 'no' && <>
                                    <div className={'mt-4'}>Primary contact</div>
                                    <hr className={'mt-1'}/>
                                    <div className="fw-bold mt-3">First name</div>
                                    <div className="w-50 mt-2">
                                      <input
                                          type="text"
                                          className="w-full form-control"
                                          onChange={(e) => handlePrimaryContactInfoChange("firstname",e.target.value,child)}
                                          value={child?.primaryContactInfo?.firstname || ''}
                                      />
                                    </div>
                                    <div className="fw-bold mt-3">Middle initial</div>
                                    <div className="w-50 mt-2">
                                      <input
                                          type="text"
                                          className="w-full form-control"
                                          onChange={(e) => handlePrimaryContactInfoChange("middleInitial",e.target.value,child)}
                                          value={child?.primaryContactInfo?.middleInitial || ""}
                                      />
                                    </div>
                                    <div className="fw-bold mt-3">Last name</div>
                                    <div className="w-50 mt-2">
                                      <input
                                          type="text"
                                          className="w-full form-control"
                                          onChange={(e) => handlePrimaryContactInfoChange("lastname",e.target.value,child)}
                                          value={child?.primaryContactInfo?.lastname || ""}
                                      />
                                    </div>
                                    <div className="fw-bold mt-3">Title</div>
                                    <div className="w-50 mt-2">
                                      <input
                                          type="text"
                                          className="w-full form-control"
                                          onChange={(e) => handlePrimaryContactInfoChange("title",e.target.value,child)}
                                          value={child?.primaryContactInfo?.title || ""}
                                      />
                                    </div>
                                    <div className="fw-bold mt-3">SSN</div>
                                    <div className="w-50 mt-2">
                                      <Input
                                          className={`form-control`}
                                          value={child?.primaryContactInfo?.ssn || ""}
                                          fn={(e) => {
                                            handlePrimaryContactInfoChange("ssn",e,child)
                                          }}
                                          placeholder=""
                                          showLabel={false}
                                          mask={"999-99-9999"}
                                          max={10}
                                      />
                                    </div>
                                    <div className="fw-bold mt-3">Email</div>
                                    <div className="w-50 mt-2">
                                      <input
                                          type="text"
                                          className="w-full form-control"
                                          onChange={(e) => handlePrimaryContactInfoChange("email",e.target.value,child)}
                                          value={child?.primaryContactInfo?.email || ""}
                                      />
                                    </div>
                                    <div className="fw-bold mt-3">Phone number</div>
                                    <div className="w-50 mt-2">
                                      <Input
                                          className={`form-control`}
                                          value={child?.primaryContactInfo?.phone_number || ""}
                                          fn={(e) => {
                                            handlePrimaryContactInfoChange("phone_number",e,child)

                                          }}
                                          placeholder=""
                                          showLabel={false}
                                          mask={"(999) 999-9999"}
                                          max={10}
                                      />
                                    </div>
                                  </>}
                                </> : <div>
                                  <div className={'fw-bold'}>No signing officer selected</div>
                                  <hr />
                                  <div className={'mt-3'}>Select the officer who will sign the tax return. Create or open an entry for the signing officer above and check the "Officer will sign the tax return" box.</div>
                                </div>}
                              </div>

                          );
                        }
                        if (child?.type === "file-upload" && (!child?.condition || conditionCheck(child))) {
                          return (
                              <div className="row mb-3 question-cont-form">
                                <div className="col-sm-6 col-form-label">{child?.question}</div>

                                {/* File Uploader Section */}
                                {!uploading && (
                                    <div className="col-sm-6 d-flex gap-3 justify-content-end">
                                      <FileUploader
                                          classes="form-control file-upload file-upload-custom-styles"
                                          multiple={false}
                                          values={[]}
                                          handleChange={(files) => {
                                            handleFileUpload(files, child?.id, setActiveFileId);
                                          }}
                                          name="documents"
                                          types={["jpg", "png", "jpeg", "pdf"]}
                                          maxSize={5} // Size in MB
                                          onSizeError={() => {
                                            console.log("File size exceeds the limit");
                                          }}
                                          onTypeError={() => {
                                            console.log("Incorrect file type");
                                          }}
                                          label="Click here or Drag & Drop files to upload documents"
                                      />
                                    </div>
                                )}

                                {/* Upload Progress Section */}
                                {uploading && child?.id === activeFileID && (
                                    <div className="col-sm-6 d-flex gap-3 justify-content-end">
                                      <div className="progress w-100 custom-progress-styles">
                                        <div
                                            className="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar"
                                            style={{
                                              width: `${uploadProgress}%`,
                                            }}
                                            aria-valuenow={uploadProgress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                          {uploadProgress}%
                                        </div>
                                      </div>
                                    </div>
                                )}

                                {/* File List Section */}
                                {child?.file?.length > 0 ? (
                                    <div className="d-flex flex-column gap-2 align-items-end w-100">
                                      {child?.file?.map((file, index) => {
                                        return (
                                            <div
                                                key={file?.uuid || index} // Ensure unique keys
                                                className="d-flex justify-content-end align-items-center w-100 mt-3 gap-3"
                                            >
                                              <div className="text-primary">{`taxDoc${file?.extension}`}</div>
                                              <div className="d-flex justify-content-end align-items-center gap-2">
                                                <div
                                                    onClick={() => setSelectedDocument(file)}
                                                    className="file-open-icon-cont d-flex align-items-center justify-content-center"
                                                    role="button"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#openDocumentModal"
                                                    aria-controls="openDocumentModal"
                                                    data-bs-id={file.uuid}
                                                >
                                                  <VscEye color="#4B57E7" size={13} />
                                                </div>
                                                <img
                                                    style={{ height: "20px" }}
                                                    src={DeleteIcon2}
                                                    className="cursor-pointer"
                                                    alt="delete"
                                                    onClick={() => {
                                                      handleDeleteFile(file?.uuid, child?.id, setActiveFileId);
                                                    }}
                                                />
                                              </div>
                                            </div>
                                        );
                                      })}
                                    </div>
                                ) : null}
                              </div>
                          );
                        }

                        if (
                          child?.type === "number-phone" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                                  <span className="text-danger"> *</span>
                                )}
                              </label>
                              <div className="col-sm-6">
                                <Input
                                  value={child?.answer}
                                  className={`form-control ${
                                      dividerErrors[i]
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  fn={(value) =>
                                    handleQuestionChange(value, child?.id)
                                  }
                                  type="text"
                                  placeholder="Enter your answer here..."
                                  mask="(999) 999-9999"
                                  showLabel={false}
                                  max={10}
                                />
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type === "number-zipcode" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                              <span className="text-danger"> *</span>
                            )}
                              </label>
                              <div className="col-sm-6">
                                 <Input
                              value={child?.answer}
                              className={`form-control ${
                                  dividerErrors[i] ? "is-invalid" : ""
                              }`}
                              fn={(value) => handleQuestionChange(value, child?.id)}
                              type="text"
                              placeholder="ie. 93882"
                              maskChar={null} 
                              mask={'99999999'}
                              formatChars={{
                                9: "[0-9-]",
                              }}
                              showLabel={false}
                              max={10}
                            />
                                
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type === "number-amount" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                               className="d-flex justify-content-between align-items-center mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label
                                className=""
                                htmlFor={child?.question}
                              >
                                {child?.question}
                                {child?.required && (
                              <span className="text-danger"> *</span>
                            )}
                              </label>
                              <div className="d-flex border border-1 rounded justify-content-between col-sm-6 p-0 amount-percentage-input-cont">
                                <div className="amount-icon-cont">$</div>
                                <input
                                  value={child?.answer}
                                  onWheel={(e) => e.target.blur()}
                                  className={`form-control border-0 ${
                                  dividerErrors[i] ? "is-invalid" : ""
                                  }`}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (!child?.validation && child?.maxLength) {
                                      if (inputValue.length <= child?.maxLength || inputValue.length < child?.answer?.length) {
                                        handleQuestionChange(inputValue, child.id);
                                      }
                                    } else {
                                      handleQuestionChange(inputValue, child.id); 
                                    }
                                  }}
                                  type="number"
                                  id={child?.question}
                                  placeholder="Enter your answer here..."
                                />
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type === "number-percentage" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="d-flex justify-content-between align-items-center mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label
                                className=""
                                htmlFor={child?.question}
                              >
                                {child?.question} {child?.required && (
                              <span className="text-danger"> *</span>
                            )}
                              </label>
                              <div className="d-flex border border-1 rounded justify-content-between col-sm-6 p-0 amount-percentage-input-cont">
                                <input
                                  value={child?.answer}
                                  onWheel={(e) => e.target.blur()}
                                  className={`form-control border-0 ${
                                      dividerErrors[i] ? "is-invalid" : ""
                                  }`}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (!child?.validation && child?.maxLength) {
                                      if (inputValue.length <= child?.maxLength || inputValue.length < child?.answer?.length) {
                                        handleQuestionChange(inputValue, child.id);
                                      }
                                    } else {
                                      handleQuestionChange(inputValue, child.id); 
                                    }
                                  }}
                                  type="number"
                                  id={child?.question}
                                  placeholder="Enter your answer here..."
                                />
                                <div className="amount-icon-cont">%</div>
                              </div>
                            </div>
                          );
                        }
                        
                        if (
                          child?.type === "date" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="row mb-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                              <span className="text-danger"> *</span>
                            )}
                              </label>
                              <div className="col-sm-6">
                             <DatePicker
                              value={
                                child?.answer
                                  ? typeof child?.answer === "string"
                                    ? dayjs(child.answer)
                                    : child?.answer
                                  : null
                              }
                              onChange={(date) => {
                                handleQuestionChange(date, child?.id);
                              }}
                              format={
                                child?.validation === "year"
                                  ? "YYYY"
                                  : "DD/MM/YYYY"
                              }
                              placeholder={
                                child?.validation === "year"
                                  ? "YYYY"
                                  : "DD/MM/YYYY"
                              }
                              className={`form-control ${
                                  dividerErrors[i]  ? "is-invalid" : ""
                              }`}
                              disabledDate={
                                child?.validation === "birthdate"
                                  ? (current) =>
                                      current && current.isAfter(dayjs())
                                  : undefined
                              }
                              picker={
                                child?.validation === "year" ? "year" : undefined
                              }
                            />
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type?.includes("dropdown") &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          const options = child?.questionData?.map((state) => ({
                            value: state,
                            label: state,
                          }));
                          return (
                            <div
                              className="row mb-3 mt-3"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label className="col-sm-6 col-form-label">
                                {child?.question}
                                {child?.required && (
                              <span className="text-danger"> *</span>
                            )}
                              </label>
                              <div className="col-sm-6">
                                <ReactSelect
                                   className={`question-input ${
                                       dividerErrors[i] ? "is-invalid" : ""
                                  }`}
                                  placeholder="Enter your answer here..."
                                  value={
                                    child?.type ===
                                      "dropdown-custom-multy-select" &&
                                    Array.isArray(child?.answer)
                                      ? child?.answer?.map((answrField) => ({
                                          label: answrField,
                                          value: answrField,
                                        }))
                                      : {
                                          label: child?.answer,
                                          value: child?.answer,
                                        }
                                  }
                                  options={options}
                                  isMulti={
                                    child?.type ===
                                    "dropdown-custom-multy-select"
                                  }
                                  onChange={(selected) =>
                                    handleQuestionChange(
                                      child?.type ===
                                        "dropdown-custom-multy-select"
                                        ? selected.map((option) => option.value)
                                        : selected?.value,
                                      child?.id
                                    )
                                  }
                                />
                              </div>
                            </div>
                          );
                        }
                        if (
                          child?.type?.includes("yes-no") &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className="question-cont-form gap-2"
                              onClick={() => setActiveQuestionId(child?.id)}
                            >
                              <label>{child?.question}
                              {child?.required && (
                              <span className="text-danger"> *</span>
                            )}
                              </label>
                              <div className="d-flex align-items-center gap-2">
                                <div className="d-flex gap-3">
                                  {child?.questionData?.map((answer) => {
                                    return (
                                      <div
                                        className={`yes-no-cont ${
                                          child?.answer === answer
                                            ? "yes-no-cont-active"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          handleQuestionChange(
                                            answer,
                                            child.id
                                          );
                                        }}
                                      >
                                        {answer}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if(child?.type === 'list'  &&
                          (!child?.condition || conditionCheck(child))){
                          return (
                            <div className={`mt-2 mb-3`}>
                              <div className="container mt-5">
                                <div className="table-responsive">
                                  <div className="table-scroll-container">
                                    {/* Header Row (Columns) */}
                                    <div className="row ">
                                      {child?.questionData?.columns.map(
                                        (col, index) => (
                                          <div
                                            key={index}
                                            className="col px-3 text-sm mb-1 fw-bold"
                                          >
                                            {col.name}
                                          </div>
                                        )
                                      )}
                                    </div>
                                    {/* Rows */}
                                    {child?.questionData?.rows.map(
                                      (row, rowIndex) => (
                                        <div
                                          key={rowIndex}
                                          className="row mb-2"
                                        >
                                          {child?.questionData?.columns.map(
                                            (col, colIndex) => {
                                              if (
                                                col?.type === "text-one-line"
                                              ) {
                                                return (
                                                  <div
                                                    key={colIndex}
                                                    className="col px-1 text-center"
                                                  >
                                                    <input
                                                      type="text"
                                                      id={col?.name}
                                                      placeholder="Enter your answer..."
                                                      className="form-control"
                                                      style={{ width: "100%" }}
                                                      value={row[col?.name]}
                                                      onChange={(e) =>
                                                        handleListFieldChange(
                                                          child,
                                                          rowIndex,
                                                          col?.name,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                );
                                              }
                                              if (
                                                col?.type === "text-multi-line"
                                              ) {
                                                return (
                                                  <div
                                                    key={colIndex}
                                                    className="col px-1"
                                                  >
                                                    <textarea
                                                      id={col?.name}
                                                      placeholder="Enter your answer..."
                                                      className="form-control"
                                                      style={{ width: "100%" }}
                                                      value={row[col?.name]}
                                                      onChange={(e) =>
                                                        handleListFieldChange(
                                                          child,
                                                          rowIndex,
                                                          col?.name,
                                                          e.target.value
                                                        )
                                                      }
                                                    ></textarea>
                                                  </div>
                                                );
                                              }
                                              if (col?.type === "text-email") {
                                                return (
                                                  <div
                                                    key={colIndex}
                                                    className="col px-1"
                                                  >
                                                    <input
                                                      type="email"
                                                      id={col?.name}
                                                      placeholder="Enter your email here..."
                                                      className="form-control"
                                                      style={{ width: "100%" }}
                                                      value={row[col?.name]}
                                                      onChange={(e) =>
                                                        handleListFieldChange(
                                                          child,
                                                          rowIndex,
                                                          col?.name,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                );
                                              }

                                              if (
                                                col?.type ===
                                                "number-any-number"
                                              ) {
                                                return (
                                                  <div
                                                    key={colIndex}
                                                    className="col px-1"
                                                  >
                                                    <input
                                                      type="number"
                                                      id={col?.name}
                                                      placeholder="Enter your number here..."
                                                      className="form-control"
                                                      style={{ width: "100%" }}
                                                      value={row[col?.name]}
                                                      onWheel={(e) => e.target.blur()}
                                                      onChange={(e) =>
                                                        handleListFieldChange(
                                                          child,
                                                          rowIndex,
                                                          col?.name,
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                );
                                              }

                                              if (
                                                col?.type === "number-amount"
                                              ) {
                                                return (
                                                  <div
                                                    key={colIndex}
                                                    className="col px-1 d-flex align-items-center justify-content-center"
                                                  >
                                                    <div
                                                      className="input-group"
                                                      style={{ width: "100%" }}
                                                    >
                                                      <span className="input-group-text grid-amount">
                                                        $
                                                      </span>
                                                      <input
                                                        type="number"
                                                        id={col?.name}
                                                        placeholder="Enter amount"
                                                        className="form-control"
                                                        value={row[col?.name]}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={(e) =>
                                                          handleListFieldChange(
                                                            child,
                                                            rowIndex,
                                                            col?.name,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                );
                                              }

                                              if (
                                                col?.type ===
                                                "number-percentage"
                                              ) {
                                                return (
                                                  <div
                                                    key={colIndex}
                                                    className="col px-1 d-flex align-items-center justify-content-center"
                                                  >
                                                    <div
                                                      className="input-group "
                                                      style={{ width: "100%" }}
                                                    >
                                                      <input
                                                        type="number"
                                                        id={col?.name}
                                                        placeholder="Enter percentage"
                                                        className="form-control"
                                                        value={row[col?.name]}
                                                        onWheel={(e) => e.target.blur()}
                                                        onChange={(e) =>
                                                          handleListFieldChange(
                                                            child,
                                                            rowIndex,
                                                            col?.name,
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                      <span className="input-group-text grid-amount">
                                                        %
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              }

                                              if (col?.type === "yes-no") {
                                                return (
                                                  <div
                                                    key={colIndex}
                                                    className="col px-1 d-flex align-items-center justify-content-center"
                                                  >
                                                    <div className="d-flex gap-2">
                                                      {col?.questionData?.map(
                                                        (answer) => (
                                                          <div
                                                            key={answer}
                                                            className={`yes-no-cont ${
                                                              row[col?.name] ===
                                                              answer
                                                                ? "yes-no-cont-active"
                                                                : "yes-no-cont"
                                                            }`}
                                                            onClick={() => {
                                                              handleListFieldChange(
                                                                child,
                                                                rowIndex,
                                                                col?.name,
                                                                answer
                                                              );
                                                            }}
                                                          >
                                                            {answer}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }

                                              if (col?.type === "date") {
                                                return (
                                                  <div
                                                    key={colIndex}
                                                    className="col px-1"
                                                  >
                                                    <DatePicker
                                                      format="DD/MM/YYYY"
                                                      placeholder="DD/MM/YYYY"
                                                      className="form-control"
                                                      style={{ width: "100%" }}
                                                      value={
                                                        row[col?.name]
                                                          ? typeof row[
                                                              col?.name
                                                            ] === "string"
                                                            ? dayjs(
                                                                row[col?.name]
                                                              )
                                                            : row[col?.name]
                                                          : null
                                                      }
                                                      onChange={(date) =>
                                                        handleListFieldChange(
                                                          child,
                                                          rowIndex,
                                                          col?.name,
                                                          date
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                );
                                              }
                                              if (
                                                col?.type?.includes("dropdown")
                                              ) {
                                                const options =
                                                  col?.questionData?.map(
                                                    (state) => ({
                                                      value: state,
                                                      label: state,
                                                    })
                                                  );
                                                return (
                                                  <div
                                                    className="col px-1"
                                                    key={index}
                                                  >
                                                    <ReactSelect
                                                      placeholder="Select ..."
                                                      className="question-input-list"
                                                      options={options}
                                                      isMulti={
                                                        col?.type ===
                                                        "dropdown-custom-multy-select"
                                                      }
                                                      value={
                                                        col?.type ===
                                                          "dropdown-custom-multy-select" &&
                                                        Array.isArray(
                                                          row[col?.name]
                                                        )
                                                          ? row[col?.name]?.map(
                                                              (answrField) => ({
                                                                label:
                                                                  answrField,
                                                                value:
                                                                  answrField,
                                                              })
                                                            )
                                                          : row[col?.name]
                                                          ? {
                                                              label:
                                                                row[col?.name],
                                                              value:
                                                                row[col?.name],
                                                            }
                                                          : null 
                                                      }
                                                      onChange={(selected) => {
                                                        handleListFieldChange(
                                                          child,
                                                          rowIndex,
                                                          col?.name,
                                                          col?.type ===
                                                            "dropdown-custom-multy-select"
                                                            ? selected?.map(
                                                                (option) =>
                                                                  option.value
                                                              )
                                                            : selected?.value
                                                        );
                                                      }}
                                                        menuPosition="fixed" 
                                                    />
                                                  </div>
                                                );
                                              }

                                              return null;
                                            }
                                          )}
                                              <div className="col-auto d-flex cursor-pointer del-row-icon" onClick={() => {
                                   setActiveFormData((prevForm) => {
                                    return {
                                      ...prevForm,
                                      data:{
                                          ...prevForm?.data,
                                          sections:prevForm?.data?.sections?.map(sec => {
                                            if(sec?.id === activeSection){
                                              return {
                                                ...sec,
                                                subsections: sec?.subsections?.map(sub => {
                                                  if(sub?.id === activeSubSection){
                                                    return {
                                                      ...sub,
                                                      fields: sub?.fields?.map((field) => {
                                                        if(field?.id === child?.id){
                                                          return {
                                                            ...field,
                                                            questionData: {
                                                              ...field?.questionData,
                                                              rows: field?.questionData?.rows?.filter((_,idx) => idx!== rowIndex) 
                                                            }
                                                          }
                                                        }
                                                        return field
                                                      })
                                                    }
                                                  }
                                                  return sub
                                                })
                                              }
                                            }
                                            return sec
                                          })
                                      }
                                      }
                                  })
                               }}>
                                      <img src={DeleteIcon2} alt="closeIcon" style={{height:'20px'}} />
                                    </div>
                                        </div>
                                        
                                      )
                                    )}
                                   
                                  </div>
                                  <div className="d-flex justify-content-center">
                                    <div
                                      className="cursor-pointer fw-normal text-primary mt-3"
                                      onClick={() => addRow(child)}
                                    >
                                      Add Row
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        } 
                        if (
                          child?.type === "card" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div
                              className={`form-card-cont px-4 border border-1 rounded m-1 py-3 ${
                                validationErrors[index]
                                  ? "border border-danger"
                                  : ""
                              }`}
                            >
                             {child?.showTitle && 
                              <div className="card-question my-3 fw-bold fs-5 ">
                              {child?.question}
                            </div>}
                              <div className="mt-3">
                                {child?.questionData?.length > 0 &&
                                  child?.questionData?.map((q) => {
                                    if (q?.type === "text-one-line") {
                                      return (
                                        <div className="row  p-0 justify-content-between align-items-start mb-3" key={index}>
                                          <div className="col-md-7 text-start">
                                         <label
                                            htmlFor={q?.label}
                                          >
                                            {q?.label}
                                          </label>
                                         </div>
                                         <div className="col-md-5 ">
                                            <input
                                              type="text"
                                              id={q?.label}
                                              placeholder="Enter your answer here..."
                                              className="question-input form-control"
                                              value={q?.answer}
                                              onChange={(e) =>
                                                handleCardInputChange(
                                                  e.target.value,
                                                  child,
                                                  q
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (q?.type === "text-email") {
                                      return (
                                        <div className="row  p-0 justify-content-between align-items-start mb-3" key={index}>
                                          <div className="col-md-7 text-start">
                                          <label
                                            htmlFor={q?.label}
                                          >
                                            {q?.label}
                                          </label>
                                          </div>
                                          <div className="col-md-5 ">
                                            <input
                                              value={q?.answer}
                                              onChange={(e) =>
                                                handleCardInputChange(
                                                  e.target.value,
                                                  child,
                                                  q
                                                )
                                              }
                                              type="email"
                                              id={q?.label}
                                              placeholder="Enter your answer here..."
                                              className="question-input form-control"
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (q?.type === "number-any-number") {
                                      return (
                                        <div
                                          className="row p-0 justify-content-between align-items-start mb-3"
                                          key={index}
                                        >
                                          <div className="col-md-7 text-start">
                                            <label htmlFor={q?.label}>
                                              {q?.label}
                                            </label>
                                          </div>
                                          <div className="col-md-5">
                                            <input
                                              value={q?.answer}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) =>
                                                handleCardInputChange(
                                                  e.target.value,
                                                  child,
                                                  q
                                                )
                                              }
                                              type="number"
                                              id={q?.label}
                                              placeholder="Enter your answer here..."
                                              className="form-control"
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    
    
                                    if (q?.type === "number-amount") {
                                      return (
                                        <div
                                          className="row p-0 justify-content-between align-items-start mb-3"
                                          key={index}
                                        >
                                          <div className="col-md-7 text-start">
                                            <label htmlFor={q?.label}>
                                              {q?.label}
                                            </label>
                                          </div>
                                          <div className="col-md-5 d-flex align-items-center">
                                            <div className="input-group">
                                              <span className="input-group-text grid-amount">
                                                $
                                              </span>
                                              <input
                                                value={q?.answer}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) =>
                                                  handleCardInputChange(  
                                                    e.target.value,
                                                    child,
                                                    q
                                                  )
                                                }
                                                type="number"
                                                id={q?.label}
                                                placeholder="Enter your answer here..."
                                                className="form-control"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (q?.type === "number-percentage") {
                                      return (
                                        <div
                                          className="row p-0 justify-content-between align-items-start mb-3"
                                          key={index}
                                        >
                                          <div className="col-md-7 text-start">
                                            <label htmlFor={q?.label}>
                                              {q?.label}
                                            </label>
                                          </div>
                                          <div className="col-md-5 d-flex align-items-center">
                                            <div className="input-group">
                                              <input
                                                value={q?.answer}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) =>
                                                  handleCardInputChange(
                                                    e.target.value,
                                                    child,
                                                    q
                                                  )
                                                }
                                                type="number"
                                                id={q?.label}
                                                placeholder="Enter your answer here..."
                                                className="[&::-webkit-inner-spin-button]:appearance-none form-control"
                                              />
                                              <span className="input-group-text grid-amount">
                                                %
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                    if(q?.type === 'horizontal-line'){
                                      return <hr className="horizontal-line p-0"/>
                                    }
                                    if (q?.type === "yes-no") {
                                      return (
                                        <div className="row p-0 justify-content-between align-items-start mb-3" key={index}>
                                           <div className="col-md-7 text-start">
                                            <label htmlFor={q?.label}>
                                              {q?.label}
                                            </label>
                                          </div>
                                          <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                            <div className="d-flex gap-3">
                                              {q?.questionData?.map(
                                                (answer) => {
                                                  return (
                                                    <div
                                                  className={`yes-no-cont ${
                                                    q?.answer === answer
                                                      ? "yes-no-cont-active"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    handleCardInputChange(
                                                      answer,
                                                      child,
                                                      q
                                                    );
                                                  }}
                                                >
                                                  {answer}
                                                </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (q?.type === "date") {
                                      return (
                                        <div
                                          className="row p-0 justify-content-between align-items-start mb-3"
                                          key={index}
                                        >
                                          <div className="col-md-7 text-start">
                                            <label
                                              htmlFor={q?.label}
                                            >
                                              {q?.label}
                                            </label>
                                          </div>
                                          <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                          <DatePicker
                                              value={
                                                q?.answer
                                                  ? typeof q?.answer === "string"
                                                    ? dayjs(q.answer)
                                                    : q?.answer
                                                  : null
                                              }
                                              onChange={(date) => {
                                                handleCardInputChange(date,child, q);
                                              }}
                                              format="DD/MM/YYYY"
                                              placeholder="DD/MM/YYYY"
                                              className="question-input form-control "
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (q?.type?.includes("dropdown")) {
                                      const options = q?.questionData?.map(
                                        (state) => ({
                                          value: state,
                                          label: state,
                                        })
                                      );
                                      return (
                                        <div
                                        className="row p-0 justify-content-between align-items-start mb-3"
                                        key={index}
                                      >
                                          <div className='col-md-7'>
                                            <label htmlFor={q?.label}>
                                              {q?.label}
                                            </label>
                                          </div>
                                          <div className="col-md-5 d-flex justify-content-end align-items-center gap-2">
                                            <ReactSelect
                                              className="input-cont question-input"
                                              options={options}
                                              isMulti={
                                                q?.type ===
                                                "dropdown-custom-multy-select"
                                              }
                                              value={
                                                q?.type ===
                                                  "dropdown-custom-multy-select" &&
                                                Array.isArray(q?.answer)
                                                  ? q?.answer?.map((answrField) => ({
                                                      label: answrField,
                                                      value: answrField,
                                                    }))
                                                  : {
                                                      label: q?.answer,
                                                      value: q?.answer,
                                                    }
                                              }
                                              onChange={(selected) => {
                                                handleCardInputChange(
                                                  q?.type ===
                                                    "dropdown-custom-multy-select"
                                                    ? selected.map(
                                                        (option) => option.value
                                                      ) // For multi-select, save as array of values
                                                    : selected?.value,
                                                  child,
                                                  q
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                              {child?.allowMultiple &&  <div className="d-flex justify-content-around mt-4 align-items-center">
                                            {!child?.original && (
                                              <div
                                                className="text-danger cursor-pointer"
                                                onClick={() => {
                                                  setActiveFormData(
                                                    (prevForm) => {
                                                      return {
                                                        ...prevForm,
                                                        data: {
                                                          ...prevForm?.data,
                                                          sections:
                                                            activeFormData?.data?.sections?.map(
                                                              (section) => {
                                                                return {
                                                                  ...section,
                                                                  subsections:
                                                                    section?.subsections?.map(
                                                                      (sub) => {
                                                                        if (
                                                                          sub?.id ===
                                                                          activeSubSection
                                                                        ) {
                                                                          return {
                                                                            ...sub,
                                                                            fields:
                                                                              sub?.fields?.filter(
                                                                                (
                                                                                  f
                                                                                ) =>
                                                                                  f?.id !==
                                                                                  child?.id
                                                                              ),
                                                                          };
                                                                        }
                                                                        return sub;
                                                                      }
                                                                    ),
                                                                };
                                                              }
                                                            ),
                                                        },
                                                      };
                                                    }
                                                  );
                                                }}
                                              >
                                                {" "}
                                                Remove {child?.question || 'card'}
                                              </div>
                                            )}
                                            <div
                                              className="text-primary cursor-pointer"
                                              onClick={() => {
                                                const generateUniqueId = () => {
                                                  const timestamp = Date.now().toString(36);
                                                  const randomString = Math.random().toString(36).substring(2, 15);
                                                  const randomSegment = Math.random().toString(36).substring(2, 15);
                                                  return timestamp + randomString + randomSegment;
                                                };
                                              
                                                setActiveFormData((prevForm) => {
                                                  return {
                                                    ...prevForm,
                                                    data: {
                                                      ...prevForm?.data,
                                                      sections: activeFormData?.data?.sections?.map((section) => {
                                                        return {
                                                          ...section,
                                                          subsections: section?.subsections?.map((sub) => {
                                                            if (sub?.id === activeSubSection) {
                                                              // Define the new field
                                                              const newField = {
                                                                ...child,
                                                                id: generateUniqueId(),
                                                                original: false,
                                                                questionData: child?.cardTemplate?.map((tempField) => ({
                                                                  ...tempField,
                                                                  id: generateUniqueId(),
                                                                })),
                                                              };
                                                              return {
                                                                ...sub,
                                                                fields: [
                                                                  ...sub?.fields.slice(0, i + 2), 
                                                                  newField,                           
                                                                  ...sub?.fields.slice(i + 2),   
                                                                ],
                                                              };
                                                            }
                                                            return sub;
                                                          }),
                                                        };
                                                      }),
                                                    },
                                                  };
                                                });
                                              }}
                                              
                                            >
                                              Add new {child?.question || 'card'}
                                            </div>
                                          </div>}
                            </div>
                          );
                        }
                        if (
                          child?.type === "grid" &&
                          (!child?.condition || conditionCheck(child))
                        ) {
                          return (
                            <div className="mt-4 border px-4 py-3 border-1 rounded mb-3">
                              <div className="row mt-3">
                                <div className="col-md-5">
                               {child?.showTitle && 
                                <div className="grid-header-item grid-question d-flex align-items-center">
                                {child?.question}
                              </div>}
                                </div>
                                {child?.questionData?.columns.map((column, index) => (
                                  <div key={column.id} className="col text-center">
                                    {column.label}
                                  </div>
                                ))}
                              </div>
                        
                              {child?.questionData?.rows.map((row) => (
                                 <div key={row.id} className="row mb-2 d-flex align-items-center mt-3">
                                   <div className="col-md-5 align-items-center">
                                    {row.label}
                                  </div>
                        
                                  {child?.questionData?.columns.map((column, columnIndex) => {
                                    const answer =
                                      child.questionData.answers[row.id]?.[column.id] || "";
                        
                                    if (column?.type === "text-one-line") {
                                      return (
                                        <div
                                          className={`question-cont grid-header-item grid-input-cont ${
                                            validationErrors[index] ? "border border-danger" : ""
                                          } col px-1`} // Tightened gap between columns
                                        >
                                          <input
                                            type="text"
                                            id={column?.label}
                                            placeholder="Enter your answer here..."
                                            className="form-control"
                                            value={answer}
                                            onChange={(e) =>
                                              handleGridInputChange(
                                                row.id,
                                                column.id,
                                                child?.question,
                                                e.target.value
                                              )
                                            }
                                            style={{ width: "100%" }} 
                                          />
                                        </div>
                                      );
                                    }
                        
                                    if (column?.type === "text-multi-line") {
                                      return (
                                        <div
                                          className={`question-cont grid-header-item grid-input-cont ${
                                            validationErrors[index] ? "border border-danger" : ""
                                          } col px-1`}
                                        >
                                          <textarea
                                            id={column?.label}
                                            placeholder="Enter your answer here..."
                                            className="form-control"
                                            value={answer}
                                            onChange={(e) =>
                                              handleGridInputChange(
                                                row.id,
                                                column.id,
                                                child?.question,
                                                e.target.value
                                              )
                                            }
                                            style={{ width: "100%" }}
                                          ></textarea>
                                        </div>
                                      );
                                    }
                                    if (column?.type === "text-email") {
                                      return (
                                        <div
                                          className={`question-cont grid-header-item grid-input-cont ${
                                            validationErrors[index] ? "border border-danger" : ""
                                          } col px-1`}
                                        >
                                          <input
                                            type="email"
                                            id={column?.label}
                                            placeholder="Enter your email here..."
                                            className="form-control"
                                            value={answer}
                                            onChange={(e) =>
                                              handleGridInputChange(
                                                row.id,
                                                column.id,
                                                child?.question,
                                                e.target.value
                                              )
                                            }
                                            style={{ width: "100%" }}
                                          />
                                        </div>
                                      );
                                    }
                                    if (column?.type === "number-any-number") {
                                      return (
                                        <div
                                          className={`question-cont grid-header-item grid-input-cont ${
                                            validationErrors[index] ? "border border-danger" : ""
                                          } col px-1`}
                                        >
                                          <input
                                            type="number"
                                            id={column?.label}
                                            placeholder="Enter your number here..."
                                            className="form-control"
                                            value={answer}
                                            onWheel={(e) => e.target.blur()}
                                            onChange={(e) =>
                                              handleGridInputChange(
                                                row.id,
                                                column.id,
                                                child?.question,
                                                e.target.value
                                              )
                                            }
                                            style={{ width: "100%" }}
                                          />
                                        </div>
                                      );
                                    }
                                    if (column?.type === "number-amount") {
                                      return (
                                        <div
                                          className={`question-cont grid-input-cont ${
                                            validationErrors[index] ? "border border-danger" : ""
                                          } col px-1`}
                                        >
                                          <div className="input-group" 
                                              style={{ width: "100%" }}
                                              >
                                            <span className="input-group-text grid-amount">$</span>
                                            <input
                                              type="number"
                                              id={column?.label}
                                              placeholder="Enter amount"
                                              className="form-control"
                                              value={answer}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) =>
                                                handleGridInputChange(
                                                  row.id,
                                                  column.id,
                                                  child?.question,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    }                        
                                    if (column?.type === "number-percentage") {
                                      return (
                                        <div
                                          className={`question-cont grid-input-cont ${
                                            validationErrors[index] ? "border border-danger" : ""
                                          } col px-1`}
                                        >
                                          <div className="input-group"
                                              style={{ width: "100%" }}
                                              >
                                            <input
                                              type="number"
                                              id={column?.label}
                                              placeholder="Enter percentage"
                                              className="form-control"
                                              value={answer}
                                              onWheel={(e) => e.target.blur()}
                                              onChange={(e) =>
                                                handleGridInputChange(
                                                  row.id,
                                                  column.id,
                                                  child?.question,
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <span className="input-group-text grid-amount">%</span>
                                          </div>
                                        </div>
                                      );
                                    }                      
                                    if (column?.type === "yes-no") {
                                      return (
                                        <div
                                          className={`question-cont ${
                                            validationErrors[index] ? "border border-danger" : ""
                                          } col px-1 d-flex align-items-center justify-content-center`}
                                        >
                                          <div className="d-flex gap-2">
                                            {column?.questionData?.map((answer) => (
                                              <div
                                                className="yes-no-cont"
                                                onClick={() => {
                                                  handleGridInputChange(
                                                    row.id,
                                                    column.id,
                                                    child?.question,
                                                    answer
                                                  );
                                                }}
                                              >
                                                {answer}
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      );
                                    }
                                    if (
                                        column?.type?.includes(
                                            "dropdown"
                                        )
                                    ) {
                                      const options =
                                          column?.questionData?.map(
                                              (state) => ({
                                                value: state,
                                                label: state,
                                              })
                                          );
                                      return (
                                          <div
                                              className="col px-1"
                                              key={index}
                                          >
                                            <ReactSelect
                                                className="question-input-list"
                                                options={
                                                  options
                                                }
                                                value={
                                                  column?.type ===
                                                  "dropdown-custom-multy-select" &&
                                                  Array.isArray(child?.questionData?.answers[row?.id] ? child?.questionData?.answers[row?.id][column?.id] : [])
                                                      ? child?.questionData?.answers[row?.id] && child?.questionData?.answers[row?.id][column?.id]?.map((answrField) => ({
                                                    label: answrField,
                                                    value: answrField,
                                                  }))
                                                      : {
                                                        label: child?.questionData?.answers[row?.id] ? child?.questionData?.answers[row?.id][column?.id] : 'Select ...',
                                                        value: child?.questionData?.answers[row?.id] ? child?.questionData?.answers[row?.id][column?.id] : 'Select ...'
                                                      }
                                                }
                                                isMulti={
                                                    column?.type ===
                                                    "dropdown-custom-multy-select"
                                                }
                                                onChange={(selected) =>
                                                    handleGridInputChange(row.id, column.id, child?.question, selected?.value)
                                                }
                                                menuPosition="fixed"

                                            />
                                          </div>
                                      );
                                    }

                                    if (column?.type === "date") {
                                      return (
                                        <div
                                          className={`question-cont grid-input-cont ${
                                            validationErrors[index] ? "border border-danger" : ""
                                          } col px-1`}
                                        >
                                          <DatePicker
                                            value={
                                              answer
                                                ? typeof answer === "string"
                                                  ? dayjs(answer)
                                                  : answer
                                                : null
                                            }
                                            onChange={(date) => {
                                              handleGridInputChange(row.id, column.id, child?.question, date);
                                            }}
                                            format="DD/MM/YYYY"
                                            placeholder="DD/MM/YYYY"
                                            className="form-control"
                                            style={{ width: "100%" }}
                                          />
                                        </div>
                                      );
                                    }
                        
                                    return null;
                                  })}
                                </div>
                              ))}j
                            </div>
                          );
                        }
                        
                      })}
                    </div>
                  </div>
                ))}
              {Object?.keys(validationErrors)?.length > 0 && Object?.values(validationErrors)?.find(value => value === true) && groupedData?.length === 0 &&
                  <div className={'text-danger my-3'}>Please make sure to fill out all fields with red *</div>}
              <RenderInputList groupedData={groupedData}
                               activeSection={activeSection}
                               activeSubSection={activeSubSection}
                               activeFormData={activeFormData}
                               conditionCheck={conditionCheck}
                               setActiveQuestionId={setActiveQuestionId}
                               handleQuestionChange={handleQuestionChange}
                               validationErrors={validationErrors}
                               setPartnerToEdit={setPartnerToEdit}
                               setActiveFormData={setActiveFormData}
                               setAddPartner={setAddPartner}
                               addPartner={addPartner}
                               partnerToEdit={partnerToEdit}
                               handleSavePartner={handleSavePartner}
                               handleSaveEditedPartner={handleSaveEditedPartner}
                               addShareholder={addShareholder}
                               setAddShareholder={setAddShareholder}
                               shareholderToEdit={shareholderToEdit}
                               setShareholderToEdit={setShareholderToEdit}
                               setOfficerToEdit={setOfficerToEdit}
                               officerToEdit={officerToEdit}
                               addOfficer={addOfficer}
                               setAddOfficer={setAddOfficer}
                               handleSaveOfficer={handleSaveOfficer}
                               handleSaveEditedOfficer={handleSaveEditedOfficer}
                               handlePrimaryContactInfoChange={handlePrimaryContactInfoChange}
                               expensesData={expensesData}
                               dependent={dependent}
                               handleDependentChange={handleDependentChange}
                               dependentFielderrors={dependentFielderrors}
                               setDependent={setDependent}
                               handleAddDependent={handleAddDependent}
                               incomeSources={incomeSources}
                               uploading={uploading}
                               handleFileUpload={handleFileUpload}
                               setActiveFileId={setActiveFileId}
                               uploadProgress={uploadProgress}
                               setSelectedDocument={setSelectedDocument}
                               handleDeleteFile={handleDeleteFile}
                               handleCardInputChange={handleCardInputChange}
                               handleGridInputChange={handleGridInputChange}
                               handleListFieldChange={handleListFieldChange}
                               addRow={addRow}
              />
            </div>

            {!previewMode && (
              <div className="d-flex justify-content-end px-5 gap-3 ">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <button
                    type="submit"
                    className="btn text-primary p-2 fw-bold gap-2 mb-3"
                    onClick={(e) => {
                      e.preventDefault();
                      goToStart();
                    }}
                  >
                    Go to Start{" "}
                  </button>
                </div>
                <div className="d-flex gap-3">
                  <button
                    type="submit"
                    className="btn text-primary border border-1 py-2 px-3 fw-bold gap-2 mb-3"
                    onClick={(e) => {
                      e.preventDefault();
                      handleBack();
                    }}
                  >
                    Back{" "}
                  </button>
                  {!renderSubmitButton() && (
                    <button
                      type="submit"
                      disabled={isFormDataSaveing}
                      className="btn btn-primary p-2 fw-bold gap-2 mb-3"
                      onClick={(e) => {
                        e.preventDefault();
                        handleContinue();
                      }}
                    >
                      Continue{" "}
                    </button>
                  )}
                </div>
                {renderSubmitButton() && (
                  <button
                    disabled={isFormDataSaveing}
                    className="btn btn-primary p-2 fw-bold gap-2 mb-3"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit{" "}
                  </button>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default OpenJobForm;
