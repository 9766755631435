import { MILESTONE_TYPE } from "./Type";

const DEFAULT_STATE = {
	createMilestone: { message: "", status: "idle", data: {} },
	getJobMilestone: { message: "", status: "idle", data: [] },
	fundMilestone: { message: "", status: "idle", data: [] },
	updateStatusMilestone: { message: "", status: "idle", data:{} },
	updateMilestone: { message: "", status: 'idle', data:{} },
	deleteMilestone: { message: "", status: 'idle', data:{} },
	milestoneMarkAsPaid: { message: "", status: 'idle', data:{} },

};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const milestone = (state = INITIAL_STATE, action) => {
	if (action.type === MILESTONE_TYPE.createMilestoneSuccess) {
		return {
			...state,
			createMilestone: action.payload,
		};
	}
	else if (action.type === MILESTONE_TYPE.createMilestoneError) {
		return {
			...state,
			createMilestone: action.payload,
		};
	}
	else if (action.type === MILESTONE_TYPE.createMilestoneIdle) {
		return {
			...state,
			createMilestone:DEFAULT_STATE.createMilestone,
		};
	}
	else if (action.type === MILESTONE_TYPE.getJobMilestoneSuccess) {
		return {
			...state,
			getJobMilestone: action.payload,
		};
	}else if (action.type === MILESTONE_TYPE.getJobMilestoneError) {
		return {
			...state,
			getJobMilestone: action.payload,
		};
	}
	else if (action.type === MILESTONE_TYPE.getJobMilestoneIdle) {
		return {
		  ...state,
		  getJobMilestone: DEFAULT_STATE.getJobMilestone,

		};
	  }else if (action.type === MILESTONE_TYPE.fundMilestoneSuccess) {
		return {
			...state,
			fundMilestone: action.payload,
		}
	} else if (action.type === MILESTONE_TYPE.fundMilestoneError) {
		return {
			...state,
			fundMilestone: action.payload,
		};
	} else if (action.type === MILESTONE_TYPE.fundMilestoneIdle) {
		return {
			...state,
			fundMilestone: DEFAULT_STATE.fundMilestone,
		};
	} else if (action.type === MILESTONE_TYPE.updateStatusMilestoneSuccess) {
		return {
			...state,
			updateStatusMilestone: action.payload,
		};
	}else if (action.type === MILESTONE_TYPE.updateStatusMilestoneError) {
		return {
			...state,
			updateStatusMilestone: action.payload,
		};
	}
	else if (action.type === MILESTONE_TYPE.updateStatusMilestoneIdle) {
		return {
			...state,
			updateStatusMilestone: DEFAULT_STATE.updateStatusMilestone,
		};
	}
	else if (action.type === MILESTONE_TYPE.updateMilestoneSuccess) {
		return {
			...state,
			updateMilestone: action.payload,
		};
	}else if (action.type === MILESTONE_TYPE.updateMilestoneError) {
		return {
			...state,
			updateMilestone: action.payload,
		};
	} else if (action.type === MILESTONE_TYPE.updateMilestoneIdle) {
		return {
			...state,
			updateMilestone: DEFAULT_STATE.updateMilestone,

		};
	}else if (action.type === MILESTONE_TYPE.deleteMilestoneSuccess) {
		return {
			...state,
			deleteMilestone: action.payload,
		};
	}else if (action.type === MILESTONE_TYPE.deleteMilestoneError) {
		return {
			...state,
			deleteMilestone: action.payload,
		};
	}else if (action.type === MILESTONE_TYPE.deleteMilestoneIdle) {
		return {
			...state,
			deleteMilestone:DEFAULT_STATE.deleteMilestone,
		};
	}
	else if (action.type === MILESTONE_TYPE.milestoneMarkAsPaidSuccess) {
		return {
			...state,
			milestoneMarkAsPaid: action.payload,
		};
	}
	else if (action.type === MILESTONE_TYPE.milestoneMarkAsPaidError) {
		return {
			...state,
			milestoneMarkAsPaid: action.payload,
		};
	}
	else if (action.type === MILESTONE_TYPE.milestoneMarkAsPaidIdle) {
		return {
			...state,
			milestoneMarkAsPaid: DEFAULT_STATE.milestoneMarkAsPaid,
		};
	}
	return state;
};

export default milestone;
