import { useState } from "react";

const generateUniqueId = () => {
    const timestamp = Date.now().toString(36);
    const randomString = Math.random().toString(36).substring(2, 15);
    const randomSegment = Math.random().toString(36).substring(2, 15);
    return timestamp + randomString + randomSegment;
  };
  

 const AddGridColumnModal = ({
    gridData,
    setAddGridColumnModalOpen,
    setGridData
   }) => {
     const [column, setColumn] = useState("");
     const [errorMessage, setErrorMessage] = useState("");
     return (
       <div
         className="custom-modal-overlay"
         onClick={() => setAddGridColumnModalOpen(false)}
       >
         <div className="custom-modal-cont" onClick={(e) => e.stopPropagation()}>
           <h2 className="custom-modal-heading">Add Column</h2>
           <p className="custom-modal-description">
           Enter label for new list column
           </p>
           <div className="custom-modal-input-cont">
             {errorMessage && <div className="text-danger">{errorMessage}</div>}
             <input
               className={errorMessage ? "border-1 border-danger" : ""}
               type="text"
               id="section-title"
               value={column}
               onChange={(e) => {
                 setColumn(e.target.value);
                 setErrorMessage("");
               }}
               />
           </div>
           <div className="custom-modal-buttons">
             <button
               className="btn"
               onClick={() => {
                 setAddGridColumnModalOpen(false);
               }}
             >
               Cancel
             </button>
             <div
               di
               className="btn btn-primary"
               onClick={() => {
                 const isOptionAdded = gridData?.columns?.find(
                   (col) => col?.label === column
                 );
   
                 if (!isOptionAdded) {
                   setGridData(prev => {
                     return {
                       ...prev,
                       columns:[...prev?.columns,{id:generateUniqueId(),label:column,type:'text-one-line'}]
                     }
                   })
                   setAddGridColumnModalOpen(false);
                   setErrorMessage("");
                 } else {
                   setErrorMessage("The column is added already!");
                 }
               }}
               disabled={column?.length < 3}
             >
               Save
             </div>
           </div>
         </div>
       </div>
     );
   };


 export default  AddGridColumnModal