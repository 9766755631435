/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import editIcon from '../../../assets/img/edit1.svg';
import success from '../../../assets/img/success.jpg';

import Input from '../fields/Input';
import Message from '../fields/Message';
import { MdDelete } from 'react-icons/md';
import TextArea from '../fields/TextArea';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import allActions from '../../../Store/action';
import { CiCirclePlus } from 'react-icons/ci';
import { useSelector } from 'react-redux';

const EditGroupDocumentTable = ({
	Heading,
	selectedGroupDocument,
	editSave,
	editmodalMessage,
	seteditmodalMessage,
	setSelectedGroupDocument,
}) => {
	const [editAllow, seteditAllow] = useState(false);
	const [name, setName] = useState('');
	const [description, setdescription] = useState('');
	const [newName, setNewName] = useState('');
	const [newDescription, setNewDescription] = useState('');
	const [editId, setEditId] = useState('');
	const dispatch = useDispatch();

	const [{ documentNames }, { createDocumentName, editDocumentName, deleteDocumentName }] =
		useSelector((state) => [state.dashboard, state.documentGroup]);

	// create Group  document name
	useEffect(() => {
		if (!createDocumentName.status) {
			dispatch(allActions.global.Loader(false));
			seteditmodalMessage({
				show: true,
				type: 'Error',
				text: createDocumentName?.message || 'server error',
			});
		}
		if (createDocumentName.status === true) {
			dispatch(allActions.global.Loader(false));
			let newDocument = {
				id: createDocumentName?.data?.data?.id,
				description: newDescription,
				message: '',
				name: newName,
			};
			if (newName && newDescription) {
				setSelectedGroupDocument((selectedGroupDocument) => ({
					...selectedGroupDocument, // Spread previous state
					docs_data: {
						...selectedGroupDocument?.docs_data, // Spread docs_data state
						documents: [...selectedGroupDocument?.docs_data.documents, newDocument], // Append new document
					},
				}));
				setNewName('');
				setNewDescription('');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, createDocumentName]);

	// edit Group  document name
	useEffect(() => {
		if (!editDocumentName.status) {
			dispatch(allActions.global.Loader(false));
			seteditmodalMessage({
				show: true,
				type: 'Error',
				text: editDocumentName?.message || 'server error',
			});
		}
		if (editDocumentName.status === true) {
			dispatch(allActions.global.Loader(false));
			seteditmodalMessage({ show: false });
			if (name && description) {
				selectedGroupDocument?.docs_data?.documents.forEach((item) => {
					if (item.id === editId) {
						item.name = name;
						item.description = description;
					}
				});
				setEditId('');
				setName('');
				setdescription('');
				seteditAllow(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, editDocumentName]);

	// delete Group  document name
	useEffect(() => {
		if (!deleteDocumentName.status) {
			dispatch(allActions.global.Loader(false));
			seteditmodalMessage({
				show: true,
				type: 'Error',
				text: deleteDocumentName?.message || 'server error',
			});
		}
		if (deleteDocumentName.status === true) {
			dispatch(allActions.global.Loader(false));
			seteditmodalMessage({ show: false });
			setSelectedGroupDocument((prevData) => ({
				...selectedGroupDocument,
				docs_data: {
					...selectedGroupDocument.docs_data,
					documents: selectedGroupDocument.docs_data.documents.filter((doc) => {
						// Check if every property in `targetDoc` matches the current `doc` property
						return !Object.keys(editId).every((key) => editId[key] === doc[key]);
					}),
				},
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, deleteDocumentName]);

	const asd = (index) => {
		if (editAllow === index) {
			seteditAllow(false);
		} else {
			seteditAllow(index);
		}
	};
	const saveData = (id) => {
		let newDocument = {
			doc_id: id,
			description: description,
			message: '',
			name: name,
		};
		setEditId(id);
		dispatch(allActions.documentsgroup.editdocumentname(newDocument));
	};
	const deleteDocument = (targetDoc) => {
		let newDocument = {
			doc_id: targetDoc.id,
		};
		setEditId(targetDoc);
		dispatch(allActions.documentsgroup.deletedocumentname(newDocument));
	};
	return (
		<div className="my-2">
			{editmodalMessage.show ? <Message message={editmodalMessage} /> : <></>}

			<table className="table">
				<thead>
					<tr>
						{Heading?.map((data) => (
							<th scope="col fw-bolder">{data.heading}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{selectedGroupDocument?.docs_data?.documents?.map((data, index) => {
						return (
							<tr>
								<td>
									{editAllow === index ? (
										<Input type="text" value={name} fn={setName} showLabel={false} />
									) : (
										data?.name
									)}
								</td>
								<td>
									{editAllow === index ? (
										<Input type="text" value={description} fn={setdescription} showLabel={false} />
									) : (
										data?.description
									)}
								</td>
								<td>
									{' '}
									{editAllow !== index ? (
										<img
											style={{ height: '30px' }}
											className="cursor-pointer"
											src={editIcon}
											alt="edit"
											onClick={() => {
												setEditId('');
												seteditmodalMessage({ show: false });
												setName(data?.name);
												setdescription(data?.description);
												asd(index);
											}}
										/>
									) : (
										<img
											style={{ height: '30px' }}
											className="cursor-pointer"
											src={success}
											alt="edit"
											onClick={() => {
												saveData(data?.id);
											}}
										/>
									)}
								</td>
								<td>
									<MdDelete onClick={() => deleteDocument(data)} size={23} color="red" />
								</td>
							</tr>
						);
					})}
					<tr>
						<td>
							<Input
								placeholder={'Write name'}
								type="text"
								value={newName}
								fn={setNewName}
								showLabel={false}
							/>
							<div
								style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 250 }}
							>
								{newName.trim().length > 0 &&
									documentNames
										?.filter((v) => v.name?.toLowerCase().indexOf(newName.toLowerCase()) !== -1)
										.map((v) => {
											return (
												<>
													<Link onClick={() => setNewName(v.name)}>
														<div className="tagContainer">
															<span className="labelSuggestion">{v.name}</span>
															<button className="closeButton">+</button>
														</div>
													</Link>
												</>
											);
										})}
							</div>
						</td>
						<td colSpan={2}>
							<TextArea
								inputName="form-control"
								defaultValue={newDescription}
								placeholder="Details"
								name="details"
								fn={setNewDescription}
								row="6"
								required={false}
								showLabel={false}
							/>
						</td>
						<td>
							<Link
								// className="btn btn-primary"
								onClick={() => {
									let newDocument = {
										description: newDescription,
										message: '',
										name: newName,
									};

									seteditmodalMessage({ show: false });
									dispatch(allActions.global.Loader(true));
									dispatch(allActions.documentsgroup.createdocumentname(newDocument));
								}}
							>
								<CiCirclePlus size={30} />
							</Link>
						</td>
					</tr>
				</tbody>
			</table>

			<button
				className="btn btn-primary mt-3"
				onClick={() => {
					editSave(
						selectedGroupDocument?.docs_data?.documents,
						selectedGroupDocument?.docs_data?.groupName,
						selectedGroupDocument?.id
					);
				}}
			>
				Save
			</button>
		</div>
	);
};

export default EditGroupDocumentTable;
