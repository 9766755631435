/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import SweetAlert2 from 'react-sweetalert2';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setQueryParams, getQueryParam, removeQueryParam } from '../../../utilites';
import allActions from '../../../Store/action';
import ListPageWrapper from '../components/ListPageWrapper';
import Input from '../../../components/forms/fields/Input';
import Message from '../../../components/forms/fields/Message';
import PlusSvg from '../../../assets/img/plussvg.svg';
import editIcon from '../../../assets/img/edit1.svg';
import DeleteIcon2 from '../../../assets/img/delete1.svg';
import CreateNewForm from '../../../components/forms/CreateNewForm';
import { FaEye } from 'react-icons/fa';
import OpenJobForm from '../../../components/forms/OpenJobForm';
import { FaRegClone } from 'react-icons/fa6';
import { VscEye } from 'react-icons/vsc';
import QuestionaireSvg from '../../../assets/img/quetenionairesvg.svg';
import Modal from '../../../components/Dashboard/Modal';
import Select from '../../../components/forms/fields/Select';
import axios from 'axios';
import { BASEURL } from '../../../utilites';
import RequestJobDocumentForm from '../../../components/forms/RequestJobDocumentForm';
import GroupDocumentTable from '../../../components/forms/Table/groupDocumentTable';
import { doc } from 'firebase/firestore';
import EditGroupDocumentTable from '../../../components/forms/Table/editGroupDocumentTable';

const ChangeFormStatus = ({
	handleChangeStatus,
	formToChangeStatus,
	setFormToChangeStatus,
	setMessage,
}) => {
	const [status, setStatus] = useState(
		formToChangeStatus?.status ? formToChangeStatus?.status : ''
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (Object.keys(formToChangeStatus)?.length > 0) {
			setStatus(formToChangeStatus?.status);
		}
	}, [formToChangeStatus]);


	return (
		<div className="mb-3">
			<Select
				// eslint-disable-next-line eqeqeq
				value={status == 1 ? '1' : '0'}
				name="status"
				placeholder="User Status"
				showLabel={false}
				fn={(val) => setStatus(val)}
			>
				<option value="1">Published</option>
				<option value="0">Pending</option>
			</Select>
			<button
				className="btn btn-primary mt-3"
				onClick={() => {
					let body = {
						doc_id: formToChangeStatus?.id,
						status_id: status,
					};
					setMessage({ show: false });
					dispatch(allActions.global.Loader(true));
					dispatch(allActions.documentsgroup.markdocumentsgroupidle());
					dispatch(allActions.documentsgroup.markdocumentsgroup(body));
				}}
			>
				Submit
			</button>
		</div>
	);
};

const DocumentsGroup = () => {
	const [
		{ loginStatus, LoginData },
		{ services },
		{ documentGroup, createDocumentGroup, deleteDocumentGroup, markDocumentGroup,editDocumentGroup },
	] = useSelector((state) => [state.user, state.dashboard, state.documentGroup]);
	const dispatch = useDispatch();
	const [alertMessage, setAlertMessage] = useState('');
	const [message, setMessage] = useState({ show: false });
	const [modalMessage, setmodalMessage] = useState({ show: false });
	const [editmodalMessage, seteditmodalMessage] = useState({ show: false });
	const [selectedGroupDocument, setSelectedGroupDocument] = useState('');

	const [filteredForms, setFilteredForms] = useState(null);
	const [groupName, setGroupName] = useState('');
	const [formToChangeStatus, setFormToChangeStatus] = useState({});
	const [swalProps, setSwalProps] = useState({});

	useEffect(() => {
		if (loginStatus) {
			dispatch(allActions.global.Loader(true));
			dispatch(allActions.documentsgroup.getdocumentsgroup());
		}
	}, [loginStatus, dispatch]);

	// get   Group document
	useEffect(() => {
		if (!documentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: documentGroup.message,
			});
		}
		if (documentGroup.status === true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, documentGroup]);

	// create Group  document
	useEffect(() => {
		if (!createDocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setmodalMessage({
				show: true,
				type: 'Error',
				text: createDocumentGroup.message,
			});
		}
		if (createDocumentGroup.status === true) {
			document.querySelector('.requestJobDocumentsModalClose').click();
			dispatch(allActions.documentsgroup.getdocumentsgroup());
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, createDocumentGroup]);

	// delete Group  document
	useEffect(() => {
		if (!deleteDocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: deleteDocumentGroup.message,
			});
		}
		if (deleteDocumentGroup.status === true) {
			dispatch(allActions.documentsgroup.getdocumentsgroup());
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, deleteDocumentGroup]);

	// mark Group  document
	useEffect(() => {
		if (!markDocumentGroup.status) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: markDocumentGroup.message,
			});
		}
		if (markDocumentGroup.status === true) {
			setFormToChangeStatus('');
			document.querySelector('.openChangeFormStatusModal').click();
			dispatch(allActions.documentsgroup.getdocumentsgroup());
			dispatch(allActions.global.Loader(false));
		}
	}, [dispatch, markDocumentGroup]);

		// edit Group  document
		useEffect(() => {
			if (!editDocumentGroup.status) {
				dispatch(allActions.global.Loader(false));
				seteditmodalMessage({
					show: true,
					type: 'Error',
					text: editDocumentGroup.message,
				});
			}
			if (editDocumentGroup.status === true) {
				setFormToChangeStatus('');
				document.querySelector('.editGroupDocumentsModalClose').click();
				dispatch(allActions.documentsgroup.getdocumentsgroup());
				dispatch(allActions.global.Loader(false));
			}
		}, [dispatch, editDocumentGroup]);

	const deleteDocById = async (id) => {
		setSwalProps((prev) => ({
			...prev,
			[`ud-${id}`]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				confirmButtonText: 'Confirm',
				denyButtonText: 'Cancel',
				text: 'Do you want to delete this form?',
				didClose: () => {
					setSwalProps({});
				},
				onConfirm: () => {
					let body = {
						doc_id: id,
					};
					dispatch(allActions.global.Loader(true));
					dispatch(allActions.documentsgroup.deletedocumentsgroupidle());
					dispatch(allActions.documentsgroup.deletedocumentsgroup(body,'delete-groupDocument-byadmin'));
					setAlertMessage('Form has been deleted');
					setSwalProps({});
				},
			},
		}));
	};

	const Rows = ({ docs }) => {
		console.log('@11111 step2', docs);

		return (
			docs?.length > 0 &&
			docs?.map((doc) => (
				<tr className="align-middle" key={doc.id}>
					<th scope="row">{doc.id}</th>
					<td className="text-md-start text-center ">
						<Link
							className="text-decoration-none d-flex align-items-center"
							data-bs-toggle="modal"
							data-bs-target="#groupDocumentsModal"
							onClick={() => setSelectedGroupDocument(doc)}
						>
							<img src={QuestionaireSvg} alt="file" />

							<div className="text-dark ps-1 mx-3">{`${doc?.docs_data?.groupName}`}</div>
						</Link>
					</td>
					<td width="30%">
						<div className="w-75 text-truncate">{`${doc?.user?.firstname} ${doc?.user?.lastname}`}</div>
					</td>
					<td width="30%">
						{doc?.status === 0 ? (
							<Link
								data-bs-toggle="modal"
								data-bs-target="#openChangeFormStatusModal"
								aria-controls="openChangeFormStatusModal"
								className={`btn btn-grey-400 btn-sm text-white`}
								onClick={() => setFormToChangeStatus(doc)}
							>
								Pending
							</Link>
						) : (
							<Link
								className={`btn btn-success btn-sm text-white`}
								data-bs-toggle="modal"
								data-bs-target="#openChangeFormStatusModal"
								aria-controls="openChangeFormStatusModal"
								onClick={() => setFormToChangeStatus(doc)}
							>
								Published
							</Link>
						)}
					</td>
					<td className="">
						<div className="d-flex gap-2">
							<Link onClick={() => setSelectedGroupDocument(doc)}>
								<div
									className="action-icon-cont"
									data-bs-toggle="modal"
									data-bs-target="#groupDocumentsModal"
								>
									<VscEye color="#4B57E7" size={13} />
								</div>
							</Link>
							<Link
								data-bs-toggle="modal"
								data-bs-target="#editGroupDocumentsModal"
								aria-controls="editGroupDocumentsModal"
							>
								<img
									style={{ height: '30px' }}
									className="cursor-pointer"
									src={editIcon}
									alt="edit"
									onClick={() => {
										seteditmodalMessage({show:false})
										setSelectedGroupDocument(doc);
									}}
								/>
							</Link>
							<Link
								to={'#'}
								onClick={() => {
									deleteDocById(doc.id);
								}}
							>
								{swalProps[`ud-${doc?.id}`]?.show && (
									<SweetAlert2 {...swalProps[`ud-${doc?.id}`]} />
								)}
								<img
									style={{ height: '30px' }}
									className="cursor-pointer"
									src={DeleteIcon2}
									alt="edit"
								/>
							</Link>
						</div>
					</td>
				</tr>
			))
		);
	};

	const handleRequest = (doc) => {
		let body = {
			doc_data: {
				documents: doc,
				groupName: groupName,
			},
		};
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.documentsgroup.createdocumentsgroupidle());
		dispatch(allActions.documentsgroup.createdocumentsgroup(body,'create-groupDocument-byAdmin'));
	};

	const editSave=(doc,groupName,id)=>{
		let body = {
			doc_id:id,
			doc_data: {
				documents: doc,
				groupName: groupName,
			},
		};
		dispatch(allActions.global.Loader(true));
		dispatch(allActions.documentsgroup.editdocumentsgroupidle());
		dispatch(allActions.documentsgroup.editdocumentsgroup(body));
	};	
	  
	return (
		<>
			<ListPageWrapper
				heading="Group Documents"
				headerControls={
					<Fragment>
						<div className="col-md-12">
							{message.show ? <Message message={message} /> : <></>}
							<div className="row g-2 justify-between">
								<div className="col-md-2">
									{/* <Input
										type="search"
										value={keyword}
										name="name"
										placeholder="Search by name"
										fn={filterByKeyword}
										showLabel={false}
										inputClass="search-input border h-auto min-height-initial"
										extraSetter={() => {
											// if (page !== 1) moveTo(1);
										}}
										required={false}
									/> */}
								</div>

								<div
									className={`col-md-${
										LoginData.isAdmin || LoginData.isSupport || LoginData.isAccountManager
											? '10'
											: '5'
									}`}
								>
									<Link
										className="formButton rounded px-4 float-md-end float-none d-flex justify-content-center align-items-center gap-1"
										data-bs-toggle="modal"
										data-bs-target="#requestJobDocumentsModal"
									>
										<img style={{ height: '15px' }} src={PlusSvg} alt="plus" />
										Create new
									</Link>
								</div>
							</div>
						</div>
					</Fragment>
				}
				alert={{ message: alertMessage, setMessage: setAlertMessage }}
				dataState={{
					loaded: true,
					fetching: false,
				}}
				tableHeader={
					<Fragment>
						<th scope="col">ID</th>
						<th scope="col">Document Group name</th>
						<th scope="col">Created by</th>
						<th scope="col">Status</th>
						<th scope="col">Action</th>
					</Fragment>
				}
				tableBody={
					<Fragment>
						{filteredForms?.length === 0 ||
						(documentGroup.status === true &&
							documentGroup?.data &&
							documentGroup?.data?.length === 0) ? (
							<tr>
								<td className="text-center opacity-50" colSpan={7}>
									There are no group document available at this time
								</td>
							</tr>
						) : (
							<Rows
								docs={
									filteredForms
										? filteredForms
										: documentGroup.status === true && documentGroup?.data
								}
							/>
						)}
					</Fragment>
				}
				// paginationProps={{
				// 	pageCount: Math.ceil(forms?.data?.count / perPage),
				// 	currentPage: parseInt(page),
				// 	setCurrentPage: moveTo,
				// 	perPage: perPage,
				// 	setPerPage: (pc) => {
				// 		changePerPage(pc);
				// 		// Move to first page
				// 		if (page !== 1) moveTo(1);
				// 	},
				// }}
				modals={<></>}
			/>

			<Modal
				title="Create Group Documents"
				bodyClass="px-5 py-4 text-center"
				trigger="requestJobDocumentsModal"
				showBtn={false}
				btnClass="requestJobDocumentsModalClose"
				size="lg"
			>
				<RequestJobDocumentForm
					closeClass="requestJobDocumentsModalClose"
					sendRequestHandle={(e) => handleRequest(e)}
					secondaryInputField={true}
					setGroupName={setGroupName}
					groupName={groupName}
					modalMessage={modalMessage}
					setmodalMessage={setmodalMessage}
					buttonText={"Create Document Group"}
					Heading={[{ heading: 'Name' }, { heading: 'Description' },{ heading: 'Edit' },{ heading: 'Delete' }]}
/>         
			</Modal>

			<Modal
				title="Group Documents"
				bodyClass="px-5 py-4 text-center"
				trigger="groupDocumentsModal"
				showBtn={false}
				btnClass="groupDocumentsModalClose"
				size="lg"
			>
				<GroupDocumentTable
					selectedGroupDocument={selectedGroupDocument}
					Heading={[{ heading: 'Name' }, { heading: 'Description' }]}
				/>
			</Modal>

			<Modal
				title="Change form status"
				bodyClass="p-4 text-center text-dark text-opacity-50"
				trigger="openChangeFormStatusModal"
				showBtn={false}
				btnClass="openChangeFormStatusModal"
			>
				<ChangeFormStatus
					setMessage={setMessage}
					formToChangeStatus={formToChangeStatus}
					setFormToChangeStatus={setFormToChangeStatus}
				/>
			</Modal>

			<Modal
				title="Edit Group Documents"
				bodyClass="px-5 py-4 text-center"
				trigger="editGroupDocumentsModal"
				showBtn={false}
				btnClass="editGroupDocumentsModalClose"
				size="lg"
			>
				<EditGroupDocumentTable
				editSave={editSave}
				editmodalMessage={editmodalMessage}
				seteditmodalMessage={seteditmodalMessage}
				selectedGroupDocument={selectedGroupDocument}
				setSelectedGroupDocument={setSelectedGroupDocument}
				Heading={[{ heading: 'Name' }, { heading: 'Description' },{ heading: 'Edit' },{ heading: 'Delete' }]}
				/>
			</Modal>
		</>
	);
};

export default DocumentsGroup;
