import { TAXFORMS_ACTION_TYPE } from "./Type";

const DEFAULT_STATE = {
	taxPayerFormData: [],
	forms: { message: "", status: "idle", data: {} },
	formsCreated: { message: "", status: "idle", data: {} },
	formDeleted: { message: "", status: "idle", data: {} },
	taxPayerForm: {
		data: {
			// 1040 Forms
		},
		message: "",
		state: "idle",
	},

	formInformation: null,
};

const INITIAL_STATE = {
	...DEFAULT_STATE,
};

const taxForms = (state = INITIAL_STATE, action) => {
	if (action.type === TAXFORMS_ACTION_TYPE.taxPayerForm) {
		return {
			...state,
			taxPayerForm: action.payload,
		};
	} else if (action.type === TAXFORMS_ACTION_TYPE.taxPayerFormIdle) {
		return {
			...state,
			taxPayerForm: {
				data: action.formObj,
				message: "",
				state: "idle",
			},
			taxPayerFormData: action.payload,
		};
	} else if (action.type === TAXFORMS_ACTION_TYPE.taxPayerFormData) {
		return {
			...state,
			taxPayerFormData: action.payload,
		};
	} else if (action.type === TAXFORMS_ACTION_TYPE.formInformation) {
		return {
			...state,
			formInformation: action.payload,
		};
	} else if (action.type === TAXFORMS_ACTION_TYPE.forms) {
		console.log(action.payload,"action.payload.data");
		return {
			...state,
			forms: action.payload,
		};
	}
	else if (action.type === TAXFORMS_ACTION_TYPE.formCreated) {
		return {
			...state,
			formsCreated: action.payload,
		};
	}
	else if (action.type === TAXFORMS_ACTION_TYPE.formDeleted) {
		return {
			...state,
			formDeleted: action.payload,
		};
	}

	return state;
};

export default taxForms;
